import { Pipe, PipeTransform } from '@angular/core';

import { UtilsService } from './../../common';

/**
 * Generated class for the LbsToKgPipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: 'lbsToKg',
})
export class LbsToKgPipe implements PipeTransform {
  /**
   * Takes a value and makes it lowercase.
   */
  constructor(private utilsService: UtilsService) {
  }

  transform(value: string, ...args) {
    return this.utilsService.lbsToKg(value);
  }
}
