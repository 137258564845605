import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataTransferService } from 'src/app/core/services/data-transfer/data-transfer.service';

@Component({
  selector: 'app-overlay-mode-switch',
  templateUrl: './overlay-mode-switch.component.html',
  styleUrls: ['./overlay-mode-switch.component.scss'],
})
export class OverlayModeSwitchComponent implements OnInit {
  loading: boolean;
  modeSwitchSubscription:Subscription;
  constructor( public dataTransferService: DataTransferService,) { }

  ngOnInit() {
    this.loading = this.dataTransferService.modeswitched;
     this.modeSwitchSubscription = this.dataTransferService.getModeSwitchStatus.subscribe((res: boolean) => { 
      setTimeout(() => {
        this.loading = res;
        this.dataTransferService.modeswitched = res;
      }, 100);   
    });
  }
  ngOnDestroy(){
    this.modeSwitchSubscription.unsubscribe();
  }

}
