import { Injectable } from '@angular/core';
import { ApiService } from '..';
import { Subject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { StorageService } from './authentication/storage.service';
import { map } from 'rxjs/operators';
import { ApiUrls } from 'src/app/shared/api-urls';
import { ENV } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentService extends ApiService{

  private openModalType = new Subject();
  modalType = this.openModalType.asObservable();
  isPopupClicked: Boolean = false;

  constructor(public http: HttpClient, public storage: StorageService) {
    super(http, storage);
  }

  getDocuments(params): Observable<any> {
    const url = `${ENV.patient_baseurl}${ApiUrls.PATIENT_SERVICE.patient_report}`;
    return this.get(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  setModalType(modalType: any) {
    this.openModalType.next(modalType);
  }


}
