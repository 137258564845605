import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Constants } from 'src/app/shared/constants';
import { ModalController, NavParams } from '@ionic/angular';
import { PatientService } from '../../components/patient/patient.service';
import { UtilsService } from 'src/app/shared/common';
import { AuthenticationService } from 'src/app/core';

@Component({
  selector: 'app-forgot-password-popup',
  templateUrl: './forgot-password-popup.page.html',
  styleUrls: ['./forgot-password-popup.page.scss'],
})
export class ForgotPasswordPopupPage implements OnInit {

  headerText: string;
  username: string;
  public confirmUsernameForm: FormGroup;
  cancelText = Constants.BUTTON_TEXT.cancel;
  submitText = Constants.BUTTON_TEXT.submit;
  okText = Constants.BUTTON_TEXT.okay;
  descriptionText: string = Constants.MESSAGE.PASSWORD_UPDATION.FORGOT_PWD_DESCRIPTION;
  isLoading = false;
  message: string;
  isSuccess: boolean;
  msgClass: string;

  constructor(
    private formBuilder: FormBuilder,
    public modalCtrl: ModalController,
    public navParams: NavParams,
    public utilService: UtilsService,
    public authService: AuthenticationService
  ) {
    this.username = this.navParams.get('username');
    this.headerText = 'Forgot password';
    this.initialiseForm();
  }

  ngOnInit() { }

  initialiseForm() {
    this.confirmUsernameForm = this.formBuilder.group({
      username: ['', Validators.compose([Validators.required])],
    },
    );
    this.confirmUsernameForm.patchValue({ username: this.username });
  }

  get enrolFormControl() {
    return this.confirmUsernameForm.controls;
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  submitUsername() {
    this.isLoading = true;
    const params = {
      user_name: this.confirmUsernameForm.value.username //.toLowerCase()
    };
    this.authService.initiateForgotPasswordEmail(params).subscribe(
      res => {
        if (res) {
          this.isSuccess = res.is_success;
          this.isLoading = false;
          this.message = res.message;
          this.msgClass = this.isSuccess ? 'success-msg' : 'error-msg';
          setTimeout(() => {
            this.isSuccess = undefined;
          }, 4000);
        }
      },
      err => {
        this.isLoading = false;
      }
    );

  }

}
