import { Constants } from 'src/app/shared/constants';
import * as _ from 'lodash';
import moment from 'moment';
import { UserInterface } from './user';
import { PhoneNumberPipe } from 'src/app/shared/pipes/phone-number-pipe/phone-number-pipe';

export interface EHRPatientAppoinmentInterface {
    medicardia_id: string,
    hospital: {
        id: string
    },
    patient_details: {
        mr_id: string,
        ehrid: string,
        demographics: {
            first_name: string,
            middle_name: string,
            last_name: string,
            dob: string,
            sex: string,
            race: string,
            marital_status: string,
            email_addresses: string,
            phone_number: {
                home: string,
                office: string,
                mobile: string
            },
            height: number,
            weight: number,
            reference_note: string,
            address: any
        }
    },
    appointment_details: {
        id: string | null,
        appointment_date: string,
        visit_type: string,
        appointment_status: string,
        provider: {
            user_id: string
        },
        appointment_data: {
            visit_date_time: string,
            patient_class: string,
            duration: string,
            reason: string,
            status: string,
            type: string,
            instructions: string,
            attending_provider: string | null,
            consulting_provider: string | null,
            referring_provider: string | null,
            visiting_provider: string | null,
            diagnosis: string | null,
            pcp: {
                ID: null | string,
                IDType: null | string,
                FirstName: string,
                LastName: string,
                Address: {
                    street_address: null | string,
                    city: null | string,
                    state: null | string,
                    zip: null | string,
                    county: null | string,
                    country: null | string
                },
                EmailAddresses: null | string,
                PhoneNumber: {
                    office: null | string,
                    home: null | string,
                    mobile: null | string
                }
            },
            guarantor: any
        }
    }
}


export interface EHRPatientAppoinmentFormInterface {
    medicardia_id: string,
    hospital: {
        id: string
    },
    mr_id: string,
    ehrid: string,
    first_name: string,
    middle_name: string,
    last_name: string,
    dob: any,
    ref_link_length: number
    gender: any,
    race: any,
    marital_status: any,
    email: string,
    phone_number: {
        home: string,
        office: string,
        mobile: string
    },
    height: number,
    weight: number,
    reference_note: string,
    appointmentDetails: {
        id: string | null,
        appointment_date: any,
        visit_type: any,
        appointment_status: any,
        provider: any
        duration: any,
        reason: string,
        pcp: any
    }
};

export class EHRPatientPatientFormClass {
    public static create(data, providerList, dataTransferService, timezone, isNewAppointment): EHRPatientAppoinmentFormInterface {
        let phonePipe = new PhoneNumberPipe();
        const isPastAppointment = isNewAppointment === false &&
            (new Date(new Date(data.appointment_date).toLocaleString("en-US", { timeZone: timezone })).valueOf() -
                new Date(new Date().toLocaleString("en-US", { timeZone: timezone })).valueOf() >= 0) ? false : true;
        const userData: UserInterface = dataTransferService.getUserData();
        const selectedProvider = dataTransferService.selectedProvider && !userData.isPhysician ?
            providerList.find(res => res.value === dataTransferService.selectedProvider.value) :
            (userData.user_id ? providerList.find(res => res.value === userData.user_id) : '');
        const defaultStatus = Constants.DROPDOWN_OPTIONS.APPOINTMENT_STATUS_OPTIONS.find(res => res.value === 'Scheduled');
        const defaultVisitType = Constants.DROPDOWN_OPTIONS.VISIT_TYPE_OPTIONS.find(res => res.value === 'In person');

        return {
            medicardia_id: data && data.medicardia_id ? data.medicardia_id : null,
            hospital: {
                id: dataTransferService.selectedLocation.id
            },
            mr_id: data.mr_id,
            ehrid: data.ehrid,
            first_name: data.first_name,
            middle_name: data.middle_name,
            last_name: data.last_name,
            dob: data.dob ? moment(data.dob).toDate() : null,
            ref_link_length: data.ref_link_length ? data.ref_link_length : null,
            gender: Constants.DROPDOWN_OPTIONS.GENDER.find(res => res.value === data.gender),
            race: Constants.DROPDOWN_OPTIONS.RACE.find(res => res.value === data.race),
            marital_status: Constants.DROPDOWN_OPTIONS.MARITAL_STATUS.find(res => res.value === data.marital_status),
            email: data.email,
            phone_number: {
                home: data.phone_number ? phonePipe.transform(data.phone_number.home) : '',
                mobile: data.phone_number ? phonePipe.transform(data.phone_number.mobile) : '',
                office: data.phone_number ? phonePipe.transform(data.phone_number.office) : '',
            },
            height: data.height ? data.height : '',
            weight: data.weight ? data.weight : '',
            reference_note: data.reference_note ? data.reference_note : '',
            appointmentDetails: {
                id: data.appointment_id && !isPastAppointment ? data.appointment_id : null,
                appointment_date: data.appointment_date && !isPastAppointment ?
                    new Date(new Date(data.appointment_date).toLocaleString("en-US", { timeZone: timezone })) : null,
                visit_type: data.visit_type && !isPastAppointment ? data.visit_type : defaultVisitType,
                appointment_status: data.status && !isPastAppointment ? data.status : defaultStatus,
                provider: selectedProvider,
                duration: data.duration && !isPastAppointment ?
                    Constants.DROPDOWN_OPTIONS.DURATIONS.find(res => res.value === data.duration) : null,
                reason: data.fu_visit && !isPastAppointment ? data.fu_visit : null,
                pcp: data.pcp && !isPastAppointment ? providerList.find(res => res.NPI_id == data.pcp) : null
            }
        };
    }
}



export class EHRPatientAppoinmentClass {
    public static create(data, timezone?): EHRPatientAppoinmentInterface {
        let address = undefined;
        if(data.demographic !==undefined && data.demographic.address){
            address = data.demographic.address;
            address.zip = address.postalcode?address.postalcode:'';
            address.street_address = address.line?address.line:'';
        }
        return {
            medicardia_id: data && data.medicardia_id ? data.medicardia_id : null,
            hospital: {
                id: data.hospital && data.hospital.id ? data.hospital.id : data.hospital
            },
            patient_details: {
                mr_id: data.mr_id ? data.mr_id : null,
                ehrid: data.mr_id ? data.mr_id : null,
                demographics: {
                    first_name: data.f_name ? data.f_name : null,
                    middle_name: data.m_name ? data.m_name : null,
                    last_name: data.l_name ? data.l_name : null,
                    dob: data.dob ? moment(data.dob).format('YYYY-MM-DD') : '',
                    sex: data.gender && data.gender.value ? data.gender.value : (data.demographic !==undefined && data.demographic.sex?data.demographic.sex:''),
                    race: data.race && data.race.value ? data.race.value : (data.demographic !==undefined && data.demographic.race?data.demographic.race:''),
                    marital_status: data.marital_status && data.marital_status.value ? data.marital_status.value : (data.demographic !==undefined && data.demographic.marital_status?data.demographic.marital_status:''),
                    email_addresses: data.email ? data.email : (data.demographic !==undefined && data.demographic.email_addresses?data.demographic.email_addresses:''),
                    phone_number: {
                        home: data.phone_number && data.phone_number.home ? data.phone_number.home.replace(/[^0-9]/g, '') : (data.demographic !==undefined && data.demographic.phone_number !== undefined && data.demographic.phone_number.home?data.demographic.phone_number.home.replace(/[^0-9]/g, ''):null),
                        office: data.phone_number && data.phone_number.office ? data.phone_number.office.replace(/[^0-9]/g, '') : (data.demographic !==undefined && data.demographic.phone_number !== undefined && data.demographic.phone_number.office?data.demographic.phone_number.office.replace(/[^0-9]/g, ''):null),
                        mobile: data.phone_number && data.phone_number.mobile ? data.phone_number.mobile.replace(/[^0-9]/g, '') : (data.demographic !==undefined && data.demographic.phone_number !== undefined && data.demographic.phone_number.mobile?data.demographic.phone_number.mobile.replace(/[^0-9]/g, ''):null)
                    },
                    height: data.height ? data.height : (data.demographic !==undefined && data.demographic.height?data.demographic.height:''),
                    weight: data.weight ? data.weight : (data.demographic !==undefined && data.demographic.weight?data.demographic.weight:''),
                    reference_note: data.reference_note ? data.reference_note : null,
                    address: address
                }
            },
            appointment_details: {
                id: data && data.appointmentDetails && data.appointmentDetails.id ? data.appointmentDetails.id : null,
                appointment_date: data && data.appointmentDetails && data.appointmentDetails.appointment_date ?
                    moment(data.appointmentDetails.appointment_date).format('YYYY-MM-DDTHH:mm:ss') + 'Z' : null,
                visit_type: data && data.appointmentDetails && data.appointmentDetails.visit_type &&
                    data.appointmentDetails.visit_type.value ?
                    (data.appointmentDetails.appointment_status.value === 'Cancelled' ? Constants.APPOINTMENT_TYPES.IN_PERSON :
                        data.appointmentDetails.visit_type.value) : null,
                appointment_status: data && data.appointmentDetails && data.appointmentDetails.appointment_status &&
                    data.appointmentDetails.appointment_status.value ? data.appointmentDetails.appointment_status.value : null,
                provider: {
                    user_id: data && data.appointmentDetails && data.appointmentDetails.provider &&
                        data.appointmentDetails.provider.value ? data.appointmentDetails.provider.value : null,
                },
                appointment_data: {
                    visit_date_time: "",
                    patient_class: "",
                    duration: data && data.appointmentDetails && data.appointmentDetails.duration &&
                        data.appointmentDetails.duration.value ? data.appointmentDetails.duration.value : '',
                    reason: data && data.appointmentDetails && data.appointmentDetails.reason ? data.appointmentDetails.reason : '',
                    status: "",
                    type: "",
                    instructions: "",
                    attending_provider: null,
                    consulting_provider: null,
                    referring_provider: null,
                    visiting_provider: null,
                    diagnosis: null,
                    pcp: {
                        ID: data.appointmentDetails && data.appointmentDetails.pcp &&
                            data.appointmentDetails.pcp.NPI_id ? data.appointmentDetails.pcp.NPI_id : null,
                        IDType: "NPI",
                        FirstName: data && data.appointmentDetails && data.appointmentDetails.pcp &&
                            data.appointmentDetails.pcp.first_name ? data.appointmentDetails.pcp.first_name : null,
                        LastName: data && data.appointmentDetails && data.appointmentDetails.pcp &&
                            data.appointmentDetails.pcp.last_name ? data.appointmentDetails.pcp.last_name : null,
                        Address: {
                            street_address: null,
                            city: null,
                            state: null,
                            zip: null,
                            county: null,
                            country: null
                        },
                        EmailAddresses: null,
                        PhoneNumber: {
                            office: null,
                            home: null,
                            mobile: null
                        }
                    },
                    guarantor: {}
                }
            }
        };
    }
}