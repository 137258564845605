import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appextentionSlice]',
})
export class extentionSliceDirective {
  constructor(private el: ElementRef, private control: NgControl) {
  }

  @HostListener('input')
  onChange() {
    let inputVal = (this.el.nativeElement).value;
    inputVal = inputVal ? inputVal.replace(/[^0-9]/g, '') : '';
    return parseInt(inputVal,10); 
  }
}
// function retnum(str) { 
//     var num = str.replace(/[^0-9]/g, ''); 
//     return parseInt(num,10); 
// }