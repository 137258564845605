import { Component, OnInit, ViewChild } from '@angular/core';
import { UserIdleService } from 'angular-user-idle';
import { CountdownComponent } from 'ngx-countdown';
import { ModalController, NavController } from '@ionic/angular';
import { Constants } from 'src/app/shared/constants';
import { ConfigurationService } from 'src/app/core/services/configuration/configuration.service';
import { RpmdashboardService } from '../../components/rpmdashboard/rpmdashboard.service';
import { RpmMultiTimerService } from 'src/app/core/services/rpmmultitimer/rpmmultitimer.service';
import { PatientService } from '../../components/patient/patient.service';

@Component({
  selector: 'app-user-inactivity-manager-modal',
  templateUrl: './rpm-dashboard-inactivity-modal.page.html',
  styleUrls: ['./rpm-dashboard-inactivity-modal.page.scss'],
})
export class RpmDashboardInactivityModalPage implements OnInit {

  public timerCount: any;
  public infoText: string;
  isTimeUp = false;
  timeLeft = Constants.USER_INACTIVITY.COUNT_DOWN;
  @ViewChild('countdown', { static: false }) counter: CountdownComponent;

  constructor(
    private userIdle: UserIdleService,
    public modalCtrl: ModalController,
    private navCtrl: NavController,
    private confirgurationService: ConfigurationService,
    private rpmdashboardService: RpmdashboardService,
    private multiTimerService: RpmMultiTimerService,
    public patientService: PatientService
  ) { }

  ngOnInit() {
    const time = this.confirgurationService.getConfigurationDetails() &&
      this.confirgurationService.getConfigurationDetails().rpmTimeout ?
      this.confirgurationService.getConfigurationDetails().rpmTimeout / 60 : 1;
    this.infoText = `You have been idle for ${time} minute , timer in ${this.patientService.workflow.toUpperCase()} will get stopped in`;
  }

  finishTest(e) {
    if (e.action === 'done') {
      this.isTimeUp = true;
      this.multiTimerService.pauseTimer();
    }
  }

  resetTimer() {
    this.counter.restart();
  }

  OnCancel() {
    this.closeModal();
    if (this.isTimeUp) {
      // redirect  to appointment screen
      this.navCtrl.navigateRoot('appointments');

    } else {
      this.userIdle.stopWatching();
      this.rpmdashboardService.checkForUserInactivity();
    }
  }

  OnResetTimer() {
    this.closeModal();
    console.log("OnResetTimer", this.isTimeUp);
    if (this.isTimeUp) {
      this.multiTimerService.startTimer();
      this.rpmdashboardService.checkForUserInactivity();
    } else {
    }
  }

  closeModal(data = {}) {
    this.modalCtrl.dismiss(data);
  }

}
