import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appNoWhiteSpace]'
})
export class NoWhiteSpaceDirective {

  constructor(private el: ElementRef, private control: NgControl,
  ) { }

  @HostListener('input', ['$event'])
  onChange($event) {
    const inputVal = (this.el.nativeElement).value;
    const trimValue = typeof (inputVal) === 'number' ? inputVal : (inputVal).toString().trim();
    if (trimValue === '') {
      this.control.control.setValue('');
    }
  }
}
