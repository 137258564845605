import { Injectable, EventEmitter } from '@angular/core';
import moment from 'moment';
import { PatientService } from 'src/app/modules/components/patient/patient.service';
import { Constants } from 'src/app/shared/constants';
import { ConfigurationInterface } from '../../models/configuration';
import { ConfigurationService } from '../configuration/configuration.service';
@Injectable({
  providedIn: 'root'
})

export class TimerService {
  counter: number = 0;
  counterNonStp: number = 0;
  timerLoop: any;
  timerLoopNonstop: any;
  isTimerRunning = false;
  isRpmTimerRunning = false;
  rpmTimerKey: any = 0;
  checkCounter: EventEmitter<any> = new EventEmitter();
  syncFrequency: EventEmitter<any> = new EventEmitter();
  autoSave: EventEmitter<any> = new EventEmitter();
  syncCareplanData: EventEmitter<any> = new EventEmitter();
  pausedTime: number;
  restartedTime: number;
  rpmAutoUpdateBillTime = Constants.RPM_UPDATE_BILL_COUNTS;
  isRpm = this.patientService.workflow == Constants.WORKFLOW.RPM;
  timezone: any;
  constructor(
    public configurationService: ConfigurationService,
    public patientService: PatientService,
  ) {
    this.timezone = this.configurationService.getConfigurationDetails().hospitalTimezone; // hospital timezone
  }

  startTimer() {
    this.startNonStopTimer();
    this.isTimerRunning = true;
    let counter = this.counter;
    if (this.timerLoop) {
      clearInterval(this.timerLoop);
    }
    this.timerLoop = setInterval(function () {
      if (this.isRpmTimerRunning) {
        this.counter = counter + 1;
        counter = this.counter;
        this.rpmTimerKey = this.rpmTimerKey + 1;
        if ((this.rpmTimerKey % 600) == 0) { // trigger the call on every 10 minuetes instead of 20 minutes as it there is 30 min billing and 20 min billings
          this.getBillDetails();
        }
      }
    }.bind(this), 1000);
  }

  /**
   * Function to run a nonstop timer for other sync activities
   * This cannot be included in the above timer as the above timer can pause in between.
   */
  startNonStopTimer(){
    let counter = this.counterNonStp;
    if (this.timerLoopNonstop) {
      clearInterval(this.timerLoopNonstop);
    }
    this.timerLoopNonstop = setInterval(function () {
      this.counterNonStp = counter + 1;
      counter = this.counterNonStp;
      let now = moment().tz(this.timezone).format('HH:mm:ss'); // time in the hospital timezone
      //console.log(this.timezone,' :' ,now)
      if(now == "23:59:58"){ // on day change save the activity automatically
        this.getBillDetails();
      }
      this.syncAndAutoSave();
    }.bind(this), 1000);
  }

  resetTimer(resetValue) {
    let totalTime = 0;
    if (resetValue != 0) {
      let time = resetValue.split(':');
      totalTime = this.getTimeinSecond(time[0], time[1], time[2]);
    }
    this.counter = totalTime;
  }

  getTimeinString(timeConvert) {
    let timeToConvert = timeConvert ? timeConvert : this.counter;
    if (timeConvert != 0) {
      const minutes: number = Math.floor(timeToConvert / 60);
      return (minutes != 0 ? (minutes < 10 ? ('0' + minutes) : minutes) : '00') + ':' + ((timeToConvert - minutes * 60) < 10 ? '0' + (timeToConvert - minutes * 60) : (timeToConvert - minutes * 60));
    } else {
      return ('00:00');
    }
  }

  getTimeinSecond(hour, min, sec) {
    let total_time = parseInt(hour) * 3600 + parseInt(min) * 60 + parseInt(sec);
    //console.log("Time in seconds", total_time);
    return total_time;
  }
  getRpmTimerValue() {
    return this.getTimeinString(this.rpmTimerKey);
  }
  setRpmTimerKey(timerKey) {
    if (timerKey != 0) {
      let time = timerKey.split(':');
      this.rpmTimerKey = this.getTimeinSecond(time[0], time[1], time[2]);
    }
  }
  pauseTimer() {
    if (this.timerLoop) {
      clearInterval(this.timerLoop);
      this.isTimerRunning = false;
    }
  }

  getExternlTimeInSeconds(timekey){
    //console.log(timekey);
      let time = timekey.split(':');
      return this.getTimeinSecond(0, time[0], time[1]);
  }

  getBillDetails() {
    this.checkCounter.emit(true);
  }

  syncAndAutoSave() {
    const configurationDetails: ConfigurationInterface = this.configurationService.getConfigurationDetails();
    if (configurationDetails && configurationDetails.syncFrequency !== undefined &&
      this.counterNonStp % configurationDetails.syncFrequency === 0) {
      this.syncFrequency.emit(true);
    }
    if (configurationDetails && configurationDetails.autoSave !== undefined &&
      this.counterNonStp % configurationDetails.autoSave === 0) {
      this.autoSave.emit(true);
    }
  }

  addToRpmTimerKey(externalTime) {
    if (externalTime != 0 && (externalTime != '00:00' || externalTime != '00:00:00')) {
      let newTime = externalTime.split(':');
      if(newTime.length > 2){
        this.rpmTimerKey = this.getTimeinSecond(newTime[0], newTime[1], newTime[2]) + this.rpmTimerKey;
      }else{
        this.rpmTimerKey = this.getTimeinSecond(0, newTime[0], newTime[1]) + this.rpmTimerKey;
      }
    }
  }

  rpmTimerPaused() {
    this.isRpmTimerRunning = false;
    this.pausedTime = this.counter;
  }
  rpmTimerStarted() {
    this.isRpmTimerRunning = true;
  }

  subtractToTimerKey() {
    let rpmTimerKeySplit = this.rpmTimerKey.split(':');
    let total_time = this.getTimeinSecond(rpmTimerKeySplit[0], rpmTimerKeySplit[1], rpmTimerKeySplit[21]);
    this.rpmTimerKey = this.getTimeinString(total_time + this.pausedTime - this.counter);
    this.isRpmTimerRunning = true;
  }

  /**
   * FUnction will trigger the CPT code and activity data call subscription on care plan page
   */
  triggerCareplanSync(){
    this.syncCareplanData.emit(true);
  }
}
