import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CalendarModule } from 'primeng/calendar';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { DropdownModule } from 'primeng/dropdown';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { LoaderModule } from 'src/app/shared/components/loader/loader.module';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AddEditEcgComponent } from './add-edit-ecg.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';



@NgModule({
  declarations: [AddEditEcgComponent],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    CalendarModule,
    PipesModule,
    DropdownModule,
    DirectivesModule,
    LoaderModule,
    AutoCompleteModule,
    PerfectScrollbarModule
  ],
  exports: [AddEditEcgComponent]
})
export class AddEditEcgModule { }
