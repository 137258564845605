import { Environment } from './environment.model';
import { build_version } from 'package.json';
import  *  as  instanceParam  from  './../assets/config/instance.json';

export const ENV: Environment = {
  mode: 'Development',
  url: 'http://localhost:4200/',
  non_ehr_mode: false,
  version: build_version[0].dev,
  production: false,
  isTwilioEnhanced: instanceParam.ISTWILIOENHANCED,
  patient_baseurl: instanceParam.PATIENT_BASEURL,
  appointment_baseurl: instanceParam.APPOINTMENT_BASEURL,
  auth_baseurl: instanceParam.AUTH_BASEURL,
  healthstate_baseurl: instanceParam.HEALTHSTATE_BASEURL,
  telehealth_baseurl: instanceParam.TELEHEALTH_BASEURL,
  media_baseUrl: instanceParam.MEDIA_BASEURL,
  rpm_baseurl: instanceParam.RPM_BASEURL,
  admin_baseurl: instanceParam.ADMIN_BASEURL,
  ehr_baseurl: instanceParam.EHR_BASEURL,
  idc_baseurl: instanceParam.IDC_BASEURL,
  fhir_baseurl: instanceParam.FHIR_BASEURL
};
