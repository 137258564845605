import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { DemographicDataModalPage } from './demographic-data-modal.page';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PipesModule,
    NgbModule,
    PerfectScrollbarModule
  ],
  declarations: [DemographicDataModalPage],
  entryComponents: [
    DemographicDataModalPage
  ]
})
export class DemographicDataModalPageModule {}
