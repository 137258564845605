import { Component, HostListener } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthenticationService } from './core';
import { UtilsService } from './shared/common';
import { CookieService } from 'ngx-cookie-service';
import { LocationStrategy } from '@angular/common';
import { StorageService } from './core/services/authentication/storage.service';


interface FsDocument extends HTMLDocument {
  mozFullScreenElement?: Element;
  msFullscreenElement?: Element;
  msExitFullscreen?: () => void;
  mozCancelFullScreen?: () => void;
  webkitExitFullscreen?: () => void;
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  isFullScreen = false;
  themeKey = 'light';

  @HostListener('fullscreenchange', ['$event'])
  @HostListener('webkitfullscreenchange', ['$event'])
  @HostListener('mozfullscreenchange', ['$event'])
  @HostListener('MSFullscreenChange', ['$event'])
  screenChange(event) {
    this.isFullScreen = document.fullscreenElement === null ? false : true;
    if (this.authService.isFullScreen !== this.isFullScreen) {
      this.authService.setIsFullScreenModeEnabled(this.isFullScreen);
    }
  }
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public authService: AuthenticationService,
    public utilService: UtilsService,
    private cookieService: CookieService,
    private location: LocationStrategy,
    public storageService: StorageService,
  ) {
    this.initializeApp();
    const themeCookieValue = this.storageService.getStorageItem(localStorage, '_tks', true); //this.cookieService.get('themeKey');
    this.themeKey = themeCookieValue != '' && themeCookieValue != null ? themeCookieValue : this.utilService.themeKey;
    document.body.classList.add(this.themeKey+'-theme'); // adding this to fix the popups adding under the body tag
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
        this.statusBar.styleDefault();
        this.splashScreen.hide();
      }
      this.subscribeFullscreenMode();
    });
  }

  subscribeFullscreenMode() {
    this.authService.getIsFullScreenModeEnabled.subscribe(status => {
      this.isFullScreen = status;
      const fsDoc = <FsDocument>document;
      const el: any = document.documentElement;
      if (status) {
        if (el.requestFullscreen) {
          el.requestFullscreen();
        } else if (el.mozRequestFullScreen) {
          el.mozRequestFullScreen();
        } else if (el.webkitRequestFullscreen) {
          el.webkitRequestFullscreen();
        } else if (el.msRequestFullscreen) {
          el.msRequestFullscreen();
        }
      } else {
        if (fsDoc.exitFullscreen) {
          fsDoc.exitFullscreen();
        } else if (fsDoc.mozCancelFullScreen) { /* Firefox */
          fsDoc.mozCancelFullScreen();
        } else if (fsDoc.webkitExitFullscreen) { /* Chrome, Safari and Opera */
          fsDoc.webkitExitFullscreen();
        } else if (fsDoc.msExitFullscreen) { /* IE/Edge */
          fsDoc.msExitFullscreen();
        }
      }
    });
  }
}
