import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ForgotPasswordPopupPage } from './forgot-password-popup.page';
import { LoaderModule } from 'src/app/shared/components/loader/loader.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    LoaderModule
  ],
  declarations: [ForgotPasswordPopupPage],
  exports: [
    ForgotPasswordPopupPage
  ]
})
export class ForgotPasswordPopupPageModule {}
