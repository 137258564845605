import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dropdown',
})
export class DropdownPipe implements PipeTransform {

  transform(value: any, selectedValues) {
    let previousChosen = [];
    const result = [];
    const valvularOptions = value;
    const valvularDataChosen: any[] = selectedValues; // formData
    previousChosen = valvularDataChosen.map(obj => obj.Valvular_abnormality).filter(el => {
      return el !== '';
    });
    valvularOptions.forEach(option => {
      if ((previousChosen.indexOf(option.value) === -1)
        && (!(previousChosen.length > 0 && previousChosen.indexOf('None') === -1 && option.value === 'None'))) {
        result.push(option);
      }
    });
    return result;
  }
}
