import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ENV } from '../../../../environments/environment';


/**
 * Prefixes all requests not starting with `http[s]` with `environment.serverUrl`.
 */
@Injectable({
  providedIn: 'root'
})
export class ApiPrefixInterceptor implements HttpInterceptor {

  getCleanedParams(params) {
    let cleanedParams = new HttpParams();
    for (const item in params) {
      if (undefined !== params[item]) {
        cleanedParams = cleanedParams.append(item, params[item]);
      }
    }
    return cleanedParams;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!/^(http|https):/i.test(request.url)) {
      request = request.clone({
        body: this.getCleanedParams(request.params),
        url: request.url });
    }
    return next.handle(request);
  }
}
