import { Component, OnInit, OnChanges, Input, SimpleChanges, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Constants } from 'src/app/shared/constants';
import { ModalController } from '@ionic/angular';
import { UtilsService } from 'src/app/shared/common';
import { PatientService } from '../../patient.service';
import { VitalsModalPage } from 'src/app/modules/modals/vitals-modal/vitals-modal.page';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/core';
import { VitalsModel } from 'src/app/core/models/vitals';
import { HeaderService } from 'src/app/shared/components/header/header.service';
import { StorageService } from 'src/app/core/services/authentication/storage.service';
import { AppointmentsService } from '../../../appointments/appointments.service';
import { DataTransferService } from 'src/app/core/services/data-transfer/data-transfer.service';
import { ConfigurationService } from 'src/app/core/services/configuration/configuration.service';


@Component({
  selector: 'app-patient-vital-info',
  templateUrl: './patient-vital-info.component.html',
  styleUrls: ['./patient-vital-info.component.scss'],
})
export class PatientVitalInfoComponent implements OnInit, OnChanges, OnDestroy {

  @Input() patientData = {};
  isDataScreenVisited = false;
  public timezone: string;

  permissionCheckType_EHR = Constants.PERMISSION_CHECK_TYPE.EHR;
  rightsideComponent: any;
  componentTypes = Constants.COMPONENTS;
  vitalInfo = {};
  vitalData = [];
  selectedVitals = [];
  colors = Constants.COLORS.TREND_CHART;
  isPopupClicked: boolean;
  vitalsPageModal: any;
  selectedTrendCount: number;
  isParentInitiated = false;
  isPatientDataLoaded = false;
  permission: any;
  patientId: string;
  patientInfo: any;
  ehrLastSync = '';
  ehrSyncStatus = '';

  // Components
  trendComponent: string = Constants.COMPONENTS.TREND;
  trendTypes: any = Constants.TREND;
  vitalsComponent: string = Constants.COMPONENTS.VITALINFO;
  discardConsentSubscription: Subscription;
  getVitalUpdateModeSubscription: Subscription;
  headerSubscription: Subscription;
  vitalSelectionSubscription: Subscription; 
  isRpm = this.patientService.workflow == Constants.WORKFLOW.RPM;

  public careProgramTypes = Constants.CARE_TYPES;
  public is_care_managemnt = this.careProgramTypes.includes(this.patientService.workflow) ? true : false;

  is_ehr: any;
  constructor(
    public headerService:HeaderService,
    public authService: AuthenticationService,
    public storageService: StorageService,
    public modalCtrl: ModalController,
    public appointmentService: AppointmentsService,
    public utilsService: UtilsService,
    private patientService: PatientService,
    public configurationService: ConfigurationService,
    public dataTransferService: DataTransferService,
    private changeDetector: ChangeDetectorRef) {
    this.isPopupClicked = false;
    this.selectedTrendCount = 0;
    this.timezone = this.configurationService.getConfigurationDetails().hospitalTimezone;

    this.headerSubscription = this.headerService.getModeChangeFromDetailsPageEmitter().subscribe(params => {
      const appointIdFromSession = (this.storageService.getStorageItem(sessionStorage, 'userData', true) 
      && this.storageService.getStorageItem(sessionStorage, 'userData', true).appointmentId) ?
      this.storageService.getStorageItem(sessionStorage, 'userData', true).appointmentId: '';
      if(appointIdFromSession) {
        this.selectedVitals = [];
      }
      if(params && params.navFrom == 'rpm' && params.navTo== 'clinical') {
        this.isRpm = false;
      } else if(params && params.navFrom == 'clinical' && params.navTo == 'rpm') {
        this.isRpm = true;
      }
      this.is_care_managemnt = this.careProgramTypes.includes(this.patientService.workflow) ? true : false;
    });
  }

  ngOnInit() {
    if (0 === this.vitalData.length) {
      this.initVitalComponent();
    }
    this.subscribeChanges();
    this.subscribeVitalTrendUpdate();
    this.is_ehr = this.configurationService.getIsEHRAvailable();
  }

  ngOnChanges(changes: SimpleChanges) {
    const PATIENTDATA = 'patientData';
    for (const propName in changes) {
      if (propName) {
        const change = changes[propName];
        const curVal = change.currentValue;
        const preVal = change.previousValue;
        const strCurVal = JSON.stringify(curVal);
        const strPrevVal = JSON.stringify(preVal);
        if (strCurVal !== strPrevVal && strCurVal) {
          switch (propName) {
            case PATIENTDATA:
              if (curVal.patientInfo) {
                this.permission = this.getPermissions();
                this.vitalInfo = this.patientData ? this.utilsService.deepCopy(this.patientData['vitals']) : {};
                this.patientInfo = this.patientData ? this.utilsService.deepCopy(this.patientData['patientInfo']) : {};
                this.isPatientDataLoaded = !this.utilsService.isEmpty(this.patientInfo);
                if (this.vitalInfo) {
                  this.patientId = this.vitalInfo['id'];
                  this.getVitalData();                  
                  if(curVal && preVal == undefined && this.isRpm && this.patientService.activeTab == this.componentTypes.DATA){
                    this.checkCondition(Constants.VITALS_ACTION.PARENT_CLICK, true);
                    //  this.patientService.setVitalUpdateMode(Constants.VITALS_ACTION.PARENT_CLICK);
                  }
                }
                // EHR sync status
                if(this.patientInfo.process !== undefined 
                  && this.patientInfo.process.patient_chart_pull !== undefined
                  && this.patientInfo.process.patient_chart_pull.process_status !== undefined){
                    this.ehrSyncStatus = this.patientInfo.process.patient_chart_pull.process_status;
                    if(this.patientInfo.process.patient_chart_pull.last_updated_at !== undefined
                      && this.patientInfo.process.patient_chart_pull.last_updated_at !== null){
                        this.ehrLastSync = this.patientInfo.process.patient_chart_pull.last_updated_at['$date'] !== undefined?this.patientInfo.process.patient_chart_pull.last_updated_at['$date']:'';
                    }
                }
              }
          }
        }
      }
    }
  }

  getPermissions() {
    return {
      vital: {
        feature_name: Constants.PERMISSION_FEAUTRE_NAME.Vitals,
        action: Constants.PERMISSION_ACTION_TEXT.write
      },
      Summary_Review: {
        feature_name: Constants.PERMISSION_FEAUTRE_NAME.Summary_Review,
        action: Constants.PERMISSION_ACTION_TEXT.write
      }
    };
  }


  async openVitalsModal() {
    this.isPopupClicked = true;
    const params: any = {
      patientId: this.patientId,
      // visitNumber: this.patientInfo['visit_number'] ? this.patientInfo['visit_number'] : undefined
    };
    params.vitalsReport = this.vitalData;
    this.vitalsPageModal = await this.modalCtrl.create({
      component: VitalsModalPage,
      backdropDismiss: false,
      componentProps: params, cssClass: 'vitals-page'
    });
    this.vitalsPageModal.onDidDismiss().then(status => {
      this.isPopupClicked = false;
      this.utilsService.changeDetected(this.vitalsComponent, false);
      delete status.role;
      if (status.data && status !== undefined) {
        this.patientService.emitRiskCalcChangeEvent();
      }
    });
    return await this.vitalsPageModal.present();
  }

  updateVitalData() {
    const params = {
      id: this.patientId,
      duration: 0,
      data: 'recent'
    }
    this.patientService.getVitalsInfo(params)
      .subscribe(
        res => {
          if (res.is_success) {
            this.vitalInfo = VitalsModel.create({ vitals: res.data });
            this.vitalInfo['id'] = params.id;
            this.getVitalData();
            this.patientService.emitVitalChangeEvent(this.vitalInfo);
          }
        },
        err => err
      );
  }

  /**
   * @description To initialize the component with basic details
   *              Data will be binded after API call response
   */
  initVitalComponent() {
    this.vitalData = [
      {
        name: Constants.TREND.BP.TITLE,
        firstValUnit: 'mmHg',
        val: '',
        valText: 'SBP',
        isHigh: false,
        isSelected: false,
        secondValUnit: 'DBP',
        secondVal: '',
        trend: Constants.TREND.BP.PARAM,
        changes: [],
        trendCount: 1, //2
        tooltipText: 'Blood Pressure',
        isHidden: false
      },
      {
        name: Constants.TREND.PULSE.TITLE,
        firstValUnit: 'bpm',
        val: undefined,
        valText: '',
        isHigh: false,
        isSelected: false,
        secondValUnit: '',
        secondVal: '',
        trend: Constants.TREND.PULSE.PARAM,
        changes: [],
        trendCount: 1,
        tooltipText: 'Heart Rate',
        isHidden: false
      },
      {
        name: Constants.TREND.PULSE_OX.TITLE,
        firstValUnit: 'perc',
        val: '',
        valText: '',
        isHigh: true,
        isSelected: false,
        secondValUnit: 'RR',
        secondVal: '',
        trend: Constants.TREND.PULSE_OX.PARAM,
        changes: [],
        trendCount: 1, //2
        tooltipText: 'PulseOx',
        isHidden: false
      },
      {
        name: Constants.TREND.BMI.TITLE,
        firstValUnit: 'Kg/m<sup>2</sup>',
        val: '',
        valText: '',
        isHigh: false,
        isSelected: false,
        secondValUnit: 'lbs',
        // secondValUnit: 'Kg',
        secondVal: '',
        trend: Constants.TREND.BMI.PARAM,
        changes: [],
        trendCount: 1, //2
        tooltipText: 'Body Mass Index',
        isHidden: false
      },
      {
        name: Constants.TREND.TEMP.TITLE,
        firstValUnit: '°F',
        val: '',
        valText: '',
        isHigh: false,
        isSelected: false,
        secondValUnit: '',
        secondVal: '',
        trend: Constants.TREND.TEMP.PARAM,
        changes: [],
        trendCount: 1, // 3
        tooltipText: 'Temperature',
        isHidden: false
      },
    ];
    this.changeDetector.detectChanges();
  }

  getVitalData() {
    const BP = this.vitalInfo['BP'];
    const BMI = this.vitalInfo['BMI'];
    const PULSE = this.vitalInfo['PULSE'];
    const PULSE_OX = this.vitalInfo['PulseOx'];
    const TEMP = this.vitalInfo['TEMP'];

    if (this.vitalData.length === 0) {
      this.initVitalComponent();
    }
    if (BP) {
      this.vitalData[0].val = BP['sys'] !== undefined ? BP['sys'] : 'NA';
      this.vitalData[0].secondVal = BP['dias'] !== undefined ? BP['dias'] : 'NA';
      this.vitalData[0].changes = [this.vitalInfo['sbp_magnitude'], this.vitalInfo['dbp_magnitude']]
      this.vitalData[0].isHidden = BP['is_hidden'] !== undefined ? BP['is_hidden'] : false;
    }

    if (PULSE) {
      this.vitalData[1].secondVal = PULSE['bpm'] !== undefined ? PULSE['bpm'] : 'NA';
      this.vitalData[1].changes = ['', this.vitalInfo['pulse_magnitude']];
      this.vitalData[1].isHidden = PULSE['is_hidden'] !== undefined ? PULSE['is_hidden'] : false;
    }

    if (PULSE_OX) {
      this.vitalData[2].val = PULSE_OX['perc'] !== undefined ? PULSE_OX['perc'] : 'NA';
      this.vitalData[2].secondVal = PULSE_OX['brpm'] !== undefined ? PULSE_OX['brpm'] : 'NA';
      this.vitalData[2].changes = [this.vitalInfo['pulse_ox_magnitude'], this.vitalInfo['pulseox_brpm_magnitude']];
      this.vitalData[2].isHidden = PULSE_OX['is_hidden'] !== undefined ? PULSE_OX['is_hidden'] : false;
    }

    if (BMI) {
      this.vitalData[3].height = this.vitalInfo['height'] !== undefined ? this.vitalInfo['height'] : '';
      this.vitalData[3].val = BMI['perc'] !== undefined ? BMI['perc'] : 'NA';
      this.vitalData[3].secondVal = BMI['kg'] !== undefined ? BMI['kg'] : 'NA';
      this.vitalData[3].changes = [this.vitalInfo['bmi_magnitude'], this.vitalInfo['weight_magnitude']];
      this.vitalData[3].isHidden = BMI['is_hidden'] !== undefined ? BMI['is_hidden'] : false;
    }

    if (TEMP) {
      let TEMP_LOC = TEMP['locations'] != undefined ? TEMP['locations'] : {};
      let keysNames = Object.keys(TEMP_LOC);

      this.vitalData[4].val = TEMP_LOC[keysNames[0]] && TEMP_LOC[keysNames[0]]['value'] !== undefined && TEMP_LOC[keysNames[0]]['value'] != null ? parseFloat(TEMP_LOC[keysNames[0]]['value']).toFixed(1) : 'NA';
      this.vitalData[4].val = TEMP_LOC[keysNames[1]] && TEMP_LOC[keysNames[1]]['value'] !== undefined && TEMP_LOC[keysNames[1]]['value'] != null  ? this.vitalData[4].val +'/'+ parseFloat(TEMP_LOC[keysNames[1]]['value']).toFixed(1) : this.vitalData[4].val;
      this.vitalData[4].val = TEMP_LOC[keysNames[2]] && TEMP_LOC[keysNames[2]]['value'] !== undefined && TEMP_LOC[keysNames[2]]['value'] != null  ? this.vitalData[4].val +'/'+ parseFloat(TEMP_LOC[keysNames[2]]['value']).toFixed(1) : this.vitalData[4].val;
      this.vitalData[4].secondVal = '';//TEMP_LOC[keysNames[2]]['value'] !== undefined && TEMP_LOC[keysNames[2]]['value'] != null  ? TEMP_LOC[keysNames[2]]['value'] : 'NA';
      //this.vitalData[4].thirdVal = TEMP_LOC[keysNames[2]]['value'] !== undefined && TEMP_LOC[keysNames[2]]['value'] != null  ? TEMP_LOC[keysNames[2]]['value'] : 'NA';
      this.vitalData[4].changes = ['']; 
      this.vitalData[4].isHidden = TEMP['is_hidden'] !== undefined ? TEMP['is_hidden'] : false;
    }

    this.vitalData[0].isHigh = this.vitalInfo['bp_change'];
    this.vitalData[1].isHigh = this.vitalInfo['pulse_change'];
    this.vitalData[2].isHigh = this.vitalInfo['pulse_ox_change'];
    this.vitalData[3].isHigh = this.vitalInfo['bmi_change'];
    this.vitalData[4].isHigh = this.vitalInfo['pulse_ox_change'];
    this.changeDetector.detectChanges();
    this.setSelection();
  }

  setSelection() {
    this.vitalData.forEach(vital => {
      if (vital.isSelected) {
        this.selectedVitals.push(vital);
      }
    });
  }

  removeAllSelection() {
    this.selectedVitals = [];
    this.selectedTrendCount = 0;
    this.patientService.emitTrendCount(this.selectedTrendCount);
    this.vitalData.forEach(vital => {
      vital.isSelected = false;
    });
  }

  getTrendColor(endIndex) {
    const colorList = this.utilsService.deepCopy(this.colors)
    return this.utilsService.deepCopy(colorList.splice(this.selectedTrendCount, endIndex));
  }

  getPulseIndication(vital) {
    let arrowColor: string = '';
    if (vital && !this.utilsService.isEmpty(vital) && vital.secondVal !== '') {
      let hr = vital.secondVal;
      if (hr < 40 || hr > 150) {
        arrowColor = 'too-danger-indicator'
      } else if ((hr >= 40 && hr < 50) || (hr > 120 && hr <= 150)) {
        arrowColor = 'danger-indicator'
      } else if ((hr >= 50 && hr < 60) || (hr > 100 && hr <= 120)) {
        arrowColor = 'mild-indicator'
      } else if (hr >= 60 && hr <= 100) {
        arrowColor = 'normal-indicator'
      }
    }

    return arrowColor;
  }

  getBMIIndication(vital) {
    let arrowColor: string = '';
    if (vital && !this.utilsService.isEmpty(vital) && vital.val !== '') {
      if (vital.val >= 18.5 && vital.val < 25) {
        arrowColor = 'normal-indicator'
      } else if (vital.val >= 25 && vital.val < 30 ||
        vital.val >= 17 && vital.val < 18.5) {
        arrowColor = 'mild-indicator'
      } else if (vital.val >= 16 && vital.val < 17 ||
        vital.val >= 30 && vital.val < 35) {
        arrowColor = 'danger-indicator'
      } else if (vital.val >= 35 || vital.val < 16) {
        arrowColor = 'too-danger-indicator'
      }
    }

    return arrowColor;
  }

  getPulseOxIndication(vital) {
    let arrowColor: string = '';
    if (vital && !this.utilsService.isEmpty(vital) && vital.val !== '') {
      if (vital.val >= 94 && vital.val <= 100) {
        arrowColor = 'normal-indicator'
      } else if (vital.val >= 91 && vital.val <= 93) {
        arrowColor = 'mild-indicator'
      } else if (vital.val >= 88 && vital.val <= 90) {
        arrowColor = 'danger-indicator'
      } else if (vital.val <= 87) {
        arrowColor = 'too-danger-indicator'
      }
    }

    return arrowColor;
  }

  getBPIndication(vital) {
    let arrowColor: string = '';
    if (vital && !this.utilsService.isEmpty(vital) && vital.secondVal !== '') {
      if (vital.val < 120 && vital.secondVal < 80) {
        arrowColor = 'normal-indicator'
      }
      if (((vital.val >= 120 && vital.val < 130) || (vital.val >= 60 && vital.val < 80)) && vital.secondVal < 80) {
        arrowColor = 'mild-indicator'
      }
      if ((vital.val >= 130 && vital.val < 140) || (vital.secondVal >= 80 && vital.secondVal < 90)) {
        arrowColor = 'moderate-indicator'
      }
      if (((vital.val < 180 && vital.val >= 140) || (vital.val < 60)) || (vital.secondVal < 120 && vital.secondVal >= 90)) {
        arrowColor = 'danger-indicator'
      }
      if (vital.val >= 180 || vital.secondVal >= 120) {
        arrowColor = 'too-danger-indicator'
      }
    }
    return arrowColor;
  }

  getDirectionalArrow(vital) {
    let arrowColor: string = '';
    switch (vital.name) {
      case Constants.TREND.PULSE.TITLE:
        arrowColor = this.getPulseIndication(vital);
        break;
      case Constants.TREND.BMI.TITLE:
        arrowColor = this.getBMIIndication(vital);
        break;
      case Constants.TREND.PULSE_OX.TITLE:
        arrowColor = this.getPulseOxIndication(vital);
        break;
      case Constants.TREND.BP.TITLE:
        arrowColor = this.getBPIndication(vital);
        break;
    }
    let diff = 0;
    let direction = ''
    if(vital.changes != undefined && vital.changes.length > 0){
      let changeInd = 0;
      if(vital.name == Constants.TREND.PULSE.TITLE){
        changeInd = 1;
      }
      diff = vital.changes[changeInd] != undefined && vital.changes[changeInd] != null && vital.changes[changeInd] != ''? vital.changes[changeInd] : 0;
    }
    direction = diff > 0 ? 'icon-triangle' : (diff < 0 ? 'icon-triangle_down': 'icon-round');
    /* const direction = vital.isHigh === Constants.VITAL_CHANGE.LOW ? 'icon-triangle_down' :
      (vital.isHigh === Constants.VITAL_CHANGE.HIGH ? 'icon-triangle' : 'icon-triangle') */
    return `${arrowColor} ${direction}`;

  }


  emitTrendChangeEvent(vital, index, isParentInitiated = false) {
    if (this.rightsideComponent !== this.componentTypes.DATA) {
      vital.isSelected = false;
      this.selectedVitals = [];
      this.selectedTrendCount = 0;
      this.vitalData.forEach(vital => {
        vital.isSelected = false;
      });
    }
    // console.log("vital"+JSON.stringify(vital));
    if (this.isClickable(vital)) {
      if (vital.isSelected && this.rightsideComponent !== this.componentTypes.DATA) {
        this.patientService.setRightsideComponent(Constants.COMPONENTS.DATA);
      } else {
        const params = this.getVitalEmitParams(vital, index, isParentInitiated);
        this.patientService.emitTrendChangeEvent(params);
      }
      if (this.patientService.reviewedData.vitalSigns === false && this.authService.hasPermission(this.permission.Summary_Review)) {
        this.patientService.setReviewData('vitalSigns', true);
      }
      this.rightsideComponent = this.componentTypes.DATA;
    } else {
      this.utilsService.showToast(Constants.MESSAGE.VITALS_SELECTION_MAX_LIMIT, 'danger');
    }
  }

  getVitalEmitParams(vital, index, isParentInitiated) {
    let params: any = {};
    if ((!vital.isSelected && (vital.trendCount + this.selectedTrendCount < Constants.TOTAL_TREND_COUNT.VITAL + 1))
      || vital.isSelected) {
      this.vitalData[index].isSelected = !this.vitalData[index].isSelected;
      let vitalsCount = this.selectedVitals.length;
      params = {
        name: vital.name,
        trend: vital.trend,
        selectedVitals: this.vitalData.filter(res => res.isSelected),
        trendCount: vital.trendCount,
        component: Constants.COMPONENTS.VITALINFO,
        color: this.getTrendColor(vital.trendCount),
        isParentInitiated,
        vitalList: isParentInitiated ? this.vitalData : []
      };
      if (vital.isSelected) {
        this.selectedTrendCount = this.selectedTrendCount + vital.trendCount;
        vitalsCount++;
        this.selectedVitals.push(params);
      } else {
        params.remove = true;
        params.index = this.getIndexToBeRemoved(vital);
        // params.index = this.utilsService.findObjectIndex(this.selectedVitals, 'name', vital.name);
        this.utilsService.removeObject(this.selectedVitals, 'name', vital.name);
        this.selectedTrendCount = this.selectedTrendCount - vital.trendCount;

      }
      this.patientService.emitTrendCount(this.selectedTrendCount);

    } else {
      this.utilsService.showToast(Constants.MESSAGE.VITALS_SELECTION_MAX_LIMIT, 'danger');
    }
    return params;
  }

  /**
   * @description Returns the index of the graph to be removed
   * @param vital: selected vital
   */
  getIndexToBeRemoved(vital) {
    let removalIndex: any;
    let trendCount = 0;
    this.selectedVitals.forEach(selectedVital => {
      if (removalIndex === undefined) {
        if (vital.name !== selectedVital.name) {
          trendCount = selectedVital.trendCount + trendCount;
        } else {
          removalIndex = trendCount;
        }
      }
    });
    return removalIndex;
  }

  subscribeChanges() {
    this.patientService.resetWorkflow.subscribe(val => {
      this.isRpm = this.patientService.workflow == Constants.WORKFLOW.RPM;
      this.is_care_managemnt = this.careProgramTypes.includes(this.patientService.workflow) ? true : false;
    });
    this.discardConsentSubscription = this.patientService.discardConsent.subscribe(res => {
      if (res && !this.utilsService.isEmpty(res) && this.vitalsComponent == res.component) {
        if (res.status) {
          this.vitalsPageModal ? this.vitalsPageModal.dismiss() : undefined;
        }
      }
    });    

    /*Function to update vital selection if tred potted from care plan */
    this.vitalSelectionSubscription = this.patientService.vitalSelectionChange.subscribe(data => {
      this.selectedVitals = [];
      this.selectedTrendCount = 0;
      this.vitalData.forEach(vital => {
        vital.isSelected = false;
        if (vital.trend == data.trend) {
          vital.isSelected = true;
          this.selectedVitals.push(vital);
          this.selectedTrendCount = data.trendCount;
        }
      });
      this.rightsideComponent = this.componentTypes.DATA;
      this.patientService.emitTrendCount(this.selectedTrendCount);
    });

  }
  subscribeVitalTrendUpdate() {

    // this.vitalChangeSubscription = this.patientService.getVitalEmitter()
    //   .subscribe(params => { this.onVitalChange(); });

    this.getVitalUpdateModeSubscription = this.patientService.getVitalUpdateMode.subscribe(mode => {
      this.checkCondition(mode);
    });
  }

  isVitalsEmpty() {
    let isEmpty = true;
    this.vitalData.forEach(vital => {
      if (!((vital.val !== 'NA' || vital.val === '' || vital.val === undefined) &&
        vital.secondVal !== 'NA' || vital.secondVal === '' || vital.secondVal === undefined)) {
        isEmpty = false;
      }
    });

    return isEmpty;
  }

  getVitalsHavingData() {
    var indexHavingData = -1;
    for (var i = 0; i < this.vitalData.length; i++) {
      if (((this.vitalData[i].val !== 'NA' && this.vitalData[i].val !== '' && this.vitalData[i].val !== undefined) ||
        this.vitalData[i].secondVal !== 'NA' && this.vitalData[i].secondVal !== '' && this.vitalData[i].secondVal !== undefined)) {
        // isEmpty = false;
        indexHavingData = i;
        break;
      }
    }
    return indexHavingData;
  }

  checkCondition(mode, dataInitial = false) {
    switch (mode) {
      case Constants.VITALS_ACTION.PARENT_CLICK:
        // this.removeAllSelection();
        setTimeout(() => {
          var index = this.getVitalsHavingData();
          if (-1 != index) {
            this.displayTrends(index, dataInitial);
          } else {
            this.patientService.emitRiskProfileLoadEvent();
          }
          // this.isDataScreenVisited = true;
          this.isDataScreenVisited = dataInitial;
        }, 50);
        break;
      case Constants.VITALS_ACTION.UPDATE_VITALS:
        this.updateVitalData();
        break;
      case Constants.VITALS_ACTION.REMOVE_SELECTION:
        this.removeAllSelection();
        break;
      case Constants.VITALS_ACTION.TREND_INACTIVE:
        this.rightsideComponent = undefined;
        break;
    }
  }

  displayTrends(index, dataInitial = false) {
    this.isParentInitiated = true;
    this.rightsideComponent = this.componentTypes.DATA;
    // if (this.selectedVitals.length === 0 && this.isDataScreenVisited === false) {
    if (this.selectedVitals.length === 0 && (this.isDataScreenVisited === false || dataInitial)) {
      this.emitTrendChangeEvent(this.vitalData[index], index, this.isParentInitiated);
      // const params = this.getVitalEmitParams(this.vitalData[1], 1, this.isParentInitiated);
    }
  }

  isClickable(vital) { 
    return this.isPatientDataLoaded === true && (
      (vital.val !== undefined || vital.secondVal !== undefined || (vital.isHidden == true)) &&
      ((vital.val !== 'NA' && vital.val !== undefined && vital.val !== "") || (vital.secondVal !== 'NA' && vital.secondVal !== undefined && vital.secondVal !== "") || 
      (vital.isHidden == true)));
  }
  manualEhrSync() {
    this.patientService.setMultiAxesGraphUpdateStatus(true);
    const params = {
      mr_id: this.patientInfo && this.patientInfo['mr_id'] ? this.patientInfo['mr_id'] : null,
      hospital_id: this.dataTransferService.selectedLocation && this.dataTransferService.selectedLocation.id ? this.dataTransferService.selectedLocation.id : null,
      medicardia_id: this.patientId ? this.patientId : null,
      fhir_id: this.patientInfo && this.patientInfo['ehrid'] ? this.patientInfo['ehrid'] : null
    }
    this.patientService.pullPatientChart(params).subscribe(
      res => {
        this.patientService.setMultiAxesGraphUpdateStatus(false);
        if (res && res.is_success) {
          this.patientService.syncPatientData();
        }
      }, err => {
        this.patientService.setMultiAxesGraphUpdateStatus(false);
      });
  }
  ngOnDestroy() {
    this.getVitalUpdateModeSubscription ? this.getVitalUpdateModeSubscription.unsubscribe() : undefined;
    this.vitalSelectionSubscription ? this.vitalSelectionSubscription.unsubscribe() : undefined;
    this.headerSubscription ? this.headerSubscription.unsubscribe() : undefined;
    this.vitalsPageModal ? this.vitalsPageModal.dismiss() : undefined;
  }
}
