import { Directive } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[appRangeValidator]',
})
export class RangeValidatorDirective {

  rangeValidator(min: number, max: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== undefined && (isNaN(control.value) || control.value < min || control.value > max)) {
        return { range: true };
      }
      // tslint:disable-next-line:no-null-keyword
      return null;
    };
  }
}
