import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Constants } from 'src/app/shared/constants';
import { Router } from '@angular/router';
import { UtilsService } from 'src/app/shared/common';
import { AuthenticationService } from 'src/app/core';
import { MustMatch } from 'src/app/core/services/must-match.validator';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-change-password-popup',
  templateUrl: './change-password-popup.page.html',
  styleUrls: ['./change-password-popup.page.scss'],
})
export class ChangePasswordPopupPage implements OnInit {

  changePasswordForm: FormGroup;
  removalPos: number;
  userId: any;
  isLoading = false;
  expired: boolean;
  errorText = '';
  requiredError: string = Constants.PASSWORD_RESET_ERRORS.required;
  misMatchError: string = Constants.PASSWORD_RESET_ERRORS.misMatch;
  policyError: string = Constants.PASSWORD_RESET_ERRORS.policyError;
  cancelText = Constants.BUTTON_TEXT.cancel;
  submitText = Constants.BUTTON_TEXT.submit;
  isSuccess: boolean;
  message: string;

  constructor(
    public fb: FormBuilder,
    private router: Router,
    public utilService: UtilsService,
    public modalCtrl: ModalController,
    public authService: AuthenticationService) {
    this.changePasswordForm = this.fb.group({
      oldPassword: ['', [Validators.required, Validators.minLength(8), Validators.pattern(Constants.VALIDATION_REGEX.passwordPolicy)]],
      newPassword: ['', [Validators.required, Validators.minLength(8), Validators.pattern(Constants.VALIDATION_REGEX.passwordPolicy)]],
      confirmPassword: ['', Validators.required]
    }, {
      validator: MustMatch('newPassword', 'confirmPassword'),
    });
  }

  ngOnInit() {
  }

  checkPasswordValidity() {
    if ((this.changePasswordForm.controls.newPassword.errors &&
      this.changePasswordForm.controls.newPassword.touched &&
      this.changePasswordForm.controls.newPassword.errors.required) ||
      (this.changePasswordForm.controls.oldPassword.errors &&
        this.changePasswordForm.controls.oldPassword.touched &&
        this.changePasswordForm.controls.oldPassword.errors.required) ||
      (this.changePasswordForm.controls.confirmPassword.errors &&
        this.changePasswordForm.controls.confirmPassword.touched &&
        this.changePasswordForm.controls.confirmPassword.errors.required)) {
      this.errorText = this.requiredError;
    } else if (this.changePasswordForm.controls.confirmPassword.errors &&
      this.changePasswordForm.controls.confirmPassword.touched &&
      this.changePasswordForm.controls.confirmPassword.errors.mustMatch) {
      this.errorText = this.misMatchError;
    } else if (this.isPasswordPolicyNotFollowed(this.changePasswordForm.controls.newPassword)
      || this.isPasswordPolicyNotFollowed(this.changePasswordForm.controls.confirmPassword) ||
      this.isPasswordPolicyNotFollowed(this.changePasswordForm.controls.oldPassword)) {
      this.errorText = this.policyError;
    } else {
      this.errorText = '';
    }
  }

  changePassword() {
    if (this.changePasswordForm.valid) {
      this.errorText = '';
      const params = {
        old_password: this.changePasswordForm.value.oldPassword,
        new_password: this.changePasswordForm.value.newPassword
      };
      this.isLoading = true;
      this.authService.changePassword(params).subscribe(
        res => {
          this.isLoading = false;
          this.isSuccess = res && res.is_success ? true : false;
          this.errorText = res && !res.is_success && res.message ? res.message : '';
          if (res.is_success) {
            this.closeModal(res);
          }
          setTimeout(() => {
            this.isSuccess = undefined;
          }, 1000);
        },
        err => {
          this.isLoading = false;
        }
      );
    } else {
      this.checkPasswordValidity();
    }
  }

  isPasswordPolicyNotFollowed(password) {
    return (password.errors && password.touched && (password.errors.pattern || (password.errors.minlength)));
  }

  onChangePasswordValue(event: string, formControlName) {
    if (event) {
      this.changePasswordForm.get(formControlName).patchValue(event);
    }
    this.checkPasswordValidity();
  }

  keyDownFunction(event) {
    if (event.target.className.indexOf('password') !== -1) {
      this.removalPos = (event.keyCode === 46 || event.keyCode === 8) && event.eventPhase === 3 ? (event.keyCode === 46 ?
        event.srcElement.selectionStart : event.srcElement.selectionStart - 1) : undefined;
      if (this.removalPos !== undefined) {
        setTimeout(() => {
          this.removalPos = undefined;
        }, 50);
      }
    }

    // If enter key is pressed
    if (event.keyCode === 13) {
      // this.changePassword();
    }
  }

  closeModal(res: any = {}) {
    if (this.utilService.isEmpty(res)) {
      res.isCancelled = true;
    }
    this.modalCtrl.dismiss(res);
  }

}
