import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EcgGraphComponent } from './ecg-graph.component';
import { EcgModalPageModule } from 'src/app/modules/modals/ecg-modal/ecg-modal.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FormsModule } from '@angular/forms';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { RemoveNewlinePipe } from 'src/app/shared/pipes/remove-newline/remove-newline.pipe';
import { AmChartsModule } from '@amcharts/amcharts3-angular';



@NgModule({
  declarations: [
    EcgGraphComponent
  ],
  imports: [
    CommonModule,
    EcgModalPageModule,
    NgbTooltipModule,
    DirectivesModule,
    FormsModule,
    InputSwitchModule,
    PerfectScrollbarModule,
    PipesModule,
    AmChartsModule
  ],
  exports: [
    EcgGraphComponent
  ],
  providers:[ RemoveNewlinePipe ]
})
export class EcgGraphModule { }
