import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-overlay-loader',
  templateUrl: './overlay-loader.component.html',
  styleUrls: ['./overlay-loader.component.scss'],
})
export class OverlayLoaderComponent implements OnInit {
  @Input() msg: string;
  constructor() { }

  ngOnInit() { 
    this.msg = this.msg ? this.msg : 'Please wait...';
  }

}
