import { Component, OnInit, OnChanges, Input, SimpleChanges, OnDestroy } from '@angular/core';
import { ENV } from 'src/environments/environment';
import { Constants } from 'src/app/shared/constants';
import { UtilsService } from 'src/app/shared/common';
import { ModalController } from '@ionic/angular';
import { DemographicDataModalPage } from 'src/app/modules/modals/demographic-data-modal/demographic-data-modal.page';
import { DocumentModalPage } from 'src/app/modules/modals/document-modal/document-modal.page';
import { DocumentService } from 'src/app/core/services/document.service';
import { PatientService } from '../../patient.service';
import { ConfigurationService } from 'src/app/core/services/configuration/configuration.service';
import { any } from '@amcharts/amcharts4/.internal/core/utils/Array';

@Component({
  selector: 'app-patient-meta-data',
  templateUrl: './patient-meta-data.component.html',
  styleUrls: ['./patient-meta-data.component.scss'],
})
export class PatientMetaDataComponent implements OnInit, OnChanges, OnDestroy {

  @Input() patientData: any;

  patientInfo: any = {};
  riskProfile: any = {};

  metaDataModal: any;
  config: any;
  bpSys: Number;
  bpDias: Number;
  pulseOxPerc: Number;
  pulseOxBrpm: Number;
  isInPatientMode: boolean;
  isMediumScreen: boolean;
  mobileActive: boolean;
  genderDisplayFormat: string;

  baseURL = ENV.patient_baseurl;
  documentPageModal: any;
  documentType = Constants.PATIENT_DOCUMENT_TYPE;
  componentTypes = Constants.COMPONENTS;
  signedUrlExpity = Constants.SIGNED_URL_DATA.expityDuration;

  ngOnInit() {
    this.isInPatientMode = true;
    this.windowResized();
    this.genderDisplayFormat = Constants.GENDER_DISPLAYFORMAT.LONG;
  }

  windowResized() {
    this.isMediumScreen = screen.width < 1200 ? true : false;
  }

  constructor(
    public utilService: UtilsService,
    public modalCtrl: ModalController,
    public documentService: DocumentService,
    public patientService: PatientService,
    private configurationService: ConfigurationService,
  ) {
    this.documentService.isPopupClicked = false;
    this.config = this.configurationService.getConfigurationDetails();
    this.mobileActive = this.config.mobile && this.config.mobile.is_active ? this.config.mobile.is_active : false
    this.patientService.docViewEnabledEvent.subscribe(val => {
      if (val) {
        setTimeout(() => {
          this.openDocumentModal(this.documentType.CCD);
        }, 3000);
      }
    });
   }

  ngOnChanges(changes: SimpleChanges) {
   
    const PATIENTDATA = 'patientData';
    for (const propName in changes) {
      if (propName) {
        const change = changes[propName];
        const curVal = change.currentValue;
        const preVal = change.previousValue;
        const strCurVal = JSON.stringify(curVal);
        const strPrevVal = JSON.stringify(preVal);
        if (strCurVal !== strPrevVal && strCurVal) {
          switch (propName) {
            case PATIENTDATA:
              if (curVal) {
                let index = 0;
                if (curVal['riskProfile']) {
                  index = curVal['riskProfile'].length - 1;
                }
                this.patientInfo = curVal['patientInfo'] ? curVal['patientInfo'] : {};
                if (curVal['riskProfile']) {
                  if (curVal['riskProfile'].length) {
                    this.riskProfile = curVal['riskProfile'][index].data;
                  }
                }
              }
              break;
          }
        }
      }
    }    
  }

  /**
   * @description If the image URL is broken/no image is available,
   *              Then show a default image
   */
  // public setDefaultPatientImg(patient) {
  //   this.baseURL = '';
  //   patient.profile_pic = '../../../assets/imgs/default_profile_pic.png';
  // }

  async displayMetaDataModal() {
    if(this.patientData &&
      this.patientData.patientInfo &&
      this.patientData.patientInfo.medicardia_id) {
      const patientInformation = this.patientData &&  this.patientData.patientInfo ? this.patientData.patientInfo : {};
      let cssClassValue = 'metadata-page-mobile' ? this.mobileActive : 'metadata-page'
      this.metaDataModal = await this.modalCtrl.create({
        component: DemographicDataModalPage,
        componentProps: { patientInfo: patientInformation }, 
        cssClass: this.mobileActive ? 'metadata-page' : 'metadata-page-mobile',
        backdropDismiss: true
      });
      return await this.metaDataModal.present();
    }
  }

  /**
   * Function to open the docment url in popup. Change the implementation with new signed URL
   * @param docType 
   */
  openDocumentModal(docType) {
    var ref_link = this.patientData.patientInfo.ref_link.length ? this.patientData.patientInfo.ref_link[0] : null;
    if(ref_link !=''){
      let params = {
        url: ref_link,
        expiry: this.signedUrlExpity
      }
      this.patientService.getSignedURL(params).subscribe(
        res => {
          if (res.is_success) {
            if (res.data && res.data.s3_signed_url) {
              this.openSignedDocumentModal(docType, res.data.s3_signed_url); // call the pop up window
            }
          } else {
            this.utilService.showToast(res.message, 'danger');
          }
        });
    }
  }

  /**
   * Function to open the signed url of the s3 data
   * @param docType 
   * @param signedUrl 
   */
  async openSignedDocumentModal(docType, signedUrl) {
    const params = {
      type: docType,
      patientId: "",
      ref_link: signedUrl,
      isDocBtnClicked: true
    };
    if (this.patientService.activeTab === this.componentTypes.SUMMARY && this.patientService.visitType !== 'Video') {
      this.patientService.isDocPopBtnClicked(params);
    } else {
      if (this.patientData.patientInfo.ref_link.length) {
        this.documentService.isPopupClicked = true;
        this.documentPageModal = await this.modalCtrl.create({
          component: DocumentModalPage,
          componentProps: params,
          cssClass: this.patientService.visitType !== 'Video' ? 'document-page video-doc-middle modal-doc-hght' :
            'document-page video-document-page modal-doc-hght',
          backdropDismiss: false
        });
        this.documentPageModal.onDidDismiss().then(res => {
          this.documentService.isPopupClicked = false;
          // if (res.data) {
          //   this.getPdfDocument(params.type);
          // }
        });
        return await this.documentPageModal.present();
      }
    }
  }

  ngOnDestroy() {
    if (this.metaDataModal) {
      this.metaDataModal.dismiss();
    }
    if(this.documentPageModal){
      this.documentPageModal.dismiss();
    }
  }
}
