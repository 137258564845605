import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
// tslint:disable-next-line:no-import-side-effect
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ENV } from './../../../../environments/environment';
import { StorageService } from '../authentication/storage.service';
import { Constants } from 'src/app/shared/constants';
import { RequestParams, PatientRequestParams } from '../../models/request-params';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private loader;
  private _requestCount = 0;

  constructor(public http: HttpClient, public storageService: StorageService) {
  }

  private _getHeadersConfig(isPostX = false, isCsv = false) {
    let config = {};
    const storageData = this.storageService.getStorageItem(sessionStorage, 'userData', true);
    if (storageData && storageData.token) {
      config = { 'Authorization': `Token ${storageData.token}` };
    }
    if (isCsv) {
      config['Accept'] = 'text/csv';
    } else if (!isPostX) {
      config['Accept'] = 'application/json';
      config['Content-Type'] = 'application/json';
    }
    return config;
  }

  private _getPostParams(data: object, msgHeader: number, workflow?: string, isPostX?: boolean): any {
    let body: RequestParams | PatientRequestParams;
    body = {
      msgHeader,
      data: msgHeader === Constants.MSG_HEADER.ENCRYPTED ?
        btoa(JSON.stringify(data)) : data,
    }
    if (workflow) {
      body = {
        ...body,
        workflow,
      };
    }
    return body;
  }

  getCleanedParams(params) {
    let cleanedParams = new HttpParams();
    for (const item in params) {
      if (undefined !== params[item]) {
        cleanedParams = cleanedParams.append(item, params[item]);
      }
    }
    return cleanedParams;
  }

  get(path: string, params: any, msgHeader = Constants.MSG_HEADER.UNENCRYPTED, workflow?, isCsv = false) {
    const postData = this.getCleanedParams(params);
    if(!isCsv){
      return this.http.get(path,
        { headers: new HttpHeaders(this._getHeadersConfig(false, isCsv)), params: postData }).pipe(map(
          res => res,
          err => err.json()
        ));
    }else{ // CSV type response
      return this.http.get(path,
        { headers: new HttpHeaders(this._getHeadersConfig(false, isCsv)), params: postData , 'responseType': 'text'}).pipe(map(
          res => res,
          err => err.json()
        ));
    }
    
  }

  put(path: string, body: object = {}) {
    return this.http.put(path,
      JSON.stringify(body),
      { headers: new HttpHeaders(this._getHeadersConfig()) }).pipe(map(
        res => res,
        err => err.json()
      ));
  }

  post(path: string, body: object = {}, msgHeader = Constants.MSG_HEADER.UNENCRYPTED, workflow?, isPdf = false): Observable<any> {
    const postData = this._getPostParams(body, msgHeader);
    if(!isPdf){
      return this.http.post(path,
        JSON.stringify(postData),
        { headers: new HttpHeaders(this._getHeadersConfig()) }).pipe(map(
          res => res,
          err => err.json()
        ));
    }else{
      return this.http.post(path,
        JSON.stringify(postData),
        { headers: new HttpHeaders(this._getHeadersConfig()) , 'responseType': 'arraybuffer'}).pipe(map(
          res => res,
          err => err.json()
        ));
    }
    
  }

  postfhir(apipath: string, postdata: Object = {}, showLoader = true, baseUrl = '', isFormData = false) {
    if (showLoader) {
      // this.loaderService.startLoaders();
    }
    const base_url = baseUrl;
    return this.http.post(`${base_url}${apipath}`, (isFormData ? postdata : JSON.stringify(postdata)),
      {headers: new HttpHeaders(this._getHeadersConfig()) });
  }
  

  postX(path: string, requestParams: object = {}, msgHeader = Constants.MSG_HEADER.UNENCRYPTED): Observable<any> {
    const fd = new FormData();
    const postData = this._getPostParams(requestParams, msgHeader);
    if (postData.data.file) {
      postData.file = postData.data.file;
    }
    // postData.file_name = postData.data.file && postData.data.file.name ? postData.data.file.name : '';
    delete postData.data.file;
    postData.data = JSON.stringify(postData.data);
    for (const key in postData) {
      if (postData[key]) {
        if (postData[key].constructor === Array) {
          for (const innerKey in postData[key]) {
            if (postData[key][innerKey]) {
              fd.append(key, postData[key][innerKey]);
            }
          }
        } else {
          fd.append(key, postData[key]);
        }
      } else {
        fd.append(key, postData[key]);
      }
    }
    return this.http.post(path, fd,
      { headers: new HttpHeaders(this._getHeadersConfig(true)) }).pipe(map(
        res => res,
        err => err.json()
      ));
  }

  delete(path: string, body: object = {}): Observable<any> {
    return this.http.request('delete',
      path,
      {
        body: JSON.stringify(body),
        headers: new HttpHeaders(this._getHeadersConfig())
      }).pipe(map(
        res => res,
        err => err.json()
      ));
  }
}
