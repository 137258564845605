import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Constants } from 'src/app/shared/constants';
import { NavParams, NavController, ModalController } from '@ionic/angular';
import { UtilsService } from 'src/app/shared/common';
import { PatientService } from '../../components/patient/patient.service';
import { DataTransferService } from 'src/app/core/services/data-transfer/data-transfer.service';

import * as _ from 'lodash';
import { PhoneNumberPipe } from 'src/app/shared/pipes/phone-number-pipe/phone-number-pipe';
import { ConfigurationService } from 'src/app/core/services/configuration/configuration.service';
import { StorageService } from 'src/app/core/services/authentication/storage.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.page.html',
  styleUrls: ['./user-profile.page.scss'],
})
export class UserProfilePage implements OnInit, OnDestroy, AfterViewChecked {

  isFormSubmitted = false;
  phoneNumbrInvalid = false;
  isDirty: boolean;
  userProfileComponent = Constants.COMPONENTS.USER_PROFILE;
  public addEditUserForm: FormGroup;
  selectedUser;
  phonePipe = new PhoneNumberPipe();
  public timezone: string;
  mobileActive : boolean = false
  config : any;
  mobileDetails;
  userDetails;
  activeMobileDetails;
  themeOptions = [];
  existingTheme = '';

  public emailLength = Constants.USER_VALIDATION_DATA.email;
  public lastnameLength = Constants.USER_VALIDATION_DATA.lastname;
  public firstnameLength = Constants.USER_VALIDATION_DATA.firstname;
  public contactNumberLength = Constants.USER_VALIDATION_DATA.contactnumber;
  patientFormValidations = Constants.PATIENT_DETAILS_VALIDATION;

  public invalidPhone = Constants.ERROR_TEXT_DATA.invalidPhonenumber;
  public invalidEmail = Constants.ERROR_TEXT_DATA.invalidEmail;
  public requiredError = Constants.ERROR_TEXT_DATA.requiredError;

  constructor(
    private dataTransferService: DataTransferService,
    public navParams: NavParams,
    public navCtrl: NavController,
    public modalCtrl: ModalController,
    public utilService: UtilsService,
    public patientService: PatientService,
    public storageService: StorageService,
    public changeDetectorRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private configurationService: ConfigurationService,
    private cookieService: CookieService
  ) {
    this.timezone = this.configurationService.getConfigurationDetails().hospitalTimezone;
    this.userDetails = this.dataTransferService.getUserData();
    this.config = this.configurationService.getConfigurationDetails();
    this.mobileActive = this.config.mobile && this.config.mobile.is_active ? this.config.mobile.is_active : false

  }

  ngOnInit() {
    this.getUserData();
    this.initialiseForm();
    this.getUserMobileDetails();
  }

  /**
   * Function will load the 
   */
  getUserData() {
    // getUserProfile
    this.patientService.getUserProfile().subscribe(
      res => {
        if (res['is_success'] && res['data'] && res['data']) {
          //console.log(res['data']);
          this.selectedUser = res['data'];
          this.themeOptions = Object.keys(this.selectedUser.theme_choice).map(key => ({label: this.selectedUser.theme_choice[key].label, value: this.selectedUser.theme_choice[key].value}));
          this.existingTheme = this.selectedUser.theme;
          this.patchUserProfileForm();
        }
      },
      err => {
      }
    );
  }

  private initialiseForm() {
    this.addEditUserForm = this.formBuilder.group({
      userName: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      firstName: ['', Validators.compose([Validators.required])],
      lastName: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required, Validators.pattern(Constants.VALIDATION_REGEX.email)])],
      contactNumber: ['', [Validators.required, Validators.minLength(10)]],
      country_code: ['+1', [Validators.required, Validators.pattern(Constants.VALIDATION_REGEX.countryCode),
      Validators.maxLength(Constants.PATIENT_DETAILS_VALIDATION.COUNTRY_CODE.MAXLENGTH)]],
      homeNumber: ['', Validators.minLength(10)],
      officeNumber: ['', Validators.minLength(10)],
      theme: ['', Validators.compose([Validators.required])],
    });
    this.patchUserProfileForm();
  }

  /**
   * Function will patch the user profile form with the selected user data
   */
  patchUserProfileForm() {
    if (this.selectedUser) {
      const mobile_number = (this.selectedUser.contact && this.selectedUser.contact.length >= 10) ?
      this.selectedUser.contact.slice(-10) : '';
        const country_code = (this.selectedUser.contact && this.selectedUser.contact.length > 10 ? this.selectedUser.contact.slice(0, -10) : '');

      this.addEditUserForm.patchValue({
        firstName: this.selectedUser.first_name ? this.selectedUser.first_name : '',
        lastName: this.selectedUser.last_name ? this.selectedUser.last_name : '',
        userName: this.selectedUser.username ? this.selectedUser.username : '',
        email: this.selectedUser.email ? this.selectedUser.email : '',
        contactNumber: mobile_number ? this.phonePipe.transform(mobile_number) : '',
        country_code: country_code,
        homeNumber: this.selectedUser.contact_numbers ? this.phonePipe.transform(this.selectedUser.contact_numbers.home) : '',
        officeNumber: this.selectedUser.contact_numbers ? this.phonePipe.transform(this.selectedUser.contact_numbers.office) : '',
        theme: this.selectedUser.theme ? this.selectedUser.theme : 'light'
      });
    }
  }

  /**
   * Function to get the user mobile details
   */
  getUserMobileDetails() {
    const apiParams = {
      lazy: true,
      identifier: this.userDetails.user_id ? this.userDetails.user_id : ''
    };
    this.patientService.getUserAccessCode(apiParams).subscribe(
      res => {
        if (res['is_success'] && res['data'] && res['data']) {
          this.mobileDetails = res['data'];
          this.mapActiveMobileData();
        }
      },
      err => {
      }
    );
  }

  /**
   * Function will call the API and regenerates the access code.
   * API response needs the new code and that can be shown over the UI.
   */
  regenerateCode() {
    const apiParams = {
      identifier: this.userDetails.user_id ? this.userDetails.user_id : '',
      generate: true,
      email: false
    };
    this.patientService.generateUserAccessCode(apiParams).subscribe(
      res => {
        if (res['is_success'] && res['data']) {
          this.mobileDetails = res['data'];
          this.mapActiveMobileData();
          this.utilService.showToast(res['message'], 'success');
        }
      },
      err => {
      }
    );
  }

  /**
   * Select the device with active status
   */
  mapActiveMobileData() {
    if (this.mobileDetails && this.mobileDetails.device_details) {
      this.mobileDetails.device_details.forEach(deviceInfo => {
        if (deviceInfo.is_active) {
          this.activeMobileDetails = deviceInfo;
        }
      });
    }
  }

  /**
   * Description: To get form control.
   */
  get addEditUserFormControl() { return this.addEditUserForm.controls; }

  openDiscardChangePop() {
    if (this.isDirty) {
      const params = { component: this.userProfileComponent };
      this.dataTransferService.openDiscardPopup(params);
    } else {
      this.closeModal(false);
    }
  }

  closeModal(status) {
    this.modalCtrl.dismiss(status);
  }

  ngAfterViewChecked() {
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Function will validate the form and calls the API to update the user profile.
   */
  updateUser() {
    this.isFormSubmitted = true;
    this.phoneNumbrInvalid = false;
    var formattedNumber = this.addEditUserFormControl.contactNumber.value.toString().replace(/[^0-9]/g, '');
    if (this.addEditUserForm.invalid) {
      return false;
    } else if (!(/[0-9]/).test(this.addEditUserFormControl.contactNumber.value)
      || formattedNumber.length < 10) {
      this.phoneNumbrInvalid = true;
      return false;
    }

    let inputParams;
    inputParams = {
      first_name: this.addEditUserForm.get('firstName').value,
      last_name: this.addEditUserForm.get('lastName').value,
      email: this.addEditUserForm.get('email').value,
      contact: this.addEditUserForm.get('country_code').value.toString() +
        this.addEditUserForm.get('contactNumber').value.toString().replace(/[^0-9]/g, ''),
      contact_numbers: {
        home: this.addEditUserForm.get('homeNumber').value ?
          this.addEditUserForm.get('homeNumber').value.toString().replace(/[^0-9]/g, '') : '',
        office: this.addEditUserForm.get('officeNumber').value ?
          this.addEditUserForm.get('officeNumber').value.toString().replace(/[^0-9]/g, '') : '',
      },
      theme: this.addEditUserForm.get('theme').value
    };

    this.patientService.updateUser(inputParams).subscribe(res => {
      if (res && res['is_success'] === true && res['message']) {
        this.utilService.showToast(res['message'], 'success');
        // if the theme is changed then update in the local storage and in the cookie then emit for auto update in UI and refresh the page.
        if(this.existingTheme != inputParams.theme){
          this.utilService.themeKey = inputParams.theme;
          const storageData: any = {};
          const userDetails = this.storageService.getStorageItem(sessionStorage, 'userData', true);
          userDetails['themKey'] = inputParams.theme;
          storageData['userData'] = userDetails;
          this.storageService.setStorageItem(sessionStorage, storageData, true);
          const themeLS = this.storageService.getStorageItem(localStorage, '_tks', true);//localStorage.getItem('_tks');
          if(themeLS != inputParams.theme){
            //localStorage.setItem('_tks',inputParams.theme);
            let themestData = {}
            themestData['_tks'] = inputParams.theme;
            this.storageService.setStorageItem(localStorage, themestData, true);
          }
          /* let expiryDate = new Date();
          expiryDate.setDate( expiryDate.getDate() + 365 );
          const themeCookieValue = this.cookieService.get('themeKey');
          if(themeCookieValue != inputParams.theme){
            this.cookieService.delete('themeKey');
            this.cookieService.set('themeKey', inputParams.theme, expiryDate);
          } */          
          //this.dataTransferService.setThemeSwitch(inputParams.theme);
          window.location.reload();
        }
      } else if (res && res['message']) {
        this.utilService.showToast(res['message'], 'danger');
      }
    });
  }

  /**
   * Description: Phone number validation to allow only numbers.
   */
  public onlyNumber(event) {
    const chara = event.target.value;
    if (chara && chara.length && chara.length >= 10) {
      return false;
    }
    if ((/[0-9]/).test(event.key)) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Function to remove the phone number formatting
   * @param formElement 
   */
  deFormatPhoneNumber(formElement) {
    if (this.addEditUserForm &&
      this.addEditUserForm.value) {
      if (formElement == 'homeNumber' && this.addEditUserForm.value.homeNumber && this.addEditUserForm.value.homeNumber) {
        this.addEditUserForm.value.homeNumber = this.addEditUserForm.value.homeNumber.replace(/[^\d]/g, '');
      } else if (formElement == 'officeNumber' && this.addEditUserForm.value.officeNumber && this.addEditUserForm.value.officeNumber) {
        this.addEditUserForm.value.officeNumber = this.addEditUserForm.value.officeNumber.replace(/[^\d]/g, '');
      } else if (formElement == 'contactNumber' && this.addEditUserForm.value.contactNumber && this.addEditUserForm.value.contactNumber) {
        this.addEditUserForm.value.contactNumber = this.addEditUserForm.value.contactNumber.replace(/[^\d]/g, '');
      }
    }
  }

  /**
   * Function to format the phone number in US format
   * @param formElement 
   */
  focusOutFunction(formElement) {
    if (this.addEditUserForm &&
      this.addEditUserForm.value) {
      if (formElement == 'homeNumber' && this.addEditUserForm.value.homeNumber && this.addEditUserForm.value.homeNumber) {
        this.addEditUserForm.value.homeNumber = this.phonePipe.transform(this.addEditUserForm.value.homeNumber);
      } else if (formElement == 'officeNumber' && this.addEditUserForm.value.officeNumber && this.addEditUserForm.value.officeNumber) {
        this.addEditUserForm.value.officeNumber = this.phonePipe.transform(this.addEditUserForm.value.officeNumber);
      } else if (formElement == 'contactNumber' && this.addEditUserForm.value.contactNumber && this.addEditUserForm.value.contactNumber) {
        this.addEditUserForm.value.contactNumber = this.phonePipe.transform(this.addEditUserForm.value.contactNumber);
      }
    }
  }

  ngOnDestroy() {

  }
}
