import { Constants } from 'src/app/shared/constants';
import * as _ from 'lodash';
import { ConfigurationInterface, ConfigurationModel } from './configuration';
export interface LocationInterface {
    name: string,
    id: string,
};

export interface CategoryInterface {
    isEnabled: boolean,
    id: number
};

export interface GroupInterface extends LocationInterface { }

export interface PermissionInterface {
    hospital: LocationInterface,
    group: GroupInterface,
    permissions: any[]
};

export interface UserInterface {
    username: string,
    user_id: string,
    name: string,
    first_name: string,
    last_name: string,
    isNative: boolean,
    isFirstTimeLogin: boolean,
    permission: any[],
    isPhysician: boolean,
    categoryList: CategoryInterface[],
    featureCategoryList: any[],
    configuration: ConfigurationInterface
    isSSOorLaunchFromEpic:boolean
};

export interface CategoryBasedPermissionInterface {
    categoryId: number,
    isEnabled: boolean,
    feature_name: string,
    feature_tag: string,
    feature_id: any,
    read: boolean,
    write: boolean,
};

export class LocationModel {

    public static create(location): LocationInterface {
        return {
            id: location.length && location[0]['_id'] && location[0]['_id']['$oid'] ? location[0]['_id']['$oid'] : undefined,
            name: location.length && location[0].name ? location[0].name : undefined,
        };
    }
}

export class GroupModel {
    public static create(data) {
        return {
            id: data && data.length && data[0]._id && data[0]._id['$oid'] ? data[0]._id['$oid'] : undefined,
            name: data && data.length && data[0]['name'] ? data[0]['name'] : undefined
        }
    }
}

export class CategoryBasedPermissionModel {

    public static create(permissionList): CategoryBasedPermissionInterface[] {
        const parsedPermissionList: CategoryBasedPermissionInterface[] = [];
        permissionList.permissions.forEach(element => {
            const categoryData = _.find(permissionList.categoryList, ['tag', element['feature_tag']]);
            const obj: CategoryBasedPermissionInterface = {
                categoryId: categoryData && categoryData.category ? categoryData.category.category_id : undefined,
                isEnabled: categoryData && categoryData.category ? categoryData.category.is_enabled : false,
                feature_name: element.feature_name,
                feature_tag: element.feature_tag,
                feature_id: element.feature_id,
                read: element.read,
                write: element.write,
            }
            parsedPermissionList.push(obj);
        });
        return parsedPermissionList;
    }
}

export class CategoryModel {
    public static create(categoryList, configurationSettings): CategoryInterface[] {
        const parsedCategoryList: CategoryInterface[] = [];
        if (categoryList && categoryList.length) {
            categoryList.forEach(element => {
                var ind = _.find(parsedCategoryList, ['id', element.category.category_id]);
                if (ind === undefined) {
                    const obj: CategoryInterface = {
                        id: element.category.category_id,
                        isEnabled: element.category.is_enabled,
                    }
                    parsedCategoryList.push(obj);
                }
            });
            const nonEHRSetting: CategoryInterface = {
                id: Constants.MAIN_FEATURE_CATEGORY_NAME.EHR,
                isEnabled: configurationSettings.isEHRAvailable,
            };
            parsedCategoryList.push(nonEHRSetting);
        }
        return parsedCategoryList;
    }
}

export class PermissionModel {

    public static create(permissionList): PermissionInterface[] {
        const parsedPermissionList: PermissionInterface[] = [];
        permissionList.hospitals.forEach(element => {
            const obj: PermissionInterface = {
                hospital: LocationModel.create(element.hospital_details),
                group: GroupModel.create(element.group),
                permissions: CategoryBasedPermissionModel.create({
                    permissions: element.permissions,
                    categoryList: permissionList.categoryList
                })
            }
            parsedPermissionList.push(obj);
        });
        return parsedPermissionList;
    }
}

export class UserModel {
    public static create(userData): UserInterface {
        const configurationSettings: ConfigurationInterface = ConfigurationModel.create(userData.configuration);
        return {
            username: userData.user_data && userData.user_data.user_name ? userData.user_data.user_name : '',
            user_id: userData.user_data && userData.user_data.user_id ? userData.user_data.user_id : '',
            name: userData.user_data && userData.user_data.first_name && userData.user_data.last_name ?
                `${userData.user_data.first_name} ${userData.user_data.last_name}` :
                (userData.user_data.first_name ? `${userData.user_data.first_name}` :
                    (userData.user_data.last_name ? `${userData.user_data.last_name}` : '')),
            first_name: userData.user_data && userData.user_data.first_name ? userData.user_data.first_name : '',
            last_name: userData.user_data && userData.user_data.last_name ? userData.user_data.last_name : '',
            isNative: userData.user_data && userData.user_data.auth_type ? userData.user_data.auth_type === Constants.AUTH_TYPE.NATIVE :
                false,
            isFirstTimeLogin: userData.user_data && userData.user_data.is_first_time_login !== undefined ?
                userData.user_data.is_first_time_login : false,
            permission: PermissionModel.create({
                hospitals: userData.hospitals,
                categoryList: userData.category_list ? userData.category_list : []
            }),
            isPhysician:  _.find(userData.hospitals[0].group, ['name', 'Physician']) !== undefined ? true : false,
            categoryList: CategoryModel.create(userData.category_list, configurationSettings),
            featureCategoryList: userData.feature_categories ? userData.feature_categories : [],
            configuration: configurationSettings,
            isSSOorLaunchFromEpic:userData && userData.isSSOorLaunchFromEpic && userData.isSSOorLaunchFromEpic !== undefined ? 
            userData.isSSOorLaunchFromEpic :false,
        };
    }
}