import { Directive, ElementRef, HostListener, Input, OnChanges } from '@angular/core';
import { NgControl } from '@angular/forms';
import * as _ from 'lodash';

@Directive({
  selector: '[appDecimalNumber]',
})
export class DecimalNumberDirective implements OnChanges {

  @Input() minValue: string;
  @Input() maxValue: string;
  @Input() allowNegative: boolean;
  constructor(private el: ElementRef, private control: NgControl) {
  }
  @HostListener('input')
  OnChange() {
    const regex = this.allowNegative ? /[^-0-9\.]/g : /[^0-9\.]/g;
    let inputVal = this.el.nativeElement ? (this.el.nativeElement).value.replace(regex, '') : '';
    const negativeCheck = inputVal.split('-');
    const decimalCheck = inputVal.split('.');
    if (negativeCheck.length > 1) {
      negativeCheck[1] = negativeCheck[1].slice(0, negativeCheck[1].length);
      inputVal = negativeCheck[0] + '-' + negativeCheck[1];
      if (negativeCheck[0].length > 0) {
        inputVal = negativeCheck[0];
      }

    }
    if (decimalCheck.length > 1) {
      inputVal = decimalCheck[0] + '.' + decimalCheck[1].substring(0, 3);
    }

    if (this.maxValue) {
      if (parseFloat(inputVal) > parseFloat(this.maxValue)) {
        this.el.nativeElement.style.color = '#ff4949';
      }
    }

    if (this.minValue !== undefined && this.minValue !== '') {
      if (parseFloat(inputVal) < parseFloat(this.minValue)) {
        this.el.nativeElement.style.color = '#ff4949';
      }
    }

    if (this.minValue !== undefined && this.minValue !== '' && this.maxValue !== undefined) {
      if (parseFloat(inputVal) >= parseFloat(this.minValue) && (parseFloat(inputVal) <= parseFloat(this.maxValue))) {
        this.el.nativeElement.style.color = 'white';
      }
    }
    this.control.control.setValue(inputVal !== undefined && inputVal !== '' ? inputVal : '');
  }
  ngOnChanges() {
    const regex = this.allowNegative ? /[^-0-9\.]/g : /[^0-9\.]/g;
    let inputVal = this.el.nativeElement ? (this.el.nativeElement).value.replace(regex, '') : '';
    const negativeCheck = inputVal.split('-');
    const decimalCheck = inputVal.split('.');
    if (negativeCheck.length > 1) {
      negativeCheck[1] = negativeCheck[1].slice(0, negativeCheck[1].length);
      inputVal = negativeCheck[0] + '-' + negativeCheck[1];
      if (negativeCheck[0].length > 0) {
        inputVal = negativeCheck[0];
      }

    }
    if (decimalCheck.length > 1) {
      inputVal = decimalCheck[0] + '.' + decimalCheck[1].substring(0, 3);
      // if (decimalCheck[1] != '') {
      //   inputVal = _.round(+parseFloat(inputVal).toFixed(3), 3);
      // }
    }

    if (this.maxValue) {
      if (parseFloat(inputVal) > parseFloat(this.maxValue)) {
        this.el.nativeElement.style.color = '#ff4949';
      }
    }

    if (this.minValue !== undefined && this.minValue !== '') {
      if (parseFloat(inputVal) < parseFloat(this.minValue)) {
        this.el.nativeElement.style.color = '#ff4949';
      }
    }

    if (this.minValue !== undefined && this.minValue !== '' && this.maxValue !== undefined) {
      if (parseFloat(inputVal) >= parseFloat(this.minValue) && (parseFloat(inputVal) <= parseFloat(this.maxValue))) {
        this.el.nativeElement.style.color = 'white';
      }
    }
    this.control.control.setValue(inputVal !== undefined && inputVal !== '' ? inputVal : '');
  }
}
