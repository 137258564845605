import { Component, OnInit, ElementRef, OnDestroy, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ENV } from 'src/environments/environment';
import { AuthenticationService } from '../../../core/services/authentication/authentication.service';
import { UtilsService } from '../../common';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { HeaderService } from './header.service';
import { StorageService } from '../../../core/services/authentication/storage.service';
import { Subscription } from 'rxjs';
import { NavController, ModalController, AlertController } from '@ionic/angular';
import { Constants } from '../../constants';
import { DemographicInterface } from 'src/app/core/models/demographic';
import { PatientService } from 'src/app/modules/components/patient/patient.service';
import { DataTransferService } from 'src/app/core/services/data-transfer/data-transfer.service';
import moment from 'moment';
import { ChangePasswordPopupPage } from 'src/app/modules/modals/change-password-popup/change-password-popup.page';
import { UserInterface } from 'src/app/core/models/user';
import { AppointmentsService } from 'src/app/modules/components/appointments/appointments.service';
import { ConfigurationService } from 'src/app/core/services/configuration/configuration.service';
import { ViewAboutBoxPage } from 'src/app/modules/modals/view-about-box/view-about-box.page';
import { FormControl, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { CiedService } from 'src/app/modules/components/cied/cied.service';
import { UserProfilePage } from 'src/app/modules/modals/user-profile/user-profile.page';
import { debounceTime } from 'rxjs/operators';

declare var telemedwebrtc: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  // tslint:disable-next-line: no-host-metadata-property
  host: {
    '(document:click)': 'onClick($event)',
  }
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {

  currentAppointment = {};
  headers = [];
  headerRightList = [];
  headerLeftList = [];
  headerCenterList = [];
  locationOptions = [];
  ecgData: any = [];

  // baseURL = ENV.api_baseurl;

  isReadOnlyView: boolean;
  isLogoutClicked: boolean;
  isClicked: number;
  isHeaderLoaded: boolean;
  isiPadView: boolean;
  isPatientScreen: boolean;
  isRPMDashboard: boolean;
  isFullScreen: boolean;
  medicardiaLogo: string;
  showProfileOptions = false;
  isScreenshareStopped = false;
  isLeaveMeetingClicked = false;
  isProgramaticallyExited = false;
  isInitiatedFromFullscreen = false;
  isRPMOnlyMode = false;
  listenerEvent: any;
  patientData: any;
  patientInfo: DemographicInterface;
  userData: UserInterface;
  selectedVisitMode: number = Constants.VISIT_MODE.MEDICARDIA;
  visitModes = Constants.VISIT_MODE;
  changePasswordPromptTypes = Constants.CHANGE_PASSWORD_PROMPT_TYPE;
  selectedChangePasswordPromptType = Constants.CHANGE_PASSWORD_PROMPT_TYPE.USER_INITIATED;
  videoVisitActions = Constants.VIDEO_VISIT_ACTIONS;
  selectedVideoAction: number;
  timezone: string;

  changePasswordModal: any;
  alertPopup: any;
  fuVisitSubscription: Subscription;
  vitalChangeSubscription: Subscription;
  videoScreenModeSuscribe: Subscription;
  rpmDataSubscription: Subscription;
  pastDateList = [];
  futureDateList = [];
  @ViewChild('vitalInfo', { static: false }) vitalInfo;
  @ViewChild('videocontainerThumbnail', { static: false }) videocontainer;
  @ViewChild('callFrameContainerThumbnail', { static: false }) callFrameContainer: ElementRef;
  aboutBoxPage: HTMLIonModalElement;
  userProfilePage: HTMLIonModalElement;
  aboutBoxData: any;
  discardConsentSubscription: Subscription;
  dropdownDate: FormGroup;
  currentAppointmentDate: any;
  isRpm: boolean = false;
  isCIED: boolean = false;
  isHeaderDatesLoaded: boolean;

  // #53712
  monitoringOptions = Constants.WORKFLOW_OPTIONS;
  workFlow =  Constants.WORKFLOW;
  rpmStandaloneEnable = false;
  selectedMode: any;
  rpmModeSubscription: Subscription;
  rpmStandaloneEnableSubscription: Subscription;
  updatedMonitoringOptionSubscription: Subscription;
  isLoaded: number;
  loadingStatus = Constants.LOADING_STATUS;
  updatedIsLoadedValueSubscription: Subscription;
  isCiedDetailsScreen: boolean;
  isAnalyticsDashboardScreen: boolean;
  isPatientSearchScreen: boolean;
  isEhrTransmissionScreen: boolean;
  detailsPageEntrySubscription: Subscription;
  enableWorkFlowBn = true;
  enableWorkFlowBtnStatusSubscription: Subscription;
  workFlowOption :any;
  previousMode:any;
  public careProgramTypes = Constants.CARE_TYPES;
  public is_care_managemnt = false;
  constructor(
    private navCtrl: NavController,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    private elementRef: ElementRef,
    public router: Router,
    private route: ActivatedRoute,
    private dataTransferService: DataTransferService,
    private headerService: HeaderService,
    private appointmentService: AppointmentsService,
    public authService: AuthenticationService,
    public patientService: PatientService,
    public storageService: StorageService,
    public utilService: UtilsService,
    public ciedService: CiedService,
    private configurationService: ConfigurationService,
    private changeDetector: ChangeDetectorRef) {
      this.monitoringOptions = this.monitoringOptions.reduce((acc, curr) => {
        const featurePermissionEnabled = this.authService.hasPermission(curr.feature);
        curr['featurePermissionEnabled'] = featurePermissionEnabled;        
        if (featurePermissionEnabled) {
          acc.push(curr);
        }
        return acc;
      }, []);
    this.timezone = this.configurationService.getConfigurationDetails().hospitalTimezone;
    this.userData = this.dataTransferService.getUserData();
    if (this.userData.isFirstTimeLogin) {
      this.selectedChangePasswordPromptType = this.changePasswordPromptTypes.FIRST_TIME_LOGIN;
      this.changePassword();
    }
    this.dropdownDate = new FormGroup({
      past_date: new FormControl(''),
      future_date: new FormControl('')
    })
    this.subscribeChanges();

    // #53712
    this.rpmStandaloneEnableSubscription = this.headerService.getrpmStandaloneEnableEmitter().subscribe(params => {
      if(params) {
        this.rpmStandaloneEnable = params;
      }
    });

    this.updatedMonitoringOptionSubscription = this.headerService.getUpdatedMonitoringOptions().subscribe(params => {
      if(params) {
        this.monitoringOptions = params;
      }
    });

    this.updatedIsLoadedValueSubscription = this.headerService.getUpdatedIsLoadedValue().subscribe(params => {
      if(params) {
        this.isLoaded = params;
      }
    })

    this.rpmModeSubscription = this.headerService.getRpmModeChangeEmitter().subscribe(params => {
      if (params) {
        this.selectedMode = params;
        this.is_care_managemnt = this.careProgramTypes.includes(this.selectedMode) ? true : false; // set the mode based on selected mode value
        this.workFlowOption = this.monitoringOptions.find(obj => {
          return obj.value === this.selectedMode
        });
      }
    });

    // IMPROVE SWITCHING SPEED
    this.subscribetDetailsPageEntryEvent();

    this.enableWorkFlowBtnStatusSubscription = this.headerService.getEnableWorkflowBtnEventEmitter().subscribe(bool => {
      this.enableWorkFlowBn = bool;
    });

  }

  ngOnInit() {
    const isWorkflowPresent = this.monitoringOptions.findIndex(res => res.value === this.patientService.workflow) !== -1; // #53712
    this.selectedMode = this.patientService.workflow && isWorkflowPresent ? this.patientService.workflow : this.monitoringOptions[0].value; // #53712
    this.workFlowOption = this.monitoringOptions.find(obj => {
      return obj.value === this.selectedMode
    });
    this.is_care_managemnt = this.careProgramTypes.includes(this.selectedMode) ? true : false; // set the mode based on selected mode value
    this.isClicked = 0;
    this.isLogoutClicked = false;
    this.isHeaderLoaded = this.router.url === '/appointments' ? true : false;
    this.isHeaderDatesLoaded = this.router.url === '/appointments'  ? true : false;
    this.isRPMDashboard = this.router.url === '/rpmdashboard'  ? true : false;
    this.isPatientSearchScreen = this.router.url === '/patientsearch'  ? true : false;
    this.isEhrTransmissionScreen = this.router.url === '/ehrtransmission'  ? true : false;
    this.isPatientScreen = (this.router.url === '/appointments' 
    || this.router.url === '/rpmdashboard' 
    || this.router.url === '/analytics-dashboard'
    || this.router.url === '/patientsearch'
    || this.router.url === '/ehrtransmission') ? false : true;
    this.isCiedDetailsScreen = (this.router.url.includes('/cied-details')) ? true: false;
    this.isAnalyticsDashboardScreen = (this.router.url === '/analytics-dashboard') ? true: false;
    this.medicardiaLogo = this.isPatientScreen ? Constants.LOGO_URL.LOGO_WITHOUT_TEXT :
      (this.configurationService.getIsEHRAvailable() ? Constants.LOGO_URL.LOGO_EHR : 
      (this.utilService.themeKey == 'light' ? Constants.LOGO_URL.LIGHT_THEME_LOGO_WITH_TEXT : Constants.LOGO_URL.LOGO_WITH_TEXT));
    this.isFullScreen = this.authService.isFullScreen;
    this.isRpm = this.patientService.workflow == Constants.WORKFLOW.RPM;
    this.isCIED = this.patientService.workflow == Constants.WORKFLOW.CIED;
    // this.aboutBoxData = this.configurationService.getConfigurationDetails().about;
    this.isRPMOnlyMode = this.authService.checkFeaturePermission(Constants.PERMISSION_CHECK_TYPE.RPM_STANDALONE);
    this.initHeaderData();
    this.subscribeFuVisit();
    this.subscribeFullScreenMode();
    this.subscribeVideoNormalScreenMode();
    this.setHeaderContents(); // moved to down
    if (!this.isPatientScreen && !this.isRPMDashboard) {
      this.getClinicalData();
    } else {
      this.getHeaderCommonData();
      this.headers[0].value = this.dataTransferService.selectedLocation ? this.dataTransferService.selectedLocation.name : '';
    }
    //this.setHeaderContents();
  }

  // IMPROVE SWITCHING SPEED
  subscribetDetailsPageEntryEvent() {
    this.detailsPageEntrySubscription = this.headerService.getDetailsPageEntryEventEmitter().subscribe(resp => {
      let apiParams;

      if (resp && (resp.mode === 'clinical' || resp.mode === 'rpm' || resp.mode === 'ccm' || resp.mode === 'pcm')) {
         apiParams = {
          mr_id: this.headerService.selectedPatientInDetailsView && this.headerService.selectedPatientInDetailsView.mr_id ?
                  this.headerService.selectedPatientInDetailsView.mr_id.trim() : (resp.mr_id ? resp.mr_id : null),
        };
      } else if (resp && (resp.mode === 'cied')) {
        apiParams = {
          mr_id: this.headerService.selectedPatientInDetailsView && this.headerService.selectedPatientInDetailsView.mr_id ?
          this.headerService.selectedPatientInDetailsView.mr_id.trim() : (resp.mr_id ? resp.mr_id : null),
        };
      }
      // if (!this.headerService.selectedPatientInDetailsView) {
      //   this.headerService.selectedPatientInDetailsView = resp.patientData ? resp.patientData : '';
      // }
      if(apiParams && apiParams.mr_id){
        this.patientService.getPatientsByMrid(apiParams).subscribe(res => {
          if(this.headerService.selectedPatientInDetailsView) {
            // check any of the programs are selected or not
            if ((res && res.data && res.data.rpm_data && res.data.rpm_data.medicardia_id
              && res.data.rpm_data.medicardia_id === resp.medi_id) || (res && res.data && res.data.rpm_data && res.data.rpm_data.status) 
              || (res && res.data && res.data.rpm_data && res.data.rpm_data.ccm_status)
              || (res && res.data && res.data.rpm_data && res.data.rpm_data.pcm_status)) { //# header issues //T0621  
              if(res.data.rpm_data.program && res.data.rpm_data.program.includes("rpm") ){ 
                // && res.data.rpm_data.status && res.data.rpm_data.status.toLowerCase() === 'enrolled'
                this.headerService.selectedPatientInDetailsView['isRpmEnrolled'] = true;
              }
              if(res.data.rpm_data.program && res.data.rpm_data.program.includes("pcm") ){ 
                //&& res.data.rpm_data.pcm_status && res.data.rpm_data.pcm_status.toLowerCase() === 'enrolled'
                this.headerService.selectedPatientInDetailsView['isPcmEnrolled'] = true;
              }
              if(res.data.rpm_data.program && res.data.rpm_data.program.includes("ccm") ){ 
                //&& res.data.rpm_data.ccm_status && res.data.rpm_data.ccm_status.toLowerCase() === 'enrolled'
                this.headerService.selectedPatientInDetailsView['isCcmEnrolled'] = true;
              }
              // this.patientService.isEnrolled  = true; //# header issues //T0621 
              // commented as it shows error
    
            } else {
              this.headerService.selectedPatientInDetailsView['isRpmEnrolled'] = false;
              this.headerService.selectedPatientInDetailsView['isPcmEnrolled'] = false;
              this.headerService.selectedPatientInDetailsView['isCcmEnrolled'] = false;
              this.patientService.isEnrolled  = false; //# header issues //T0621 
    
            }
    
            if (res && res.data && res.data.cied_data && res.data.cied_data.medicardia_id
              && res.data.cied_data.medicardia_id === resp.medi_id) {
              this.headerService.selectedPatientInDetailsView['isCiedEnrolled'] = true;
    
            } else {
              this.headerService.selectedPatientInDetailsView['isCiedEnrolled'] = false;
    
            }
          }
        });
      }
      

    });
  }

  getGoAppointmentTooltip() {
    let tooltiptext = 'Appointment'
    if(this.is_care_managemnt) { // any care management then show patient list.
      tooltiptext = 'Patient List'
    } else if(this.isCIED) {
      tooltiptext = 'Patient List';

      if(this.ciedService.selectedTab == 'dashboard') {
        tooltiptext = 'Dashboard';
      } else if(this.ciedService.selectedTab == 'patient') {
        tooltiptext = 'Patient List';
      }
    }
    return tooltiptext;
  }

  ngAfterViewInit() {
    if(this.isRPMDashboard || this.isRpm || this.isCIED){
      this.dataTransferService.isRefreshed = false; // for stay in  the current mode rpm or clinical
    }
    const urlParams = this.router.url.split('sp=');
    if(urlParams[1] != undefined){
      let sp = urlParams[1].split('&')[0];
      if(sp == 'ecg'){
        // open the ecg box
        setTimeout(() => {
          this.patientService.setCardioAddEdit('ecg');
        }, 4000);
      }
    }
  }

  setNavParams(From, To, data) {
    return  {
      navFrom: From,
      navTo: To,
      patientData: data
    };
  }

  getVisitType(param, patient) {
    const visitType = param.navTo === Constants.WORKFLOW.CLINICAL ?
          (patient && patient.visit_type && patient.visit_type.value && patient.provider_id === this.userData.user_id ?
            patient.visit_type.value : Constants.APPOINTMENT_TYPES.IN_PERSON) : Constants.APPOINTMENT_TYPES.IN_PERSON;

    return visitType;
  }

  setWorkFlowAndCurrentPageViewAtService(mode) {
    this.patientService.workflow = mode;
    this.headerService.currentPageView = mode;
  }

  switchWorkFlow(event){
    this.workFlowOption = event.value;
    this.patientService.updateModeSwitch();
    this.dataTransferService.setModeSwitchStatus(true);
    //this.navCtrl.navigateRoot('appointments');
    var loaderTime = 2500; // 2.5 seconds
    if(!this.headerService.isRpmAlertsPage){
      loaderTime = 3000; // 3 seconds
      this.navCtrl.navigateRoot('appointments');
    }    
    setTimeout(() => {
      this.dataTransferService.setModeSwitchStatus(false);
    },loaderTime);
    if(event.value.value !== 'billing'){
      //this.navCtrl.navigateRoot('appointments');
      setTimeout(() => {
        this.getWorkFlowBasedAppointments(event.value); // billing not a mode. so no need to switch the mode.
        this.dataTransferService.emitHeaderModeChange(false); // emits that the mode is not billing
        this.headerService.emitRpmModeChangeEvent(event.value.value);
      }, 1500);  
    }else{
      this.navCtrl.navigateRoot('appointments');
      setTimeout(() => {
        this.utilService.updateWorkflowInStorage(event.value.value);
        this.selectedMode = event.value.value;
        this.dataTransferService.emitHeaderModeChange(true); // emit the mode is billing
        this.setWorkFlowAndCurrentPageViewAtService('billing');
        this.headerService.emitRpmModeChangeEvent('billing');
      }, 1200);
    }
  }
  // #53712 // IMPROVE SWITCHING SPEED
  getWorkFlowBasedAppointments(radioOption) {  
    this.utilService.updateWorkflowInStorage(radioOption.value); // Defects #57489 When user refresh the page in clinical mode ,it is redirected to RPM page.
    this.headerService.emitEnableWorkflowBtnEvent(false);
    this.selectedMode = radioOption.value;
    this.is_care_managemnt = this.careProgramTypes.includes(this.selectedMode) ? true : false; // set the mode based on selected mode value
    this.headerService.emitModeChangeEvent(this.selectedMode);
    this.patientService.workflow = this.selectedMode;
    this.previousMode = this.headerService.currentPageView;
    if (this.selectedMode !== Constants.WORKFLOW.CIED) { // #55442
      this.ciedService.selectedTab = 'dashboard';
    }
    //console.log('this.headerService.currentPageView header component', this.headerService.currentPageView);
    // New section for the switching logic ---------------------------Starts----------------------------------------------
    // TOTO: Go to appointments page if the use is currently on patient page // TODO: temp fix // || this.headerService.isDetailView || this.ciedService.isCiedPatientDetailsPage
    if(this.headerService.isRpmAlertsPage){ // if alerts page
      this.goToAppointment();
      this.dataTransferService.emitHeaderModeChange(false); // emits that the mode is not billing // TODO: temp fix
    }else if (this.headerService.isDetailView || this.ciedService.isCiedPatientDetailsPage){ // patient page or cied profile page
      let param;
      let url;
      // multiple cases
      if((this.careProgramTypes.includes(this.headerService.currentPageView) ||
      this.headerService.currentPageView === 'cied') && this.selectedMode === 'clinical'){ // to Clinical
        param = this.setNavParams(this.headerService.currentPageView,
        this.selectedMode, this.headerService.selectedPatientInDetailsView);
        const patient = param.patientData;
        const params = {
          pid: patient.medicardia_id ? patient.medicardia_id : '',
          aid: '',
        };

        const appointIdFromSession = (this.storageService.getStorageItem(sessionStorage, 'userData', true) 
        && this.storageService.getStorageItem(sessionStorage, 'userData', true).appointmentId) ?
        this.storageService.getStorageItem(sessionStorage, 'userData', true).appointmentId: '';
        if(appointIdFromSession) {
          params.aid = appointIdFromSession;
        }
        this.patientService.visitType = this.getVisitType(param, patient);
        url = `/patient/${params.pid}/${params.aid}`;
        this.setWorkFlowAndCurrentPageViewAtService('clinical');

      }else if((this.careProgramTypes.includes(this.headerService.currentPageView) ||
      this.headerService.currentPageView === 'clinical') && this.selectedMode === 'cied'){ // to CIED
        if (this.headerService.selectedPatientInDetailsView &&
          this.headerService.selectedPatientInDetailsView['isCiedEnrolled'] &&
          this.headerService.selectedPatientInDetailsView['isCiedEnrolled'] === true) {

          param = this.setNavParams(this.headerService.currentPageView,
          this.selectedMode, this.headerService.selectedPatientInDetailsView);
          const patient = param.patientData;
          const params = {
            mid: patient.medicardia_id,
            tid: '',
          };
          url = `/cied-details/${params.mid}/${params.tid}`;
          this.setWorkFlowAndCurrentPageViewAtService('cied');

        } else {
          this.goToAppointment();
        }
      } else if (((this.headerService.currentPageView === 'clinical' ||
      this.headerService.currentPageView === 'cied') && (this.careProgramTypes.includes(this.selectedMode)))
      || (this.careProgramTypes.includes(this.headerService.currentPageView) && this.careProgramTypes.includes(this.selectedMode))){ // to caremanagement

        if (this.headerService.selectedPatientInDetailsView && 
          ((this.selectedMode === "rpm" && this.headerService.selectedPatientInDetailsView['isRpmEnrolled'] && 
          this.headerService.selectedPatientInDetailsView['isRpmEnrolled'] === true)
          || (this.selectedMode === "pcm" && this.headerService.selectedPatientInDetailsView['isPcmEnrolled'] && 
          this.headerService.selectedPatientInDetailsView['isPcmEnrolled'] === true)
          || (this.selectedMode === "ccm" && this.headerService.selectedPatientInDetailsView['isCcmEnrolled'] && 
          this.headerService.selectedPatientInDetailsView['isCcmEnrolled'] === true))) {
          param = this.setNavParams(this.headerService.currentPageView,
            this.selectedMode, this.headerService.selectedPatientInDetailsView);
          const patient = param.patientData;
          const params = {
            pid: patient.medicardia_id ? patient.medicardia_id : '',
            aid: patient.appointment_id ? patient.appointment_id : '',
          };
          this.patientService.visitType = this.getVisitType(param, patient);
          url = `/patient/${params.pid}/${params.aid}`;
          this.setWorkFlowAndCurrentPageViewAtService(this.selectedMode);

        } else {
          this.goToAppointment();
        }

      } else {
        this.goToAppointment();
      } 

      if(url){
        this.navCtrl.navigateRoot(url);
      }
      this.headerService.emitModeChangeFromDetailsPageEvent(param);
    }
    // New section for the switching logic ---------------------------ends------------------------------------------------
  }

  getAppointmentDatesList(patientDetails) {
    const loggedUserData = this.dataTransferService.getUserData();
    const params = {
      medicardia_id: patientDetails && patientDetails.patientInfo && patientDetails.patientInfo.medicardia_id ? patientDetails.patientInfo.medicardia_id : null,
      // provider_id: loggedUserData.user_id ? loggedUserData.user_id : null,
      date: this.utilService.convertDatePickerTimeToMySQLTime(this.utilService.getCurrentDateTime())
    }
    if (this.selectedMode === 'clinical'){
      params['mode'] = 'clinical';
    }
    this.appointmentService.getAppointmentDateList(params).subscribe(
      res => {
        if (res && res.is_success && res.data && !this.utilService.isEmpty(res.data.appointments)) {
          this.pastDateList = res.data.appointments.past && (res.data.appointments.past.length > 0) ? res.data.appointments.past : [];
          this.futureDateList = res.data.appointments.future && (res.data.appointments.future.length > 0) ? res.data.appointments.future : [];
          this.formatAppointmentDates(this.pastDateList);
          this.formatAppointmentDates(this.futureDateList);
          this.isHeaderDatesLoaded = true;
          this.dropdownDate.patchValue({
            past_date: _.find(this.pastDateList, ['appointment_date', this.currentAppointmentDate]),
            future_date: _.find(this.futureDateList, ['appointment_date', this.currentAppointmentDate]),
          })
        }
      },
      err => {
      });
  }
  getParticipantData(event) {
    if (!this.utilService.isEmpty(event.data)) {
      if (!event.data.participantDetails.audio && !event.data.participantDetails.video) {
        telemedwebrtc.setViewMode(Constants.VISIT_MODE.FULLSCREEN);
      }
    }
  }
  formatAppointmentDates(list) {
    if (list.length > 0) {
      list.forEach(item => {
        item.appointment_date = moment(item.appointment_date['$date']).tz(this.timezone).format('DD MMM YYYY hh:mm A');
      });
    }
  }
  subscribeChanges() {
    this.patientService.resetWorkflow.subscribe(val => {
      this.isRpm = this.patientService.workflow == Constants.WORKFLOW.RPM;
      this.isCIED = this.patientService.workflow == Constants.WORKFLOW.CIED;
      this.initHeaderData();
      this.getHeaderCommonData();
      this.setHeaderValues();
      this.setHeaderContents();
    })
    this.discardConsentSubscription = this.patientService.discardConsent.subscribe(res => {
      if (res && !this.utilService.isEmpty(res) && Constants.COMPONENTS.HEADER === res.component) {
        if (res.status) {
          this.utilService.resetIsDirty();
          this.navCtrl.navigateRoot('appointments');
        }
      }
    });
    this.rpmDataSubscription = this.patientService.rpmData.subscribe(res => {
      this.setReadOnlyIcon();
    });
  }

  emitVideoMode(selectedMode, action) {
    const data = {
      selectedMode,
      isExitedMeeting: this.isLeaveMeetingClicked,
      action
    };
    this.patientService.setIsVideoNormalScreen(data);
  }

  exitMeetingProgramatically() {
    this.isProgramaticallyExited = true;
    if (this.videocontainer && this.videocontainer.nativeElement
      && this.videocontainer.nativeElement.querySelector('#leave-meeting-thumbnail')) {
      this.videocontainer.nativeElement.querySelector('#leave-meeting-thumbnail').click();
    }
  }

  updateAction(action) {
    this.selectedVideoAction = action;
    switch (action) {
      case this.videoVisitActions.SCREENSHARE_TOGGLE:
        this.stopScreenshare();
        break;
      case this.videoVisitActions.VIDEO_TOGGLE:
        break;
      case this.videoVisitActions.LEAVE_MEETING:
        this.leaveMeeting();
        break;
      case this.videoVisitActions.LEAVE_MEETING_THUMBNAIL:
        this.exitMeetingProgramatically();
        break;
      case this.videoVisitActions.THUMBNAIL_VIEW:
        break;
      case this.videoVisitActions.FULLSCREEN_VIEW:
        break;
      case this.videoVisitActions.IN_PERSON:
        break;
      case this.videoVisitActions.MIC_TOGGLE:
        break;
    }
    if (!this.isInitiatedFromFullscreen) {
      this.emitVideoMode(this.selectedVisitMode, action);
    }
  }

  leaveMeeting() {
    this.isLeaveMeetingClicked = !this.isProgramaticallyExited;
    this.selectedVisitMode = Constants.VISIT_MODE.FULLSCREEN;
  }

  stopScreenshare() {
    this.isScreenshareStopped = true;
    this.selectedVisitMode = Constants.VISIT_MODE.FULLSCREEN;
    this.exitMeetingProgramatically();
  }

  initTeleMedWebRtc() {
    this.isScreenshareStopped = false;
    this.isLeaveMeetingClicked = false;
    this.isInitiatedFromFullscreen = false;
    if (this.selectedVisitMode !== undefined && this.selectedVisitMode !== this.visitModes.MEDICARDIA && this.isPatientScreen) {
      if (this.videocontainer && this.videocontainer.nativeElement && this.videocontainer.nativeElement.children
        && this.videocontainer.nativeElement.children[0]
        && this.selectedVisitMode === this.visitModes.THUMBNAIL) {
        setTimeout(() => {
          if (this.videocontainer.nativeElement.children[0].children.length === 0) {
            if (this.callFrameContainer && this.callFrameContainer.nativeElement) {
              this.listenerEvent = this.callFrameContainer.nativeElement.addEventListener('participantDataUpdated',
                this.getParticipantData.bind(this), false);
            }
            telemedwebrtc.init(this.patientData.patientInfo.video_url, this.visitModes.THUMBNAIL);
          }
          setTimeout(() => {
            this.videocontainer.nativeElement.querySelector('#join-meeting-thumbnail').click();
          }, 1000);
        }, 50);
      }
    }
  }

  updateLocation(data) {
    this.dataTransferService.setLocationDetails(data.value);
  }

  setVideoNormalScreenMode(videoMode) {
    // this.patientService.setIsVideoNormalScreen(videoMode);
    this.setHeaderContents();
  }

  private subscribeVideoNormalScreenMode() {
    this.videoScreenModeSuscribe = this.patientService.getIsVideoNormalScreenMode
      .subscribe((res: any) => {
        /* if (res.selectedMode) {
          this.isProgramaticallyExited = false;
          const visitModeSelected = res.selectedMode;
          if (visitModeSelected !== this.selectedVisitMode) {
            this.selectedVisitMode = visitModeSelected;
            this.setHeaderContents();
            this.initTeleMedWebRtc();
          }
        }
        if (res.action === this.videoVisitActions.LEAVE_MEETING_THUMBNAIL) {
          this.isInitiatedFromFullscreen = res.initiatedFromFullscreen ? res.initiatedFromFullscreen : false;
          this.updateAction(res.action);
        } */
      });
  }

  setReadOnlyIcon() {
    this.isReadOnlyView = (this.userData.permission[0].group.name === 'Physician' &&
      !this.utilService.isAssignedPhysician()) || (!this.utilService.isEmpty(this.patientData) &&
        !this.utilService.isEmpty(this.patientData.patientInfo) &&
        this.patientData.patientInfo.appointment_status === 'Cancelled' &&
        this.patientService.workflow === Constants.WORKFLOW.CLINICAL) ? true : false;
  }

  private subscribeFuVisit() {
    this.fuVisitSubscription = this.headerService.getFuVisit //# header issues //T0621
    .subscribe(patientDetails => {
      if (patientDetails) {
        this.getAppointmentDatesList(patientDetails);
      }
      // this.currentAppointmentDate = this.dataTransferService.patientDemographic && this.dataTransferService.patientDemographic.appointment_date ? moment(this.dataTransferService.patientDemographic.appointment_date).tz(this.timezone).format('DD MMM YYYY hh:mm A') : null;
      this.currentAppointmentDate = this.dataTransferService.patientDemographic && this.dataTransferService.patientDemographic.appointment_date ? moment(this.dataTransferService.patientDemographic.appointment_date).tz(this.timezone).format('DD MMM YYYY hh:mm A') 
      : (patientDetails && patientDetails.patientInfo && patientDetails.patientInfo.appointment_date ? moment(patientDetails.patientInfo.appointment_date).tz(this.timezone).format('DD MMM YYYY hh:mm A') :null ); //header issues //T0621
      if (this.isPatientScreen) {
        this.patientData = patientDetails;
        this.changeDetector.detectChanges();
        this.patientInfo = patientDetails && patientDetails.patientInfo ?
          patientDetails.patientInfo : undefined;
        if (0 === this.headers.length) {
          this.initHeaderData();
        }
        this.setReadOnlyIcon();
        this.getHeaderCommonData();
        this.setHeaderValues();
        this.setHeaderContents();
        // this.initTeleMedWebRtc();
        
        //console.log(patientDetails,'----------------')
      }
    });
  }
  setHeaderValues() {
    const userDetails = this.storageService.getStorageItem(sessionStorage, 'userData', true);
    // this.currentAppointmentDate = this.patientInfo && this.patientInfo.appointment_date && this.selectedMode === "clinical" && this.previousMode === "rpm" ? "":this.currentAppointmentDate;
    this.headers.forEach(item => {
      if(item.key.toLowerCase() == 'provider') {
        item.value = !userDetails.appointmentId && this.selectedMode === 'clinical' ? "-": (this.patientInfo && this.patientInfo.provider_name ? this.patientInfo.provider_name : '-');
      } else if(item.key.toLowerCase() == 'insurance') {
        item.value = this.patientInfo && this.patientInfo.insurance ? this.patientInfo.insurance : 'NA';
      } else if(item.key.toLowerCase() == 'mr id') {
        item.value = this.patientInfo && this.patientInfo.mr_id ? this.patientInfo.mr_id : 'NA';
      } else if(item.key.toLowerCase() == 'location') {
        item.value = this.dataTransferService.selectedLocation ? this.dataTransferService.selectedLocation.name : '';
      }else if(item.key.toLowerCase() == 'pcp') {
        item.value = !userDetails.appointmentId && this.selectedMode === 'clinical'  ? "-" : ( this.patientInfo && this.patientInfo.pcp ? this.patientInfo.pcp : '-');
        item.toBeDisplayed = this.selectedMode.toLowerCase() === "clinical" ? true : false;
      }else if(item.key.toLowerCase() == 'reason') {
        item.value = !userDetails.appointmentId && this.selectedMode === 'clinical' ? "NA": (!this.utilService.isEmpty(this.patientInfo) && this.patientInfo.fu_visit ?
        this.patientInfo.fu_visit : 'NA');
        item.iconName = !this.utilService.isEmpty(this.patientInfo) && !this.patientInfo.last_visited ?
      'icon-reason' : 'icon-visit';
        item.toBeDisplayed = this.selectedMode.toLowerCase() === "clinical" ? true : false;
      } else if(item.order == 5) {
        item.toBeDisplayed = this.selectedMode.toLowerCase() === "clinical" ? true : false;
      } else if(item.order == 3 ) {
        item.toBeDisplayed = this.selectedMode.toLowerCase() === "clinical" ? true : false;
      } 
    })    
    this.isHeaderLoaded = true;
  } //# header issues //T0621

  
  setHeaderContents() {
    const headerList = this.utilService.deepCopy(this.headers);
    if(this.isCiedDetailsScreen) {
      this.headerRightList = (headerList && headerList.length) ? headerList.slice(0, 2) : [];
      this.headerCenterList = (headerList && headerList.length) ? [headerList[3]] : [];
      this.headerLeftList = (headerList && headerList.length) ? [headerList[6]] : [];
    } else {
      this.headerRightList = this.isPatientScreen ? headerList.slice(0, 3) : this.headers;
      this.headerCenterList = this.isPatientScreen ? headerList.slice(3, 6) : [];
      this.headerLeftList = this.isPatientScreen ? headerList.slice(6, 8) : [];
    }
    // this.initTeleMedWebRtc();
  }

  /**
   * @description To getclinical data
   */
  private getClinicalData() {
    this.getHeaderCommonData();
    let selectedLocation;
    this.locationOptions = this.userData.permission.map(res => res.hospital);
    if (this.dataTransferService.selectedLocation) {
      this.headers[0].value = this.locationOptions.length > 1 ? this.dataTransferService.selectedLocation :
        this.dataTransferService.selectedLocation.name;        
      selectedLocation = this.dataTransferService.selectedLocation;
    } else {
      this.headers[0].value = (this.locationOptions.length > 1 ? this.locationOptions[0] : this.locationOptions[0].name);
      selectedLocation = this.locationOptions[0];
    }
    this.dataTransferService.setLocationDetails(selectedLocation);
  }

  getHeaderCommonData() {
    this.headers[3].value = `${this.userData.last_name}, ${this.userData.first_name} `;
    // this.headers[3].value = !this.isPatientScreen ? this.utilService.getCurrentDateTime() :
    //   (this.patientInfo && this.patientInfo.appointment_date ?
    //     moment(this.patientInfo.appointment_date).tz(this.timezone) : undefined);
  }


  private initHeaderData() {
    this.headers = [
      {
        key: 'Location',
        value: undefined,
        iconName: 'icon-location',
        toBeDisplayed: true,
        className: this.isPatientScreen && this.isRPMOnlyMode ? 'location header-col padding-left' : 'location header-col',
        order: 8
      },
      {
        key: 'Insurance',
        value: undefined,
        iconName: 'icon-insurance',
        toBeDisplayed: this.isPatientScreen && !this.isRPMOnlyMode,
        className: 'insurance header-col',
        order: 7
      },
      /* commented while CIED implementation - Removed this from the header - Added worked flow button in the header */
      // {
      //   key: 'Appt. History',
      //   value: undefined,
      //   iconName: 'icon-date',
      //   toBeDisplayed: this.isPatientScreen && !this.isRPMOnlyMode && !this.isRpm,
      //   className: 'last-visit header-col',
      //   order: 6
      // },
      {
        key: 'Appt. Dates',
        value: undefined,
        iconName: 'icon-date',
        toBeDisplayed: this.isPatientScreen && !this.isRPMOnlyMode && !this.isRpm,
        className: 'date-field header-col',
        order: 5
      },
      {
        key: 'Provider',
        value: undefined,
        iconName: 'icon-Provider',
        toBeDisplayed: true,
        className: 'provider header-col',
        order: 4
      },
      {
        key: 'Reason',
        value: undefined,
        iconName: 'icon-reason',
        toBeDisplayed: this.isPatientScreen && !this.isRPMOnlyMode,
        className: 'reason header-col',
        order: 3
      },
      {
        key: 'PCP',
        value: undefined,
        iconName: 'icon-Provider',
        toBeDisplayed: this.isPatientScreen && !this.isRPMOnlyMode,
        className: 'pcp header-col',
        order: 2
      },
      // {
      //   key: 'Date [CST]',
      //   value: moment(this.utilService.getCurrentDateTime()).tz(this.timezone).format('DD MMM YYYY'),
      //   iconName: 'icon-date',
      //   toBeDisplayed: true,
      //   className: 'date-field header-col',
      //   order: 2
      // },
      {
        key: 'MR ID',
        value: undefined,
        iconName: 'icon-Provider',
        toBeDisplayed: this.isPatientScreen && !this.isRPMOnlyMode,
        className: 'mr-id header-col',
        order: 1
      },
    ];
  }

  toggleProfileOptionsView(clicked = false) {
    this.isLogoutClicked = clicked ? true : false;
    this.isClicked = 0;
    this.showProfileOptions = this.isLogoutClicked ? true : false;
  }

  openDiscardChangePop() {
    this.dataTransferService.openDiscardPopup({ component: Constants.COMPONENTS.HEADER });
  }

  clearAppointIdFromSessionStorage() {
    const storageData: any = {};
    const userDetails = this.storageService.getStorageItem(sessionStorage, 'userData', true);
    userDetails['appointmentId'] = '';
    storageData['userData'] = userDetails;
    this.storageService.setStorageItem(sessionStorage, storageData, true);
  }

  goToAppointment() {
    this.clearAppointIdFromSessionStorage();
    this.headerService.isDetailView = false;
    this.headerService.currentPageView = '';
    if (this.patientService.isDirty.status) {
      this.openDiscardChangePop();
    } else {
      this.navCtrl.navigateRoot('appointments');
    }
  }

  goToAnalyticsDashboard() {

    this.navCtrl.navigateRoot('analytics-dashboard');

  }
  navigateToPatientDetails(key) {
    this.previousMode = "clinical";
    const params = {
      pid: this.patientData.patientInfo && this.patientData && this.patientData.patientInfo.medicardia_id ? this.patientData.patientInfo.medicardia_id : null,
      aid: key === 1 ? (this.dropdownDate.value && this.dropdownDate.value.future_date && this.dropdownDate.value.future_date.appointment_id ? this.dropdownDate.value.future_date.appointment_id : '') :
        (this.dropdownDate.value && this.dropdownDate.value.past_date && this.dropdownDate.value.past_date.appointment_id ? this.dropdownDate.value.past_date.appointment_id : '')
    };
    if (key === 1) {
      this.dropdownDate.patchValue({
        past_date: null,
      });
    } else {
      this.dropdownDate.patchValue({
        future_date: null,
      });
    }
    const url = `/patient/${params.pid}/${params.aid}`;
    this.navCtrl.navigateRoot(url);
  }

  logOut() {
    this.authService.logout().subscribe(res => {
      if (res.is_success) {
        if (!this.isPatientScreen) {
          this.dataTransferService.selectedLocation = undefined;
        }
        this.dataTransferService.selectedProvider = undefined;
        this.patientService.workflow = undefined;
        this.dataTransferService.setUserData(undefined);
        this.ciedService.selectedTab = 'dashboard';
        this.ciedService.dashboardSelectedProvider = undefined;
        this.ciedService.dashboardSelectedFilter = undefined;
        this.ciedService.dashboardSelectedStatus = undefined;
        this.ciedService.dashboardSelectedSearch = undefined;
        this.ciedService.patientSelectedProvider = undefined;
        this.storageService.clearStorage();
        this.navCtrl.navigateRoot('login');
      } else {
        this.utilService.showToast(Constants.MESSAGE.LOGOUT_FAILED, 'error');
      }
    });
  }

  onClick(event) {
    this.isClicked = this.isClicked + 1;
    if ((event && !this.elementRef.nativeElement.contains(event.target) && this.showProfileOptions)
      || this.isClicked === 2) {
      this.toggleProfileOptionsView();
    }
  }

  tooltipText(event, data) {
    if (data) {
      const params = {
        data,
      };
      const cls = 'header-popover';
      this.utilService.showTooltip(event, params, cls);
    }
  }

  exitFullScreen() {
    if (this.isFullScreen) {
      this.authService.setIsFullScreenModeEnabled(false);
    } else {
      this.authService.setIsFullScreenModeEnabled(true);
    }
  }

  subscribeFullScreenMode() {
    this.authService.getIsFullScreenModeEnabled.subscribe(mode => {
      this.isFullScreen = mode;
    });
  }

  // subscribe change in vital
  // onVitalChange() {
  //   this.reloadVitalCharts();
  // }

  // reloadVitalCharts(params?) {
  //   if (this.vitalInfo) {
  //     const selectedVitals = this.vitalInfo.selectedVitals;
  //     this.reloadCharts(selectedVitals, params);
  //   }
  // }

  async changePassword() {
    this.changePasswordModal = await this.modalCtrl.create({
      component: ChangePasswordPopupPage,
      backdropDismiss: false,
      cssClass: 'change-password-popup',
    });
    this.changePasswordModal.onDidDismiss().then((res: any) => {
      if (res && res.data) {
        if (res.data.is_success) {
          switch (this.selectedChangePasswordPromptType) {
            case this.changePasswordPromptTypes.USER_INITIATED:
              this.presentAlert(Constants.MESSAGE.PASSWORD_UPDATION.CHANGE_PASSWORD_SUCCESS);
              break;
            case this.changePasswordPromptTypes.FIRST_TIME_LOGIN:
              this.userData.isFirstTimeLogin = false;
              this.dataTransferService.setUserData(this.userData);
              this.appointmentService.reloadApppointment(true);
              break;
          }
        }
        if (this.userData.isFirstTimeLogin && res.data.isCancelled) {
          this.logOut();
        }
      }
    });
    await this.changePasswordModal.present();
  }

  async presentAlert(message) {
    this.alertPopup = await this.alertCtrl.create({
      header: 'Password Updated',
      subHeader: message,
      buttons: [Constants.BUTTON_TEXT.okay],
      backdropDismiss: false
    });
    this.alertPopup.onDidDismiss().then((res: any) => {
      if (this.selectedChangePasswordPromptType === this.changePasswordPromptTypes.USER_INITIATED) {
        this.logOut();
      }
    });
    await this.alertPopup.present();
  }

  async openAboutBox() {
    this.aboutBoxPage = await this.modalCtrl.create({
      component: ViewAboutBoxPage,
      backdropDismiss: true,
      cssClass: 'about-box-pop',
    });
    this.aboutBoxPage.onDidDismiss().then((res: any) => {
    });
    await this.aboutBoxPage.present();
  }

  async userProfile(){
    this.userProfilePage = await this.modalCtrl.create({
      component: UserProfilePage,
      backdropDismiss: false,
      cssClass: 'user-profile-pop',
    });
    this.userProfilePage.onDidDismiss().then((res: any) => {
    });
    await this.userProfilePage.present();
  }

  ngOnDestroy() {
    this.discardConsentSubscription ? this.discardConsentSubscription.unsubscribe() : undefined;
    this.changePasswordModal ? this.changePasswordModal.dismiss() : undefined;
    this.alertPopup ? this.alertPopup.dismiss() : undefined;
    this.vitalChangeSubscription ? this.vitalChangeSubscription.unsubscribe() : undefined;
    this.videoScreenModeSuscribe ? this.videoScreenModeSuscribe.unsubscribe() : undefined;
    if (this.fuVisitSubscription) {
      this.fuVisitSubscription.unsubscribe();
    }
    this.aboutBoxPage ? this.aboutBoxPage.dismiss() : undefined;
    this.userProfilePage ? this.userProfilePage.dismiss() : undefined;
    this.rpmDataSubscription ? this.rpmDataSubscription.unsubscribe() : undefined;
    this.rpmStandaloneEnableSubscription.unsubscribe();
    this.rpmModeSubscription.unsubscribe();
    this.updatedMonitoringOptionSubscription.unsubscribe();
    this.updatedIsLoadedValueSubscription.unsubscribe();
    this.detailsPageEntrySubscription.unsubscribe();
    this.enableWorkFlowBtnStatusSubscription.unsubscribe();
  }
}
