import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RangeFactorComponent } from './range-factor.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';



@NgModule({
  declarations: [RangeFactorComponent],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    PerfectScrollbarModule
  ],
   exports: [
    RangeFactorComponent
  ]
})
export class RangeFactorModule { }
