import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LaunchFromEpicComponent } from 'src/app/modules/pages/launch-from-epic/launch-from-epic.component';
import { Routes } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { OverlayLoaderModule } from 'src/app/shared/components/overlay-loader/overlay-loader.module';

const routes: Routes = [
  {
    path: '',
    component: LaunchFromEpicComponent
  }
];

@NgModule({
  declarations: [LaunchFromEpicComponent],
  imports: [
    CommonModule,
    IonicModule,
    OverlayLoaderModule
  ],
  exports: [LaunchFromEpicComponent]
})
export class LaunchFromEpicModule { }
