import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeNewline'
})
export class RemoveNewlinePipe implements PipeTransform {

  transform(value: string) {
    let stringData: any = value;
    if(stringData && stringData.length){
      stringData = stringData.replace(/(\r\n|\n|\r)/gm, ""); 
    }       
    return stringData;
  }

}
