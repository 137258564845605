import { Directive, Input, ElementRef, OnChanges } from '@angular/core';
import { AuthGroup } from 'src/app/core/models/role-permission';
import { AuthenticationService } from 'src/app/core';
import { PatientService } from 'src/app/modules/components/patient/patient.service';
import { Constants } from '../../constants';

@Directive({
  selector: '[appDisableFeature]'
})
export class DisableFeatureDirective implements OnChanges {
  permissionCheckTypes = Constants.PERMISSION_CHECK_TYPE;

  @Input('appDisableFeature') featureName: AuthGroup; // Required featureName passed in
  @Input() isRPMCheckRequired = false;
  @Input() isEHRCheckRequired = false;
  constructor(private el: ElementRef, private authService: AuthenticationService, private patientService: PatientService) { }

  ngOnChanges() {
    const show = this.authService.hasPermission(this.featureName);
    const isAuthorized = this.isEHRCheckRequired ? this.authService.checkFeaturePermission(this.permissionCheckTypes.EHR) : true;
    const isRpm = this.patientService.workflow === Constants.WORKFLOW.RPM && this.isRPMCheckRequired;
    if (!show) {
       this.el.nativeElement.disabled = true;
    }
  }

}
