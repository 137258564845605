import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AvatarPipe } from './avatar/avatar';
import { CustomDatePipe } from './custom-date/custom-date';
import { DropdownPipe } from './dropdown/dropdown';
import { GenderPipe } from './gender/gender';
import { LbsToKgPipe } from './lbs-to-kg/lbs-to-kg';
import { SafePipe } from './safe-pipe/safe-pipe';
import { TrimPipe } from './trim/trim';
import { MagnitudePipe } from './magnitude/magnitude.pipe';
import { PhoneNumberPipe } from './phone-number-pipe/phone-number-pipe';
import { HighlightPipe } from './highlight/highlight.pipe';
import { ToHourPipe } from './to-hour/to-hour.pipe';
import { RemoveNewlinePipe } from './remove-newline/remove-newline.pipe';
import { SafeHtmlPipe } from './safe-html/safe-html.pipe';


@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    LbsToKgPipe,
    DropdownPipe,
    SafePipe,
    GenderPipe,
    AvatarPipe,
    CustomDatePipe,
    TrimPipe,
    MagnitudePipe,
    PhoneNumberPipe,
    HighlightPipe,
    ToHourPipe,
    RemoveNewlinePipe,
    SafeHtmlPipe
  ],
  exports: [
    LbsToKgPipe,
    DropdownPipe,
    SafePipe,
    GenderPipe,
    AvatarPipe,
    CustomDatePipe,
    TrimPipe,
    MagnitudePipe,
    PhoneNumberPipe,
    HighlightPipe,
    ToHourPipe,
    RemoveNewlinePipe,
    SafeHtmlPipe
  ]  
})
export class PipesModule { }
