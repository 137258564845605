import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { AuthenticationService } from 'src/app/core';
import { DataTransferService } from 'src/app/core/services/data-transfer/data-transfer.service';
import { PatientService } from 'src/app/modules/components/patient/patient.service';
import { UtilsService } from '../../common';
import { Constants } from '../../constants';

@Component({
  selector: 'app-header-tab',
  templateUrl: './header-tab.component.html',
  styleUrls: ['./header-tab.component.scss'],
})
export class HeaderTabComponent implements OnInit, OnChanges, OnDestroy {
  currentTab = 0;
  collapse = false;
  selectedTab: any;
  perRowTabs: number;
  discardConsentSubscription: any;
  cardioEditPopup = Constants.COMPONENTS.CARDIO_EDIT;
  visitModes = Constants.VISIT_MODE;
  ehrPermission:boolean;
  showMoreIconOrange: boolean = false;

  isRpm: boolean;
  @Input() pdfList;
  @Input() Tabset;
  @Input() selectedVisitMode: any;
  @Output() sendDate = new EventEmitter<string>();
  @Output() isCollapsed = new EventEmitter<boolean>();
  @Input() hasPermission: boolean;

  constructor(
    public utilService: UtilsService,
    private patientService: PatientService,
    private dataTransferService: DataTransferService,
    public authService: AuthenticationService) {
    this.subscribeChanges();
  }

  ngOnChanges() {
    this.currentTab = this.Tabset ? this.Tabset : 0;
    this.selectedTab = this.currentTab;
    this.emitSelected(this.currentTab);
    if(!this.Tabset){
      this.collapse = false;
    }
    if (this.currentTab > this.perRowTabs) {
      this.openCollapse(true);
    }
    this.perRowTabs = this.selectedVisitMode === this.visitModes.FULLSCREEN ? (
      window.innerWidth >= 1200 ? (window.innerWidth >= 1800 ? 5 : (window.innerWidth <= 1500 ? 3 : 4)) : 3) :
      (window.innerWidth >= 1200 ? (window.innerWidth >= 1800 ? 7 : (window.innerWidth <= 1500 ? 5 : 6)) : 4);   
      if(this.pdfList && this.pdfList.length > this.perRowTabs){
        this.showMoreIconOrange = false;
        for (let index = this.perRowTabs; index < this.pdfList.length; index++) {
          if(this.pdfList[index] && this.pdfList[index].is_reviewed == false){
            this.showMoreIconOrange = true;
          }
        }

      }   
  }

  ngOnInit() {
    //console.log('------------pdfList---------------',this.pdfList)
    this.ehrPermission = this.authService.checkFeaturePermission(Constants.PERMISSION_CHECK_TYPE.EHR);
    this.isRpm = this.patientService.workflow === Constants.WORKFLOW.RPM;
  }

  onChangeTab(tabIndex) {
    this.selectedTab = tabIndex;
    this.openDiscardChangePop(this.selectedTab);
  }

  openCollapse(isCollapsed?) {
    this.collapse = isCollapsed !== undefined ? isCollapsed : !this.collapse;
    this.isCollapsed.next(this.collapse);
  }

  emitSelected(tabIndex) {
    this.currentTab = tabIndex;
    if (tabIndex != -1 && this.pdfList[tabIndex] && this.pdfList[tabIndex].object_id) {
      this.sendDate.next(this.pdfList[tabIndex].object_id);
    } else {
      this.sendDate.next(tabIndex);
    }
  }

  openDiscardChangePop(selectedId) {
    if (this.patientService.isDirty.component.includes(Constants.COMPONENTS.CARDIO_EDIT)) {
      const params = { component: this.cardioEditPopup, rightSideComponent: Constants.COMPONENTS.SUMMARY };
      this.dataTransferService.openDiscardPopup(params);
    } else {
      this.emitSelected(selectedId);
    }
  }

  subscribeChanges() {
    this.discardConsentSubscription = this.patientService.discardConsent.subscribe(res => {
      if (res && !this.utilService.isEmpty(res) && this.cardioEditPopup == res.component) {
        if (res.status && this.selectedTab != this.currentTab) {
          this.emitSelected(this.selectedTab);
        }
      }
    });
  }

  ngOnDestroy() {
    this.discardConsentSubscription.unsubscribe();
  }
}
