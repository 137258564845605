import { Constants } from 'src/app/shared/constants';
import * as _ from 'lodash';
import moment from 'moment';

export interface CardioInterface {
    val: any,
    nlp_val: any,
    source: string,
    nlp_key_start_index: number,
    nlp_key_end_index: number,
    nlp_val_start_index: number,
    nlp_val_end_index: number,
    nlp_key: string
}

export class CardioModel {

    public static create(data): CardioInterface {
        return {
            val: data && data.val ? data.val : null,
            nlp_val: data && data.nlp_val ? data.nlp_val : null,
            source: data && data.source ? data.source : null,
            nlp_key_start_index: data && data.nlp_key_start_index != null ? data.nlp_key_start_index : null,
            nlp_key_end_index: data && data.nlp_key_end_index != null ? data.nlp_key_end_index : null,
            nlp_val_start_index: data && data.nlp_val_start_index != null ? data.nlp_val_start_index : null,
            nlp_val_end_index: data && data.nlp_val_end_index != null ? data.nlp_val_end_index : null,
            nlp_key: data && data.nlp_key ? data.nlp_key : null,
        }
    }

    public static createParams(data,nlpData = null): CardioInterface {
        return {
            val: data ? data : null,
            nlp_val: nlpData ? nlpData.nlp_val : null,
            source: 'manual',
            nlp_key_start_index: nlpData ? nlpData.nlp_key_start_index : null,
            nlp_key_end_index: nlpData ? nlpData.nlp_key_end_index : null,
            nlp_val_start_index: nlpData ? nlpData.nlp_val_start_index : null,
            nlp_val_end_index: nlpData ? nlpData.nlp_val_end_index : null,
            nlp_key: nlpData ? nlpData.nlp_key : null,
        }
    }
}