import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatientVitalInfoComponent } from './patient-vital-info.component';
import { VitalsModalPageModule } from 'src/app/modules/modals/vitals-modal/vitals-modal.module';
import { VitalsModalPage } from 'src/app/modules/modals/vitals-modal/vitals-modal.page';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [
    PatientVitalInfoComponent
  ],
  imports: [
    IonicModule,
    CommonModule,
    DirectivesModule,
    PipesModule,
    NgbTooltipModule
  ],
  exports: [
    PatientVitalInfoComponent,
    VitalsModalPageModule
  ],
  entryComponents: [
    VitalsModalPage
  ]
})
export class PatientVitalInfoModule { }
