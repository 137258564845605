import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UtilsService } from 'src/app/shared/common';
import { Constants } from 'src/app/shared/constants';
import { AboutBoxService } from './about-box.service';
import { ENV } from 'src/environments/environment';

@Component({
  selector: 'app-view-about-box',
  templateUrl: './view-about-box.page.html',
  styleUrls: ['./view-about-box.page.scss'],
})
export class ViewAboutBoxPage implements OnInit {

  public aboutData: any = {};
  noData = Constants.MESSAGE.NO_DATA.NO_DATA;
  loadingData = 'Loading...';
  isLoaded = false;
  buttonOk = Constants.BUTTON_TEXT.okay;
  appVersion = '';

  constructor(
    public modalCtrl: ModalController,
    public utilService: UtilsService,
    public aboutBoxService: AboutBoxService) { }

  ngOnInit() {
    this.getAboutBoxData();
    this.getAppVersion();
  }

  getAppVersion() {
    this.appVersion = ENV.version;
  }

  closeModal(params = {}) {
    this.modalCtrl.dismiss(params);
  }

  public onOpenMainManual() {
    const manualFileUrl = this.aboutData.user_manual;
    window.open(manualFileUrl, '_blank');
  }
  public onOpenReleaseNote() {
    const manualFileUrl = this.aboutData.release_note;
    window.open(manualFileUrl, '_blank');
  }
  getAboutBoxData() {
    const params = {};
    this.aboutBoxService.getAboutBoxData(params).subscribe(res => {
      this.isLoaded = true;
      if (res.is_success && res.data && !this.utilService.isEmpty(res.data)) {
        this.aboutData = res.data && res.data.about ? res.data.about : {};
        if (this.aboutData && this.aboutData.website) {
          this.aboutData.website = this.aboutData.website;
        }
        this.aboutData.address = this.aboutData && this.aboutData.address ? this.aboutData.address : Constants.MEDICARDIA_ADDRESS;
      } else if (!res.is_success && res.message) {
        this.utilService.showToast(res.message, 'danger');
      }
    }, err => err);
  }
}
