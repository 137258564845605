import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { LoaderService } from '../loader/loader.service';
import { DataTransferService } from '../data-transfer/data-transfer.service';
import { UtilsService } from 'src/app/shared/common';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  apiCount = 0;

  constructor(
    public loaderService: LoaderService,
    private dataTransferService: DataTransferService,
    private utilService: UtilsService,
  ) { }



  getUpdatedBy() {
    const loggedUserData = this.dataTransferService.getUserData();
    return loggedUserData && loggedUserData.user_id && this.dataTransferService.patientDemographic &&
      this.dataTransferService.patientDemographic.provider_id &&
      loggedUserData.user_id !== this.dataTransferService.patientDemographic.provider_id ?
      this.dataTransferService.patientDemographic.provider_id : undefined;
  }

  /**
   * @description The header variable "cp" means the "current provider in the session / 
   *              patient chart". This variable shall be set only if the application finds that the 
   *              physician or nurse who has taken the patient chart , which is not being scheduled for them. 
   * @param request HttpRequest
   * @param updatedUsersId Id of the physician or nurse who has taken the patient chart , which is not being scheduled for them. 
   */
  appendCPToHeader(request, updatedUsersId) {
    return {
      headers: request.headers.append("cp", btoa(updatedUsersId))
    };
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.apiCount++;
    this.loaderService.showLoader();
    let clonedData = {};
    // if (request.method === 'POST') {
    //   const updatedUsersId = this.getUpdatedBy();
    //   if (updatedUsersId) {
    //     clonedData = this.appendCPToHeader(request, updatedUsersId);
    //   }
    // }
    const authReq = request.clone(clonedData);
    return next.handle(authReq).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        this.apiCount--;
        if (0 >= this.apiCount) {
          this.loaderService.hideLoader();
        }
        if (event.body && event.body.status === 401) {
          this.utilService.sessionExpired();
        }
        if (event.body.status === 500) {
          this.utilService.showAlert(event.body.message);
        }
        return event.body;
      }
    }, (err: any) => {
      let errorMessage: string;
      if (err instanceof HttpErrorResponse) {
        switch (err.status) {
          case 400: errorMessage = '400 Bad request'; break;
          case 401:
            errorMessage = '401 Unauthorized';
            this.utilService.sessionExpired();
            break;
          case 403: errorMessage = '403 Forbidden'; break;
          case 404: errorMessage = '404 Not found'; break;
          case 405: errorMessage = '405 Method Not Allowed'; break;
          case 406: errorMessage = '406 Not Acceptable'; break;
          case 408: errorMessage = '408 Request Timeout'; break;
          case 413: errorMessage = '413 Request Entity Too Large'; break;
          case 422: errorMessage = '422 Error'; break;
          case 423: errorMessage = '423 Error'; break;
          case 429: errorMessage = '429 Too Many Requests'; break;
          case 500: errorMessage = '500 Internal server error'; break;
          case 501: errorMessage = '501 Not Implemented'; break;
          case 502: errorMessage = '502 Bad Gateway'; break;
          case 503: errorMessage = '503 Service unavailable'; break;
          case 504: errorMessage = '504 Gateway Timeout'; break;
          default: errorMessage = 'An error occurred while processing your request. Please try again. If the problem persists, contact your system administrator.';
            break;
        }
      }
      if (401 !== err.status) {
        this.utilService.showAlert(errorMessage);
      }
      this.apiCount--;
      if (0 >= this.apiCount) {
        this.loaderService.hideLoader();
      }
      return err;
    }, () => { }));

  }
}
