import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toHour'
})
export class ToHourPipe implements PipeTransform {

  transform(time): any {
    let hours = 0;
    let min = 0;
    let modifiedtime = time;
    if (time != null && time !== '00:00') {
      let sections = time.split(':');
      if (sections.length == 2) {
        if (sections[0] > 60) {
          hours = Math.floor(sections[0] / 60);
          min = sections[0] % 60;
          modifiedtime = (hours > 0 ? (hours + ':') : '') + (min >= 10 ? (min + ':') : (min > 0 && min < 10) ? ('0' + min + ':') : '00:') + (sections[1] ? sections[1] : '00');
        }
      }
    }
    return modifiedtime;
  }
}
