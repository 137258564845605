import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { ENV } from 'src/environments/environment';
import { Constants } from 'src/app/shared/constants';
import { UtilsService } from 'src/app/shared/common';
import { PatientService } from '../../components/patient/patient.service';
import { ConfigurationService } from 'src/app/core/services/configuration/configuration.service';

@Component({
  selector: 'app-demographic-data-modal',
  templateUrl: './demographic-data-modal.page.html',
  styleUrls: ['./demographic-data-modal.page.scss'],
})
export class DemographicDataModalPage implements OnInit{

  public timezone: string;
  patientInfo: any = {};
  genderDisplayFormat: string;
  // baseURL = ENV.api_baseurl;
  baseURL = ENV.patient_baseurl;
  tableHeaders = Constants.DEMOGRAPHIC_EMERGENCY_CONTACT_TABLE_HEADERS;
  buttonOk = Constants.BUTTON_TEXT.okay;
  mobileDetails;
  config:  any
  activeMobileDetails;
  mobileActive : boolean;

  constructor(
    public modalCtrl: ModalController,
    public utilService: UtilsService,
    public navParams: NavParams,
    public patientService: PatientService,
    private configurationService: ConfigurationService) {
    this.timezone = this.configurationService.getConfigurationDetails().hospitalTimezone;
    this.patientInfo = this.navParams.get('patientInfo') ? this.navParams.get('patientInfo') : {};
    this.genderDisplayFormat = Constants.GENDER_DISPLAYFORMAT.LONG;
    this.config = this.configurationService.getConfigurationDetails();
    this.mobileActive = this.config.mobile && this.config.mobile.is_active ? this.config.mobile.is_active : false
  }

  ngOnInit(){
    this.getAccessCodeDetails();
  }

  getAccessCodeDetails() {
    const apiParams = {
      lazy: true,
      identifier: this.patientInfo.medicardia_id?this.patientInfo.medicardia_id:''
    };
    this.patientService.getAccessCode(apiParams).subscribe(
      res => {
        if(res['is_success'] && res['data'] && res['data']){
          this.mobileDetails = res['data'];
          this.mapActiveMobileData();
        }
      },
      err => {
      }
    );
  }

  /**
   * Select the device with active status
   */
  mapActiveMobileData(){
    if(this.mobileDetails && this.mobileDetails.device_details){
      this.mobileDetails.device_details.forEach(deviceInfo => {
        if(deviceInfo.is_active){
          this.activeMobileDetails = deviceInfo;
        }
      });
    }
  }

  /**
   * Function will call the API and regenerates the access code.
   * API response needs the new code and that can be shown over the UI.
   */
  regenerateCode(){
    const apiParams = {
      identifier: this.patientInfo.medicardia_id?this.patientInfo.medicardia_id:'',
      generate: true,
      email: false
    };
    this.patientService.generateAccessCode(apiParams).subscribe(
      res => {
        if(res['is_success'] && res['data']){
          this.mobileDetails = res['data'];
          this.mapActiveMobileData();
          this.utilService.showToast(res['message'], 'success');
        }
      },
      err => {
      }
    );
  }

  /**
   * Send code will send the existing code to the patient by calling the API.
   */
  sendCode(){
    const apiParams = {
      identifier: this.patientInfo.medicardia_id?this.patientInfo.medicardia_id:'',
      generate: false,
      email: true
    };
    this.patientService.generateAccessCode(apiParams).subscribe(
      res => {
        if(res['is_success']){
          //this.mobileDetails = res['data'];
          this.utilService.showToast(res['message'], 'success');
        }
      },
      err => {
      }
    );
  }

  closeModal(data = {}) {
    this.modalCtrl.dismiss();
  }
}
