import { EventEmitter, Injectable } from '@angular/core';
import { UserInterface, LocationInterface } from '../../models/user';
import { StorageService } from '../authentication/storage.service';
import { UtilsService } from 'src/app/shared/common';
import { DemographicInterface } from '../../models/demographic';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataTransferService {

  selectedProvider: any;
  isRefreshed: boolean = true;
  selectedLocation: LocationInterface;
  fromLoginScreen:boolean = false;
  fromEpicLoginScreen:boolean = false;
  private locationDetails = new Subject<any>();
  public getLocationDetails = this.locationDetails.asObservable();

  private discardPopup = new Subject<any>();
  public getDiscardPopup = this.discardPopup.asObservable();

  public timerStoppedCheckEvent = new EventEmitter();

  private userData: UserInterface;
  patientDemographic: DemographicInterface;

  public billingPage = new EventEmitter();

  modeswitched = false;
  public ModeSwitchStatus = new Subject();
  getModeSwitchStatus = this.ModeSwitchStatus.asObservable();


  constructor(private storageService: StorageService, public router: Router) {
    this.getUserDataFromStorage();
    if (this.router.url === '/appointments') {
      this.setSelectedProvider(undefined);
    } else {
      const userDetails = this.storageService.getStorageItem(sessionStorage, 'userData', true);
      this.selectedProvider = userDetails && userDetails['selectedProvider'] ? userDetails['selectedProvider'] : undefined;
      this.selectedLocation = userDetails && userDetails['selectedLocation'] ? userDetails['selectedLocation'] : undefined;
    }
  }

  setSelectedProvider(data) {
    this.selectedProvider = data;
    const keyValue = { selectedProvider: data };
    this.storageService.addDataToStorage(sessionStorage, keyValue, true);
  }

  setLocationDetails(data) {
    this.selectedLocation = data;
    const keyValue = { selectedLocation: data };
    this.storageService.addDataToStorage(sessionStorage, keyValue, true);
    this.locationDetails.next(data);
  }

  private getUserDataFromStorage() {
    const userDetails = this.storageService.getStorageItem(sessionStorage, 'userData', true);
    this.userData = userDetails && userDetails.user ? userDetails.user : undefined;
  }

  getUserData(): UserInterface {
    if (this.userData) {
      this.getUserDataFromStorage();
    }
    return this.userData;
  }

  setUserData(data: UserInterface) {
    this.userData = data;
    if (data) {
      const keyValue = { user: data };
      this.storageService.addDataToStorage(sessionStorage, keyValue, true);
    }
  }

  setRPMPhysician(rpmDetails) {
    if (this.patientDemographic) {
      const patientData: DemographicInterface = this.patientDemographic;
      patientData.rpm_enrolled_provided_id = (this.getUserData().permission[0].group['name'] === 'Physician' ?
        (rpmDetails && rpmDetails.physician_id && rpmDetails.physician_id['$oid'] ? rpmDetails.physician_id['$oid'] : '') : '');
      this.patientDemographic = patientData;
    }
  }

  openDiscardPopup(data) {
    this.discardPopup.next(data);
  }

  emitTimerStoppedCheck(data) {
    this.timerStoppedCheckEvent.emit(data);
  }

  emitHeaderModeChange(data){
    this.billingPage.emit(data);
  }

  setModeSwitchStatus(status) {
    this.modeswitched = true;
    this.ModeSwitchStatus.next(status);
  }

}
