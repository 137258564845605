import { Directive, OnChanges, Input, Output, EventEmitter, ElementRef, SimpleChanges } from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';

@Directive({
  selector: '[appPasswordToAsterisk]'
})
export class PasswordToAsteriskDirective implements OnChanges {
  actualValue = '';
  transformedValue = '';

  @Input() removalPos: number;
  @Input() passwordValue: string;
  @Output() valueChanged: EventEmitter<string> = new EventEmitter();
  currentSubscription: Subscription;
  constructor(private element: ElementRef) {
    fromEvent(element.nativeElement, 'input').subscribe(({ target }) => {
      this.transformValue(target.value);
    });
  }

  transformValue(value: string) {
    value = value.replace(/\s/g, '');
    let index;
    if (value.length > this.actualValue.length) {
      /** Insertion of character */
      if (this.actualValue.length !== 0) {
        for (let i = 0; i < value.length; i++) {
          if (value[i] !== '\u2022') {
            index = i;
            break;
          }
        }
      }
      if (index !== undefined && index !== value.length - 1) {
        this.actualValue =
          this.actualValue.slice(0, index) + value[index] + this.actualValue.slice(index, value.length);
      } else {
        this.actualValue =
          this.actualValue + value.slice(this.actualValue.length, value.length);
      }
    } else {
      /** Removal of character */
      if (this.removalPos === undefined) {
        this.actualValue = this.actualValue.slice(0, value.length);
      } else {
        this.actualValue = this.actualValue.slice(0, this.removalPos) + this.actualValue.slice(this.removalPos + 1);
      }
    }

    this.valueChanged.emit(this.actualValue);
    this.transformedValue = this.formatValue(this.actualValue);
    this.element.nativeElement.value = this.transformedValue;
    if (index !== this.actualValue.length - 1) {
      const caretPosition = index !== undefined ? index + 1 : this.removalPos;
      if (caretPosition !== undefined) {
        this.setCaretPosition(caretPosition);
      }
    }
  }

  setCaretPosition(index) {
    if (this.element.nativeElement.setSelectionRange) {
      this.element.nativeElement.focus();
      this.element.nativeElement.setSelectionRange(index, index);
      // IE8 and below
    } else if (this.element.nativeElement.createTextRange) {
      const range = this.element.nativeElement.createTextRange();
      range.collapse(true);
      range.moveEnd('character', index);
      range.moveStart('character', index);
      range.select();
    }
  }

  formatValue(value: any) {
    const s = value;
    return s.replace(/[^\s]/gi, '\u2022');
  }

  ngOnChanges(changes: SimpleChanges) {
    const passwordValue = changes.passwordValue;
    if (passwordValue && passwordValue.firstChange) {
      this.transformValue(this.passwordValue);
    }
  }
}
