import { StorageService } from "../services/authentication/storage.service";
import { ConfigurationService } from "../services/configuration/configuration.service";

export interface ConfigurationInterface {
    hospitalTimezone: string,
    hospitalTimezoneMeta: string,
    rpmTimeout: number,
    sessionTimeout: number,
    syncFrequency: number,
    autoSave: number,
    about: any,
    isEHRAvailable: boolean,
    storage_urls: any,
    mobile:any
}

export class ConfigurationModel {
    public static create(data): ConfigurationInterface {
        const storageService = new StorageService();
        const configurationService = new ConfigurationService(storageService);
        return {
            hospitalTimezone: data.hospital_timezone,
            hospitalTimezoneMeta: data.hospital_timezone_meta,
            rpmTimeout: +data.rpm_timeout * 60,
            sessionTimeout: +data.session_timeout * 60 * 1000,
            syncFrequency: +data.sync_frequency,
            autoSave: +data.auto_save,
            about: data.about,
            isEHRAvailable: configurationService.getIsEHRAvailable(),
            storage_urls: data.storage_urls,
            mobile: data.mobile
        }
    }
}