import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NavParams } from '@ionic/angular';
import moment from 'moment';
import { EcgRequestModel } from 'src/app/core/models/ecg';
import { DataTransferService } from 'src/app/core/services/data-transfer/data-transfer.service';
import { UtilsService } from 'src/app/shared/common';
import { Constants } from 'src/app/shared/constants';
import { RangeValidatorDirective } from 'src/app/shared/directives/range-validator/range-validator';
import { PatientService } from '../patient.service';
import * as _ from 'lodash';
import { DocumentService } from 'src/app/core/services/document.service';
import { ConfigurationService } from 'src/app/core/services/configuration/configuration.service';

@Component({
  selector: 'app-add-edit-ecg',
  templateUrl: './add-edit-ecg.component.html',
  styleUrls: ['./add-edit-ecg.component.scss'],
})
export class AddEditEcgComponent implements OnInit, OnChanges {

  @Input() ecgReport;
  @Input() patientId;
  @Input() visitNumber;
  @Output() exitViewEmitter = new EventEmitter<string>();
  @Output() showOcr = new EventEmitter<any>();
  @Input() fileUploaded: any;
  @Input() hasPermission: boolean;
  @Input() nlpValuesList: any;
  @Input() status;
  @Input() selectedVisitMode: number;
  @Input() isRPMStandalone : boolean;
  
  permissionCheckType = Constants.PERMISSION_CHECK_TYPE;
  ecgForm: FormGroup;
  yearRange: string;
  isFailed = false;
  isLoading = false;
  isSuccess = false;
  currentTimestamp: any;
  isSubmitClicked = false;
  previousData: any;
  ecgControl;
  riskControl;
  rateControl;
  QRSControl;
  QTControl;
  QTcControl;
  notes;
  haveEchoData: boolean = false;

  rhythmOptions = [];
  dropDownControlNameList = [];
  selectedDetails = {};
  errorMessage = Constants.MESSAGE.INVALID_RANGE;
  stringErrorMessage = Constants.MESSAGE.INVALID_DATA;
  visitModes = Constants.VISIT_MODE;
  rangeValidatorDirective = new RangeValidatorDirective();
  saveButtonText = Constants.BUTTON_TEXT.save;
  isDirty: boolean;
  ecgPopComponent = Constants.COMPONENTS.CARDIO_EDIT;
  allrhythmOptions = Constants.DROPDOWN_OPTIONS.RHYTHM;
  pdfList: any;
  isNewEcgData: boolean;
  timezone: string;
  panelClosed = false;
  nlpValueAccepted: boolean;
  showLed: any;
  permission_ecg = {
    feature_name: Constants.PERMISSION_FEAUTRE_NAME.ECG,
    action: Constants.PERMISSION_ACTION_TEXT.write
  };
  isRpm: boolean;
  fileupdated: boolean = true;
  constructor(
    public documentService: DocumentService,
    public utilService: UtilsService,
    public dataTransferService: DataTransferService,
    public configurationService: ConfigurationService,
    public patientService: PatientService) {
    this.riskControl = Constants.CONTROLS.RISKFACTOR_CONTROLS;
    this.ecgControl = Constants.CONTROLS.ECG_CONTROLS;
    this.dropDownControlNameList = ['rhythm'];
    this.timezone = this.configurationService.getConfigurationDetails().hospitalTimezone;
    this.currentTimestamp = moment(+(this.utilService.getCurrentDateTime().format('x'))).toDate();
    this.currentTimestamp.setSeconds(0);
    this.setYearRange();
    this.isRpm = this.patientService.workflow === Constants.WORKFLOW.RPM;
  }
  ngOnInit() {
    this.subcribeNlpChanges();
    // this.initEcgForm({ ...this.ecgReport });
  }
  subcribeNlpChanges() {
    this.patientService.acceptNlpValue.subscribe(val => {
      if (Constants.CARDIOLOGY_DOCUMENT_TYPE.ECG === this.patientService.cardioSelected) {
        this.showLed = this.utilService.updateShowLed(this.showLed, val.formKey);
        if (val.response === 1) {
          this.patchNlpValues(val.formKey);
        }
      }
    });

    this.patientService.acceptAllNlpValue.subscribe(val => {
      if (Constants.CARDIOLOGY_DOCUMENT_TYPE.ECG === this.patientService.cardioSelected) {
       this.updateEcg();
      }
    });
  }
  patchNlpValues(value) {
    this.showLed = this.utilService.updateShowLed(this.showLed, value);
    if (value === 'rhythm') {
      this.ecgForm.patchValue({
        [value]: {
          key: this.nlpValuesList[value].nlp_val,
          value: this.nlpValuesList[value].nlp_val
        }
      });
    } else {
      this.ecgForm.patchValue({
        [value]: this.nlpValuesList[value].nlp_val
      });
    }
    this.nlpValuesList[value].val = this.nlpValuesList[value].nlp_val;
    this.nlpValueAccepted = true;
    this.changeDetected();
  }
  getLedStatus() {
    this.showLed = this.utilService.getLedStatus(this.nlpValuesList);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.haveEchoData = false;
    if (this.ecgReport && this.ecgReport.date) {
      this.haveEchoData = true;
    }
    if (changes.ecgReport) {
      this.isNewEcgData = this.ecgReport !== undefined && this.ecgReport.echo_date === undefined;
      if (this.ecgForm === undefined || (this.ecgForm && !this.ecgForm.dirty) ||
        _.isEqual(this.previousData, this.ecgReport) === false) {
        this.initEcgForm({ ...this.ecgReport });
      }
    }

    if (changes.status) {
      if (this.status == 'COMPLETED') {
        this.getLedStatus();
      }
    }
    if (this.fileUploaded) {
      this.isDirty = true;
      this.changeDetected();
    } else {
      this.isDirty = _.isEqual(this.ecgForm.value, this.previousData) ? false : true;
      this.changeDetected();
    }
  }

  setYearRange() {
    const endYear = moment().tz(this.timezone).year();
    const startYear = endYear - 100;
    this.yearRange = `${startYear}:${endYear}`;
  }

  initEcgForm(ecgReport) {
    this.ecgForm = new FormGroup({
      date: new FormControl('', [Validators.required]),
      // ECG: new FormControl('', [
      //   Validators.required, Validators.min(this.riskControl.ECG.MIN),
      //   Validators.max(this.riskControl.ECG.MAX)]),
      Rate: new FormControl('', [
        Validators.required, Validators.min(this.ecgControl.RATE.ABS_MIN),
        Validators.max(this.ecgControl.RATE.ABS_MAX)]),
      rhythm: new FormControl(null, [Validators.required]),
      QRS: new FormControl('', [
        Validators.min(this.riskControl.QRSDURAT.ABS_MIN),
        Validators.max(this.riskControl.QRSDURAT.ABS_MAX)]),
      QT: new FormControl('', [
        Validators.min(this.riskControl.QTINTERV.ABS_MIN),
        Validators.max(this.riskControl.QTINTERV.ABS_MAX)]),
      QTc: new FormControl('', [
        Validators.min(this.ecgControl.QTc.ABS_MIN),
        Validators.max(this.ecgControl.QTc.ABS_MAX)]),
      notes: new FormControl(''),
      pr: new FormControl(''),
      qrsd: new FormControl(''),
      p: new FormControl(''),
      t: new FormControl(''),
      summary: new FormControl(''),
      object_id: new FormControl(''),
    });
    if (!this.utilService.isEmpty(ecgReport)) {

      this.ecgForm.patchValue(ecgReport);
      // this.setSingleSelectDropDownValues();
      this.ecgForm.patchValue({
        rhythm: ecgReport && ecgReport.rhythm &&
          _.find(Constants.DROPDOWN_OPTIONS.RHYTHM, ['value', ecgReport.rhythm]) ?
          _.find(Constants.DROPDOWN_OPTIONS.RHYTHM, ['value', ecgReport.rhythm]) : ecgReport.rhythm ?
            { key: ecgReport.rhythm, value: ecgReport.rhythm } : null
      });
    }
    this.previousData = this.ecgForm.value;
    this.subscribeChanges();
  }

  subscribeChanges() {
    this.isSubmitClicked = false;
    this.panelClosed = false;
    this.getLedStatus();
    this.nlpValueAccepted = false;
    this.ecgForm.valueChanges.subscribe(val => {
      if (val.object_id === this.previousData.object_id) {
        val.Rate = val.Rate ? +val.Rate : val.Rate;
        val.QTc = val.QTc ? +val.QTc : val.QTc;
        val.QRS = val.QRS ? +val.QRS : val.QRS;
        val.QT = val.QT ? +val.QT : val.QT;
        val.rhythm = val.rhythm && !val.rhythm.value ? { key: val.rhythm, value: val.rhythm } : val.rhythm;
        this.isDirty = _.isEqual(val, this.previousData) ? false : true;
        this.changeDetected();
      }
    });
  }

  updateEcg() {
    if(this.fileUploaded && !this.ecgReport.object_id ){
      this.fileupdated = true;
    }else if(!this.fileUploaded && this.ecgReport.object_id){
      this.fileupdated = true;
    } else{
      this.fileupdated = false;
    }
    this.isSubmitClicked = true;
    if (this.ecgForm.valid && this.fileupdated) {
      this.isFailed = false;
      this.isSuccess = false;
      if (this.ecgForm.valid) {
        this.isLoading = true;
        // this.getEcgDate();
        let params: any = {};
        if (!this.utilService.isEmpty(this.ecgReport)) {
          params = EcgRequestModel.createParams({ ...this.ecgForm.value }, this.nlpValuesList);
          if (this.nlpValueAccepted || this.nlpValuesList.is_reviewed === true && this.ecgReport.nlp_status_label === 'COMPLETED') {
            params.is_reviewed = true;
          }
        } else {
          params = EcgRequestModel.createParams({ ...this.ecgForm.value });
        }
        params.date = this.utilService.getEchoDates(this.ecgForm.value.date);
        params.report_type = 'ecg';
        params.object_id = this.ecgReport.object_id;

        params.medicardia_id = this.patientId;
        params.visit_number = this.visitNumber;
        params.ecg_data.ecg = this.ecgReport.ecg;
        params.ecg_data.ref_link = this.ecgReport.ref_link;
        if (this.fileUploaded) {
          params.file = this.fileUploaded;
        }
        if(this.ecgReport && this.ecgReport.source && this.ecgReport.source == "health_app"){
          params.is_reviewed = true;
        }
        this.patientService.saveCardioReport(params, true).subscribe(
          res => {
            this.isSubmitClicked = false;
            this.isLoading = false;
            this.isSuccess = res.is_success;
            this.isFailed = !this.isSuccess;
            const message = res && res.is_success ? Constants.MESSAGE.ECG_UPDATE.SUCCESS :
              res['message'] ? res['message'] : Constants.MESSAGE.COMMON_ERROR_MESSAGE.MESSAGE;
            const toastColor = res && res.is_success ? 'success' : 'danger';
            this.utilService.showToast(message, toastColor);
            this.isDirty = !this.isSuccess;
            if (res.is_success) {
              this.nlpValueAccepted = false;
              this.getLedStatus();
              if (!params.object_id) {
                this.ecgForm.reset();
              }
              this.utilService.resetIsDirty();
              const objId = this.ecgReport.object_id ? this.ecgReport.object_id : (res.data && res.data._id && res.data._id.$oid ?
                res.data._id.$oid : undefined);
              this.exitViewEmitter.emit(objId);
            }
            this.patientService.heartscoreupdate();
            this.patientService.labHistoryUpdate();

            setTimeout(() => {
              this.isSuccess = false;
              this.isFailed = false;
            }, 1000);
          },
          err => {
            this.isSubmitClicked = false;
            this.isLoading = false;
            setTimeout(() => {
              this.isSuccess = false;
              this.isFailed = false;
            }, 1000);
          }
        );

      }
    }
  }

  getEcgDate() {
    const dateTime = this.utilService.convertDatePickerTimeToMySQLTime(this.ecgForm.value.date);
    // this.ecgForm.controls['date'].setValue(dateTime);
    return dateTime;
  }

  setSingleSelectDropDownValues() {
    this.dropDownControlNameList.forEach(control => {
      this.selectedDetails[control] = [];
      this.selectedDetails[control] = this.selectedDetails[control].concat(this.utilService.findObject(
        this.rhythmOptions, 'value', this.ecgForm.value[control]));
    });
  }

  changeDetected() {
    this.utilService.changeDetected(this.ecgPopComponent, this.isDirty);
  }

  searchEcg(event) {
    this.rhythmOptions = this.utilService.getRhythmFiltertedResults(event.query);
  }
  showOcrText(key) {
    this.showOcr.emit(key);
  }
  keyDownForm(event) {
    const targetElement = event.target as HTMLInputElement | HTMLTextAreaElement;
    // If enter key is pressed, 
    if (event.keyCode === 13 && targetElement.type != 'textarea') {
      this.updateEcg();
    }
  }
}
