import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { VitalsModalPage } from './vitals-modal.page';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { LoaderModule } from 'src/app/shared/components/loader/loader.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    PipesModule,
    DirectivesModule,
    LoaderModule
  ],
  declarations: [VitalsModalPage]
})
export class VitalsModalPageModule {}
