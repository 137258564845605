import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './core/services/http/token.interceptor';
import { ErrorHandlerInterceptor } from './core/services/http/error-handler.interceptor';
import { ApiPrefixInterceptor } from './core/services/http/api-prefix.interceptor';
import { CoreModule } from './core/core.module';
import { StorageService } from './core/services/authentication/storage.service';
import { UtilsService } from './shared/common';
import { BaseModule } from './core/component/base/base.module';
import { LoginPageModule } from './modules/auth/login/login.module';
import { PagesModule } from './modules/pages/pages.module';
import { DataTransferService } from './core/services/data-transfer/data-transfer.service';
import { TimerService } from './core/services/timer/timer.service';
import { UserInactivityManagerModalPageModule } from './modules/modals/user-inactivity-manager-modal/user-inactivity-manager-modal.module';
import { CountdownGlobalConfig } from 'ngx-countdown';
import { UserInactivityManagerModalPage } from './modules/modals/user-inactivity-manager-modal/user-inactivity-manager-modal.page';
import { ConfigurationService } from './core/services/configuration/configuration.service';
import { RpmMultiTimerService } from './core/services/rpmmultitimer/rpmmultitimer.service';
import { RpmDashboardInactivityModalPageModule } from './modules/modals/rpm-dashboard-inactivity-modal/rpm-dashboard-inactivity-modal.module';
import { RpmDashboardInactivityModalPage } from './modules/modals/rpm-dashboard-inactivity-modal/rpm-dashboard-inactivity-modal.page';
import {CookieService} from 'ngx-cookie-service';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [
    UserInactivityManagerModalPage,
    RpmDashboardInactivityModalPage
  ],
  imports: [
    BaseModule,
    LoginPageModule,
    PagesModule,
    CoreModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    UserInactivityManagerModalPageModule,
    RpmDashboardInactivityModalPageModule
    ],
  providers: [
    TimerService,
    RpmMultiTimerService,
    DataTransferService,
    ConfigurationService,
    { provide: HTTP_INTERCEPTORS, useClass: ApiPrefixInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    StorageService,
    UtilsService,
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: CountdownGlobalConfig },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    CookieService
  ],

  bootstrap: [AppComponent]
})
export class AppModule {}
