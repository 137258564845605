import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RpmBillingComponent } from './rpm-billing.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { IonicModule } from '@ionic/angular';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

@NgModule({
  declarations: [RpmBillingComponent],
  imports: [
    IonicModule,
    FormsModule,
    CommonModule,
    PipesModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    DropdownModule,    
    PerfectScrollbarModule,
  ],
  exports:[RpmBillingComponent]
})
export class RpmBillingModule { }
