import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from './pipes/pipes.module';
import { DirectivesModule } from './directives/directives.module';
import { HeaderModule } from './components/header/header.module';
import { VersionModule } from './components/version/version.module';
import { RangeFactorModule } from './components/range-factor/range-factor.module';
import { LoaderModule } from './components/loader/loader.module';
import { HeaderTabModule } from './components/header-tab/header-tab.module';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
  ],
  exports: [
    VersionModule,
    HeaderModule,
    PipesModule,
    DirectivesModule,
    RangeFactorModule,
    LoaderModule,
    HeaderTabModule
  ],
})
export class SharedModule { }
