import { EventEmitter, Injectable } from '@angular/core';
import { ApiService } from '../../../core/services/http/api.service';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../../../core/services/authentication/storage.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocationInterface } from 'src/app/core/models/user';

@Injectable({
  providedIn: 'root'
})
export class HeaderService extends ApiService {

  isRpmAlertsPage = false;
  isDemo: boolean;
  private fuVisit = new Subject<any>();
  public getFuVisit = this.fuVisit.asObservable();
  // #53712
  public modeSelectionStatus: EventEmitter<string> = new EventEmitter();
  public rpmModeSelectionStatus: EventEmitter<string> = new EventEmitter();
  public rpmStandaloneEnableStatus: EventEmitter<string> = new EventEmitter();
  public updatedMonitoringOptions: EventEmitter<string> = new EventEmitter();
  public updatedIsLoadedValue: EventEmitter<string> = new EventEmitter();
  public modeChangeFromDetailsPageStatus: EventEmitter<string> = new EventEmitter();
  public currentPageView = '';
  public detailviewPatientDetails: any;
  public isDetailView = false;
  public detailsPageEntryEventStatus: EventEmitter<string> = new EventEmitter();
  public selectedPatientInDetailsView: any;
  public enableWorkflowBtnEventStatus: EventEmitter<string> = new EventEmitter();

  constructor(public http: HttpClient, public storageService: StorageService) {
    super(http, storageService);
    this.isDemo = true;
  }

  /**
   * @description To get the clinic details
   * @param params Parameters being passed
   */
  _getClinicDetailsFromServer(params): Observable<any> {
    return this.get('user/userinfo/', params).pipe(
      map(
        data => data,
        err => err
      ));
  }


  getClinicDetails(params): Observable<any> {
    let observableResult = new Observable<any>();
    observableResult = this.isDemo ? this._getMockClinicDetails() : this._getClinicDetailsFromServer(params);
    return observableResult;
  }

  private _getMockClinicDetails(): Observable<any> {
    return this.http.get<any>('assets/data/provider-info.json').pipe(map(res => {
      return res;
    }));
  }

  setFuVisit(reason) {
    this.fuVisit.next(reason);
  }

  // #53712
  public emitModeChangeEvent(params?) {
    this.modeSelectionStatus.emit(params);
  }

  public getModeChangeEmitter() {
    return this.modeSelectionStatus;
  }

  public emitRpmModeChangeEvent(params?) {
    this.rpmModeSelectionStatus.emit(params);
  }

  public getRpmModeChangeEmitter() {
    return this.rpmModeSelectionStatus;
  }

  public emitrpmStandaloneEnableChangeEvent(params?) {
    this.rpmStandaloneEnableStatus.emit(params);
  }

  public getrpmStandaloneEnableEmitter() {
    return this.rpmStandaloneEnableStatus;
  }

  public emitUpdatedMonitoringOptions(params?) {
    this.updatedMonitoringOptions.emit(params);
  }

  public getUpdatedMonitoringOptions() {
    return this.updatedMonitoringOptions;
  }

  public emitUpdatedIsLoadedValue(params?) {
    this.updatedIsLoadedValue.emit(params);
  }

  public getUpdatedIsLoadedValue() {
    return this.updatedIsLoadedValue;
  }

  public emitModeChangeFromDetailsPageEvent(params?) {
    this.modeChangeFromDetailsPageStatus.emit(params);
  }

  public getModeChangeFromDetailsPageEmitter() {
    return this.modeChangeFromDetailsPageStatus;
  }

  // IMPROVE SWITCHING SPEED
  public emitDetailsPageEntryEvent(params?) {
    this.detailsPageEntryEventStatus.emit(params);
  }

  public getDetailsPageEntryEventEmitter() {
    return this.detailsPageEntryEventStatus;
  }

  public emitEnableWorkflowBtnEvent(params?) {
    this.enableWorkflowBtnEventStatus.emit(params);
  }

  public getEnableWorkflowBtnEventEmitter() {
    return this.enableWorkflowBtnEventStatus;
  }
}
