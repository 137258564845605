import { Constants } from 'src/app/shared/constants';
import * as _ from 'lodash';

export interface VitalsInterface {
    BMI: {
        perc: number,
        kg: number,
        is_hidden: boolean
    },
    BP: {
        sys: number,
        dias: number,
        is_hidden: boolean
    },
    PULSE: { bpm: number,
        is_hidden: boolean },
    PulseOx: {
        perc: number,
        brpm: number,
        is_hidden: boolean
    },
    TEMP: {
        locations: any,
        is_hidden: boolean
    },
    height: number,
    id: string,
    bp_change: number,
    sbp_magnitude: number,
    dbp_magnitude: number,
    pulse_change: number,
    pulse_magnitude: number,
    pulse_ox_change: number,
    pulse_ox_magnitude: number,
    pulseox_brpm_magnitude: number,
    bmi_change: number,
    bmi_magnitude: number,
    weight_magnitude: number
}

export class VitalsModel {
    private static getBPChange(vitals_data, prev_vitals_data) {

        /**
         * Calculates the change in bp using the equation
         * MAP = (2 * DBP + SBP) / 3
         */

         let bp_change = Constants.VITAL_CHANGE.NO_CHANGE;
        // if (vitals_data !== undefined && vitals_data.DBP.diff && vitals_data.SBP.diff) {
        //     const bp_current_diff = vitals_data.DBP.diff + vitals_data.SBP.diff;
        //     const bp_previos = 2 * prev_vitals_data.DBP + prev_vitals_data.SBP;
        //     bp_change = bp_current_diff > 0 ? Constants.VITAL_CHANGE.HIGH :
        //         (bp_current_diff < 0 ? Constants.VITAL_CHANGE.LOW : bp_change);
        // }
        if (prev_vitals_data !== undefined) {
            const bp_current = 2 * vitals_data.DBP + vitals_data.SBP;
            const bp_previos = 2 * prev_vitals_data.DBP + prev_vitals_data.SBP;
            bp_change = bp_current > bp_previos ? Constants.VITAL_CHANGE.HIGH :
                (bp_current < bp_previos ? Constants.VITAL_CHANGE.LOW : bp_change);
        }
        return bp_change;
    }

    private static getChangeInMagitude(vitals_data, prev_vitals_data, key) {

        /**
         * Calculates the change in vitals except BP
         */

        let change = Constants.VITAL_CHANGE.NO_CHANGE;
        if (prev_vitals_data !== undefined) {
            change = vitals_data[key] > prev_vitals_data[key] ? Constants.VITAL_CHANGE.HIGH :
                (vitals_data[key] < prev_vitals_data[key] ? Constants.VITAL_CHANGE.LOW : change);
        }
        return change;
    }
    private static lbsToKg(lbs) {
        if (lbs != '') {
            return +((1 / 2.204623) * lbs);
        }
    }


    public static create(data): VitalsInterface {
        const vitals_data = data.vitals && data.vitals.most_recent ? data.vitals.most_recent : {};
        const prev_vitals_data = data.vitals && data.vitals.second_most_recent ?
            data.vitals.second_most_recent : {};
        return {
            BMI: {
                perc: vitals_data && vitals_data.BMI !== undefined && vitals_data.BMI !== null  && vitals_data.BMI.value !== null ?
                    _.round((vitals_data.BMI.value).toFixed(1), 1) : undefined,
                kg: vitals_data && vitals_data.WT !== undefined && vitals_data.WT !== null && vitals_data.WT.value !== null? 
                vitals_data.WT.value : undefined,
                is_hidden: vitals_data && vitals_data.BMI !== undefined  && vitals_data.BMI.is_hidden !== undefined ? vitals_data.BMI.is_hidden : false
            },
            BP: {
                sys: vitals_data && vitals_data.SBP !== undefined && vitals_data.SBP !== null && vitals_data.SBP.value !== null ? vitals_data.SBP.value : undefined,
                dias: vitals_data && vitals_data.DBP !== undefined && vitals_data.DBP !== null && vitals_data.DBP.value !== null ? vitals_data.DBP.value : undefined,
                is_hidden: vitals_data && vitals_data.SBP !== undefined  && vitals_data.SBP.is_hidden !== undefined ? vitals_data.SBP.is_hidden : false
            },
            PULSE: {
                bpm: vitals_data && vitals_data.HR !== undefined && vitals_data.HR !== null && vitals_data.HR.value !== null ? vitals_data.HR.value : undefined,
                is_hidden: vitals_data && vitals_data.HR !== undefined  && vitals_data.HR.is_hidden !== undefined ? vitals_data.HR.is_hidden : false
            },
            PulseOx: {
                perc: vitals_data && vitals_data.SPO2 !== undefined && vitals_data.SPO2 !== null && vitals_data.SPO2.value !== null?
                    vitals_data.SPO2.value : undefined,
                brpm: vitals_data && vitals_data.RR !== undefined && vitals_data.RR !== null  && vitals_data.RR.value !== null?
                    vitals_data.RR.value : undefined,
                is_hidden: vitals_data && vitals_data.SPO2 !== undefined  && vitals_data.SPO2.is_hidden !== undefined ? vitals_data.SPO2.is_hidden : false
            },
            TEMP: {
                locations: vitals_data && vitals_data.TEMP !== undefined && vitals_data.TEMP !== null && vitals_data.TEMP.locations !== null?
                    vitals_data.TEMP.locations : undefined,
                is_hidden: vitals_data && vitals_data.TEMP !== undefined  && vitals_data.TEMP.is_hidden !== undefined ? vitals_data.TEMP.is_hidden : false
            },
            height: vitals_data && vitals_data.HT !== undefined && vitals_data.HT !== null && vitals_data.HT.value !== null ? vitals_data.HT.value : undefined,
            id: data && data.patient_details && data.patient_details.medicardia_id ?
                data.patient_details.medicardia_id : undefined,
            bp_change: this.getBPChange(vitals_data, prev_vitals_data),
            sbp_magnitude: vitals_data && vitals_data.SBP !== undefined && vitals_data.SBP.diff !== undefined  ?
            vitals_data.SBP.diff : 0,
            dbp_magnitude: vitals_data && vitals_data.DBP !== undefined &&  vitals_data.DBP.diff !== undefined ?
                vitals_data.DBP.diff  : 0,
            pulse_magnitude: vitals_data && vitals_data.HR !== undefined &&  vitals_data.HR.diff !== undefined ?
                vitals_data.HR.diff : 0,
            pulse_change: this.getChangeInMagitude(vitals_data, prev_vitals_data, 'HR'),
            pulse_ox_magnitude: vitals_data && vitals_data.SPO2 !== undefined &&  vitals_data.SPO2.diff !== undefined ? 
            vitals_data.SPO2.diff : 0,
            pulseox_brpm_magnitude: vitals_data && vitals_data.RR !== undefined && vitals_data.RR.diff !== undefined ? 
            vitals_data.RR.diff : 0,
            pulse_ox_change: this.getChangeInMagitude(vitals_data, prev_vitals_data, 'SPO2'),
            bmi_magnitude: vitals_data && vitals_data.BMI !== undefined && vitals_data.BMI.diff !== undefined ?
                vitals_data.BMI.diff : 0,
            weight_magnitude: vitals_data && vitals_data.WT !== undefined && vitals_data.WT.diff !== undefined ? 
            vitals_data.WT.diff : 0, //this.lbsToKg(vitals_data.WT.diff)  - Commented as the diff is showing in lbs so no need to convert
            bmi_change: this.getChangeInMagitude(vitals_data, prev_vitals_data, 'BMI'),

        };
    }
}