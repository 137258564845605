import { Component, OnInit } from '@angular/core';
import { ENV } from 'src/environments/environment';

@Component({
  selector: 'app-version',
  template: '<div class="version extra-small-font">{{version}}</div>',
  styleUrls: ['./version.component.scss'],
})
export class VersionComponent {

  version: string = ENV.version;

}
