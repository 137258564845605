import { Injectable, EventEmitter } from '@angular/core';
import { Observable, BehaviorSubject, Subject, Subscription } from 'rxjs';
import { ApiService } from 'src/app/core/services/http/api.service';
import { HttpClient } from '@angular/common/http';
import { StorageService } from 'src/app/core/services/authentication/storage.service';
import { map } from 'rxjs/operators';
import * as _ from 'underscore';
import { ENV } from 'src/environments/environment';
import { ApiUrls } from 'src/app/shared/api-urls';
import * as lodash from 'lodash';
import { UserIdleService } from 'angular-user-idle';
import { ModalController } from '@ionic/angular';
import { Constants } from 'src/app/shared/constants';

@Injectable({
  providedIn: 'root'
})
export class PatientService extends ApiService {

  workflow: string;
  cardioSelected: string;
  isEnrolled: boolean;
  isFirstTime = true;
  isInitial: number;
  is_rpm_bill_saved = false;
  visitType: string = '';
  activeTab: string = '';
  discardPopupInitiatorComponent: string = '';
  isTimerRunning: boolean = true;
  isDirty = {
    component: [],
    status: false,
  };
  reviewedData = {
    patientId: '',
    vitalSigns: false,
    labs: false,
    diagnostics: false /* pdf documents */
  };
  documentData = {
    ecg: false,
    echo: false,
    cath: false,
    stress: false,
    lab: false
  }

  TOGGLABLE_ELEMENTS = {
    MIC: false,
    CAM: false,
    SCREENSHARE: false
  };
  selectedLabTrendCount: number = 0;
  selectedRiskTrendCount: number = 0;
  isLabClicked: boolean = false;
  selectedParameterTrends;

  formattedProblemList = [];
  isValidChronicConditionList = false;

  isDirtyDeviceEnrollment = false;
  isDiscardedDeviceEnrollment = false;

  newIdcCodeArray : any = [];


  orderMessageSelect: EventEmitter<string> = new EventEmitter();
  RiskCalc: EventEmitter<string> = new EventEmitter();
  vitalChange: EventEmitter<string> = new EventEmitter();
  discardConsent: EventEmitter<boolean> = new EventEmitter();
  trendChange: EventEmitter<string> = new EventEmitter();
  heartChartChange: EventEmitter<string> = new EventEmitter();
  heartDiseaseClick: EventEmitter<string> = new EventEmitter();
  currDataIndexChange: EventEmitter<string> = new EventEmitter();
  heartChartGraphChange: EventEmitter<string> = new EventEmitter();
  riskProfileLabelClick: EventEmitter<string> = new EventEmitter();
  summaryDataChange: EventEmitter<string> = new EventEmitter();
  labClickedFromCardio: EventEmitter<string> = new EventEmitter();
  trendCountChange: EventEmitter<any> = new EventEmitter();
  selectedLabs: EventEmitter<any> = new EventEmitter();
  labCountChange: EventEmitter<any> = new EventEmitter();
  updateGraph: EventEmitter<any> = new EventEmitter();
  profileHistory: EventEmitter<any> = new EventEmitter();
  heartScoreTrend: EventEmitter<any> = new EventEmitter();
  rpmData: EventEmitter<any> = new EventEmitter();
  allergyMedicalUpdated: EventEmitter<any> = new EventEmitter();
  familyHistoryupdated: EventEmitter<any> = new EventEmitter();
  labHistoryUpdated: EventEmitter<any> = new EventEmitter();
  heartscoreUpdated: EventEmitter<any> = new EventEmitter();
  vitalSelectionChange: EventEmitter<any> = new EventEmitter();
  labSelectionChange: EventEmitter<any> = new EventEmitter();
  acceptNlpValue: EventEmitter<any> = new EventEmitter();
  acceptAllNlpValue : EventEmitter<any> = new EventEmitter();
  acceptLabVitalValue: EventEmitter<any> = new EventEmitter();
  acceptAllLabVitalValue : EventEmitter<any> = new EventEmitter();
  resetWorkflow: EventEmitter<any> = new EventEmitter();
  isDocumentPopBtnClicked: EventEmitter<any> = new EventEmitter();
  visitTypeChange: EventEmitter<any> = new EventEmitter();
  docViewEnabledEvent: EventEmitter<any> = new EventEmitter();
  updateDemographicVitalsEvent: EventEmitter<any> = new EventEmitter();
  showHideTrendUpdated: EventEmitter<any> = new EventEmitter();
  modeSwitchUpdate: EventEmitter<any> = new EventEmitter();
  heartScoreChange: EventEmitter<any> = new EventEmitter();
  loadRiskProfile: EventEmitter<any> = new EventEmitter();
  // constructor(public http: HttpClient, public loadingCtrl: LoadingController) {
  //   super(http, loadingCtrl);
  // }
  private riskCalculated = new BehaviorSubject({
    params: {},
    data: {},
  });
  currentRisk = this.riskCalculated.asObservable();

  private ecgData = new Subject();
  currentEcgData = this.ecgData.asObservable();

  private videoNormalScreen = new Subject<number>();
  getIsVideoNormalScreenMode = this.videoNormalScreen.asObservable();

  private vitalUpdateMode = new Subject();
  getVitalUpdateMode = this.vitalUpdateMode.asObservable();

  private patientData = new Subject();
  getPatientData = this.patientData.asObservable();

  private reviewStatus = new Subject();
  getReviewStatus = this.reviewStatus.asObservable();

  private multiAxesGraphUpdateStatus = new Subject();
  getMultiAxesGraphUpdateStatus = this.multiAxesGraphUpdateStatus.asObservable();

  private rightsideComponentChange = new Subject();
  getRightsideComponentChange = this.rightsideComponentChange.asObservable();
  timerStartSub: Subscription;
  private checkInactivity = new Subject();
  getUserInActivityAlert = this.checkInactivity.asObservable();

  private updateHeartScore = new Subject();
  getUpdateHeartScore = this.updateHeartScore.asObservable();

  private cardioAddEdit = new Subject();
  getCardioAddEdit = this.cardioAddEdit.asObservable();
  private appointmentId = new Subject();
  setAppointmentId = this.appointmentId.asObservable();

  private updatePatientCardioData = new Subject();
  getPatientCardioUpdateData = this.updatePatientCardioData.asObservable();

  // rpm enroll device
  public updateMonitoringParamter = new Subject();
  updatedMonitoringParamterOnDeviceUpdate = this.updateMonitoringParamter.asObservable();

  public updateChronicCondition = new Subject();
  getUpdatedChronicCondition = this.updateChronicCondition.asObservable();

  public updatedDeviceOnUnenrollOrRemove = new Subject();
  getDeviceOnUnenrollOrRemove = this.updatedDeviceOnUnenrollOrRemove.asObservable();


  //update tasklist on twilio telecardia resend 
  public updatedTaskListOnVirtualAppointment = new Subject();
  getTaskListOnVirtualAppointment = this.updatedTaskListOnVirtualAppointment.asObservable();

  public closePatientAddEditPopup = new Subject();
  getClosePatientAddEditPopup = this.closePatientAddEditPopup.asObservable();
  validCCMCondition =  true;
  constructor(public http: HttpClient, public storageService: StorageService
    , private userIdle: UserIdleService,
    public modalCtrl: ModalController,

  ) {
    super(http, storageService);
    const userData = this.storageService.getStorageItem(sessionStorage, 'userData', true);
    if (userData) {
      this.workflow = userData.workflow;
      this.isEnrolled = userData.isEnrolled;
    }
  }

  discardChangesConsent(component) {
    this.discardConsent.emit(component);
  }
  clearReviewData() {
    this.reviewedData.vitalSigns = false;
    this.reviewedData.labs = false;
    this.reviewedData.diagnostics = false;
    this.documentData = lodash.mapValues(this.documentData, () => false);
  }

  setReviewStatus(docType) {
    if (this.documentData) {
      this.documentData[docType] = true;
    }
    this.reviewStatus.next();
  }

  setMultiAxesGraphUpdateStatus(status) {
    this.multiAxesGraphUpdateStatus.next(status);
  }

  setCardioAddEdit(details: string) {
    this.cardioAddEdit.next(details);
  }
  emitAppointmentId(details: string) {
    this.appointmentId.next(details);
  }
  acceptNlpEmitter(details: any) {
    this.acceptNlpValue.next(details);
  }
  acceptAllNlpEmitter(details: any) {
    this.acceptAllNlpValue.next(details);
  }
  acceptLabVitalEmitter(details: any) {
    this.acceptLabVitalValue.next(details);
  }
  acceptAllLabVitalEmitter(details: any) {
    this.acceptAllLabVitalValue.next(details);
  }
  resetWorkflowEmitter() {
    this.resetWorkflow.next(true);
  }

  isDocPopBtnClicked(details) {
    this.isDocumentPopBtnClicked.next(details);
  }

  docViewEnabled(val) {
    this.docViewEnabledEvent.next(val);
  }

  setPatientCardioUpdateData(details: any) {
    this.updatePatientCardioData.next(details);
  }

  isVisitTypeChanged(val) {
    this.visitType = val;
  }

  setReviewData(key, value) {
    if (this.reviewedData) {
      this.reviewedData[key] = value;
      this.updateReviewDataInStorage();
    }
  }

  getReviewedData(patientId) {
    const userDetails = this.storageService.getStorageItem(sessionStorage, 'userData', true);
    this.reviewedData = userDetails['reviewedData'] ? userDetails['reviewedData'] : this.reviewedData;
    this.documentData = userDetails['documentData'] ? userDetails['documentData'] : this.documentData;
    if (this.reviewedData && patientId !== this.reviewedData.patientId) {
      this.clearReviewData();
    }
  }

  updateReviewDataInStorage() {
    const storageData: any = {};
    const userDetails = this.storageService.getStorageItem(sessionStorage, 'userData', true);
    userDetails['reviewedData'] = this.reviewedData;
    userDetails['documentData'] = this.documentData;
    storageData['userData'] = userDetails;
    this.storageService.setStorageItem(sessionStorage, storageData, true);
  }

  /**
   * @description To get list of patient appointments
   * @param params
   */
  getPatientList(params): Observable<any> {
    // params['workflow'] = this.workflow;
    return this.get('appointment/patientinfo/', params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  /**
   * @description To get patient ECG data
   * @param params
   */
  getECGData(): Observable<any> {
    let observableResult = new Observable<any>();
    observableResult = this._getMockECGLeadData();
    return observableResult;
  }

  private _getMockECGLeadData(): Observable<any> {
    return this.http.get<any>('assets/data/ecg.json').pipe(map(res => {
      return res;
    }));
  }

  saveHistorianData(params): Observable<any> {
    // params['workflow'] = this.workflow;
    const url = `${ENV.patient_baseurl}${ApiUrls.PATIENT_SERVICE.appointment_summary_save}`;
    return this.post(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  /**
   * @description To get the patient appointment details like reason, last visit
   * @param params
   */
  getAppointmentDetails(params): Observable<any> {
    params['workflow'] = this.workflow;
    const url = `${ENV.appointment_baseurl}${ApiUrls.APPOINTMENT_SERVICE.appointmentDetails}`;
    return this.get(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  /**
   * @description To get the patient details
   * @param params
   */
  getPatientDetails(params): Observable<any> {
    params['workflow'] = this.workflow;
    const url = `${ENV.patient_baseurl}${ApiUrls.PATIENT_SERVICE.summary}`;
    return this.get(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  getECGReport(params): Observable<any> {
    params['workflow'] = this.workflow;
    const url = `${ENV.patient_baseurl}${ApiUrls.PATIENT_SERVICE.patient_report}`;
    return this.get(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  /**
   * @description To get the patient rpm conditions
   * @param params
   */
  getChronicData(params): Observable<any> {
    params['workflow'] = this.workflow;
    const url = `${ENV.patient_baseurl}${ApiUrls.PATIENT_SERVICE.chronic_condition}`;
    return this.get(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  /**
   * @description To get the patient lab trend data
   * @param params
   */
  getLabTrend(params): Observable<any> {
    params['workflow'] = this.workflow;
    const url = `${ENV.patient_baseurl}${ApiUrls.PATIENT_SERVICE.lab_trend}`;
    return this.get(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  /**
   * @description Creates and gets the configurations of the radar chart
   */
  getRadarConfig() {
    return {
      // 'bullet': 'none',
      'fillAlphas': 0.7,
      'fontSize': '10',
      'bullet': 'round',
      'bulletSize': 5,
      // 'bulletSizeField': 'bulletSize',
      balloonFunction: (item, graph) => {
        let balloonText = graph.balloonText;
        for (const key in item.dataContext) {
          if (item.dataContext.hasOwnProperty(key)) {
            const bText = this.getHeartChartBalloonText(item.dataContext, graph.title);
            bText === 'DEFAULT' ?
              balloonText = balloonText.replace('[[' + key + ']]', item.dataContext[key]) :
              balloonText = bText;
          }
        }
        // if(balloonText.toString().indexOf('CADDTH') > -1) {
        //   balloonText = this.createBalloonText(balloonText);
        // }
        // return balloonText;
      },
    };
  }

  // createBalloonText(balloonText:string){
  //   // console.log(balloonText);
  // }

  /**
   * @description To create Balloon Text with Values
   * @param params
   */
  getHeartChartBalloonText(item, title) {
    let balloonItem = {};
    let itemText;
    switch (item.label) {
      case 'DM':
        balloonItem = [
          { key: 'None', range: '', value: 0 },
          { key: 'HgbA1c', range: '5.8 - 6.4', value: 1 },
          { key: 'HgbA1c', range: '6.5 - 7', value: 2 },
          { key: 'HgbA1c', range: '>7', value: 3 },
          { key: 'On Insulin', range: '', value: 4 }];
        itemText = this.setBalloonItem(balloonItem, 'DM', title == 'Current' ? item.currVal : item.prevVal);
        break;
      case 'CAD':
        balloonItem = [
          { key: 'None', value: 0 },
          { key: 'nonobstructive', value: 1 },
          { key: 'any PCI/patent', value: 2 },
          { key: 'CABG/patent', value: 3 },
          { key: 'unrevasc/CTO', value: 4 }];
        itemText = this.setBalloonItem(balloonItem, 'CAD', title == 'Current' ? item.currVal : item.prevVal);
        break;
      case 'MI':
        balloonItem = [
          { key: 'None', range: '', value: 0 },
          { key: 'Any MI', range: 'no WMA', value: 1 },
          { key: 'Any MI', range: 'WMAsing', value: 2 },
          { key: 'Any MI/patent', range: 'WMAmult', value: 3 },
          { key: 'Any MI', range: 'glob hypo', value: 4 }];
        itemText = this.setBalloonItem(balloonItem, 'MI', title == 'Current' ? item.currVal : item.prevVal);
        break;
      case 'CHF':
        balloonItem = [
          { key: 'None', value: 0 },
          { key: 'NYHA I', value: 1 },
          { key: 'NYHA II', value: 2 },
          { key: 'NYHA III', value: 3 },
          { key: 'NYHA IV/Support', value: 4 }];
        itemText = this.setBalloonItem(balloonItem, 'CHF', title == 'Current' ? item.currVal : item.prevVal);
        break;
      case 'AF':
        balloonItem = [
          { key: 'None', range: '', value: 0 },
          { key: 'remote', range: '>1 year ago ', value: 1 },
          { key: 'Any AF', range: '3-12 mos', value: 2 },
          { key: 'Any AF/ patent', range: '<3 mos', value: 3 },
          { key: 'AF cont', range: '>12mos', value: 4 }];
        itemText = this.setBalloonItem(balloonItem, 'AF', title == 'Current' ? item.currVal : item.prevVal);
        break;
      case 'PAD':
        balloonItem = [
          { key: 'None', value: 0 },
          { key: 'Abn ABI ', value: 1 },
          { key: 'Claudication', value: 2 },
          { key: 'PCI/bypass', value: 3 },
          { key: 'limb loss', value: 4 }];
        itemText = this.setBalloonItem(balloonItem, 'PAD', title == 'Current' ? item.currVal : item.prevVal);
        break;
      case 'TIA/STROKEISCH':
        balloonItem = [
          { key: 'None', value: 0 },
          { key: 'Vascular Diz ', value: 1 },
          { key: 'TIA', value: 2 },
          { key: 'No neuro deficit', value: 3 },
          { key: 'With neuro deficit', value: 4 }];
        itemText = this.setBalloonItem(balloonItem, 'TIA/STROKEISCH', title == 'Current' ? item.currVal : item.prevVal);
        break;
      case 'STROKEHEM':
        balloonItem = [
          { key: 'None', value: 0 },
          { key: 'Fully resolve', value: 1 },
          { key: 'With deficit', value: 2 }];
        itemText = this.setBalloonItem(balloonItem, 'STROKEHEM', title == 'Current' ? item.currVal : item.prevVal);
        break;
      case 'CXR':
        balloonItem = [
          { key: 'None', value: 0 },
          { key: 'Fully resolve', value: 1 },
          { key: 'With deficit', value: 2 }];
        itemText = this.setBalloonItem(balloonItem, 'CXR', title == 'Current' ? item.currVal : item.prevVal);
        break;
      default: itemText = 'DEFAULT'; break;
    }
    return itemText;
  }

  /**
   * @description To create Balloon Text Table Date
   * @param params
   */
  setBalloonItem(item, itemName, itemCurrVal) {
    let itemValue = '';
    const itemTextFtr = ' </tbody></table>';

    if (itemCurrVal !== undefined) { itemValue = ' : ' + itemCurrVal; }
    let itemText = '<table class="table"><thead><tr><th colspan="' +
      (item.length - 1) + '"><h5>' + itemName + itemValue +
      '</h5></th> </tr></thead> <tbody>';

    let itemRow;
    let itemValues;

    for (const element of item) {
      if (element.range !== undefined) {
        itemValues = '<td>' + element.key + '</td><td>' + element.range +
          '</td><td>' + element.value + '</td>';
      } else {
        itemValues = '<td>' + element.key + '</td><td>' + element.value + '</td>';
      }

      itemRow = '<tr>' + itemValues + '</tr>';
      itemText = itemText + itemRow;
    }

    itemText = itemText + itemTextFtr;
    return itemText;
  }

  getHD1() {
    return {
      fields: ['CHF', 'AF', 'PAD', 'STROKEHEM', 'TIA/STROKEISCH'],
    };
  }

  getHD2() {
    return {
      fields: ['DM', 'CAD' , 'SCD', 'MI'],
    };
  }

  getHeartDiseases() {
    return {
      name: 'HEART DISEASES',
      fields: this.getHD1().fields.concat(this.getHD2().fields),
    };
  }

  getHabits() {
    return {
      name: 'HABITS AND HABITUS',
      fields: ['AGE', 'WT', 'BMI', 'SMOKE', 'ALCOHOL'],
    };
  }

  getVitals() {
    return {
      name: 'VITALS',
      fields: ['SBP', 'DBP', 'VITCAP'],
    };
  }

  getBioMarkers() {
    return {
      name: 'BIOMARKERS',
      fields: ['BNP', 'TROP', 'HGBA1C', 'GLUC', 'TCHOL', 'HDL', 'LIPO_A', 'HS_CRP'],
    };
  }

  getECG() {
    return {
      name: 'ECG',
      fields: ['QRSDURAT', 'QTINTERV'],
    };
  }

  getImaging() {
    return {
      name: 'IMAGING',
      fields: ['CALCSCORE', 'EF', 'LVTHCKNS', 'RVSP', 'LA_ENLG'],
    };
  }

  getComorbidities() {
    return {
      name: 'COMORBIDITIES',
      fields: ['CKD', 'OSA', 'COPD', 'CAROTIDDZ', 'AORTICDZ', 'CORRVSC', 'AOVALV', 'MITRVALV'],
    };
  }

  /**
   * @description To get risks in order to display in radar chart
   */
  getOrderedRisks() {
    return this.getHD1().fields.concat(
      this.getHabits().fields,
      this.getVitals().fields,
      this.getBioMarkers().fields,
      this.getECG().fields,
      this.getImaging().fields,
      this.getComorbidities().fields,
      this.getHD2().fields
    );
  }

  /**
   * @description To get the previous graph configuration
   */
  getPrevGraphConfig() {
    const radarConfig = this.getRadarConfig();
    return {
      ...radarConfig,
      title: 'Previous',
      valueField: 'prevNorVal',
      balloonText: '[[label]]: [[prevVal]]',
    };
  }

  /**
   * @description To get the current graph configuration
   */
  getCurrGraphConfig() {
    const radarConfig = this.getRadarConfig();
    return {
      ...radarConfig,
      title: 'Current',
      valueField: 'currNorVal',
      balloonText: '[[label]]: [[currVal]]',
    };
  }

  getExtraPopupData() {
    return ['ALCOHOL_INTAKE', 'HT'];
  }
  /**
   * @description To get the details of a patient to plot the radar chart
   * @param riskProfile
   */
  getRadarData(riskProfile, riskProfileNorm, currDataindex = 0) {
    const radarData = {};
    const graphs = [];
    let dataPoints = [];
    if (riskProfile) {
      const currData = riskProfile[currDataindex];
      let prevData = riskProfile[currDataindex + 1];

      const currNormData = riskProfileNorm[currDataindex];
      let prevNormData = riskProfileNorm[currDataindex + 1];

      graphs.push(this.getCurrGraphConfig());
      radarData['currentDate'] = currData.created_on;

      if (prevData) {
        graphs.push(this.getPrevGraphConfig());
        radarData['previousDate'] = prevData.created_on;
      } else {
        prevData = {};
        prevNormData = {};
      }
      // push all the risk profile data ( current and previous ) to datapoints
      const risks = this.getOrderedRisks();
      // _.each(currData, (currVal, risk) => {
      dataPoints = this.getRiskProfileDatapoints(risks, prevData, currData, prevNormData, currNormData);
    }
    radarData['graphs'] = graphs;
    radarData['datapoints'] = dataPoints;
    return radarData;
  }

  changeHeartChartLabel(key){
    let returnKey
    const labelConfig = {
      "COPD": "CHR LUNG DZ" ,
      "CAROTIDDZ" : "PRECEREB OCC/STEN",
      "CAD": "CAD/MI", 
      "MI": "BRADY/BLOCK", 
      "CHF": "CHF/CMP", 
      "PAD": "PERIPH/VASC DZ",
      "SCD": "SCD/VT/VF",
      "STROKEHEM": "TACHY/SVT",
      "TIA_STROKEISCH": "TIASTRK/ICH",
      "LIPO_A": "LIPO(a)",
      "HS_CRP": "HS-CRP",
      "LA_ENLG": "LA-ENLG",
      "BRADY_BLOCK": "BRADY/BLOCK"

    }
    //console.log(Object.keys(labelConfig).includes(key), "Object.keys(labelConfig).includes(key)")
    if (Object.keys(labelConfig).includes(key)){
      returnKey = labelConfig[key]
    }else{
      returnKey = key
    }
    return returnKey
  }

  getRiskProfileDatapoints(risks, prevData, currData, prevNormData, currNormData, patientData?) {
    const dataPoints = [];
    const omittedKeys = ["ESR", "CRP", "LVH", "CXR", "HNTNRx"]
    _(risks).each(risk => {
      if ('created_on' !== risk &&
        'id' !== risk && 'patient' !== risk && 'HEART_SCORE' !== risk
        && !omittedKeys.includes(risk)
        ) {
        let riskLabel = this.changeHeartChartLabel(risk)
        //riskLabel, "riskLabel")
        const dataPoint = {
          label: riskLabel,
          prevVal: prevData && prevData[risk] ? prevData[risk] : 0,
          currVal: currData && currData[risk] ? currData[risk] : ('HT' === risk ? (
            patientData && patientData.vitals && patientData.vitals.HT ?
              patientData.vitals.HT : (patientData && patientData.patientInfo && patientData.patientInfo.height ?
                patientData.patientInfo.height : 0)) : 0),
          prevNorVal: prevNormData && prevNormData[risk] ? prevNormData[risk] : 0,
          currNorVal: currNormData && currNormData[risk] ? currNormData[risk] : ('HT' === risk ? (
            patientData && patientData.vitals && patientData.vitals.HT ?
              patientData.vitals.HT : (patientData && patientData.patientInfo && patientData.patientInfo.height ?
                patientData.patientInfo.height : 0)) : 0),
        };
        dataPoints.push(dataPoint);
      }
    });
    return dataPoints;
  }
  /**
   * @description
   */
  getRiskProfile(params): Observable<any> {
    params['workflow'] = this.workflow;
    const url = `${ENV.healthstate_baseurl}${ApiUrls.PATIENT_SERVICE.risk_profile}`;
    return this.get(url, params)
      .pipe(map(
        data => data,
        err => err
      ));

  }
  resetNewDataCount(params): Observable<any> {
    const url = `${ENV.rpm_baseurl}${ApiUrls.RPM_SERVICE.resetDashboardData}`;
    return this.post(url, params)
      .pipe(map(
        data => data,
        err => err
      ));

  }

  /**
   * @description To post the patient details
   * @param params
   */
  // postPatientData(params): Observable<any> {
  //   return this.post('patient/patientinfo/', params)
  //   .map(
  //     data => data,
  //     err => { console.log(err); },
  //   );
  // }

  /**
   * @description To get the trend details
   * @param params
   */
  getTrendData(params): Observable<any> {
    params['workflow'] = this.workflow;
    const url = `${ENV.patient_baseurl}${ApiUrls.PATIENT_SERVICE.vitals}`;
    return this.get(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  /**
   * @description To get Heart Score
   */
  getHeartScore(params): Observable<any> {
    params['workflow'] = this.workflow;
    return this.get('patient/heart-score/', params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  /**
   * @description To save cath info
   */
  saveCathInfo(params): Observable<any> {
    // params['workflow'] = this.workflow;
    const url = `${ENV.patient_baseurl}${ApiUrls.PATIENT_SERVICE.patient_report}`;
    return this.post(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  getCardioReport(params): Observable<any> {
    const url = `${ENV.patient_baseurl}${ApiUrls.PATIENT_SERVICE.patient_report}`;
    return this.get(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  saveCardioReport(params, isFormData = false): Observable<any> {
    if (!isFormData) {
      const url = `${ENV.patient_baseurl}${ApiUrls.PATIENT_SERVICE.patient_report}`;
      return this.post(url, params)
        .pipe(map(
          data => data,
          err => err
        ));
    } else {
      const url = `${ENV.admin_baseurl}${ApiUrls.ADMIN_SERVCE.nlp_upload}`;
      return this.postX(url, params)
        .pipe(map(
          data => data,
          err => err
        ));
    }
  }

  /**
   * @description To save risk profile info
   */
  saveRiskCalcInfo(params): Observable<any> {
    const url = `${ENV.healthstate_baseurl}${ApiUrls.PATIENT_SERVICE.risk_profile}`;
    return this.post(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }
  /**
   * To get risk profile saved dates
   * @param params 
   * @returns 
   */
  getRiskProfileDatesAndData(params): Observable<any> {
    const url = `${ENV.healthstate_baseurl}${ApiUrls.PATIENT_SERVICE.risk_profile_edit}`;
    return this.get(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }
  
  getChadsvascScore(params): Observable<any> {
    const url = `${ENV.healthstate_baseurl}${ApiUrls.PATIENT_SERVICE.chadsvascScore}`;
    return this.get(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
    }

  /**
   * @description To save ecg data
   */
  updateEcg(params): Observable<any> {
    const url = `${ENV.patient_baseurl}${ApiUrls.PATIENT_SERVICE.reports_save}`;
    return this.post(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  /**
   * @description To update risk profile info
   */
  updateRiskCalcInfo(params): Observable<any> {
    params['workflow'] = this.workflow;
    return this.put(`patient/risk-profile/${params.id}/`, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  /**
   * @description To save lab report info
   */
  saveLabReportInfo(params): Observable<any> {
    params['workflow'] = this.workflow;
    return this.post('patient/fhir-report/', params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  /**
   * @description To save summary report info
   */
  saveSummaryReportInfo(params): Observable<any> {
    params['workflow'] = this.workflow;
    return this.post('patient/summary-report/', params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  /**
   * @description To get summary report info
   */
  getSummaryReportInfo(params): Observable<any> {
    params['workflow'] = this.workflow;
    const url = `${ENV.patient_baseurl}${ApiUrls.PATIENT_SERVICE.summary}`;
    return this.get(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  /**
   * @description To save vitals info
   */
  saveVitalsInfo(params): Observable<any> {
    // params['workflow'] = this.workflow;
    const url = `${ENV.patient_baseurl}${ApiUrls.PATIENT_SERVICE.vital_save}`;
    return this.post(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  /**
   * @description To get vitals info
   */
  getVitalsInfo(params): Observable<any> {
    params['workflow'] = this.workflow;
    const url = `${ENV.patient_baseurl}${ApiUrls.PATIENT_SERVICE.vitals}`;
    return this.get(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  /**
   * @description To save stress info
   */
  saveStressInfo(params): Observable<any> {
    // params['workflow'] = this.workflow;
    const url = `${ENV.patient_baseurl}${ApiUrls.PATIENT_SERVICE.patient_report}`;
    return this.post(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  /**
   * @description To get health state info
   */
  getHealthState(params): Observable<any> {
    const demo = false;
    if (demo) {
      return this.http.get<any>('assets/data/heart-risk-profile.json').pipe(map(res => {
        return res;
      }));

    } else {
      params['workflow'] = this.workflow;
      const url = `${ENV.healthstate_baseurl}${ApiUrls.PATIENT_SERVICE.health_state}`;
      return this.get(url, params)
        .pipe(map(
          data => data,
          err => err
        ));
    }
  }

  /**
   * @description To calculate stroke info
   */
  calculateStrokeRisk(params): Observable<any> {
    // params['workflow'] = this.workflow;
    const url = `${ENV.healthstate_baseurl}${ApiUrls.PATIENT_SERVICE.health_state}`;
    return this.post(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  /**
   * @description To trigger a change in the trend selection
   * @param trend
   */
  emitTrendChangeEvent(trend) {
    this.trendChange.emit(trend);
  }

  /**
   * @description To get the change in the trend selection
   */
  getTrendChangeEmitter() {
    return this.trendChange;
  }

 /**
 * @description To load risk profile initially even if vitals are NA
 */
  emitRiskProfileLoadEvent() {
    this.loadRiskProfile.emit();
  }
  
 /**
 * @description To load risk profile initially even if vitals are NA
 */
  getRiskProfileLoadEmitter() {
    return this.loadRiskProfile;
  }

  /**
   * @description To trigger when risk profile label clicked
   * @param trend
   */
  emitRiskProfileLabelClick(trend) {
    console.log(trend, "trend patientservice")
    this.selectedRiskTrendCount = trend && trend.selectedRisks ? trend.selectedRisks.length : 0;
    this.riskProfileLabelClick.emit(trend);
  }

  /**
   * @description To get event when risk profile label clicked
   */
  getRiskProfileLabelClick() {
    return this.riskProfileLabelClick;
  }

  /**
   * @description To trigger rightside component change
   * @param params
   */
  emitHeartDiseaseClick(params) {
    this.heartDiseaseClick.emit(params);
  }

  setVitalUpdateMode(params) {
    this.vitalUpdateMode.next(params);
  }

  /**
   * @description To get the change in rightside component
   */
  getHeartDiseaseClick() {
    return this.heartDiseaseClick;
  }

  /**
   * @description To trigger heart chart change
   * @param params
   */
  emitHeartChartChangeEvent(params) {
    this.heartChartChange.emit(params);
  }

  /**
   * @description To get the change in heart chart
   */
  getHeartChartEmitter() {
    return this.heartChartChange;
  }

  emitHeartScoreChangeEvent(params?) {
    this.heartScoreChange.emit(params);
  }

  getHeartScoreChangeEmitter() {
    return this.heartScoreChange;
  }

  /**
   * @description To trigger risk param change
   * @param trend
   */
  emitRiskCalcChangeEvent() {
    this.RiskCalc.emit();
  }

  /**
   * @description To get the change in risk param
   */
  getRiskCalcEmitter() {
    return this.RiskCalc;
  }

  /**
   * @description To trigger current data index in heart chart change
   * @param params
   */
  emitCurrDataIndexChangeEvent(params) {
    this.currDataIndexChange.emit(params);
  }

  /**
   * @description To get the change in current data index in heart chart
   */
  getCurrDataIndexEmitter() {
    return this.currDataIndexChange;
  }

  /**
   * @description To trigger heart chart graph change
   * @param params
   */
  emitHeartChartGraphChangeEvent(params) {
    this.heartChartGraphChange.emit(params);
  }

  /**
   * @description To get the change in heart chart graph
   */
  getHeartChartGraphEmitter() {
    return this.heartChartGraphChange;
  }

  /**
   * @description To trigger vital change
   */
  emitVitalChangeEvent(params) {
    this.vitalChange.emit(params);
  }

  emitOrderMessageSelectEvent(params) {
    this.orderMessageSelect.emit(params);
  }

  allergyMedicalUpdate(key, data) {
    const params = {
      key: key,
      data: data
    }
    this.allergyMedicalUpdated.emit(params);
  }

  syncPatientData() {
    this.familyHistoryupdated.emit();
  }

  labHistoryUpdate() {
    this.labHistoryUpdated.emit();
  }

  heartscoreupdate() {
    this.heartscoreUpdated.emit();
  }
  /**
   * @description To get the change in risk param
   */
  getVitalEmitter() {
    return this.vitalChange;
  }

  getSelectedOrderMessage(){
    return this.orderMessageSelect
  }

  /**
   * @description To get the video url
   */
  getVideoUrl(params): Observable<any> {
    const demo = false;
    if (demo) {
      return this.http.get<any>('assets/data/videos-list.json').pipe(map(res => {
        return res;
      }));
    } else {
      // params['workflow'] = this.workflow;
      // return this.get('education/video/', params)
      //   .pipe(map(
      //     data => data,
      //     err => err
      //   ));
      const url = `${ENV.media_baseUrl}${ApiUrls.PATIENT_SERVICE.video_url}`;
      return this.get(url, params)
        .pipe(map(
          data => data,
          err => err
        ));
    }
  }

  /**
   * @description To get the medical summary details and dates
   */
  getMedicalSummary(params): Observable<any> {
    params['workflow'] = this.workflow;
    return this.get('patient/summary-report-flow-chart/', params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  /**
   * @description To get the video list for autocomplete
   */
  getVideoList(params): Observable<any> {
    // params['workflow'] = this.workflow;
    // return this.get('education/videos/', params)
    //   .pipe(map(
    //     data => data,
    //     err => err
    //   ));
    const url = `${ENV.media_baseUrl}${ApiUrls.PATIENT_SERVICE.video_names}`;
    return this.get(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  updateRisk(res) {
    this.riskCalculated.next(res);
  }

  updateECG(res) {
    this.ecgData.next(res);
  }

  setIsVideoNormalScreen(data) {
    this.videoNormalScreen.next(data);
  }

  updatePatientData(res) {
    this.patientData.next(res);
  }

  getRiskHistory(params): Observable<any> {
    params['workflow'] = this.workflow;
    // return this.get(`patient/patient-risk-history/`, params).pipe(map(
    //   res => res,
    //   err => err
    // ));
    const url = `${ENV.healthstate_baseurl}${ApiUrls.PATIENT_SERVICE.risk_profile_history}`;
    return this.get(url, params)
      .pipe(map(
        data => data,
        err => err
      ))
  }
  /**
   * @description To get dummy risk profile
   */
  getDummyRiskProfile(): Observable<any> {
    return this.http.get<any>('assets/data/risk-profile.json').pipe(map(res => {
      return res;
    }));
  }


  getTrendReportData(params): Observable<any> {
    const demo = false;
    if (demo) {
      if (params.trend !== 'SBP') {
        return this.http.get<any>('assets/data/trend-report.json').pipe(map(res => {
          return res;
        }));
      } else {
        return this.http.get<any>('assets/data/sbp-trend-report.json').pipe(map(res => {
          return res;
        }));
      }
    } else {
      const url = `${ENV.healthstate_baseurl}${ApiUrls.PATIENT_SERVICE.trend_chart}`;
      return this.get(url, params)
        .pipe(map(
          data => data,
          err => err
        ));
    }
  }

  getSearchResult(params): Observable<any> {
    const url = `${ENV.media_baseUrl}${ApiUrls.PATIENT_SERVICE.video_names}`;
    return this.get(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  saveTask(params): Observable<any> {
    const url = `${ENV.rpm_baseurl}${ApiUrls.RPM_SERVICE.manage_task}`;
    return this.post(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  saveActivity(params): Observable<any> {
    const url = `${ENV.rpm_baseurl}${ApiUrls.RPM_SERVICE.clinical_activity_save}`;
    return this.post(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  updateUserSetting(params): Observable<any> {
    const url = `${ENV.rpm_baseurl}${ApiUrls.RPM_SERVICE.update_setting}`;
    return this.post(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  getAllTasks(params): Observable<any> {
    const url = `${ENV.rpm_baseurl}${ApiUrls.RPM_SERVICE.task_get}`;
    return this.get(url, params)
      .pipe(map(
        data => data,
        err => err
      ))
  }

  getAllActivity(params): Observable<any> {
    const url = `${ENV.rpm_baseurl}${ApiUrls.RPM_SERVICE.clinical_activity_get}`;
    return this.get(url, params)
      .pipe(map(
        data => data,
        err => err
      ))
  }

  getUserSetting(params): Observable<any> {
    const url = `${ENV.rpm_baseurl}${ApiUrls.RPM_SERVICE.setting_get}`;
    return this.get(url, params)
      .pipe(map(
        data => data,
        err => err
      ))
  }

  getUserSettingsAlert(params): Observable<any> {
    const url = `${ENV.rpm_baseurl}${ApiUrls.RPM_SERVICE.setting_alert_get}`;
    return this.get(url, params)
      .pipe(map(
        data => data,
        err => err
      ))
  }

  dismissAlert(params): Observable<any> {
    const url = `${ENV.rpm_baseurl}${ApiUrls.RPM_SERVICE.dismiss_alert}`;
    return this.post(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }
  //Save onboard date after confirmation
  postOnboardDate(params): Observable<any> {
    const url = `${ENV.rpm_baseurl}${ApiUrls.RPM_SERVICE.saveOnboardDate}`;
    return this.post(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  summaryData(data) {
    this.summaryDataChange.emit(data);
  }

  labClicked(data) {
    this.labClickedFromCardio.emit(data);
  }

  emitSelectedLab(params) {
    this.selectedLabs.emit(params);
  }

  emitTrendCount(count) {
    this.trendCountChange.emit(count);
  }
  emitLabCount(count) {
    this.selectedLabTrendCount = count;
    this.labCountChange.emit(count);
  }
  emitTrendgraph(status) {
    this.updateGraph.emit(status);
  }

  emitHeartScoreTrend(data) {
    this.heartScoreTrend.next(data);
  }

  getRiskProfileHistory(data) {
    this.profileHistory.emit(data);
  }

  setRightsideComponent(value) {
    this.rightsideComponentChange.next(value);
  }

  updateHeartScorePrediction(value) {
    this.updateHeartScore.next(value);
  }

  updateVitalSelection(data) {
    this.vitalSelectionChange.emit(data);
  }

  updateLabSelection(data) {
    this.labSelectionChange.emit(data);
  }

  updateModeSwitch(){
    this.modeSwitchUpdate.emit(true);
  }

  /**
   * -------------------------------------------------------------------- 
   * RPM related methods start here
   * --------------------------------------------------------------------
   */
  /**
  * @description To save enrollment
  */
  enrollementSave(params): Observable<any> {
    // params['workflow'] = this.workflow;
    const url = `${ENV.rpm_baseurl}${ApiUrls.RPM_SERVICE.problemEnroll}`;
    return this.post(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }
  /**
  * @description To unenroll the problem
  */
  unEnrollProblem(params): Observable<any> {
    // params['workflow'] = this.workflow;
    const url = `${ENV.rpm_baseurl}${ApiUrls.RPM_SERVICE.problemUnEnroll}`;
    return this.post(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  sendMail(params): Observable<any> {
    // params['workflow'] = this.workflow;
    const url = `${ENV.rpm_baseurl}${ApiUrls.RPM_SERVICE.sendMail}`;
    return this.post(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }
  sendMailDevice(params): Observable<any> {
    // params['workflow'] = this.workflow;
    const url = `${ENV.rpm_baseurl}${ApiUrls.RPM_SERVICE.sendMailDevice}`;
    return this.post(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }
  getCptDetails(params): Observable<any> {
    const url = `${ENV.rpm_baseurl}${ApiUrls.RPM_SERVICE.getCptDetails}`;
    return this.get(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  saveBill(params): Observable<any> {
    const url = `${ENV.rpm_baseurl}${ApiUrls.RPM_SERVICE.saveBill}`;
    return this.post(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }
  /**
  * -------------------------------------------------------------------- 
  * RPM related methods end here
  * --------------------------------------------------------------------
  */

  checkForUserInactivity() {
    //Start watching for user inactivity.
    this.userIdle.startWatching();

    // Start watching when user idle is starting.
    this.timerStartSub = this.userIdle.onTimerStart().subscribe(
      count => {
        if (count > 1) {
          this.checkInactivity.next();
        }
      });
  }

  rpmDataSet() {
    this.rpmData.emit();
  }
  importCsv(params) {
    let url = `${ENV.admin_baseurl}${ApiUrls.ADMIN_SERVCE.importCsv}`;

    return this.postX(url, params)
      .pipe(map(
        data => data,
        err => err
      ));

  }

  /**
   * Function to get the last uploaded file status
   * @returns 
   */
  getImportStatus(){
    let url = `${ENV.admin_baseurl}${ApiUrls.ADMIN_SERVCE.importCsv}`;
    return this.get(url,{})
      .pipe(map(
        data => data,
        err => err
      ));
  }
  patientAppointmentCRUD(params, isFormData = false, workflow?): Observable<any> {
    const workflowTypes = Constants.WORKFLOW;
    let apiUrl, url;
    if (!isFormData) {
      if (workflow === workflowTypes.RPM) {
        apiUrl = `${ENV.appointment_baseurl}${ApiUrls.APPOINTMENT_SERVICE.appointmentForRPM}`;
      } else if(workflow === workflowTypes.CIED) {
        apiUrl = `${ENV.appointment_baseurl}${ApiUrls.APPOINTMENT_SERVICE.appointmentForRPM}`;
      } else {
        apiUrl = `${ENV.appointment_baseurl}${ApiUrls.APPOINTMENT_SERVICE.appointmentCRUD}`;
      }
      return this.post(apiUrl, params).pipe(
        map(res => res,
          err => err)
      );
    } else {
      if (workflow === workflowTypes.RPM) {
        url = `${ENV.admin_baseurl}${ApiUrls.ADMIN_SERVCE.rpmAppoinments}`;
      } else if(workflow === workflowTypes.CIED) {
        url = `${ENV.admin_baseurl}${ApiUrls.ADMIN_SERVCE.rpmAppoinments}`;
      }  else {
        url = `${ENV.admin_baseurl}${ApiUrls.ADMIN_SERVCE.rpmAppoinments}`;
      }
      return this.postX(url, params)
        .pipe(map(
          data => data,
          err => err
        ));
    }
  }

  /**
* @description To save family history and surgical/med history info
*/
  savePatientHistoryInfo(params): Observable<any> {
    // params['workflow'] = this.workflow;
    const url = `${ENV.patient_baseurl}${ApiUrls.PATIENT_SERVICE.patient_report}`;
    return this.post(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }
  pullPatientChart(params): Observable<any> {
    // params['workflow'] = this.workflow;
    const url = `${ENV.fhir_baseurl}${ApiUrls.FHIR_SERVICE.get_ehr_chart}`;
    return this.post(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  getTrendRanges(params): Observable<any> {
    // params['workflow'] = this.workflow;
    const url = `${ENV.rpm_baseurl}${ApiUrls.RPM_SERVICE.setting_get}`;
    return this.get(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }
  getPatientsByMrid(params): Observable<any> {
    // params['workflow'] = this.workflow;
    const url = `${ENV.patient_baseurl}${ApiUrls.PATIENT_SERVICE.get_patients_by_mrid}`;
    return this.get(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }


  /**
   * @description To get all trend data-vitals,lab,risk etc
   * @param params
   */
  getAllTrendData(params): Observable<any> {
    params['workflow'] = this.workflow;
    const url = `${ENV.patient_baseurl}${ApiUrls.PATIENT_SERVICE.trend_data}`;
    return this.get(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  /**
    * @description To get all trend data-vitals,lab,risk etc
    * @param params
    */
  viewDocument(params): Observable<any> {
    const url = `${ENV.media_baseUrl}${ApiUrls.PATIENT_SERVICE.get_document}`;
    return this.post(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  /**
   * @description To get list of problem list
   * @param params
   */
  getProblemList(params): Observable<any> {
    const url = `${ENV.rpm_baseurl}${ApiUrls.PATIENT_SERVICE.getProblemlist}`;
    return this.get(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  /**
   * @description To format list getting from api to key value pair
   * @param problemlistFromApi list from API response
   */
  formatProblemListIntoKeyvaluePair(problemlistFromApi) {
    this.formattedProblemList = [];
    problemlistFromApi.push({ "description": "Others" });
    problemlistFromApi.forEach((element, index) => {
      const keyValueObject = { key: index + 1, value: element && element.description ? element.description : '' };
      this.formattedProblemList.push(keyValueObject);
    });
  }

  /**
   * @description To get master list data for device enrollment
   * @param params
   */
  getRPMMasterDataList(params): Observable<any> {
    const url = `${ENV.admin_baseurl}${ApiUrls.ADMIN_SERVCE.getRPMMasterList}`;
    return this.get(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }
  /**
   * @description storing non-permission values getting from categoryPermission api.
   * rpm_monitoring_params,wearable_device_list - using in enrollment
   * @param responseData
   */
  setMasterValues(responseData) {
    if (responseData && responseData.rpm_monitoring_params) {
      const storageData = {
        rpm_monitoring_params: responseData.rpm_monitoring_params,
      };
      this.storageService.setStorageItem(sessionStorage, storageData, true);
    }
    if (responseData && responseData.wearable_device_list) {
      const storageData = {
        wearable_device_list: responseData.wearable_device_list,
      };
      this.storageService.setStorageItem(sessionStorage, storageData, true);
    }
  }

  /**
   * @description To get master list of ostar devices
   * @param params
   */
  getOstarDeviceList(params): Observable<any> {
    const url = `${ENV.admin_baseurl}${ApiUrls.ADMIN_SERVCE.getOstarDeviceList}`;
    return this.get(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  /**
   * @description To save new ostar device
   */
  saveNewOstarDevice(params): Observable<any> {
    const url = `${ENV.admin_baseurl}${ApiUrls.ADMIN_SERVCE.saveOstarDevice}`;
    return this.post(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  getPatientNotes(params): Observable<any> {
    const url = `${ENV.patient_baseurl}${ApiUrls.PATIENT_SERVICE.getEHRNotes}`;
    return this.get(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }


  /**
   * Function will call the API to create the signed URL with expiry time and public URL
   * @param params 
   * @returns 
   */
  getSignedURL(params){
    const url = `${ENV.admin_baseurl}${ApiUrls.PATIENT_SERVICE.getSignedUrl}`;
    return this.post(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  /**
   * Function to get the Access code to login to mobile application
   * @param params parameters to pass for getting the access code
   * @returns 
   */
  getAccessCode(params){
    const url = `${ENV.admin_baseurl}${ApiUrls.ADMIN_SERVCE.patientMobileDetails}`;
    return this.get(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  /**
   * Function to generate access code or send access code.
   * @param params 
   * @returns 
   */
  generateAccessCode(params){
    const url = `${ENV.admin_baseurl}${ApiUrls.ADMIN_SERVCE.patientMobileDetails}`;
    return this.post(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  /**
   * Update user profile data
   * @param params 
   * @returns 
   */
  updateUser(params){
    const url = `${ENV.admin_baseurl}${ApiUrls.ADMIN_SERVCE.userProfile}`;
    return this.post(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  /**
   * Function to get the Access code to login to mobile application
   * @param params parameters to pass for getting the access code
   * @returns 
   */
   getUserAccessCode(params){
    const url = `${ENV.admin_baseurl}${ApiUrls.ADMIN_SERVCE.userMobileDetails}`;
    return this.get(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  /**
   * Function to generate access code or send access code.
   * @param params 
   * @returns 
   */
  generateUserAccessCode(params){
    const url = `${ENV.admin_baseurl}${ApiUrls.ADMIN_SERVCE.userMobileDetails}`;
    return this.post(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  /**
   * Function will get the logged in user profile details
   * @returns 
   */
  getUserProfile(){
    const url = `${ENV.admin_baseurl}${ApiUrls.ADMIN_SERVCE.userProfile}`;
    return this.get(url, [])
      .pipe(map(
        data => data,
        err => err
      ));
  }

  /**
   * Function will emit event when weight/height updated
   * @param params 
   */
  emitDemogVitalsChangeEvent(params?) {
    this.updateDemographicVitalsEvent.emit(params);
  }
  
   /**
   * Function will get event when weight/height updated
   * @returns 
   */
  getDemogVitalsChangeEmitter() {
    return this.updateDemographicVitalsEvent;
  }

  /**
   * function will call the API to update the vitals trend in DB
   * @returns 
   */
  updateShowHideTrendData(params){
    const url = `${ENV.patient_baseurl}${ApiUrls.PATIENT_SERVICE.trendSoftDel}`;
    return this.post(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  clinicalGuidelines(params): Observable<any>{
    const url = `${ENV.admin_baseurl}${ApiUrls.ADMIN_SERVCE.clinicalGuidelines}`;
    return this.post(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  clinicalGuidelinesCategory(params){
    const url = `${ENV.admin_baseurl}${ApiUrls.ADMIN_SERVCE.clinicalGuidelinesCategory}`;
    return this.get(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  carePathwaysList(params){
    const url = `${ENV.admin_baseurl}${ApiUrls.ADMIN_SERVCE.carePathwaysList}`;
    return this.get(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  carePathwaysExec(params){
    const url = `${ENV.admin_baseurl}${ApiUrls.ADMIN_SERVCE.carePathwaysExec}`;
    return this.post(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  /**
   * Data stream for download pdf
   * @param params 
   * @returns 
   */
  downloadCareplanReport(params): Observable<any>{
    const url = `${ENV.admin_baseurl}${ApiUrls.ADMIN_SERVCE.carePlanReport}`;
    return this.post(url, params, 1, '', true)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  submitCareplanReportEHR(params): Observable<any>{
    const url = `${ENV.admin_baseurl}${ApiUrls.ADMIN_SERVCE.submitReportEHR}`;
    return this.post(url, params, 1, '', true)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  /**
   * Function will parse the lab and vitals accpeted in the nlp ocr parsing
   * @param labVitalsAccepted 
   * @param reportDate 
   * @param patientId 
   */
  labVitalUpdateFromNLP(labVitalsAccepted, reportDate, patientId){
    let vitalsPresent = false;
    let labPresent = false;
    let vitals_data = {"SBP":null,"DBP":null,"BMI":null,"HT":null,"HR":null,"RR":null,"SPO2":null,"WT":null};
    let lab_data = {};
    if(labVitalsAccepted && labVitalsAccepted.length > 0){
      for(let i=0; i < labVitalsAccepted.length; i++){
        if(labVitalsAccepted[i].type == 'lab'){ // lab array create
          lab_data[labVitalsAccepted[i].data_key.toUpperCase()] = labVitalsAccepted[i].value;
          labPresent = true;
        }else if(labVitalsAccepted[i].type == 'vitals'){ // vitals array update
          vitals_data[labVitalsAccepted[i].data_key.toUpperCase()] = labVitalsAccepted[i].value;
          vitalsPresent = true;
        }
      }
      // check vitals and lab then save
      //console.log(lab_data, vitals_data, reportDate);
      if(labPresent){
        let params = {report_type: "lab", date: reportDate, medicardia_id : patientId, lab_data: lab_data};
        this.saveCathInfo(params).subscribe(
          res => {
            //console.log(res);
          }
        );
       /*  const url = `${ENV.patient_baseurl}${ApiUrls.PATIENT_SERVICE.patient_report}`;
        this.post(url, params)
          .pipe(map(
            data => data,
            err => err
          )); */
      }
      if(vitalsPresent){
        let params = {medicardia_id: patientId, vitals_data: vitals_data};
        this.saveVitalsInfo(params).subscribe(
          res => {
            //console.log(res);
          }
        );
        /* const url = `${ENV.patient_baseurl}${ApiUrls.PATIENT_SERVICE.vital_save}`;
        this.post(url, params)
          .pipe(map(
            data => data,
            err => err
          )); */
      }
    }
  }
}
