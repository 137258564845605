import { Component, OnInit, ViewChild } from '@angular/core';
import { UserIdleService } from 'angular-user-idle';
import { CountdownComponent } from 'ngx-countdown';
import { PatientService } from '../../components/patient/patient.service';
import { ModalController, NavController } from '@ionic/angular';
import { TimerService } from 'src/app/core/services/timer/timer.service';
import { Constants } from 'src/app/shared/constants';
import { ConfigurationService } from 'src/app/core/services/configuration/configuration.service';

@Component({
  selector: 'app-user-inactivity-manager-modal',
  templateUrl: './user-inactivity-manager-modal.page.html',
  styleUrls: ['./user-inactivity-manager-modal.page.scss'],
})
export class UserInactivityManagerModalPage implements OnInit {

  public timerCount: any;
  public infoText: string;
  isTimeUp = false;
  timeLeft = Constants.USER_INACTIVITY.COUNT_DOWN;
  @ViewChild('countdown', { static: false }) counter: CountdownComponent;

  constructor(
    private userIdle: UserIdleService,
    private patientService: PatientService,
    public modalCtrl: ModalController,
    private timerService: TimerService,
    private navCtrl: NavController,
    private confirgurationService: ConfigurationService,
  ) { }

  ngOnInit() {
    const time = this.confirgurationService.getConfigurationDetails() &&
      this.confirgurationService.getConfigurationDetails().rpmTimeout ?
      this.confirgurationService.getConfigurationDetails().rpmTimeout / 60 : 1;
    this.infoText = `You have been idle for ${time} minute , timer in ${this.patientService.workflow.toUpperCase()} will get stopped in`;
  }

  finishTest(e) {
    if (e.action === 'done') {
      this.isTimeUp = true;
      this.timerService.rpmTimerPaused();
      this.timerService.pauseTimer();
    }
  }

  resetTimer() {
    this.counter.restart();
  }

  OnCancel() {
    this.closeModal();
    if (this.isTimeUp) {
      // redirect  to appointment screen
      this.navCtrl.navigateRoot('appointments');

    } else {
      this.userIdle.stopWatching();
      this.patientService.checkForUserInactivity();
    }
  }

  OnResetTimer() {
    this.closeModal();
    if (this.isTimeUp) {
      this.timerService.rpmTimerStarted();
      this.timerService.startTimer();
      this.patientService.checkForUserInactivity();
    } else {
    }
  }

  closeModal(data = {}) {
    this.modalCtrl.dismiss(data);
  }

}
