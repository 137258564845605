import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UtilsService } from 'src/app/shared/common';
import { NavParams, NavController, ModalController } from '@ionic/angular';
import { Constants } from 'src/app/shared/constants';
import { RangeValidatorDirective } from 'src/app/shared/directives/range-validator/range-validator';
import { PatientService } from '../../components/patient/patient.service';
import { ECGModel, EcgRequestModel } from 'src/app/core/models/ecg';
import * as _ from 'lodash';
import moment from 'moment';
import { DataTransferService } from 'src/app/core/services/data-transfer/data-transfer.service';
import { AddEditEcgComponent } from '../../components/patient/add-edit-ecg/add-edit-ecg.component';
import { DocumentService } from 'src/app/core/services/document.service';
import { ConfigurationService } from 'src/app/core/services/configuration/configuration.service';

@Component({
  selector: 'app-ecg-modal',
  templateUrl: './ecg-modal.page.html',
  styleUrls: ['./ecg-modal.page.scss'],
})
export class EcgModalPage implements OnInit {

  ecgPopComponent = Constants.COMPONENTS.ECG_INFO;
  @ViewChild(AddEditEcgComponent, { static: false }) childData: AddEditEcgComponent;
  ecgReport: any;
  patientId: any;
  visitNumber: any;
  rightSideComponent: any;
  id: any;
  pdfList: any;
  configurationData = this.configurationService.getConfigurationDetails();
  constructor(
    public navParams: NavParams,
    public navCtrl: NavController,
    public modalCtrl: ModalController,
    public utilService: UtilsService,
    public documentService: DocumentService,
    public dataTransferService: DataTransferService,
    public configurationService: ConfigurationService,
    public patientService: PatientService) {
    this.ecgReport = this.navParams.get('ecgReport');
    this.patientId = this.navParams.get('patientId');
    this.visitNumber = this.navParams.get('visit_number');
    this.rightSideComponent = this.navParams.get('rightSideComponent');
    this.id = this.ecgReport && this.ecgReport.length && this.ecgReport[0].id ? this.ecgReport[0].id : null;
  }
  ngOnInit() {
    this.getDocumentsByType();
  }

  closeModal(data = {}) {
    this.modalCtrl.dismiss(data);
  }
  openDiscardChangePop() {
    let isDirty = this.childData.isDirty;
    const newList = this.childData.ecgForm.value;
    const previousLabdata = this.childData.ecgReport;
    const isEqual = _.isEqual(newList, previousLabdata) ? false : true;
    if (isDirty || !isEqual) {
      const params = { component: this.ecgPopComponent };
      this.dataTransferService.openDiscardPopup(params);
    } else {
      isDirty = false;
      this.utilService.changeDetected(this.ecgPopComponent, isDirty);
      this.closeModal(false);
    }
  }
  getDocumentsByType() {
    const params = {
      all: 1,
      id: this.navParams.data.patientId,
      report_type: 'ecg'
    };

    this.documentService.getDocuments(params).subscribe(
      res => {
        if (res && res.data) {
          let modifiedList = [];
          if (res.data.length != 0) {
            res.data.forEach((item) => {
              let element = {
                ecg: item
              }
              item = ECGModel.create(element, this.configurationData.hospitalTimezone);
              item.pdfdate = item && item.date && item.date.$date ? this.utilService.changeDateFormat(item.date.$date) : item.date;
              modifiedList.push(item);
            });
          }
        }
      }
    );
  }
  getReport(id) {
    this.ecgReport = {};
    if (id && this.pdfList.length != 0 && id != -1) {
      this.pdfList.forEach(element => {
        if (element.object_id == id) {
          this.ecgReport = element;
        }
      });
    }
  }
}
