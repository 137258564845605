import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DecimalNumberDirective } from './decimal-number.directive/decimal-number.directive';
import { NumbersOnlyDirective } from './number-only.directive/number-only.directive';
import { RangeValidatorDirective } from './range-validator/range-validator';
import { StringInputDirective } from './string-input/string-input';
import { HideFeatureDirective } from './hide-feature/hide-feature.directive';
import { DisableFeatureDirective } from './disable-feature/disable-feature.directive';
import { PasswordToAsteriskDirective } from './password-to-asterisk/password-to-asterisk.directive';
import { NoWhiteSpaceDirective } from './no-white-space/no-white-space.directive';
import { SvgEllipsisDirective } from './svg-ellipsis/svg-ellipsis.directive';
import { DisableClickDirective } from './disable-click/disable-click.directive';
import { RestrictZeroDirective } from './restrict-zero/restrict-zero.directive';
import { extentionSliceDirective } from './extention-slice/extention-slice.directive';
import { PhoneNumberDirective } from './phone-number/phone-number.directive';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    RestrictZeroDirective,
    SvgEllipsisDirective,
    NumbersOnlyDirective,
    DecimalNumberDirective,
    RangeValidatorDirective,
    StringInputDirective,
    HideFeatureDirective,
    DisableFeatureDirective,
    PasswordToAsteriskDirective,
    NoWhiteSpaceDirective,
    extentionSliceDirective,
    SvgEllipsisDirective,
    DisableClickDirective,
    PhoneNumberDirective
  ],
  exports: [
    RestrictZeroDirective,
    SvgEllipsisDirective,
    NumbersOnlyDirective,
    DecimalNumberDirective,
    StringInputDirective,
    RangeValidatorDirective,
    HideFeatureDirective,
    DisableFeatureDirective,
    PasswordToAsteriskDirective,
    NoWhiteSpaceDirective,
    extentionSliceDirective,
    DisableClickDirective,
    PhoneNumberDirective
  ],
})
export class DirectivesModule { }
