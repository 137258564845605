import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { RouterModule } from '@angular/router';
import { BaseComponent } from './base.component';
import { IonicModule } from '@ionic/angular';
import { SimpleModalPage } from 'src/app/modules/modals/simple-modal/simple-modal.page';
import { SimpleModalPageModule } from 'src/app/modules/modals/simple-modal/simple-modal.module';

import { OverlayFullLoaderModule } from 'src/app/shared/components/overlay-full-loader/overlay-full-loader.module';
import { RpmBillingModule } from 'src/app/modules/components/rpm-billing/rpm-billing.module';
import { OverlayModeSwitchModule } from 'src/app/shared/components/overlay-mode-switch/overlay-mode-switch.module';


@NgModule({
  declarations: [
    BaseComponent,
  ],
  imports: [
    IonicModule,
    RouterModule,
    SharedModule,
    CommonModule,
    SimpleModalPageModule,
    OverlayFullLoaderModule,
    RpmBillingModule,
    OverlayModeSwitchModule,
  ],
  entryComponents:[
    SimpleModalPage
  ]
})
export class BaseModule { }
