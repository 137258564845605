import { Pipe, PipeTransform } from '@angular/core';

import { Constants } from './../../../shared/constants';

@Pipe({
  name: 'gender',
})
export class GenderPipe implements PipeTransform {

  transform(value: string, format: string, ...args) {
    let genderType: string;
    value = value && (/^[a-zA-Z]+/).test(value) ? value.toUpperCase() : (value && (/^[0-9]+/).test(value) ? value : '');
    switch (value) {
      case 'M':
      case '1':
        genderType = format === Constants.GENDER_DISPLAYFORMAT.LONG ? 'Male' : 'M';
        break;
      case 'F':
      case '0':
        genderType = format === Constants.GENDER_DISPLAYFORMAT.LONG ? 'Female' : 'F';
        break;
      case 'O':
        genderType = format === Constants.GENDER_DISPLAYFORMAT.LONG ? 'Other' : value;
        break;
      case 'U':
        genderType = format === Constants.GENDER_DISPLAYFORMAT.LONG ? 'Unknown' : value;
        break;
      case 'A':
        genderType = format === Constants.GENDER_DISPLAYFORMAT.LONG ? 'Ambiguous' : value;
        break;
      case 'N':
        genderType = format === Constants.GENDER_DISPLAYFORMAT.LONG ? 'Not Applicable' : value;
        break;
      case '':
        genderType = 'NA';
        break;
      default:
        genderType = 'NA';
        break;
    }
    return genderType;
  }
}
