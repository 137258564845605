import { Component, OnInit, OnChanges, SimpleChanges, Output, Input, EventEmitter } from '@angular/core';
import { UtilsService } from '../../common';

@Component({
  selector: 'app-range-factor',
  templateUrl: './range-factor.component.html',
  styleUrls: ['./range-factor.component.scss'],
})
export class RangeFactorComponent implements OnChanges {

  @Output() valueChange = new EventEmitter();
  @Input() riskData: any;
  @Input() dataValue;
  value: number;
  strokeData = {};

  constructor(
    public utilService: UtilsService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    const CONTROLS = 'dataValue';
    for (const propName in changes) {
      if (propName) {
        const change = changes[propName];
        const curVal = change.currentValue;
        const preVal = change.previousValue;
        const strCurVal = JSON.stringify(curVal);
        const strPrevVal = JSON.stringify(preVal);
        if (strCurVal !== strPrevVal && strCurVal) {
          switch (propName) {
            case CONTROLS:
              this.value = this.dataValue ? this.dataValue : (this.riskData && this.riskData.MIN ? this.riskData.MIN : undefined);
              break;
          }
        }
      }
    }
  }

  onChange(newValue) {
    if (newValue) {
      this.value = newValue;
      const name = this.riskData.CONTROLNAME;
      this.strokeData[name] = newValue;
      this.valueChange.emit(this.strokeData);
    }
  }

}
