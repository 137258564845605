import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Location } from '@angular/common';
import { Constants } from './constants';
import { ToastController, PopoverController, NavController, AlertController, ModalController } from '@ionic/angular';
import moment from 'moment';
import { Router } from '@angular/router';
import { StorageService } from '../core/services/authentication/storage.service';
import { DataTransferService } from '../core/services/data-transfer/data-transfer.service';
import { PatientService } from '../modules/components/patient/patient.service';
import { ConfigurationService } from '../core/services/configuration/configuration.service';
import { ConfigurationInterface } from '../core/models/configuration';
import { UserIdleService } from 'angular-user-idle';
import { CookieService } from 'ngx-cookie-service';

@Injectable()

export class UtilsService {

  toastControl: any;
  alertPop: any;
  isIpad = false;
  allrhythmOptions = Constants.DROPDOWN_OPTIONS.RHYTHM;
  problemList = this.patientService.formattedProblemList;
  modalPage: any;

  themeKey:any = 'light';

  constructor(
    private toastCtrl: ToastController,
    private dataTransferService: DataTransferService,
    private popoverCtrl: PopoverController,
    private alertCtrl: AlertController,
    private router: Router,
    private location: Location,
    private storageService: StorageService,
    public patientService: PatientService,
    private navCtrl: NavController,
    public modalCtrl: ModalController,
    private userIdle: UserIdleService,
    public configurationService: ConfigurationService,
    private cookieService: CookieService
  ) {
    this.setIsIpad();
    const themeCookieValue = this.storageService.getStorageItem(localStorage, '_tks', true);//localStorage.getItem('_tks');//this.cookieService.get('themeKey');
    this.themeKey = this.storageService.getStorageItem(sessionStorage, 'userData', true) && 
      this.storageService.getStorageItem(sessionStorage, 'userData', true).themKey ? 
      this.storageService.getStorageItem(sessionStorage, 'userData', true).themKey : (themeCookieValue ? themeCookieValue : this.themeKey);
  }

  findObjectIndex(list: any[], obj: any, key: string): number {
    return _.findIndex(list, item => {
      return obj[key] === item[key];
    });
  }

  findObject(list: any[], key: any, value: any): any {
    return _.find(list, item => {
      return item[key] === value;
    });
  }

  findObjectByQuery(list: any[], key: string, query: string): any {
    return _.find(list, item => {
      return item[key].toLowerCase() === query.toLowerCase();
    });
  }

  removeObject(list: any[], key: any, value: any) {
    _.remove(list, currentObject => {
      return currentObject[key] === value;
    });
  }

  findIndex(list, key, value) {
    return list.map(e => e[key]).indexOf(value);
  }

  arrayContains(list, val) {
    return _.indexOf(list, val);
  }

  sortArray(list: any[], key, order) {
    return _.orderBy(list, key, order);
  }

  isEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  lbsToKg(lbs) {
    if (lbs !== '' && lbs !== undefined) {
      return parseFloat(((1 / 2.204623) * lbs).toString());
    }
    return null;
  }

  kgToLbs(kg) {
    if (kg !== '' && kg !== undefined) {
      return parseFloat((2.204623 * kg).toString());
    }
    return null;
  }

  inchToMeter(inch) {
    if (inch !== '' && inch !== undefined) {
      return (0.0254 * inch).toString();
    }
    return null;
  }

  meterToInches(meter) {
    if (meter !== '' && meter !== undefined) {
      return (39.3701 * meter).toString();
    }
    return null;
  }

  updateSortType(tableHeader, keyToBeSet, isReset) {
    tableHeader.forEach(element => {
      element.sortType = element.key === keyToBeSet ? (isReset ? Constants.SORT_TYPE.ASC : (
        element.sortType === Constants.SORT_TYPE.NIL || element.sortType === Constants.SORT_TYPE.DESC ?
          Constants.SORT_TYPE.ASC : Constants.SORT_TYPE.DESC
      )) : Constants.SORT_TYPE.NIL;
    });
    return tableHeader;
  }

  async showToast(message, color = 'success', position: any = 'top', duration = 5000) {
    const cssClass = color === 'success' ? 'success-toaster' : 'error-toaster';
    if (this.toastControl) {
      this.toastControl.dismiss();
    }
    this.toastControl = await this.toastCtrl.create({
      message,
      duration,
      position,
      color,
      cssClass
    });
    this.toastControl.present();
  }

  async showTooltip(myEvent, data, cls) {
    const alert = await this.popoverCtrl.create(
      {
        component: 'PopoverPage',
        event: myEvent,
        componentProps: data,
        cssClass: cls,
        translucent: true,
        backdropDismiss: false
      });
    return await alert.present();
  }

  async showAlert(message) {
    this.alertPop && !this.alertPop._detached ? this.alertPop.dismiss() : undefined;
    this.alertPop = await this.alertCtrl.create({
      subHeader: message,
      backdropDismiss: false,
      buttons: [Constants.BUTTON_TEXT.okay]
    });

    await this.alertPop.present();
  }
  getDateTimeString(date) {
    return date + 'T00:00:00Z';
  }

  changeToKeyValue(data) {
    const obj = {};
    data.forEach(element => {
      obj[element.key] = element.value;
    });
    return obj;
  }

  /**
   * @description To generate random color
   */
  getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let index = 0; index < 6; index++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  deepCopy(data) {
    return _.cloneDeep(data);
  }

  spreadObject(data) {
    return { ...data };
  }

  updateSeverity(data): string {
    let abnormality = '';
    for (const valvular of data) {
      if (valvular.Severity) {
        switch ((valvular.Severity).toLowerCase()) {
          case 'mild':
            abnormality = abnormality + valvular.Valvular_abnormality.toLowerCase() + '/';
            break;
          case 'moderate':
            abnormality = abnormality + (valvular.Valvular_abnormality)[0].toLowerCase()
              + (valvular.Valvular_abnormality)[1].toUpperCase() + '/';
            break;
          case 'severe':
            abnormality = abnormality + valvular.Valvular_abnormality.toUpperCase() + '/';
            break;
        }
      }

    }

    abnormality = abnormality ? abnormality.slice(0, -1) : 'None';
    return abnormality;
  }

  getRhythm(formValues) {
    const rhythmOptions = Constants.DROPDOWN_OPTIONS.RHYTHM;

    return formValues && typeof (formValues.rhythm) === 'number'
      ? ((rhythmOptions.find(obj => {
        return obj.value === formValues.rhythm;
      })) ? (rhythmOptions.find(obj => {
        return obj.value === formValues.rhythm;
      })).key : undefined) : formValues.rhythm;
  }

  convertDatePickerTimeToMySQLTime(str) {
    const date = new Date(str);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);

    const mySQLDate = [date.getFullYear(), month, day].join('-');
    const mySQLTime = [hours, minutes, seconds].join(':');
    const utcTime = [mySQLDate, mySQLTime].join('T') + 'Z';
    return utcTime;
  }

  /**
   * @description Creates editable field in svg
   * @param selectedKey selected heat map key
   * @param localpoint x and y position for the editable field
   * @param nodeToBeReplaced tspan to be replaced
   * @param currentText text value to be binded to editable field
   */
  createEditableTextNode(selectedKey, localpoint, nodeToBeReplaced, currentText) {
    const myforeign = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
    const textdiv = document.createElement('div');
    const textnode = document.createTextNode(currentText);
    textdiv.appendChild(textnode);
    textdiv.setAttribute('contentEditable', 'true');
    textdiv.setAttribute('width', '100%');
    textdiv.setAttribute('style', 'white-space: nowrap');
    myforeign.setAttribute('width', '70%');
    myforeign.setAttribute('height', '18px');
    myforeign.setAttribute('font-size', '15px');
    myforeign.setAttribute('color', 'white');
    myforeign.setAttribute('id', selectedKey);
    myforeign.setAttribute('style', 'line-height: 1');
    myforeign.setAttributeNS(null, 'transform', 'translate(' + localpoint.x + ' ' + localpoint.y + ')');
    nodeToBeReplaced.appendChild(myforeign);
    myforeign.appendChild(textdiv);
    this.placeCaretPosition(textdiv);
  }

  /**
   * @description Places the caret position at the end of text
   * @param textdiv Element where caret is to be placed at the end
   */
  placeCaretPosition(textdiv) {
    textdiv.focus();
    const range = document.createRange();
    range.selectNodeContents(textdiv);
    range.collapse(false);
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  }

  isFormArrayEntryEmpty(formData, index): boolean {
    let isEmpty = true;
    for (const key in formData[index]) {
      if (formData[index][key] !== '' || formData[index][key] === Constants.RPM_ENROLLMENT_STATUS.NOT_ENROLLED ||
        formData[index][key] === null) {
        isEmpty = false;
      }
    }
    return isEmpty;
  }

  isFormArrayAllEntriesEmpty(formData, index): boolean {
    let isEmpty = false;
    let controlCount = 0;
    for (const key in formData[index]) {
      if (key != 'Problem' && formData[index][key] === '' || formData[index][key] === Constants.RPM_ENROLLMENT_STATUS.NOT_ENROLLED ||
        formData[index][key] === null) {
        controlCount += 1;
      } else {
        if (formData[index][key].key === '' && formData[index][key].value === '') {
          controlCount += 1;
        }
      }
    }
    isEmpty = controlCount === Object.keys(formData[index]).length ? true : isEmpty;
    return isEmpty;
  }

  isFormArrayEntryValid(formData, index, controls): boolean {
    let isDataMissing = false;
    for (const key in formData[index]) {
      if (formData[index][key] === '' || formData[index][key] === null || formData[index][key] === undefined) {
        const controlData: any = this.findObject(controls[0].keys, 'name', key);
        isDataMissing = controlData.isRequired || formData[index].isDuplicate === true ? true : isDataMissing;
      }
    }
    return isDataMissing;
  }

  getVisitMode(visitType) {
    let visitMode = 0;
    switch (visitType) {
      case Constants.APPOINTMENT_TYPES.VIDEO:
        visitMode = Constants.VISIT_MODE.FULLSCREEN;
        break;
      case Constants.APPOINTMENT_TYPES.IN_PERSON:
        visitMode = Constants.VISIT_MODE.MEDICARDIA;
        break;
    }
    return visitMode;
  }

  getCurrentDateTime() {
    const configurationData: ConfigurationInterface = this.configurationService.getConfigurationDetails();
    return moment().tz(configurationData.hospitalTimezone);
  }

  isAllKeysEmpty(obj): boolean {
    const objCopy = this.deepCopy(obj);
    if ('patient' in objCopy) {
      delete objCopy.patient;
    }
    let isEmptyObj = false;
    if (!this.isEmpty(objCopy)) {
      const keys = Object.keys(objCopy);
      let count = 0;
      if (keys) {
        keys.forEach(element => {
          const isObjEmpty = objCopy[element] === undefined || (objCopy[element] && objCopy[element].length === 0);
          count = isObjEmpty ? count + 1 : count;
          if (!isObjEmpty && element === 'LA_dimension') {
            count = !objCopy[element].LAD_key && !objCopy[element].LAD_value ? count + 1 : count;
          }
        });
      }
      isEmptyObj = count === keys.length ? true : false;
    }
    return isEmptyObj;
  }

  getJoinedStringFromList(list, key) {
    return list.map(res => res[key]).join(', ');
  }

  clearUserData() {
    const storageData = this.storageService.getStorageItem(sessionStorage, 'userData', true);
    if (storageData && storageData.token) {
      this.location.replaceState('/');
      window.location.replace('/');
      this.dataTransferService.selectedProvider = undefined;
      this.dataTransferService.selectedLocation = undefined;
      this.dataTransferService.isRefreshed = true;
      this.dataTransferService.setUserData(undefined);
      sessionStorage.clear();
      this.router.navigate(['login']);
    }
  }

  checkForUserInactivity() {
    this.patientService.checkForUserInactivity();
  }

  isAssignedPhysician(): boolean {
    const loggedUserData = this.dataTransferService.getUserData();
    const providerId = this.dataTransferService.patientDemographic ? (this.patientService.workflow === Constants.WORKFLOW.RPM ?
      this.dataTransferService.patientDemographic.rpm_enrolled_provided_id : this.dataTransferService.patientDemographic.provider_id)
      : undefined;
    return loggedUserData && loggedUserData.user_id && providerId && loggedUserData.user_id !== providerId ? false : true;
  }

  isNurse(): boolean {
    const loggedUserData = this.dataTransferService.getUserData();
    const group = loggedUserData && loggedUserData.permission && loggedUserData.permission.length && loggedUserData.permission[0]
      && loggedUserData.permission[0].group && loggedUserData.permission[0].group.name ? loggedUserData.permission[0].group.name : null;
    return group && group === 'Nurse' ? true : false;
  }

  getRangeValue(label, key) {
    const range = Constants.RANGE_VALUES[label] ? Constants.RANGE_VALUES[label][key] : '';
    return range;
  }

  sessionExpired() {
    this.resetIsDirty();
    this.setSessionExpiredStatusInstrorage();
    const storageData = this.storageService.getStorageItem(sessionStorage, 'userData', true);
    if (storageData && storageData.token) {
      this.dataTransferService.selectedLocation = undefined;
      this.dataTransferService.selectedProvider = undefined;
      this.dataTransferService.isRefreshed = true;
      this.userIdle.stopWatching();
      this.navCtrl.navigateRoot('session-expiry');
    }
  }

  setSessionExpiredStatusInstrorage() {
    const sessionExpiredStatus = {
      sessionExpired: true
    };
    this.storageService.addDataToStorage(sessionStorage, sessionExpiredStatus, true);
  }

  changeDetected(component, isDirty) {
    if (isDirty) {
      this.patientService.isDirty.status = true;
      if (this.patientService.isDirty.component.indexOf(component) === -1) {
        this.patientService.isDirty.component.push(component);
      }
    } else {
      const index = this.patientService.isDirty.component.indexOf(component);
      if (index !== -1) {
        this.patientService.isDirty.component.splice(index, 1);
      }
      if (this.patientService.isDirty.component.length === 0) {
        this.patientService.isDirty.status = false;
      }
    }
  }

  resetIsDirty() {
    this.patientService.isDirty = {
      component: [],
      status: false
    };
  }

  calculateBmi(unit, height, bmiKg) {
    let weight = bmiKg;
    if (unit === Constants.BMI_SYSTEM.ENGLISH.key) {
      height = height / 39.3700787;
      weight = weight / 2.204623;
    }
    const bmiValue = _.round((weight / Math.pow(height, 2)), 1);
    return {
      value: bmiValue,
      isValid: Constants.CONTROLS.RISKFACTOR_CONTROLS.BMI.MIN > bmiValue ||
        Constants.CONTROLS.RISKFACTOR_CONTROLS.BMI.MAX < bmiValue ? false : true
    };
  }

  tooltipOperation(tooltipEvent) {
    tooltipEvent.toggle();
  }

  setIsIpad() {
    this.isIpad = navigator.userAgent.match(/iPad/i) != null ? true : false;
  }

  getRhythmFiltertedResults(keyword: string) {
    return this.allrhythmOptions.filter(item => item.key.toLowerCase().includes(keyword.toLowerCase()));
  }

  getProblemList(keyword: string) {
    this.problemList = (this.problemList && this.problemList.length > 0) ? this.problemList : this.patientService.formattedProblemList;
    return this.problemList.filter(item => item.value.toLowerCase().includes(keyword.toLowerCase())); //  || item.value.toLowerCase() == 'Others'.toLocaleLowerCase()
  }

  changeDateFormat(element) {
    element = moment(element).format('DD MMM YYYY');
    return element;
  }

  validateAppointmentDate(appointmentDate, timeZone) {
    return new Date(new Date(appointmentDate)).valueOf() -
      new Date(new Date().toLocaleString("en-US", { timeZone })).valueOf() > 0 ? false : true;

  }
  getEntityList(data, isECG = false) {
    let entityList = [];
    let keyList = Object.keys(data);
    keyList.forEach(key => {
      if (key !== "is_reviewed" && key !== 'Valvular_abnormality' && key !== 'date' && data[key]) {
        if (data[key].nlp_key && data[key].nlp_key_start_index != null
          && data[key].nlp_key_end_index != null
          && data[key].nlp_val
          && data[key].nlp_val_start_index != null
          && data[key].nlp_val_end_index != null) {
          let record = {
            entity_key: {
              entity_text: isECG ? this.getEcgKeys(key) : data[key].nlp_key,
              start_offset: data[key].nlp_key_start_index,
              end_offset: data[key].nlp_key_end_index
            },
            entity_value: {
              entity_text: data[key].nlp_val,
              start_offset: data[key].nlp_val_start_index,
              end_offset: data[key].nlp_val_end_index
            },
            formKey: key,
            showAcceptButton: (data[key].nlp_val !== data[key].val && !data.is_reviewed) ? true : false,
            response: 0,
          };
          entityList.push(record);
        }
      } else if (key === 'Valvular_abnormality') {
        let record = {};
        if (data[key].length !== 0) {

          data[key].forEach((element, index) => {
            if (!this.isEmpty(element.nlp_val)) {
              record = {
                entity_key: {
                  entity_text: element.nlp_val.valvular_abnormality,
                  start_offset: element.nlp_val.nlp_key_start_index,
                  end_offset: element.nlp_val.nlp_key_end_index
                },
                entity_value: {
                  entity_text: element.nlp_val.severity,
                  start_offset: element.nlp_val.nlp_val_start_index,
                  end_offset: element.nlp_val.nlp_val_end_index
                },
                formKey: 'valvular_abnormality',
                index: index,
                showAcceptButton: (element.nlp_val.severity !== '' && element.nlp_val.severity !== element.val.severity &&
                  !data.is_reviewed) ? true : false,
                response: 0
              };
              entityList.push(record);
            }
          });

          entityList = entityList.reduce((accumulator, current) => {
            if (!accumulator.find((res) => res.entity_key.entity_text === current.entity_key.entity_text)) {
              accumulator.push(current);
            }
            return accumulator;
          }, []);
        }
      } else if (key === 'date') {// Converting the date value
        let record = {};
        if (data[key].nlp_key && data[key].nlp_key_start_index != null
          && data[key].nlp_key_end_index != null
          && data[key].nlp_val
          && data[key].nlp_val_start_index != null
          && data[key].nlp_val_end_index != null) {
          let record = {
            entity_key: {
              entity_text: isECG ? this.getEcgKeys(key) : data[key].nlp_key,
              start_offset: data[key].nlp_key_start_index,
              end_offset: data[key].nlp_key_end_index
            },
            entity_value: {
              entity_text: moment(data[key].nlp_val['$date']).tz(this.configurationService.getConfigurationDetails().hospitalTimezone
              ).format('YYYY-MM-DDTHH:mm:ss'),

              start_offset: data[key].nlp_val_start_index,
              end_offset: data[key].nlp_val_end_index
            },
            formKey: key,
            showAcceptButton: (data[key].nlp_val !== data[key].val && !data.is_reviewed) ? true : false,
            response: 0,
          };
          entityList.push(record);
        }
      }
    });
    return entityList;
  }

  getLedStatus(nlpValuesList) {
    let showLed: any = {};
    if (!this.isEmpty(nlpValuesList)) {
      let keyList = Object.keys(nlpValuesList);
      if (keyList.length !== 0 && !nlpValuesList.is_reviewed) {
        keyList.forEach(key => {
          if (key !== "is_reviewed" && key !== 'Valvular_abnormality' && key !== 'date') {
            if (nlpValuesList[key].nlp_val !== null && nlpValuesList[key].nlp_val !== nlpValuesList[key].val &&
              !nlpValuesList.is_reviewed) {
              showLed[key.toLowerCase()] = true;
            } else {
              showLed[key.toLowerCase()] = false;
            }
          } else if (key === 'Valvular_abnormality') {
            showLed[key.toLowerCase()] = this.getValvular(nlpValuesList[key]);
          } else if (key === 'date') {
            showLed[key.toLowerCase()] = this.getDateLedStatus(nlpValuesList[key], nlpValuesList.is_reviewed);
          }
        });
      }
    }
    return showLed;
  }
  getValvular(data) {
    let setTrue = false;
    data.forEach(element => {
      if (!this.isEmpty(element.val) && !this.isEmpty(element.nlp_val)) {
        if (element.val.valvular_abnormality === element.nlp_val.valvular_abnormality
          && element.val.severity !== element.nlp_val.severity) {
          setTrue = true;
        }
      } else if (this.isEmpty(element.val) && !this.isEmpty(element.nlp_val)) {
        setTrue = true;
      }
    });
    return setTrue;
  }
  getDateLedStatus(data, isReviewed) {
    let setStatus = false;
    if (!this.isEmpty(data.val) && !this.isEmpty(data.nlp_val) &&
      (data.val['$date'] !== data.nlp_val['$date']) && !isReviewed) {
      setStatus = true;
    } else {
      setStatus = false;
    }
    return setStatus;
  }

  updateShowLed(showLed, value) {
    if (showLed[value.toLowerCase()]) {
      showLed[value.toLowerCase()] = false;
    }
    return showLed;
  }
  getCardioDates(date) {
    if (date) {
      return moment(date).format('YYYY-MM-DD') + 'T' + moment().format('HH:mm:ss') + 'Z';
    }
  }

  getEchoDates(date) {
    if (date) {
      return moment(date).format('YYYY-MM-DD') + 'T' + ('00:00:00') + 'Z';
    }
  }

  getEcgKeys(key) {
    const keyList = {
      QTc: 'QTc',
      QRS: 'QRS',
      QT: 'QT',
      notes: 'Notes',
      Rate: 'Rate',
      rhythm: 'Rhythm',
      pr: 'PR',
      qrsd: 'QRSd',
      p: 'P',
      t: 'T',
      summary: 'Summary'
    }
    return keyList[key];
  }

  getData(list) {
    const timezone = this.configurationService.getConfigurationDetails().hospitalTimezone;
    if (list.length) {
      // let data = [];
      // list.forEach((res) => {
      //   if(res.date && res.date['$date']){
      //     let check = data.findIndex(item => item.date === moment(res.date['$date']).tz(timezone).format('DD MMM YYYY'));
      //   if (check == -1) {
      //     let obj = {
      //       date: moment(res.date['$date']).tz(timezone).format('DD MMM YYYY'),
      //       result: [res]
      //     }
      //     data.push(obj);
      //   } else {
      //     data[check].result.push(res);
      //   }
      // }
      // });
      const sortedActivities = list.sort((a, b) => new Date(b.date.$date).getTime() - new Date(a.date.$date).getTime())
      // const sortedActivities = list.reverse();
      return list;
    }
  }

  getHistoryData(list) {
    if (list.length) {
      // let data = [];
      // list.forEach((res) => {
      //   if(res.date){
      //   let check = data.findIndex(item => (item.date === res.date));
      //   if (check == -1) {
      //     let obj = {
      //       date: res.date,
      //       result: [res]
      //     }
      //     data.push(obj);
      //   } else {
      //     data[check].result.push(res);
      //   }
      // }
      // });
      // const sortedActivities = data.reverse();
      // const sortedActivities = Array.from(new Set(list))
      const sortedActivities = list.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
      return list;
    }
  }
  updateWorkflowInStorage(workflow) {
    this.patientService.workflow = workflow;
    const workflowData = {
      workflow: workflow
    };
    this.storageService.addDataToStorage(sessionStorage, workflowData, true);
  }
  getUniqueValues(list) {
    if (list.length > 0) {
      let newList = list.reduce((acc, currValue) => {
        if (!acc.includes(currValue)) {
          acc.push(currValue);
        }
        return acc;
      }, []);
      return newList;
    }
  }

  downloadFile(data, filename = 'data', header) {
    let csvData = this.convertToCSV(data, header);
    //console.log(csvData)
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  convertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No,';
    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (let index in headerList) {
        let head = headerList[index];
        line += ',' + array[i][head];
      }
      str += line + '\r\n';
    }
    return str;
  }

  isRPMStandalone(featureCategoryList) {
    let isCoreEnabled = true;
    let isCIEDEnabled = true;
    for (let i = 0; i < featureCategoryList.length; i++) {
      if (featureCategoryList[i].category_id == Constants.MAIN_FEATURE_CATEGORY_NAME.CORE_PLATFORM && featureCategoryList[i].is_enabled == false) {
        isCoreEnabled = false;       
      }
      if (featureCategoryList[i].category_id == Constants.MAIN_FEATURE_CATEGORY_NAME.CIED && featureCategoryList[i].is_enabled == false) {
        isCIEDEnabled = false;        
      }
    }
    if(!isCoreEnabled && !isCIEDEnabled){
      return true;
    }
    return false;
  }

  getSalutationBasedonRole() {
    let salutation = '';
    let userData = this.dataTransferService.getUserData();
    let roleName = userData && userData.permission && userData.permission.length > 0 && userData.permission[0].group
      && userData.permission[0].group.name ? userData.permission[0].group.name : '';
    if (userData && userData.isPhysician) {
      salutation = 'Dr.'
    } else {
      salutation = 'Caregiver';
    }
    return salutation;
  }

  getUserRole() {
    let salutation = '';
    let userData = this.dataTransferService.getUserData();
    let roleName = userData && userData.permission && userData.permission.length > 0 && userData.permission[0].group
      && userData.permission[0].group.name ? userData.permission[0].group.name : '';      
    return roleName;
  }

  getProviderLastname() {
    let lastName = '';
    let userData = this.dataTransferService.getUserData();
    //console.log(userData);
    if (userData && userData.last_name && userData.last_name !== undefined && userData.last_name !== '') {
      lastName = userData.last_name
    } else {
      lastName = '';
    }
    return lastName;
  }

  getNumberFromPhoneNumber(fullNumber) {
    let phoneNumber = fullNumber && fullNumber.length >= 10 ? fullNumber.slice(-10): '';
    return phoneNumber;
  }
  getCountryCodeFromPhoneNumber(fullNumber) {
    let countryCode = fullNumber && fullNumber.length >= 10 ? fullNumber.slice(0,-10): '';
    return countryCode;
  }

  /**Component:care-plan 
   * message-email : for lengthy email message body, generating tootip data to show in UI */
  getTooltipData(text) {
    let returnValue = '';
    if (text && text.length > Constants.TOOLTIP_MAXLENGTH.CAREPLAN_COUNT) {
      returnValue = (text.substring(0, Constants.TOOLTIP_MAXLENGTH.CAREPLAN_COUNT)+'...');
    } else {
      returnValue = text;
    }
    return returnValue;
  }

  /**
   * get class name based on alert priority for CIED
   * @param priority 
   * @returns 
   */
  getClassName(priority){
    let alertPriority = Constants.CIED_ALERT_PRIORITY;
    let className = "alert-low";
    className = (priority == alertPriority.HIGH) ? "alert-high" :
     (priority == alertPriority.MEDIUM) ? "alert-medium" :(priority == alertPriority.LOW) ? "alert-low" : "alert-other";  
     return className;
  }


  getFilteredProviderList(userList) {    
    return userList.filter(function (user) {
      return user.group == "Physician" || user.group == "Nurse Practitioner" || user.key == "None" ||  user.key == "All" ;
    });    
  }

  updateMridAndMediIdInSession(mrid, medi_id) {
    // Clearing medicardia id and mrid on page destroy
    const patientmridAndMediId = {
      mr_id: mrid,
      medicardia_id: medi_id
    };
    this.storageService.addDataToStorage(sessionStorage, patientmridAndMediId, true);
    
  }

  getObjectiveList() {
    let labOptions = Constants.LAB_OPTIONS;
    let vitalOptions = Constants.VITALS_OPTIONS;
    return vitalOptions.concat(labOptions);
  }
}
