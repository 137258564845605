import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private loader = new Subject<any>();
  apiStatusChange = this.loader.asObservable();

  showLoader() {
    this.loader.next({ status: true });
  }

  hideLoader() {
    this.loader.next({ status: false });
  }
}
