import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { CountdownModule } from 'ngx-countdown';

import { RpmDashboardInactivityModalPage } from './rpm-dashboard-inactivity-modal.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CountdownModule
  ],
  declarations: [RpmDashboardInactivityModalPage],
  entryComponents: [
  ],
  exports : [
    RpmDashboardInactivityModalPage
  ]
})
export class RpmDashboardInactivityModalPageModule {}
