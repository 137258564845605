import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appNumberOnly]',
})
export class NumbersOnlyDirective {
  @Input() maxValue: string;
  @Input() maximumLength;
  @Input() minValue: string;
  @Input() returnType: any;
  constructor(private el: ElementRef, private control: NgControl) {
  }

  @HostListener('input')
  onChange() {
    let inputVal = (this.el.nativeElement).value;
    inputVal = (-1 === inputVal) ? '0' : inputVal;
    inputVal = inputVal ? inputVal.replace(/[^0-9]/g, '') : '';
    if (this.maxValue) {
      if (parseFloat(inputVal) > parseFloat(this.maxValue)) {
        this.el.nativeElement.style.color = '#ff4949';
      }
    }
    if (this.minValue !== undefined && this.minValue !== '') {
      if (parseFloat(inputVal) < parseFloat(this.minValue)) {
        this.el.nativeElement.style.color = '#ff4949';
      }
    }
    if (this.minValue !== undefined && this.minValue !== '' && this.maxValue !== undefined) {
      if (parseFloat(inputVal) >= parseFloat(this.minValue) && (parseFloat(inputVal) <= parseFloat(this.maxValue))) {
        this.el.nativeElement.style.color = 'white';
      }
    }
    if (this.maximumLength) {
      inputVal = String(inputVal).substring(0, this.maximumLength);
    }
    if (Number(this.returnType) === 1) {
      this.control.control.setValue(inputVal);
    } else {
      this.control.control.setValue(inputVal !== undefined && inputVal !== '' ? +inputVal : inputVal);
    }
  }
}
