import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PatientService } from 'src/app/modules/components/patient/patient.service';
import { SimpleModalPage } from 'src/app/modules/modals/simple-modal/simple-modal.page';
import { EpicService } from 'src/app/modules/pages/epic-login/epic.service';
import { Constants } from 'src/app/shared/constants';
import { DataTransferService } from '../../services/data-transfer/data-transfer.service';
import { UtilsService } from 'src/app/shared/common';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
})
export class BaseComponent implements OnInit, OnDestroy {

  modalPage: any;
  rightSideComponent: any;
  discardPopupSubscription: any;
  rpmBillViewEnabled: boolean = false;
  radioValue: string;
  billingModeSubscription: any;
  refreshTimeChecker: any;
  dummy: void;

  constructor(
    public modalCtrl: ModalController,
    public epicService: EpicService,
    public patientService: PatientService,
    public dataTransferService: DataTransferService,
    public utilService: UtilsService,
  ) {
    // this.refreshTokenSynch();
  }

  ngOnInit() {
    this.subscribeChanges();
    //this.getProblemList(); // moved to ngAfterViewInit
    //this.getRPMMasterDataList(); // moved to ngAfterViewInit
  }
  ngAfterViewInit(){
    setTimeout(() => {
      this.getProblemList();
      this.getRPMMasterDataList();
    }, 200);
  }

  refreshTokenSynch() {
    this.epicService.refreshToken();
    //Time synchtime is the time in minutues
    let synchTime = 10;
    this.refreshTimeChecker = setInterval(() => {
      this.epicService.refreshToken();
    }, (synchTime*60000));
  }

  subscribeChanges() {
    this.discardPopupSubscription = this.dataTransferService.getDiscardPopup.subscribe(res => {
      if (res) {
        this.rightSideComponent = res.rightSideComponent ? res.rightSideComponent : '';
        const isNavigationCheck = res.isNavigationCheck === undefined ? false : res.isNavigationCheck;
        const isClicked = res.isClicked === undefined ? false : res.isClicked;
        this.openDiscardChangePop(res.component, isNavigationCheck, isClicked);
      }
    });
    this.billingModeSubscription = this.dataTransferService.billingPage.subscribe(res => {
      if (res) {
        this.rpmBillViewEnabled = true;
      }else{
        this.rpmBillViewEnabled = false;
      }
    });
  }

  async openDiscardChangePop(component, isNavigationCheck, isClicked) {
    let params = {
      alertData: {
        title: Constants.DISCARD_CHANGES_TEXTS.TITLE,
        confirmationText: Constants.DISCARD_CHANGES_TEXTS.CONFIRMATION_TEXT,
      },
      alertType: Constants.DISCARD_CHANGES_TEXTS.ALERTTYPE
    };
    this.modalPage = await this.modalCtrl.create({
      component: SimpleModalPage,
      backdropDismiss: false,
      componentProps: params, cssClass: 'discard-changes'
    });
    this.patientService.discardPopupInitiatorComponent = this.rightSideComponent;
    this.modalPage.onDidDismiss().then(res => {
      if (res.data !== undefined && res.data !== null) {
        let data = {
          component: isNavigationCheck ? this.rightSideComponent : component,
          status: res.data,
        }
        this.patientService.discardChangesConsent(data);
        if (res.data && isNavigationCheck) {
          this.dataTransferService.emitTimerStoppedCheck({ component, isClicked });
        }
      }
      this.patientService.discardPopupInitiatorComponent = '';
    });
    return await this.modalPage.present();
  }

  getProblemList() {
    const params = {
      icd_code: 'all'
    };
    this.patientService.getProblemList(params).subscribe(res => {
      if (res && res['data']) {
        this.patientService.formatProblemListIntoKeyvaluePair(res['data']);
      } else {
        this.utilService.showToast(res.message, 'danger');
      }
    });
  }

  getRPMMasterDataList() {
    this.patientService.getRPMMasterDataList({}).subscribe(res => {
      if (res && res['data'] && res['data'].rpm) {
        this.patientService.setMasterValues(res['data'].rpm);
      } else {
        this.utilService.showToast(res.message, 'danger');
      }
    });
  }

  /**
   * Function to change the bill view page and normal page in RPM mode
   * @param status 
   */
   changeBillingMode(status){
    if(status){
      this.rpmBillViewEnabled = true;
    }else{
      this.rpmBillViewEnabled = false;
    }
  }


  ngOnDestroy() {
    let destroyStatus = this.discardPopupSubscription ? this.discardPopupSubscription.unsubscribe() : undefined;
    destroyStatus = this.modalPage ? this.modalPage.dismiss() : undefined;
    this.billingModeSubscription ? this.billingModeSubscription.unsubscribe() : undefined;
    if (this.refreshTimeChecker) {
      clearInterval(this.refreshTimeChecker);
    }
  }



}
