import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'magnitude'
})
export class MagnitudePipe implements PipeTransform {

  transform(value: any, decimals: any, ...args: any[]): any {
    let decimalPlaces = 2;
    if(decimals != undefined){
      decimalPlaces = decimals;
    }
    value = String(value).indexOf('.') !== -1 && value !='' ? Math.round(value.toFixed(decimalPlaces)) : value;
    return value > 0 ? `+${value}` : (parseFloat(value) === 0 ? `+${value}` : value);
  }

}
