import { Directive, Input, ElementRef, OnChanges } from '@angular/core';
import { AuthGroup } from 'src/app/core/models/role-permission';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { PatientService } from 'src/app/modules/components/patient/patient.service';
import { Constants } from '../../constants';

@Directive({
  selector: '[appHideFeature]'
})
export class HideFeatureDirective implements OnChanges {

  permissionCheckTypes = Constants.PERMISSION_CHECK_TYPE;

  @Input() isRPMCheckRequired = true;
  @Input() isWorkflowChanged;
  @Input() permissionCheckType = Constants.PERMISSION_CHECK_TYPE.CORE;
  @Input('appHideFeature') featureName: any; // Required permission passed in

  constructor(
    private el: ElementRef,
    private authService: AuthenticationService,
    private patientService: PatientService) { }

  ngOnChanges() {
    let isRpm = false;
    const isAuthorized = this.authService.checkFeaturePermission(this.permissionCheckType);
    //Cath ECG Echo Stress // no need to check the permission for provider assigned or not for the permissions
    let EchoCathStressECG = [Constants.PERMISSION_FEAUTRE_NAME.Cath, Constants.PERMISSION_FEAUTRE_NAME.ECG, 
      Constants.PERMISSION_FEAUTRE_NAME.Echo, Constants.PERMISSION_FEAUTRE_NAME.Stress];
    let hide;
    if(this.featureName && EchoCathStressECG.includes(this.featureName.feature_name)){
      hide = this.authService.hasPermission(this.featureName, false);
    }else{
      hide = this.authService.hasPermission(this.featureName);
    }
    if (this.featureName) {
      if (Number(this.featureName.feature_name) !== Constants.PERMISSION_FEAUTRE_NAME.Care_Plan &&
        Number(this.featureName.feature_name) != Constants.PERMISSION_FEAUTRE_NAME.Profile && // T0409: no need to restrict the rpm check for profile permission
        Number(this.featureName.feature_name) !== Constants.PERMISSION_FEAUTRE_NAME.Alert_Dashboard && // no need to hide as it is under rpm and need to show
        this.featureName.feature_name != Constants.PERMISSION_FEAUTRE_NAME.Appointment && this.isRPMCheckRequired) {
        isRpm = this.patientService.workflow === Constants.WORKFLOW.RPM && this.featureName.action === 'WRITE';
      }
      if (!hide || !isAuthorized) {
        this.el.nativeElement.style.display = 'none';
      } else if (isAuthorized) {
        this.el.nativeElement.style.display = '';
      }
    }
  }

  

}
