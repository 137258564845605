import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PatientService } from '../../components/patient/patient.service';
import { UtilsService } from 'src/app/shared/common';
import { debug } from 'util';
import { TimerService } from 'src/app/core/services/timer/timer.service';
import { Constants } from 'src/app/shared/constants';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';

@Component({
  selector: 'app-simple-modal',
  templateUrl: './simple-modal.page.html',
  styleUrls: ['./simple-modal.page.scss'],
})
export class SimpleModalPage implements OnInit {
  alertData: any;
  alertType: string;
  permission = {
    feature_name: Constants.PERMISSION_FEAUTRE_NAME.Care_Plan,
    action: Constants.PERMISSION_ACTION_TEXT.write
  };
  buttonOk = Constants.BUTTON_TEXT.okay;
  buttonYes = Constants.BUTTON_TEXT.confirm;
  buttonNo = Constants.BUTTON_TEXT.reject;
  buttonCancel = Constants.BUTTON_TEXT.cancel;
  isDisable: boolean;
  constructor(
    public modalCtrl: ModalController,
    private patientService: PatientService,
    private timerSerivce: TimerService,
    private utilService: UtilsService,
    public authService: AuthenticationService) { }

  ngOnInit() {
    //console.log(this.alertData);
    this.isDisable = !this.authService.hasPermission(this.permission);
  }

  closeModal(params = {}) {
    this.modalCtrl.dismiss(params);
  }
  Onboard() {
    const params = {
      medicardia_id: this.alertData.data.patientMRId,
      billing_date: this.utilService.getCardioDates(this.alertData.data.onboardedConfirmationDate),// confirmation onboard date from careplan page
    };
    this.patientService.postOnboardDate(params).subscribe(res => {
      this.closeModal(res);
    });
  }
  dismissAlert() {
    const alertIds = [];
    this.alertData.data.forEach(alert => {
      const id = alert._id && alert._id.$oid ? alert._id.$oid : '';
      alertIds.push(id);
    });
    // blow condition will change the alert_data string as SpO2 instead of giving as OX. T0409: Need to check the API side is taking the value without any issue. 
    let alertDataType = this.alertData.type;
    if (alertDataType === 'OX') {
      alertDataType = 'SpO2';
    }
    const params = {
      medicardia_id: this.alertData.medicardia_id,
      alert_data: alertDataType,
      alert_ids: alertIds
    };
    this.patientService.dismissAlert(params).subscribe(res => {
      if (res.is_success && res.data) {
        this.utilService.showToast(res.message, 'success');
        this.closeModal(res);
      } else if (!res.is_success && res.message) {
        this.utilService.showToast(res.message, 'danger');
      }
    });
  }
  restartTimer() {
    this.timerSerivce.rpmTimerStarted();
    this.closeModal();
  }
  /** return reason text if parameter'failedNumber' in exist in failed array */
  getFailedReason(failedNumber) {
    let returnValue = '';
    if(this.alertData && this.alertData.data
      && this.alertData.data.acknowledgement && this.alertData.data.acknowledgement.reason
      && this.alertData.data.acknowledgement.reason.failed
      && Object.keys(this.alertData.data.acknowledgement.reason.failed).length > 0){
        
      returnValue = this.alertData.data.acknowledgement.reason.failed[failedNumber];
    }
    else{
      returnValue = ' - ';
    }
    return returnValue;
  }
  /**
   * slit the url and get file name
   */
  splitUrlForFilename(value){
    let splits = value.split('/');
    return splits.pop();
  }
}
