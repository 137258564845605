import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ChangePasswordPopupPage } from './change-password-popup.page';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoaderModule } from 'src/app/shared/components/loader/loader.module';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    NgbModule,
    LoaderModule,
    DirectivesModule
  ],
  declarations: [ChangePasswordPopupPage]
})
export class ChangePasswordPopupPageModule {}
