import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayFullLoaderComponent } from './overlay-full-loader.component';



@NgModule({
  declarations: [OverlayFullLoaderComponent],
  imports: [
    CommonModule
  ],
  exports: [OverlayFullLoaderComponent]
})
export class OverlayFullLoaderModule { }
