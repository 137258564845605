import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'avatar',
})
export class AvatarPipe implements PipeTransform {

  transform(value: string, isEHR: boolean = false,  ...args) {
    let avatarImg: string;
    value = value && (/^[a-zA-Z]+/).test(value) ? value.toUpperCase() : (value && (/^[0-9]+/).test(value) ? value : '');
    switch (value) {
      case 'M':
      case '1':
        avatarImg = '/assets/imgs/male.png';
        if(isEHR){
          avatarImg = '/assets/imgs/male_ehr.png';
        }
        break;
      case 'F':
      case '0':
        avatarImg = '/assets/imgs/female.png';
        if(isEHR){
          avatarImg = '/assets/imgs/female_ehr.png';
        }
        break;
      case 'O':
      case 'U':
      case 'A':
      case 'N':
        avatarImg = '/assets/imgs/unisex.png';
        if(isEHR){
          avatarImg = '/assets/imgs/unisex_ehr.png';
        }
        break;
      case '':
        // avatarImg = '/assets/imgs/default_profile_pic.png';
        avatarImg = '/assets/imgs/unisex.png';
        if(isEHR){
          avatarImg = '/assets/imgs/unisex_ehr.png';
        }
        break;
      default:
        avatarImg = '/assets/imgs/unisex.png'; 
        if(isEHR){
          avatarImg = '/assets/imgs/unisex_ehr.png';
        }       
        break;
    }
    return avatarImg;
  }
}
