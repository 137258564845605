import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  isTokenValid: Boolean = true;
  encPassword = '!@#%%#%^ADAFgSSSDF1444)(*';
  constructor() { }

  /**
   * @author T0511
   * @date 26-Oct-2018
   * @description To get the stored values in local/session storage
   * @param storageType type of storage: localStorage / sessionStorage
   * @param key String whose value to be read
   * @param enableEncryption boolean specifying whether encryption enabled for the key
   * @returns data value stored in local/session storage
   */
  getStorageItem(storageType, key, enableEncryption?: boolean) {
    let data = storageType.getItem(key);
    enableEncryption = enableEncryption !== undefined ? enableEncryption : false;
    if (enableEncryption && data) {
      data = JSON.parse(CryptoJS.AES.decrypt(data, this.encPassword.trim()).toString(CryptoJS.enc.Utf8));
    }
    return data;
  }

  /**
   * @author T0511
   * @date 26-Oct-2018
   * @description To store values in local/session storage
   * @param storageType type of storage: localStorage / sessionStorage
   * @param data Object to be stored in local/session storage
   * @param enableEncryption boolean specifying whether encryption enabled for the data
   * @returns nil
   */
  setStorageItem(storageType, data, enableEncryption?: boolean) {
    // tslint:disable-next-line:forin
    for (const key in data) {
      if (enableEncryption) {
        data[key] = CryptoJS.AES.encrypt(JSON.stringify(data[key]), this.encPassword.trim()).toString();
      }
      storageType.setItem(key, data[key]);
    }
  }

  /**
   * @author T0511
   * @date 22-Apr-2020
   * @description To include additional key-value pairs to the stored values in local/session storage
   * @param storageType type of storage: localStorage / sessionStorage
   * @param keys list of keys to be removed from storage
   * @returns nil
   */
  addDataToStorage(storageType, keyValue, enableEncryption?: boolean) {
    const storedData = this.getStorageItem(storageType, 'userData', enableEncryption);
    const updatedData: any = {};
    updatedData.userData = { ...storedData, ...keyValue };
    this.setStorageItem(storageType, updatedData, enableEncryption);
  }

  /**
   * @author T0511
   * @date 26-Oct-2018
   * @description To get the stored values in local/session storage
   * @param storageType type of storage: localStorage / sessionStorage
   * @param keys list of keys to be removed from storage
   * @returns nil
   */
  removeStorageItem(storageType, keys) {
    for (const key of keys) {
      storageType.removeItem(key);
    }
  }

  clearStorage() {
    //const themeLS = localStorage.getItem('_tks');
    const themeLS = this.getStorageItem(localStorage, '_tks', true);
    localStorage.clear();
    sessionStorage.clear();
    //localStorage.setItem('_tks',themeLS);
    let themestData = {}
    themestData['_tks'] = themeLS;
    this.setStorageItem(localStorage, themestData, true);
  }

  clearLocalStorage() {
    //const themeLS = localStorage.getItem('_tks');
    const themeLS = this.getStorageItem(localStorage, '_tks', true);
    localStorage.clear();
    //localStorage.setItem('_tks',themeLS);
    let themestData = {}
    themestData['_tks'] = themeLS;
    this.setStorageItem(localStorage, themestData, true);
  }
}
