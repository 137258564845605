import { Directive, Input, ElementRef, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/core';
import { AuthGroup } from 'src/app/core/models/role-permission';
import { Constants } from '../../constants';
import { PatientService } from 'src/app/modules/components/patient/patient.service';

@Directive({
  selector: '[appDisableClick]'
})
export class DisableClickDirective implements OnInit {

  @Input('appDisableClick') featureName: any; // Required featureName passed in

  constructor(private el: ElementRef, private patientService: PatientService, private authService: AuthenticationService) { }

  ngOnInit() {
    let isRpm = false;
    const hide = this.authService.hasPermission(this.featureName);
    if (this.featureName) {
      if (Number(this.featureName.feature_name) !== Constants.PERMISSION_FEAUTRE_NAME.Care_Plan) {
        isRpm = this.patientService.workflow === Constants.WORKFLOW.RPM;
      }
      if (!hide || isRpm) {
        this.el.nativeElement.removeAttribute('onclick');
        this.el.nativeElement.style.cursor = 'default';
      }
    }
  }
}
