import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SimpleModalPage } from './simple-modal.page';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PerfectScrollbarModule,
    DirectivesModule,
    NgbModule
  ],
  declarations: [SimpleModalPage],
  exports: [
    SimpleModalPage
  ]
})
export class SimpleModalPageModule {}
