import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import moment from 'moment-timezone';
import { Constants } from 'src/app/shared/constants';
import { UtilsService } from 'src/app/shared/common';
import { RpmBillingService } from './rpm-billing.service';
import { SimpleModalPage } from '../../modals/simple-modal/simple-modal.page';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-rpm-billing',
  templateUrl: './rpm-billing.component.html',
  styleUrls: ['./rpm-billing.component.scss']
})
export class RpmBillingComponent implements OnInit, OnDestroy {
  @Input() workflow;
  @Output() gotopatientlist = new EventEmitter<any>();
  logList = [];
  rpmMonthList = [];
  isLoaded = false;
  monthForm: FormGroup;
  formBuilder: any;
  public perPage: number = Constants.PAGINATION_DATA.perpageCount;
  pageNumber = 1;
  totalItems;
  currentMonthYear: string;
  public emptyCSV: any;
  workflowTypes = Constants.WORKFLOW;
  programTypes = Constants.PROGRAM_TYPES;
  public isMonthChanged: boolean;
 
  rpmSelected : boolean;
  ccmSelected : boolean;
  pcmSelected : boolean;

  constructor(
    public modalCtrl: ModalController,
    public utilsService: UtilsService,
    public rpmBillingService: RpmBillingService
  ) { }

  ngOnInit(): void {
    this.logList = [];
    this.rpmSelected = false;
    this.ccmSelected = false;
    this.pcmSelected = false;
    this.isMonthChanged = false;
    this.currentMonthYear = moment(this.utilsService.getCurrentDateTime()).format('YYYY-MM');
    this.monthForm = new FormGroup({
      month: new FormControl(''),
    });
    this.rpmMonthList.push({
      key: moment(this.currentMonthYear).format('MMM YYYY'),
      value: this.currentMonthYear
    });
    this.monthForm.patchValue({
      month: this.rpmMonthList[0]
    });
    // setting 'isMonthChanged' status to true, if month changes
    this.monthForm.get('month').valueChanges.subscribe(val => {
      this.isMonthChanged = true;
    });
    this.setProgramBasedOnWorkflow();
    this.viewBill();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.workflow && changes.workflow.currentValue) {
      this.setProgramBasedOnWorkflow();
    }
  }

  setProgramBasedOnWorkflow(){    
      if(this.workflow && this.workflow == this.workflowTypes.RPM){
        this.rpmSelected = true;
      }else if(this.workflow && this.workflow == this.workflowTypes.CCM){
        this.ccmSelected = true;
      }else if(this.workflow && this.workflow == this.workflowTypes.PCM){
        this.pcmSelected = true;
      }
      // default as RPM if no other selections
      if(!this.ccmSelected && !this.pcmSelected){
        this.rpmSelected = true;
      }
  }

  
  viewBill() {
    if (this.isMonthChanged) {
      this.pageNumber = 1;
      this.isMonthChanged = false;
    }
    const params = {
      page: this.pageNumber,
      month: this.monthForm.value.month ? this.monthForm.value.month.value : this.currentMonthYear,
      program: this.getSelectedProgram()
    };
    this.logList = []; // 57897 - It is taking a time delay while trying to filter according to the month under billing screen.
    this.isLoaded = false; // 57897 - It is taking a time delay while trying to filter according to the month under billing screen.
    this.rpmBillingService.viewBill(params).subscribe(res => {
      this.isLoaded = true;
      if (res && res['is_success'] === true && res['data'] !== null) {
        this.setData(res);
        if (res['data']['rpm_billing_start_date']) {
          this.getBillingMonthsList(res['data']['rpm_billing_start_date']);
        }
      } else if (res && res['message']) {
        this.utilsService.showToast(res['message']);
      }
    });
  }
  getDiagnosisCodeAsString() {
    this.logList.forEach(item => {
      item.diagnosis_code = item.condition_list && item.condition_list.length ? ((item.condition_list).map(res => res)).join(', ') : undefined;
    });
  }
  public pageChanged(event) {
    this.pageNumber = event;
    this.viewBill();
  }
  setData(res) {
    this.logList = res['data']['results'] ? res['data']['results'] : [];
    // this.getDiagnosisCodeAsString();
    this.totalItems = res['data']['count'] ? res['data']['count'] : 0;
  }
  exportToCSV() {
    this.isLoaded = false;
    const params = {
      month: this.monthForm.value.month ? this.monthForm.value.month.value : null,
      program: this.getSelectedProgram()
    };
    this.rpmBillingService.exportToCSV(params).subscribe(res => {
      this.isLoaded = true;
      if (res) {
        this.downloadFile(res);
      } else {
        this.openSimpleAlert();
      }
    });
  }
  downloadFile(data: any) {
    const a = document.createElement('a');
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    let filename = new Date().getTime();
    a.href = url;
    a.download = filename + '.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
  public openSimpleAlert() {
    const initialState = {
      confirmationMsg: 'There is no data available in the chosen month.',
      title: 'No Data',
      buttonOkType: Constants.BUTTON_TEXT.okay,
    };
    this.emptyCSV = this.modalCtrl.create({
      component: SimpleModalPage,
      backdropDismiss: false,
      componentProps: initialState, cssClass: 'discard-changes'
    });
  }
  getBillingMonthsList(startDate) {
    this.rpmMonthList = [];
    var start = moment(startDate).format('DD-MM-YYYY').split('-');
    var end = moment(this.utilsService.getCurrentDateTime()).format('DD-MM-YYYY').split('-');
    var startYear = parseInt(start[2]);
    var endYear = parseInt(end[2]);
    var dates = {
      key: '',
      value: ''
    };
    var date = '';

    for (var i = startYear; i <= endYear; i++) {
      var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
      var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
      for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        var month = j + 1;
        var displayMonth = month < 10 ? '0' + month : month;
        date = [i, displayMonth, '01'].join('-');
        dates = {
          key: moment(date).format('MMM YYYY'),
          value: moment(date).format('YYYY-MM'),
        }
        this.rpmMonthList.push(dates);
      }
    }
    this.rpmMonthList = this.rpmMonthList.reverse();
  }
  /**
   * Go to the RPM patients listing
   */
  gotoback(){
    this.gotopatientlist.emit(false);
  }


  selectProgram(program, event){
    let checkbox = event.currentTarget;
    let status = checkbox.checked;    
    if(status && program == this.workflowTypes.RPM){
      this.rpmSelected = true;
    }else if(status && program == this.workflowTypes.CCM){
      this.ccmSelected = true;
    }else if(status && program == this.workflowTypes.PCM){
      this.pcmSelected = true;
    }else if(!status && program == this.workflowTypes.RPM){
      this.rpmSelected = false;
    }else if(!status && program == this.workflowTypes.CCM){
      this.ccmSelected = false;
    }else if(!status && program == this.workflowTypes.PCM){
      this.pcmSelected = false;
    }
    // default as RPM if no other selections
    if(!this.ccmSelected && !this.pcmSelected){
      this.rpmSelected = true;
    }
  }

  getSelectedProgram(){
    let programs = [];
    if(this.rpmSelected){
      programs.push("rpm");
    }
    if(this.ccmSelected){
      programs.push("ccm");
    }
    if(this.pcmSelected){
      programs.push("pcm");
    }
    return programs.join(",");
  }
  ngOnDestroy() {
    let emptyCsv = this.emptyCSV ? this.emptyCSV.hide() : null;
  }
}
