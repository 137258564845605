import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DirtyGuard } from 'src/app/core/services/authentication/dirty/dirty.guard';
import { ResetpasswordGuard } from 'src/app/core/services/authentication/resetpassword.guard';
import { SessionExpiryGuard } from 'src/app/core/services/authentication/session-expiry.guard';


const routes: Routes = [
  {
    path: 'patient',
    loadChildren: () => import('./patient/patient.module').then(m => m.PatientPageModule),
    canActivateChild: [SessionExpiryGuard],
    canDeactivate: [DirtyGuard]
  },
  {
    path: 'appointments',
    loadChildren: () => import('./appointments/appointments.module').then(m => m.AppointmentsPageModule),
    canActivateChild: [SessionExpiryGuard]
  },
  {
    path: 'analytics-dashboard',
    loadChildren: () => import('./analytics-dashboard/analytics-dashboard.module').then(m => m.AnalyticsDashboardPageModule),
    canActivateChild: [SessionExpiryGuard]
  },
  {
    path: 'rpmdashboard',
    loadChildren: () => import('./rpmdashboard/rpmdashboard.module').then(m => m.RPMDashboardPageModule),
    canActivateChild: [SessionExpiryGuard]
  },
  {
    path: 'patientsearch',
    loadChildren: () => import('./patientsearch/patientsearch.module').then(m => m.PatientSearchPageModule),
    canActivateChild: [SessionExpiryGuard]
  },
  {
    path: 'ehrtransmission',
    loadChildren: () => import('./ehrtransmission/ehrtransmission.module').then(m => m.EHRTransmissionPageModule),
    canActivateChild: [SessionExpiryGuard]
  },
  {
    path: 'reset',
    loadChildren: () => import('./reset-password/reset-password/reset-password/reset-password.module')
      .then(m => m.ResetPasswordPageModule),
    canActivate: [ResetpasswordGuard]
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./reset-password/reset-password/reset-password/reset-password.module')
      .then(m => m.ResetPasswordPageModule), canActivate: [ResetpasswordGuard]
  },
  {
    path: 'session-expiry',
    loadChildren: () => import('./session-expiry/session-expiry.module').then(m => m.SessionExpiryPageModule),
    canActivate: [SessionExpiryGuard]
  },
  {
    path: 'epicredirect',
    loadChildren: () => import('./epic-login/epic-login.module').then( m => m.EpicLoginPageModule),
    canActivate: [ResetpasswordGuard]
  }, 
  {
    path: 'cied-details',
    loadChildren: () => import('./cied/cied.module').then(m => m.CiedPageModule),
    canActivateChild: [SessionExpiryGuard],
    canDeactivate: [DirtyGuard]
  },


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
