import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appRestrictZero]'
})
export class RestrictZeroDirective {

  constructor(private el: ElementRef, private control: NgControl) { }
  @HostListener('input')
  onChange() {
    const inputVal = (this.el.nativeElement).value;
    const trimValue = typeof (inputVal) === 'number' ? inputVal : (inputVal).toString().trim();
    if (trimValue === 0 || trimValue === '0') {
      this.control.control.setValue('');
    }
  }
}
