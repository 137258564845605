import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from 'src/app/shared/constants';
import { StorageService } from 'src/app/core/services/authentication/storage.service';
import { AuthenticationService } from 'src/app/core';
import { UtilsService } from 'src/app/shared/common';
import { UserIdleService } from 'angular-user-idle';
import { NavController } from '@ionic/angular';
import { DataTransferService } from 'src/app/core/services/data-transfer/data-transfer.service';
import { PatientService } from '../../components/patient/patient.service';
import { AppointmentsService } from '../../components/appointments/appointments.service';

@Injectable({
  providedIn: 'root'
})
export class EpicService {

  constructor(
    public httpService: HttpClient,
    public storageService: StorageService,
    public authService: AuthenticationService,
    public utilService: UtilsService,
    private navCtrl: NavController,
    private userIdle: UserIdleService,
    private dataTransferService: DataTransferService,
    public patientService: PatientService,
    public AppointmentsService:AppointmentsService
  ) { }


  refreshToken() {
    const userDetails = this.storageService.getStorageItem(sessionStorage, 'epicData', true);
    const refresh_token = userDetails && userDetails.refresh_token ?
      userDetails.refresh_token : undefined;
    const authToken = userDetails && userDetails.token ?
    userDetails.token : undefined;

    const httpHeaders = {
      "Content-type": "application/x-www-form-urlencoded",
      "Authorization": "Basic "+ authToken
    };
    const params = 'grant_type=' + 'refresh_token' + '&refresh_token=' + refresh_token;
    if (refresh_token && authToken) {
      this.httpService.post(Constants.REFRESH_TOKEN_API, params, { headers: new HttpHeaders(httpHeaders) }).subscribe(res => {
        if (res && res['access_token']) {
          this.updateRefreshToken(res);
        } else {
          if (res && res['error']) {
            this.authService.logout().subscribe(res => {
              if (res.is_success) {
                this.utilService.showToast('Session expired due to inactivity', 'danger');
                this.userIdle.stopWatching();
                this.navCtrl.navigateRoot('session-expiry');
                this.dataTransferService.selectedProvider = undefined;
                this.patientService.workflow = undefined;
              } else {
                this.utilService.showToast(Constants.MESSAGE.LOGOUT_FAILED, 'danger');
              }
            });
          }
        }
      });
    }
  }
  updateRefreshToken(data) {
    const storageData: any = {};
    if (data.access_token) {
      this.tokenUpdateInfo(data.access_token);
      storageData.access_token = {
        access_token: data.access_token
      };
      this.storageService.setStorageItem(sessionStorage, storageData, true);
    }
  }

  tokenUpdateInfo(accessToken) {
    const params = {
      token: accessToken
    }
    this.AppointmentsService.postAuthtokenInfo(params).subscribe(
      res => { }
    )
  }

}
