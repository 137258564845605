import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';


import { EcgModalPage } from './ecg-modal.page';
import { CalendarModule } from 'primeng/calendar';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { LoaderModule } from 'src/app/shared/components/loader/loader.module';
import { DropdownModule } from 'primeng/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AddEditEcgModule } from '../../components/patient/add-edit-ecg/add-edit-ecg.module';
import { HeaderTabModule } from 'src/app/shared/components/header-tab/header-tab.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    CalendarModule,
    PipesModule,
    DropdownModule,
    DirectivesModule,
    LoaderModule,
    AutoCompleteModule,
    AddEditEcgModule,
    HeaderTabModule
  ],
  declarations: [EcgModalPage],
  entryComponents: [
    EcgModalPage
  ]
})
export class EcgModalPageModule {}
