import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiUrls } from 'src/app/shared/api-urls';
import { ENV } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/core/services/http/api.service';
import { StorageService } from 'src/app/core/services/authentication/storage.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AboutBoxService extends ApiService {

  constructor(public http: HttpClient, public storageService: StorageService) {
    super(http, storageService);
  }

  getAboutBoxData(params): Observable<any> {
    const url = `${ENV.admin_baseurl}${ApiUrls.ADMIN_SERVCE.getAboutBox}`;
    return this.get(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }
}
