import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserIdleService } from 'angular-user-idle';
import { Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigurationInterface } from 'src/app/core/models/configuration';
import { StorageService } from 'src/app/core/services/authentication/storage.service';
import { ConfigurationService } from 'src/app/core/services/configuration/configuration.service';
import { ApiService } from 'src/app/core/services/http/api.service';
import { ApiUrls } from 'src/app/shared/api-urls';
import { ENV } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RpmdashboardService extends ApiService {

  idleSubscription: Subscription;
  private checkInactivity = new Subject();
  getUserInActivityAlert = this.checkInactivity.asObservable();

  constructor(public http: HttpClient, 
    public storageService: StorageService, 
    private userIdle: UserIdleService, 
    private configurationService : ConfigurationService) { 

    super(http, storageService);
    const configurationDetails: ConfigurationInterface = this.configurationService.getConfigurationDetails();
    this.userIdle.setConfigValues({ idle: configurationDetails.rpmTimeout, timeout: 10, ping: 60 });
  }

  /**
   * Function will return the alert dashboard transisions based on the tab selected and the sort and search query parameters
   * @param params url parameter for the alert dashboard patients list get API
   * @returns 
   */
  getRPMDashboardTransList(params): Observable<any> {
    const path = `${ENV.rpm_baseurl}${ApiUrls.RPM_SERVICE.rpmDahboardTransisions}`;
    return this.get(path, params).pipe(
      map(
        data => data,
        err => err
      ));
  }

  /**
   * Update the timer and get the new list/next page/ previous page etc,...
   * @param params post params for update and get data
   * @returns return the transmissions list
   */
  updateAndGetRPMDashboardTransList(params): Observable<any> {
    // TODO: API not ready,need to change with update API
    const path = `${ENV.rpm_baseurl}${ApiUrls.RPM_SERVICE.rpmDashboardDismiss}`;
    return this.post(path, params).pipe(
      map(
        data => data,
        err => err
      ));
  }

  checkForUserInactivity() {
    //Start watching for user inactivity.
    this.userIdle.startWatching();
    // Start watching when user idle is starting.
    this.idleSubscription = this.userIdle.onTimerStart().subscribe(
      count => {
        if (count > 1) {
          this.checkInactivity.next();
        }
      });
  }

}
