import { Directive, Input, ElementRef, OnChanges } from '@angular/core';
import { Constants } from '../../constants';

@Directive({
  selector: '[appSvgEllipsis]'
})
export class SvgEllipsisDirective implements OnChanges {

  ellipsisNotation = '\u2026';
  width: number;
  selectedVisitModeScreen = {};
  visitModes = Constants.VISIT_MODE;

  @Input() text: string;
  @Input() key: string;
  @Input() selectedVisitMode: number;

  WIDTH = {
    FULLSCREEN: {
      1024: 150,
      1280: 170,
      1366: 170,
      1440: 170,
      1500: 350,
      1600: 170,
      1800: 170,
      1920: 170,
    },
    NORMALSCREEN: {
      1024: 280,
      1280: 420,
      1366: 420,
      1440: 420,
      1500: 670,
      1600: 440,
      1800: 420,
      1920: 420,
    },
  };

  constructor(private el: ElementRef) {
  }

  ngOnChanges(): void {
    if (this.text) {
      this.textEllipsis(this.el.nativeElement);
    }
  }

  calculateWidth() {
    this.selectedVisitModeScreen = this.selectedVisitMode === this.visitModes.FULLSCREEN ?
      this.WIDTH.FULLSCREEN : this.WIDTH.NORMALSCREEN;
    this.width = window.innerWidth <= 1024 ? this.selectedVisitModeScreen['1024'] : (
      window.innerWidth <= 1280 ? this.selectedVisitModeScreen['1280'] : (
        window.innerWidth <= 1366 ? this.selectedVisitModeScreen['1366'] : (
          window.innerWidth <= 1440 ? this.selectedVisitModeScreen['1440'] : (
            window.innerWidth <= 1580 ? this.selectedVisitModeScreen['1500'] : (
              window.innerWidth <= 1680 ? this.selectedVisitModeScreen['1600'] : (
                window.innerWidth <= 1800 ? this.selectedVisitModeScreen['1800'] : (
                  window.innerWidth <= 1920 ? this.selectedVisitModeScreen['1920'] : 420
                )
              )
            )
          )
        )
      )
    );
    this.width = this.key ? this.width - this.key.length + (this.key.length <= 5 ? this.key.length : 0) : this.width;
  }

  setTextAndKey(text, key) {
    this.text = text;
    this.key = key;
  }

  public textEllipsis(el: any) {
    if (el) {
      this.calculateWidth();
      let text = this.text;
      const width = this.width;
      if (typeof el.getSubStringLength !== 'undefined') {
        el.textContent = text;
        let len = text.length;
        if (el.getSubStringLength(0, len) > width) {
          while (el.getSubStringLength(0, len--) > width) {
          }
          el.textContent = text.slice(0, len) + this.ellipsisNotation;
        }
      } else if (typeof el.getComputedTextLength !== 'undefined') {
        while (el.getComputedTextLength() > width) {
          text = text.slice(0, -1);
          el.textContent = `${text}${this.ellipsisNotation}`;
        }
      } else {
        // the last fallback
        while (el.getBBox().width > width) {
          text = text.slice(0, -1);
          // we need to update the textContent to update the boundary width
          el.textContent = `${text}${this.ellipsisNotation}`;
        }
      }
    }
  }

}
