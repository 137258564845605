import { Component, OnInit, OnDestroy } from '@angular/core';
import { AlertController, NavController, ModalController } from '@ionic/angular';
import { AuthenticationService } from '../../../core/services/authentication/authentication.service';
import { Constants } from '../../../shared/constants';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/core/services/authentication/storage.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserModel } from 'src/app/core/models/user';
import { DataTransferService } from 'src/app/core/services/data-transfer/data-transfer.service';
import { UtilsService } from 'src/app/shared/common';
import { ForgotPasswordPopupPage } from '../../modals/forgot-password-popup/forgot-password-popup.page';
import { ConfigurationService } from 'src/app/core/services/configuration/configuration.service';
import { CookieService } from 'ngx-cookie-service';
import { ENV } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit, OnDestroy {

  isLoading = false;
  username: string;
  password: string;
  loginForm: FormGroup;
  removalPos: number;
  forgotPasswordModal: any;
  logoimg = 'logo_report.png';
  isEHREnabled = false;

  constructor(
    public navCtrl: NavController,
    public router: Router,
    public alertCtrl: AlertController,
    public modalCtrl: ModalController,
    public authService: AuthenticationService,
    public utilService: UtilsService,
    public dataTransferService: DataTransferService,
    public storageService: StorageService,
    public configurationService: ConfigurationService,
    public fb: FormBuilder,
    private cookieService: CookieService
  ) {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.logoimg = this.utilService.themeKey !== 'light'? 'sitelogo.png' : this.logoimg;
  }

  ngOnInit() {
    // get the permissions from the server for EHR mode and show the icons and buttons based on the response
    this.isLoading = true;
    this.authService.getFeatures().subscribe(
      res => {
        if(res['is_success'] !== undefined && res['is_success'] && res['data'] !== undefined && res['data']['length'] > 0){
          let data = res['data'];
          (data).forEach(features => {
            if(features.category_name !== undefined && features.category_name === 'EHR'){ // if the item is EHR
              this.isEHREnabled = features.is_enabled !== undefined? features.is_enabled: false;
            }
          });
        }
        this.isLoading = false;
      },
      err => {
        this.isLoading = false;
      }
    );
  }

  login() {
    const params = { ...this.loginForm.value, username: this.loginForm.value.username.toLowerCase() };
    this.isLoading = true;
    this.authService.login(params).subscribe(
      res => {
        if (res.is_success) {
          if (res.token) {
            this.setUserData(res);
            this.getCategoryPermission(res); //TODO: 22-11-2021 - Need to change the logic for category list from the feature categories list in the login response.
          } else {
            this.presentAlert(Constants.MESSAGE.LOGIN_NOT_ALLOWED);
            this.storageService.clearStorage(); //#58606 When the user enters invalid credentials while login and try to refresh the page then the user is able to view only a blank screen
          }
        } else {
          this.isLoading = false;
          if (!this.utilService.isEmpty(res) && !this.utilService.isEmpty(res.data) && res.data.message) {
            this.presentAlert(res.data.message);
          }
          this.storageService.clearStorage(); //#58606 When the user enters invalid credentials while login and try to refresh the page then the user is able to view only a blank screen

        }
      },
      err => {
        this.isLoading = false;
        this.storageService.clearStorage(); //#58606 When the user enters invalid credentials while login and try to refresh the page then the user is able to view only a blank screen

      }
    );
  }

  getCategoryPermission(userDetailsResponse) {
    this.authService.getCategoryPermissions({}).subscribe(
      res => {
        this.isLoading = false;
        if (res && res.is_success && res.data) {
          this.setUserData(userDetailsResponse, res.data);
          this.dataTransferService.fromLoginScreen = true;
          this.navCtrl.navigateRoot('appointments');
          // this.authService.setIsFullScreenModeEnabled(true);
        } else {
          this.utilService.showToast('Failed to load permissions', 'danger');
        }
      },
      err => {
        this.isLoading = false;

      }
    );
  }
  setUserData(userDetailsResponse, categoryResponse = {}) {
    const storageData: any = {};
    this.authService.isSSOorLaunchFromEpic = false;
    const user = UserModel.create({ ...userDetailsResponse.data, category_list: categoryResponse,isSSOorLaunchFromEpic:this.authService.isSSOorLaunchFromEpic });
    const themKey = userDetailsResponse.data && userDetailsResponse.data.user_data && userDetailsResponse.data.user_data.theme ? userDetailsResponse.data.user_data.theme : 'light';
    storageData.userData = {
      token: userDetailsResponse.token,
      user,
      isLoggedIn: true,
      themKey: themKey
    };
    let expiryDate = new Date();
    expiryDate.setDate( expiryDate.getDate() + 365 );
    const themeLS = this.storageService.getStorageItem(localStorage, '_tks', true);//localStorage.getItem('_tks');
    if(themeLS != themKey){
      //localStorage.setItem('_tks',themKey);
      let themestData = {}
      themestData['_tks'] = themKey;
      this.storageService.setStorageItem(localStorage, themestData, true);
    }
    /* const themeCookieValue = this.cookieService.get('themeKey');
    if(themeCookieValue != themKey){
      this.cookieService.delete('themeKey');
      this.cookieService.set('themeKey', themKey, expiryDate);
    } */
    this.configurationService.setConfigurationDetails(user.configuration);
    this.dataTransferService.setUserData(user);
    this.storageService.setStorageItem(sessionStorage, storageData, true);
    if(themKey != this.utilService.themeKey){
      this.utilService.themeKey = themKey;
      setTimeout(() => {
        // TODO: instead of reloading after 4 seconds, need to reload after all the initial API's are completed
        window.location.reload();
      }, 4000);
    }
  }

  onChangePasswordValue(event: string) {
    if (event) {
      this.loginForm.get("password").patchValue(event);
    }
  }

  async presentAlert(message) {
    const alert = await this.alertCtrl.create({
      header: Constants.MESSAGE.AUTH_FAILED,
      subHeader: message,
      buttons: [Constants.BUTTON_TEXT.okay]
    });

    await alert.present();
  }

  keyDownFunction(event) {
    if (event.target.className.indexOf('password') !== -1) {
      this.removalPos = (event.keyCode === 46 || event.keyCode === 8) && event.eventPhase === 3 ? (event.keyCode === 46 ?
        event.srcElement.selectionStart : event.srcElement.selectionStart - 1) : undefined;
      if (this.removalPos !== undefined) {
        setTimeout(() => {
          this.removalPos = undefined;
        }, 50);
      }
    }

    // If enter key is pressed
    if (event.keyCode === 13 && this.loginForm.valid) {
      this.login();
    }
  }

  isAuthenticated() {
    return this.authService.authenticated();
  }

  async forgotPassword() {
    const params = {
      username: this.loginForm.value.username,
    };
    this.forgotPasswordModal = await this.modalCtrl.create({
      component: ForgotPasswordPopupPage,
      cssClass: 'forgot-password-popup',
      componentProps: params,
      backdropDismiss: false
    });
    await this.forgotPasswordModal.present();
  }

  loginWithEpic(){
    this.isLoading = true;
    const params = {
      callback_url: ENV.url + 'epicredirect'
    }
    this.authService.getEpicUrl(params).subscribe(
      res => {
        if (res.is_success && res.data && res.data.authorization_url) {
          window.location.href = res.data.authorization_url;
        } else {
          this.presentAlert(Constants.MESSAGE.EPIC_LOGIN_NOT_ALLOWED);
        }
      },
      err => {
        this.isLoading = false;
      }
    );
  }

  ngOnDestroy() {
    this.forgotPasswordModal ? this.forgotPasswordModal.dismiss() : undefined;
  }
}
