import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LaunchFromEpicComponent } from 'src/app/modules/pages/launch-from-epic/launch-from-epic.component';
import { LaunchFromEpicModule } from 'src/app/modules/pages/launch-from-epic/launch-from-epic.module';

const routes: Routes = [
  {
    path: 'launchfromepic',  component: LaunchFromEpicComponent, pathMatch: 'full'
  },
  { path: '**', redirectTo: '/login', pathMatch: 'full' },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
];

@NgModule({
  imports: [
    LaunchFromEpicModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
