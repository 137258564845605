import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, Route } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../..';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class SessionExpiryGuard implements CanActivate {
  constructor(
    public storageService: StorageService,
    public navCtrl: NavController
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let status = this.storageService.getStorageItem(sessionStorage, 'userData', true) ?
      this.storageService.getStorageItem(sessionStorage, 'userData', true).sessionExpired : false;
    if (status) {
      return true;
    } else {
      return false;
    }
  }

  canActivateChild(route: Route): boolean {
    let status = this.storageService.getStorageItem(sessionStorage, 'userData', true) ?
      this.storageService.getStorageItem(sessionStorage, 'userData', true).sessionExpired : false;
    if (status) {
      this.navCtrl.navigateRoot('session-expiry');
      return false;
    } else {
      return true;
    }
  }

}
