import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderTabComponent } from './header-tab.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';



@NgModule({
  declarations: [HeaderTabComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
  ],
  exports: [HeaderTabComponent]
})
export class HeaderTabModule { }
