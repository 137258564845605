import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trim',
})
export class TrimPipe implements PipeTransform {

  transform(value: string, valueType: string) {
    let trimmedValue: any = value;
    if (valueType === 'float') {
      const stringValue = String(value);
      const decimalCheck = stringValue.split('.');

      if (stringValue && decimalCheck && decimalCheck.length > 1) {
        trimmedValue = decimalCheck[0];
        const decimalPart = decimalCheck[1];
        trimmedValue = decimalPart !== '' ? Number(`${trimmedValue}.${decimalPart.substring(0, 2)}`)
          : value;

      }
    }
    return trimmedValue;
  }
}
