import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Constants } from 'src/app/shared/constants';
import { NavParams, NavController, ModalController } from '@ionic/angular';
import { UtilsService } from 'src/app/shared/common';
import { PatientService } from '../../components/patient/patient.service';
import { DataTransferService } from 'src/app/core/services/data-transfer/data-transfer.service';

import * as _ from 'lodash';

@Component({
  selector: 'app-vitals-modal',
  templateUrl: './vitals-modal.page.html',
  styleUrls: ['./vitals-modal.page.scss'],
})
export class VitalsModalPage implements OnInit, OnDestroy, AfterViewChecked {

  vitalsForm: FormGroup;
  initialFormData: any = {};
  previousSelection: number;
  patientId: string;
  bmiKg: Subscription;
  bmiHeight: Subscription;
  formSubscription: Subscription;
  // vitalsReport: any;
  vitalsReport: any;
  vitalsControls = Constants.CONTROLS.VITALS_CONTROLS;
  riskControls = Constants.CONTROLS.RISKFACTOR_CONTROLS;
  errorMessage = Constants.MESSAGE.INVALID_RANGE;
  isBPValid = true;
  isBMIValid: boolean;
  bmiValue: any;
  isFailed = false;
  isLoading = false;
  isSuccess = false;
  visitNumber: any;
  bmiSystems = Constants.BMI_SYSTEM;
  bmi: any = {
    height: { unit: this.bmiSystems.ENGLISH.ht_unit, min: this.riskControls.HT.MIN, max: this.riskControls.HT.MAX, value: '' },
    weight: { unit: this.bmiSystems.ENGLISH.wt_unit, min: this.riskControls.WT.MIN, max: this.riskControls.WT.MAX, value: '' },
  };
  saveButtonText = Constants.BUTTON_TEXT.save;
  isDirty: boolean;
  vitalsPopComponent = Constants.COMPONENTS.VITALINFO;
  isFormSubmitted = false;
  constructor(
    private dataTransferService: DataTransferService,
    public navParams: NavParams,
    public navCtrl: NavController,
    public modalCtrl: ModalController,
    public utilService: UtilsService,
    public patientService: PatientService,
    public changeDetectorRef: ChangeDetectorRef
  ) {
    this.patientId = this.navParams.get('patientId');
    const vitals = this.navParams.get('vitalsReport');
    this.previousSelection = 0;

    this.vitalsForm = new FormGroup({
      bp_sys: new FormControl('', [
        Validators.min(this.riskControls.SBP.MIN),
        Validators.max(this.riskControls.SBP.MAX)]),
      bp_dias: new FormControl('', [
        Validators.min(this.riskControls.DBP.MIN),
        Validators.max(this.riskControls.DBP.MAX)]),
      pulse_bpm: new FormControl('', [
        Validators.min(this.vitalsControls.PULSE.MIN),
        Validators.max(this.vitalsControls.PULSE.MAX)]),
      pulseox_perc: new FormControl('', [
        Validators.min(this.vitalsControls.PULSEOX_PERC.MIN),
        Validators.max(this.vitalsControls.PULSEOX_PERC.MAX)]),
      pulseox_brpm: new FormControl('', [
        Validators.min(this.vitalsControls.PULSEOX_BRPM.MIN),
        Validators.max(this.vitalsControls.PULSEOX_BRPM.MAX)]),
      height: new FormControl('', [
        Validators.min(this.riskControls.HT.MIN),
        Validators.max(this.riskControls.HT.MAX)]),
      bmi_kg: new FormControl('', [
        Validators.min(this.riskControls.WT.MIN),
        Validators.max(this.riskControls.WT.MAX)]),
      bmiUnit: new FormControl(0),
    });
    this.initVitalsReport(vitals);
    this.vitalsForm.patchValue(this.vitalsReport);
    this.validateBP();
    this.bmi.height.value = this.vitalsForm.value.height;
    this.bmi.weight.value = this.vitalsForm.value.bmi_kg;
  }

  ngOnInit() {
  }

  ngAfterViewChecked() {
    this.changeDetectorRef.detectChanges();
  }

  changeDetected() {
    const currentFormValue = { ...this.vitalsForm.value };
    if (currentFormValue && currentFormValue.bmiUnit !== undefined) {
      delete currentFormValue.bmiUnit;
    }
    Object.keys(currentFormValue).forEach(key => {
      currentFormValue[key] = currentFormValue[key] !== '' ? +currentFormValue[key] : currentFormValue[key];
    });
    this.isDirty = _.isEqual(this.initialFormData, currentFormValue) ? false : true;
    this.utilService.changeDetected(this.vitalsPopComponent, this.isDirty);
  }

  initVitalsReport(vitals) {
    this.vitalsReport = {
      bp_sys: vitals[0].val !== 'NA' ? vitals[0].val : '',
      bp_dias: vitals[0].secondVal !== 'NA' ? vitals[0].secondVal : '',
      pulse_bpm: vitals[1].secondVal !== 'NA' ? vitals[1].secondVal : '',
      pulseox_perc: vitals[2].val !== 'NA' ? vitals[2].val : '',
      pulseox_brpm: vitals[2].secondVal !== 'NA' ? vitals[2].secondVal : '',
      height: vitals[3].height && vitals[3].height !== 'NA' ? vitals[3].height : '',
      bmi_kg: vitals[3].secondVal !== 'NA' ? vitals[3].secondVal : '',
    };
    this.initialFormData = { ...this.vitalsReport };
  }

  validateBP() {
    this.changeDetected();
    let isValid = this.isBPValid;
    if (this.vitalsForm.value.bp_dias && this.vitalsForm.value.bp_sys) {
      isValid = Number(this.vitalsForm.value.bp_dias) >= Number(this.vitalsForm.value.bp_sys) ? false : true;
    }
    if (isValid !== this.isBPValid) {
      this.isBPValid = isValid;
    }

      if(!this.vitalsForm.value.bp_dias && !this.vitalsForm.value.bp_sys) {
        this.isBPValid = true;
      } else if(!this.vitalsForm.value.bp_dias && this.vitalsForm.value.bp_sys) {
        this.isBPValid = false;
      } else if(this.vitalsForm.value.bp_dias && !this.vitalsForm.value.bp_sys) {
        this.isBPValid = false;
      }
  }

  updateVitals() {
    this.validateBP();
    this.isFailed = false;
    this.isSuccess = false;
    const bmiUnit = this.vitalsForm.value.bmiUnit;
    this.calculateBmi(bmiUnit, this.vitalsForm.value);
    this.isFormSubmitted = true;
    if (this.vitalsForm.valid && this.isBPValid && this.isBMIValid) {
      this.isLoading = true;
      const params: any = {
        medicardia_id: this.patientId,
        // visit_number: this.visitNumber
      };
      const bmi = this.vitalsForm.value;
      params.vitals_data = {
        SBP: this.vitalsForm.value.bp_sys ? +this.vitalsForm.value.bp_sys : null,
        DBP: this.vitalsForm.value.bp_dias ? +this.vitalsForm.value.bp_dias : null,
        BMI: this.bmiValue ? +this.bmiValue: null,
        HT: this.vitalsForm.value.height ? +this.vitalsForm.value.height : null,
        HR: this.vitalsForm.value.pulse_bpm ? +this.vitalsForm.value.pulse_bpm : null,
        RR: this.vitalsForm.value.pulseox_brpm ? +this.vitalsForm.value.pulseox_brpm : null,
        SPO2: this.vitalsForm.value.pulseox_perc ? +this.vitalsForm.value.pulseox_perc : null,
        WT: this.vitalsForm.value.bmi_kg ? +this.vitalsForm.value.bmi_kg : null
      };
      if (this.bmiSystems.METRIC.key === this.vitalsForm.value.bmiUnit) {
        params.vitals_data.WT = params.vitals_data.WT ? +this.utilService.kgToLbs(params.vitals_data.WT).toString().substring(0, 7) : null;
        params.vitals_data.HT = params.vitals_data.HT ? +parseFloat(this.utilService.meterToInches(params.vitals_data.HT)).toFixed(3) : null;
      } else {
        params.vitals_data.WT = params.vitals_data.WT ? +params.vitals_data.WT.toFixed(3) : null;
        params.vitals_data.HT = params.vitals_data.HT ? +parseFloat(params.vitals_data.HT).toFixed(3) : null;
      }
      this.patientService.saveVitalsInfo(params).subscribe(
        res => {
          this.patientService.emitDemogVitalsChangeEvent();
          this.isLoading = false;
          const message = res && res.is_success ? Constants.MESSAGE.VITALS_UPDATE.SUCCESS :
            Constants.MESSAGE.COMMON_ERROR_MESSAGE.MESSAGE;
          const toastColor = res && res.is_success ? 'success' : 'danger';
          this.utilService.showToast(message, toastColor);
          if (res.is_success) {
            this.isDirty = false;
            this.utilService.changeDetected(this.vitalsPopComponent, this.isDirty);
            this.isSuccess = res.is_success;
            this.isFailed = !this.isSuccess;
            this.closeModal(true);
          }
          setTimeout(() => {
            this.isSuccess = false;
            this.isFailed = false;
          }, 1000);
        },
        err => {
          this.isLoading = false;
          setTimeout(() => {
            this.isSuccess = false;
            this.isFailed = false;
          }, 1000);
        }
      );
    }
  }

  onBMIUnitChange(heightId, weightId) {
    const ht = heightId.getAttribute('data-height');
    const wt = weightId.getAttribute('data-weight');
    if (this.previousSelection !== this.vitalsForm.value.bmiUnit) {
      this.previousSelection = this.vitalsForm.value.bmiUnit;
      const bmi: any = {
        bmi_kg: '',
        height: '',
      };

      if (this.bmiSystems.METRIC.key === this.vitalsForm.value.bmiUnit) {
        this.bmi = {
          weight: {
            unit: this.bmiSystems.METRIC.wt_unit,
            min: +(this.utilService.lbsToKg(this.riskControls.WT.MIN).toString().slice(0, 5)),
            max: _.round(+(this.utilService.lbsToKg(this.riskControls.WT.MAX)), 3),
            value: _.round(this.utilService.lbsToKg(wt), 3),
          },
          height: {
            unit: this.bmiSystems.METRIC.ht_unit,
            min: +(this.utilService.inchToMeter(this.riskControls.HT.MIN).toString().slice(0, 5)),
            max: +(this.utilService.inchToMeter(this.riskControls.HT.MAX).toString().slice(0, 5)),
            value: _.round(parseFloat(this.utilService.inchToMeter(ht)), 3),
          },
        };

      } else {
        this.bmi = {
          height: {
            unit: this.bmiSystems.ENGLISH.ht_unit, min: this.riskControls.HT.MIN,
            max: this.riskControls.HT.MAX, value: this.utilService.meterToInches(ht),
          },
          weight: {
            unit: this.bmiSystems.ENGLISH.wt_unit, min: this.riskControls.WT.MIN,
            max: this.riskControls.WT.MAX,
            value: this.utilService.kgToLbs(wt),
          },
        };
      }
      bmi.bmi_kg = parseFloat(this.bmi.weight.value);
      bmi.height = parseFloat(this.bmi.height.value);
      this.vitalsForm.get('bmi_kg').setValidators(
        [Validators.required, Validators.min(this.bmi.weight.min), Validators.max(this.bmi.weight.max)]);
      this.vitalsForm.get('height').setValidators(
        [Validators.required, Validators.min(this.bmi.height.min), Validators.max(this.bmi.height.max)]);
      this.vitalsForm.patchValue(bmi);
      this.vitalsForm.get('bmi_kg').updateValueAndValidity();
      this.vitalsForm.get('height').updateValueAndValidity();
    }
  }

  updateBMI(changeType) {
    this.bmiValue = undefined;
    this.changeDetected();
    if (changeType === this.bmiSystems.METRIC.key) {
      this.bmi.weight.value = this.vitalsForm.value.bmi_kg;
    } else {
      this.bmi.height.value = this.vitalsForm.value.height;
    }
  }

  closeModal(status) {
    this.modalCtrl.dismiss(status);
  }

  calculateBmi(unit, form) {
    const data = this.utilService.calculateBmi(unit, form.height, form.bmi_kg);
    this.bmiValue = data.value;
    this.isBMIValid = data.isValid;
    if(!form.height && !form.bmi_kg) {
      this.isBMIValid = true;
    } else if(!form.height && form.bmi_kg) {
      this.isBMIValid = false;
    } else if(form.height && !form.bmi_kg) {
      this.isBMIValid = false;
    }
  }

  openDiscardChangePop() {
    if (this.isDirty) {
      const params = { component: this.vitalsPopComponent };
      this.dataTransferService.openDiscardPopup(params);
    } else {
      this.closeModal(false);
    }
  }

  ngOnDestroy() {
    this.formSubscription ? this.formSubscription.unsubscribe() : undefined;
  }
}
