import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageService } from 'src/app/core/services/authentication/storage.service';
import { ApiService } from 'src/app/core/services/http/api.service';
import { ApiUrls } from 'src/app/shared/api-urls';
import { ENV } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RpmBillingService extends ApiService {

  constructor(public http: HttpClient, public storageService: StorageService) { 
    super(http, storageService);
  }

  public viewBill(params) {
    const apiUrl = `${ENV.admin_baseurl}${ApiUrls.ADMIN_SERVCE.getFullBill}`;
    return this.get(apiUrl, params);
  }
  public exportToCSV(params) {
    const apiUrl = `${ENV.admin_baseurl}${ApiUrls.ADMIN_SERVCE.exportToCSV}`;
    return this.get(apiUrl , params, 1, '', true);
  }
}
