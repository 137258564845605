import { Injectable, EventEmitter } from '@angular/core';
import moment from 'moment';
import { PatientService } from 'src/app/modules/components/patient/patient.service';
import { Constants } from 'src/app/shared/constants';
import { ConfigurationInterface } from '../../models/configuration';
import { ConfigurationService } from '../configuration/configuration.service';
@Injectable({
  providedIn: 'root'
})

export class RpmMultiTimerService {
  counter: number = 0;
  counterNonStp: number = 0;
  timerLoop: any;
  timerLoopNonstop: any;
  isTimerRunning = false;
  checkCounter: EventEmitter<any> = new EventEmitter();
  syncFrequency: EventEmitter<any> = new EventEmitter();
  autoSave: EventEmitter<any> = new EventEmitter();
  pausedTime: number;
  restartedTime: number;
  timezone: any;
  rpmAutoUpdateBillTime = Constants.RPM_UPDATE_BILL_COUNTS;
  isRpm = this.patientService.workflow == Constants.WORKFLOW.RPM;
  constructor(
    public configurationService: ConfigurationService,
    public patientService: PatientService,
  ) {
    this.timezone = this.configurationService.getConfigurationDetails().hospitalTimezone; // hospital timezone
  }

  startTimer() {
    this.startNonStopTimer();
    this.isTimerRunning = true;
    let counter = this.counter;
    if (this.timerLoop) {
      clearInterval(this.timerLoop);
    }
    this.timerLoop = setInterval(function () {
      this.counter = counter + 1;
      counter = this.counter;
      this.checkCounter.emit(counter);
      //this.syncAndAutoSave();
    }.bind(this), 1000);
  }

  /**
   * Function to run a nonstop timer for other sync activities
   * This cannot be included in the above timer as the above timer can pause in between.
   */
   startNonStopTimer(){
    let counter = this.counterNonStp;
    if (this.timerLoopNonstop) {
      clearInterval(this.timerLoopNonstop);
    }
    this.timerLoopNonstop = setInterval(function () {
      this.counterNonStp = counter + 1;
      counter = this.counterNonStp;
      let now = moment().tz(this.timezone).format('HH:mm:ss'); // time in the hospital timezone
      if(now == "23:59:58"){ // on day change save the activity automatically
        this.syncFrequency.emit(true);
      }
      this.syncAndAutoSave();
    }.bind(this), 1000);
  }

  syncAndAutoSave(){
    const configurationDetails: ConfigurationInterface = this.configurationService.getConfigurationDetails();
    if (configurationDetails && configurationDetails.syncFrequency !== undefined &&
      this.counterNonStp % configurationDetails.syncFrequency === 0) {
      this.syncFrequency.emit(true);
    }
  }

  getTimeinString(timeConvert) {
    let timeToConvert = timeConvert ? timeConvert : this.counter;
    if (timeConvert != 0) {
      const minutes: number = Math.floor(timeToConvert / 60);
      return (minutes != 0 ? (minutes < 10 ? ('0' + minutes) : minutes) : '00') + ':' + ((timeToConvert - minutes * 60) < 10 ? '0' + (timeToConvert - minutes * 60) : (timeToConvert - minutes * 60));
    } else {
      return ('00:00');
    }
  }

  pauseTimer() {
    if (this.timerLoop) {
      clearInterval(this.timerLoop);
      this.isTimerRunning = false;
    }
  }

  stopTimer() {
    if (this.timerLoop) {
      clearInterval(this.timerLoop);
      this.isTimerRunning = false;
    }
    this.counter = 0;
  }

}
