export class ApiUrls {

    /**
     * Description: Stores the api-urls related to auth service
     */
    public static LAUNCH_WITH_EPIC_SERVICE = {
        callbackFromEpic: 'ehr/smart',
        getPatientFHIRId: 'ehr/patient-fetch',
        getBasicToken: 'ehr/basic-token',
        getEPICPatientFHIR: 'api/FHIR/R4/Patient/'
    };

    public static AUTH_SERVICE = {
        login: 'auth/login',
        logout: 'auth/logout',
        reset_password: 'auth/reset-password',
        forgot_password: 'auth/forgot-password',
        checkResetlinkValidity: 'auth/check-validity/',
        changePassword: 'auth/change-password',
        categoryPermission: 'auth/view-features',
        getEpicUrl: 'auth/epic-login',
        getFeatures: 'auth/view-features-unauth'
    };

    public static FHIR_SERVICE = {
        patientSearchFhir: 'ehr/patient-search',
        get_ehr_chart: 'ehr/patient-pull-chart',
        manualEhrSyncResponse: 'ehr/',
        ehrAppointment: 'ehr/patient-appointment',
        auth_token: 'ehr/patient-delete',
        getOrders: 'ehr/get-ehr-orders'
    }
    public static APPOINTMENT_SERVICE = {
        appointmentDetails: 'appointment/details',
        appointmentUpdate: 'appointment/update',
        providerListing: 'appointment/get-users',
        appointmentCRUD: 'appointments',
        appointmentForRPM: 'nlp/create-rpm-patient',
        appointmentDateList: 'appointments/list/'
    };

    public static RPM_SERVICE = {
        appointmentListing: 'rpm/patients',
        dashboardDetails: 'dashboard-data',
        problemEnroll: 'patient/enroll',
        problemUnEnroll: 'patient/unenroll',
        setting_get: 'patient/settings',
        setting_alert_get: 'patient/alerts',
        dismiss_alert: 'patient/dismiss-alerts',
        update_setting: 'patient/update-settings',
        clinical_activity_get: 'clinical-activity',
        clinical_activity_save: 'clinical-activity-save',
        task_get: 'user/view-tasks',
        manage_task: 'user/manage-task',
        sendMail: 'enroll/consent-mail',
        getCptDetails: 'user/get-bill',
        saveBill: 'user/save-bill',
        sendMailDevice: 'patient/add_new_device_email',
        resetDashboardData: 'rpm/reset-dashboard-data',
        saveOnboardDate: 'user/get_change_enrollment_date',
        rpmDahboardTransisions: 'rpm/transmission',
        rpmDashboardDismiss: 'rpm/transmission',
        rpmTrendThreeDays: 'rpm/trend-three-days/'
    };

    public static PATIENT_SERVICE = {
        patient_info: 'patient-demographic',
        patient_report: 'patient-procedure-reports',
        summary: 'patients-summary',
        vitals: 'patient-vitals',
        chronic_condition: 'patient-chronic-conditions',
        lab_trend: 'patient-trend-lab-report',
        health_state: 'health-state/',
        risk_profile: 'risk-profile',
        risk_profile_edit: 'risk-profile-edit',
        appointment_summary_save: 'appointment-summary-save',
        physical_exam_get: 'patient-physical-examination',
        physical_exam_save: 'patient-physical-examination-save',
        aipr_get: 'assessment-plans',
        aipr_save: 'assessment-plans-save',
        trend_chart: 'trend-chart',
        video_url: 'media',
        video_names: 'media-list',
        review_get: 'patient-review',
        review_save: 'patient-review-save',
        vital_save: 'patient-vitals-save',
        cath_report_save: 'patient-cath-report-save',
        stress_save: 'patient-stress-report-save',
        ecg_save: 'patient-ecg-report-save',
        reports_save: 'patient-procedure-reports/',
        risk_profile_history: 'risk-profile-history',
        echo_save: 'patient-echo-report-save',
        financial_transaction: 'redox-financial-transaction',
        trend_data: 'get-all-trend-data',
        get_patients_by_mrid: 'patient-details',
        get_document: 'document',
        getProblemlist: 'rpm/icd-codes',
        getSignedUrl: 'generic/s3_signed_url/',
        getAccessCode: 'accesscode',
        trendSoftDel: 'trend-soft-del',
        chadsvascScore: 'chadsvasc-score',
        getEHRNotes: 'ehr-notes',
    };

    public static TELEHEALTH_SERVICE = {
        visitTypeUpdate: 'visit-type/update',
    };

    public static ADMIN_SERVCE = {
        getAboutBox: 'hospital/get-settings/',
        nlp_upload: 'nlp/patient-procedure-reports/',
        appointment: 'nlp/appointments/',
        rpmAppoinments: 'nlp/create-patient/',
        importCsv: 'importer/import-patient/',
        getRPMMasterList: 'hospital/settings/',
        getOstarDeviceList: 'wearable/rpm-device/',
        saveOstarDevice: 'wearable/rpm-device/ ',
        getFullBill:'rpm-billings/view-bill/',
        exportToCSV:'rpm-billings/export-as-csv/',
        patientMobileDetails: 'mobile/patient-mobile-details/',
        userMobileDetails: 'mobile/user-mobile-details/',
        userProfile: 'user/profile/',
        clinicalGuidelines: 'rule_engine/clinical_guidelines/',
        clinicalGuidelinesCategory: 'rule_engine/clinical_guidelines_category/',
        carePlanReport:'rpm-billings/care-plan-report/',   
        submitCarePlanReport:'rpm-billings/upload-clinical-note/', 
        create_icd :'hospital/icd-codes/', 
        mongo_chart_jwt: 'generic/mongo-chart-jwt/',
        submitReportEHR: 'rpm-billings/upload-clinical-note/',
        carePathwaysExec: 'rule_engine/sequences_exec/',
        carePathwaysList: 'rule_engine/sequences_list/',
        ehrTransmissionList: 'patient/list-ehr-transmissions/'
    };
    
    public static CIED_SERVICE = {
        ciedTransmission: 'idc/transmission-list',
        patientListing: 'idc/patient-list',
        addUpdateTemplate: 'idc/create-update-templates',
        listTemplates: 'idc/templates-list',
        addUpdateNotes: 'idc/create-update-notes',
        listNotes: 'idc/notes-list',
        assignDevice:'idc/assign-device',
        patientTransmission : 'idc/patient-transmissions',
        transmissionData :'idc/transmission-data',
        updateReviewStatus : 'idc/change-review-status',
        getTransmissionHistory: 'idc/transmission-list',
        billingHistory:'idc/billing-history',
        saveBill:'idc/bill_save',
        csvExport:'idc/billing-csv-download'

    };

    public static CARE_PLAN_SERVICE = {
        careTeam: 'rpm/care-team',               
        healthGoals : 'rpm/health-goals',
        monthlyProgress: 'rpm/monthly-progress'
    };
}
