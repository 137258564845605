import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';

@Pipe({
  name: 'customDate',
})
export class CustomDatePipe implements PipeTransform {

  transform(value: string, timezone: string, format?) {
    let convertedData: string;
    moment.locale('en');
    if (
      value === undefined ||
      value === '' ||
      value === 'null' ||
      value === null ||
      value === 'Invalid date'
    ) {
      convertedData = '';
    } else {
      if (typeof (value) !== 'number') {
        convertedData = timezone ? moment(value).tz(timezone).format(format ? format : 'DD/MM/YYYY') :
          moment(value).format(format ? format : 'DD/MM/YYYY');
      } else {
        convertedData = timezone ? moment(value).tz(timezone).format(format ? format : 'DD/MM/YYYY') :
          moment(value).format(format ? format : 'DD/MM/YYYY');
      }
    }
    return convertedData;
  }
}
