import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PatientService } from 'src/app/modules/components/patient/patient.service';

@Component({
  selector: 'app-overlay-full-loader',
  templateUrl: './overlay-full-loader.component.html',
  styleUrls: ['./overlay-full-loader.component.scss'],
})
export class OverlayFullLoaderComponent implements OnInit {
  loading: boolean;
  multiAxesUpdateSubscription:Subscription;
  constructor( public patientService: PatientService,) { }

  ngOnInit() {
    this.loading = false;
    this.multiAxesUpdateSubscription = this.patientService.getMultiAxesGraphUpdateStatus.subscribe((res: boolean) => { 
      setTimeout(() => {
        this.loading = res;
      }, 0);   
    });
  }
  ngOnDestroy(){
    this.multiAxesUpdateSubscription.unsubscribe();
  }

}
