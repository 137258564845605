import { Component, HostListener, OnInit } from '@angular/core';
import { Constants } from 'src/app/shared/constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { ENV } from 'src/environments/environment';
import { ApiUrls } from 'src/app/shared/api-urls';
import { ApiService, AuthenticationService } from 'src/app/core';
import { UtilsService } from 'src/app/shared/common';
import { UserInterface, UserModel } from 'src/app/core/models/user';
import { DataTransferService } from 'src/app/core/services/data-transfer/data-transfer.service';
import { StorageService } from 'src/app/core/services/authentication/storage.service';
import { ConfigurationService } from 'src/app/core/services/configuration/configuration.service';
import { ModalController, NavController } from '@ionic/angular';
import { AppointmentsService } from '../../components/appointments/appointments.service';
import { SimpleModalPage } from '../../modals/simple-modal/simple-modal.page';
import { PatientService } from '../../components/patient/patient.service';
import { EHRPatientAppoinmentClass } from 'src/app/core/models/ehr';

@Component({
  selector: 'app-launch-from-epic',
  templateUrl: './launch-from-epic.component.html',
  styleUrls: ['./launch-from-epic.component.scss'],
})
export class LaunchFromEpicComponent implements OnInit {
  isResponseReceived: boolean;
  medicardiaLogo = Constants.LOGO_URL.LOGO_EHR;
  message: string;
  refresh_token: any;
  formattedCodeVal: any;
  formattedStateVal: string;
  modalPage: any;
  isRpm = this.patientService.workflow == Constants.WORKFLOW.RPM;
  timezone: string;
  userData: UserInterface;
  // TODO: There is no option to know the backbutton is clicked or not. so rfresh case cannt do in sub component. change need to implement globally
  /*   @HostListener("window:beforeunload", ["$event"]) unloadHandler(e) {
      e.returnValue = 'onbeforeunload';
      return 'onbeforeunload';
    } */

  constructor(public apiService: ApiService,
    private router: Router,
    public utilService: UtilsService,
    public dataTransferService: DataTransferService,
    public storageService: StorageService,
    public configurationService: ConfigurationService,
    public authService: AuthenticationService,
    public navCtrl: NavController,
    public httpService: HttpClient,
    private appointmentsService: AppointmentsService,
    private modalCtrl: ModalController,
    public patientService: PatientService
  ) {
    // launch medicardia from epic - fetching launch and iss value from url
    let url = this.router.url;
    this.userData = this.dataTransferService.getUserData();

    // TODO: back button need to confirm with client. Redirecting will cause other issues
    /* if(this.userData !== undefined){ // already user session exists then redirect to appointment list page
      this.navCtrl.navigateRoot('appointments');
    } */
    const urlWithIssLaunch = url.split('iss=');
    const issLaunchString = urlWithIssLaunch && urlWithIssLaunch.length === 2 ? urlWithIssLaunch[1].split('&launch=') : '';
    if (issLaunchString) {
      this.callbackwithLaunchISSCodeApi(issLaunchString);
    }

    const codeStateString = url.split('code=');
    let codeStateValArray = codeStateString && codeStateString.length === 2 ? codeStateString[1].split('&state=') : '';
    //this.formattedStateVal = codeStateValArray && codeStateValArray.length === 2 ? decodeURIComponent(codeStateValArray[1]) : '';
    if (codeStateValArray && codeStateValArray.length > 0) {
      this.formattedCodeVal = decodeURIComponent(codeStateValArray[0]);
      this.checkAuthoriZation();
    }
    this.formattedStateVal = codeStateValArray && codeStateValArray.length === 2 ? decodeURIComponent(codeStateValArray[1]) : '';
  }

  ngOnInit() {
    this.medicardiaLogo = Constants.LOGO_URL.LOGO_EHR;
  }

  // launch medicardia from epic - callback
  callbackwithLaunchISSCodeApi(issLaunchString) {
    const iSSVal = decodeURIComponent(issLaunchString[0]);
    const launchVal = issLaunchString[1];
    const params = {
      "iss": iSSVal,
      "launch": launchVal
    };

    const apiUrl = ENV.fhir_baseurl + ApiUrls.LAUNCH_WITH_EPIC_SERVICE.callbackFromEpic;
    this.apiService.get(apiUrl, params).subscribe(res => {
      //console.log("callbackwithLaunchISSCodeApi: ",JSON.stringify(res) );
      if (res && res['is_success'] && res['data']) {
        this.message = res['message'];
        //let url = "https://fhir.epic.com/interconnect-fhir-oauth/oauth2/authorize?scope=launch&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A4200%2Flaunchfromepic&client_id=c32b23e5-e5e7-44a4-b1f2-0f46a666ab59&launch="+launchVal+"&state=https%3A%2F%2Ffhir.epic.com%2Finterconnect-fhir-oauth%2Foauth2%2Ftoken";
        //let url = "https://authorization.cerner.com/tenants/ec2458f2-1e24-41c8-b71b-0e701af7583d/protocols/oauth2/profiles/smart-v1/personas/provider/authorize?scope=launch&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A4200%2Flaunchfromepic&client_id=d46855d8-e6a6-4536-ae1c-ddfcff72b5e6&launch="+launchVal+"&state=https%3A%2F%2Fauthorization.cerner.com%2Ftenants%2Fec2458f2-1e24-41c8-b71b-0e701af7583d%2Fprotocols%2Foauth2%2Fprofiles%2Fsmart-v1%2Ftoken&aud=https%3A%2F%2Ffhir-ehr-code.cerner.com%2Fr42%2Fec2458f2-1e24-41c8-b71b-0e701af7583d%2F";
        //console.log(url);
        //window.open(url, "_blank");
        window.location.href = res['data'] + '&aud=https%3A%2F%2Ffhir.epic.com%2Finterconnect-fhir-oauth%2Foauth2%2Ftoken';
        //window.location.href = url;
        //this.isResponseReceived = true;
      } else {
        this.isResponseReceived = true;
        this.message = Constants.MESSAGE.EPIC_LOGIN_NOT_ALLOWED;
      }
    },
      (err) => {
        this.isResponseReceived = true;
        this.utilService.showToast(Constants.MESSAGE.EPIC_LOGIN_NOT_ALLOWED, 'danger');
        this.navCtrl.navigateRoot('login');
      });
  }

  checkAuthoriZation() {
    const params = {
      id_token: this.formattedCodeVal,
    };
    // epic-login api
    this.authService.checkAuthoriZation(params).subscribe(res => {
      if (res.is_success) {
        if (res.token) {
          this.setUserData(res);
          this.getCategoryPermission(res);
        }
      } else {
        // this.getFHIR();
        this.isResponseReceived = true;
        this.message = res.message;
      }
    },
      (err) => {
        this.message = 'Failed to load page.';
        this.isResponseReceived = true;
        this.navCtrl.navigateRoot('login');
      });
  }
  getCategoryPermission(userDetailsResponse) {
    this.authService.getCategoryPermissions({}).subscribe(
      res => {
        //this.isResponseReceived = true;
        if (res.is_success) {
          this.setUserData(userDetailsResponse, res.data, 0);
          this.dataTransferService.fromLoginScreen = true;
          //this.navCtrl.navigateRoot('appointments');
        } else {
          this.isResponseReceived = true;
          this.utilService.showToast('Failed to load permissions.', 'danger');
        }
      },
      err => {
        this.isResponseReceived = true;
      }
    );
  }

  setUserData(userDetailsResponse, categoryResponse = {}, getFHIR = 1) {
    const storageData: any = {};
    this.authService.isSSOorLaunchFromEpic = true;

    const user = UserModel.create({ ...userDetailsResponse.data, category_list: categoryResponse, isSSOorLaunchFromEpic:this.authService.isSSOorLaunchFromEpic  });
    storageData.userData = {
      refresh_token: this.refresh_token,
      token: userDetailsResponse.token,
      user,
      isLoggedIn: true,
    };
    this.configurationService.setConfigurationDetails(user.configuration);
    this.dataTransferService.setUserData(user);
    this.storageService.setStorageItem(sessionStorage, storageData, true);
    if (!getFHIR) { // no need to call second time
      this.getFHIR();
    }
  }

  getFHIR() {
    const apiUrl = ENV.fhir_baseurl + ApiUrls.LAUNCH_WITH_EPIC_SERVICE.getPatientFHIRId;
    //let url = "https://fhir.epic.com/interconnect-fhir-oauth/oauth2/authorize?scope=launch&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A4200%2Flaunchfromepic&client_id=c32b23e5-e5e7-44a4-b1f2-0f46a666ab59&launch="+this.formattedCodeVal+"&state=https%3A%2F%2Ffhir.epic.com%2Finterconnect-fhir-oauth%2Foauth2%2Ftoken";
    //window.open(url, "_blank");
    const params = {
      code: this.formattedCodeVal,
      token_url: this.formattedStateVal
    };
    this.apiService.get(apiUrl, params).subscribe(res => {
      if (res && res['is_success'] && res['data']
        && res['data']['epic_auth_details']
        && res['data']['epic_auth_details']['access_token']) {
        this.message = res['message'];
        //this.getPatientIdForFetch(res['data']); // no need to call this as this step implemeted form API side.
        //console.log("Epic login data: ", res['data']['epic_auth_details']);
        this.setEpicData(res['data']['epic_auth_details']);
        //this.isResponseReceived = true;
      } else {
        this.isResponseReceived = true;
        this.message = Constants.MESSAGE.EPIC_LOGIN_NOT_ALLOWED;
      }
    },
      (err) => {
        console.log("Epic login data error: ", err);
        this.isResponseReceived = true;
        this.utilService.showToast(Constants.MESSAGE.EPIC_LOGIN_NOT_ALLOWED, 'danger');
        // need to show error page
      });
  }

  /**
   * Function will store the epic data and calls the patient data function
   * @param tokenData Epic data response for token API
   */
  setEpicData(tokenData) {
    let refresh_token = tokenData.access_token ? tokenData.access_token : '';
    let access_token = tokenData.refresh_token ? tokenData.refresh_token : '';
    let patient = tokenData.patient ? tokenData.patient : '';
    let expiryTimeMinutes = tokenData.expires_in ? tokenData.expires_in : 0;
    let timeObject = new Date();
    let expiresAt = new Date(timeObject.getTime() + (expiryTimeMinutes * 1000));
    const storageData: any = {};
    storageData.epicData = {
      refresh_token: refresh_token,
      token: access_token,
      //patient: patient, // may not be needed to store in session storage
      expires_at: expiresAt,
      isLoggedIn: true,
    };
    this.storageService.setStorageItem(sessionStorage, storageData, true); // Sore the epic session to the session storage with encoding
    if (patient != '') { // check the patient id is getting or not?
      this.getFHIRPatientDetails(tokenData);
    } else {
      this.isResponseReceived = true;
      this.utilService.showToast('Failed to load patient.', 'danger');
    }
  }

  /**
   * Function will call the epic to get the patient info using the auth token and patient id.
   * @param data Data from the API for patient-fetch with token and patient id
   */
  getFHIRPatientDetails(tokenData) {
    // let access_token = tokenData.access_token?tokenData.access_token:'';
    let patient = tokenData.patient ? tokenData.patient : '';
    let params = {
      hospital_id: this.dataTransferService.selectedLocation !== undefined ? this.dataTransferService.selectedLocation.id :
        this.dataTransferService.getUserData().permission[0].hospital.id,
      user_id: this.dataTransferService.selectedProvider !== undefined ? this.dataTransferService.selectedProvider.value :
        this.dataTransferService.getUserData().user_id,
      search: patient,
      date: undefined,
      sort: 'name',
      patient_list: 1,
      limit: 20,
      page: 1
    };
    this.appointmentsService.getAppointmentList(params).subscribe(
      res => {
        if (res.is_success && res.data) {
          if (res.data.patient_list && res.data.patient_list.length) {
            this.patientService.workflow == Constants.WORKFLOW.RPM;
            this.utilService.updateWorkflowInStorage(Constants.WORKFLOW.RPM);
            this.dataTransferService.fromEpicLoginScreen = true;
            this.isResponseReceived = true;
            let url = `/patient/${res.data.patient_list[0].medicardia_id}`
            this.navCtrl.navigateRoot(url);
          } else {
            // show the confirmation popup
            this.takeUserConfirmationForEPICLoad(tokenData);
          }
        } else {
          this.isResponseReceived = true;
          this.utilService.showToast(res['message'], 'danger');
        }
      },
      err => {
        this.isResponseReceived = true;
        this.utilService.showToast(Constants.MESSAGE.EPIC_LOGIN_NOT_ALLOWED, 'danger');
      }
    );
  }

  /**
   * Function will check the user confirmation for the load patient from epic
   * @param tokenData 
   * @returns returns the wait
   */
  async takeUserConfirmationForEPICLoad(tokenData) {
    // TODO: Model popup message need to modify
    let params = {
      alertData: {
        title: 'Confirm',
        confirmationText: "This patient does not exist in MediCardia. Do you want to import patient data from Epic?.",
      },
      alertType: 'cpt_alert'
    };
    this.modalPage = await this.modalCtrl.create({
      component: SimpleModalPage,
      backdropDismiss: false,
      componentProps: params, cssClass: 'discard-changes simple-alert-pop'
    });
    this.modalPage.onDidDismiss().then(res => {
      if (res.data) {
        this.callEpicToLoadPatient(tokenData);
      } else {
        this.patientService.workflow == Constants.WORKFLOW.RPM;
        this.utilService.updateWorkflowInStorage(Constants.WORKFLOW.RPM);
        this.dataTransferService.fromEpicLoginScreen = true;
        this.navCtrl.navigateRoot('appointments');
      }
    });
    return await this.modalPage.present();
  }

  /**
   * Call the EPIC for fetch patient data from UI.
   * @param tokenData 
   */
  callEpicToLoadPatient(tokenData) {
    let access_token = tokenData.access_token ? tokenData.access_token : '';
    let patient = tokenData.patient ? tokenData.patient : '';
    const httpHeaders = {
      "Authorization": "Bearer " + access_token,
    };
    const apiUrl = Constants.EPIC_FHIR_BASE_URL + ApiUrls.LAUNCH_WITH_EPIC_SERVICE.getEPICPatientFHIR + patient;
    this.httpService.get(apiUrl, { headers: new HttpHeaders(httpHeaders) }).subscribe(res => {
      if (res) {
        let birthDate = res['birthDate'] !== undefined ? res['birthDate'] : '';
        let first_name = '';// res['birthDate'] !== undefined?res['birthDate']:'';
        let last_ame = '';// res['birthDate'] !== undefined?res['birthDate']:'';
        if (res['name'] && res['name'].length) {
          for (let i = 0; i < res['name'].length; i++) {
            if (res['name'][i]['use'] !== undefined && res['name'][i]['use'] !== 'old') {
              last_ame = res['name'][i]['family'];
              first_name = (res['name'][i]['given'] !== undefined && res['name'][i]['given'][0] !== undefined) ? res['name'][i]['given'][0] : '';
              break;
            }
          }
        }
        if (birthDate !== '' && first_name !== '' && last_ame !== '') {
          let params = {
            dob: birthDate,
            first_name: first_name,
            last_name: last_ame
          }
          this.getEpicPatientDataFromAPI(params);
        }
      } else {
        this.isResponseReceived = true;
        this.utilService.showToast(Constants.MESSAGE.EPIC_LOGIN_NOT_ALLOWED, 'danger');
      }
    },
      (err) => {
        this.isResponseReceived = true;
        this.utilService.showToast(Constants.MESSAGE.EPIC_LOGIN_NOT_ALLOWED, 'danger');
      });
  }

  /**
   * Get the patient info from epic using backend API.
   * @param params Params for the API to get the EPIC patient
   */
  getEpicPatientDataFromAPI(params) {
    this.appointmentsService.getEHRAppointmentList(params).subscribe(
      res => {
        if (res && res['is_success'] && res['data']) {
          let patient = (res['data']['patient_list'] !== undefined && res['data']['patient_list'].length) ? res['data']['patient_list'][0] : [];
          if (patient['dob'] != undefined) {
            let patientData: any = {};
            patient['medicardia_id'] = null;
            //patient['mr_id'] = null;
            patientData = EHRPatientAppoinmentClass.create(patient, this.timezone);
            this.savePatient(patientData);
          } else {
            this.isResponseReceived = true;
            this.utilService.showToast('Failed to load patient data from EPIC.', 'danger');
            this.patientService.workflow == Constants.WORKFLOW.RPM;
            this.utilService.updateWorkflowInStorage(Constants.WORKFLOW.RPM);
            this.dataTransferService.fromEpicLoginScreen = true;
            this.navCtrl.navigateRoot('appointments');
          }
        } else {
          this.isResponseReceived = true;
          this.utilService.showToast('Failed to load patient data.', 'danger');
        }
      },
      err => {
        this.isResponseReceived = true;
        this.utilService.showToast(Constants.MESSAGE.EPIC_LOGIN_NOT_ALLOWED, 'danger');
      }
    );
  }

  /**
   * FUnction to save the patient
   * @param params 
   */
  savePatient(params) {
    this.patientService.patientAppointmentCRUD(params, true, 'rpm').subscribe(res => {
      if (res && res['is_success'] && res['data']) {
        this.utilService.updateWorkflowInStorage(Constants.WORKFLOW.RPM);
        this.dataTransferService.fromEpicLoginScreen = true;
        this.autoSyncPatientChart(params, res.data.medicardia_id); // call the patient pull and then redirect to patient profile
        /* let url = `/patient/${res.data.medicardia_id}`;
        this.navCtrl.navigateRoot(url); */
      } else {
        this.isResponseReceived = true;
        this.utilService.showToast(res['message'], 'danger');
        this.utilService.updateWorkflowInStorage(Constants.WORKFLOW.RPM);
        this.dataTransferService.fromEpicLoginScreen = true;
        this.navCtrl.navigateRoot('appointments');
      }
    },
      err => {
        console.log("SavePatient error: ", err);
        this.isResponseReceived = true;
      });
  }

  autoSyncPatientChart(patientData, medicardia_id) {
    const params = {
      mr_id: patientData && patientData.patient_details && patientData.patient_details['mr_id'] ? patientData.patient_details['mr_id'] : null,
      hospital_id: this.dataTransferService.selectedLocation && this.dataTransferService.selectedLocation.id ? this.dataTransferService.selectedLocation.id : null,
      medicardia_id: medicardia_id ? medicardia_id : null,
      fhir_id: patientData && patientData.patient_details && patientData.patient_details['ehrid'] ? patientData.patient_details['ehrid'] : null
    }
    this.patientService.pullPatientChart(params).subscribe(
      res => {
        let url = `/patient/${medicardia_id}`;
        this.navCtrl.navigateRoot(url);
      }, err => {
        let url = `/patient/${medicardia_id}`;
        this.navCtrl.navigateRoot(url);
      });
  }

  /**
   * Not using as the token call is triggering from API side.
   * @param paramJson 
   */
  getPatientIdForFetch(paramJson) {
    const client_id = paramJson && paramJson.client_id ? paramJson.client_id : '';
    const apiUrl = this.formattedStateVal;
    const httpHeaders = {
      "Content-type": "application/x-www-form-urlencoded;charset=UTF-8",
    };
    const redirect_uri_string = ENV.url + 'launchfromepic';
    const params = 'grant_type=' + 'authorization_code' + '&redirect_uri=' + redirect_uri_string + '&code=' + this.formattedCodeVal +
      '&client_id=' + client_id;
    this.httpService.post(apiUrl, params, { headers: new HttpHeaders(httpHeaders) }).subscribe(res => {
      if (res) {
        console.log(res);
        // navigation to patient page
      } else {
        this.isResponseReceived = true;
        this.utilService.showToast(Constants.MESSAGE.EPIC_LOGIN_NOT_ALLOWED, 'danger');
      }
    },
      (err) => {
        this.isResponseReceived = true;
        this.utilService.showToast(Constants.MESSAGE.EPIC_LOGIN_NOT_ALLOWED, 'danger');
        // need to show error page
      });
  }

}

