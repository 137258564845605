import { Component, OnInit, Input } from '@angular/core';
import { Constants } from 'src/app/shared/constants';
import { ENV } from 'src/environments/environment';
import { NavParams, ModalController } from '@ionic/angular';
import { DocumentService } from 'src/app/core/services/document.service';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import moment from 'moment';
import { ConfigurationService } from 'src/app/core/services/configuration/configuration.service';
import { ConfigurationInterface } from 'src/app/core/models/configuration';

@Component({
  selector: 'app-document-modal',
  templateUrl: './document-modal.page.html',
  styleUrls: ['./document-modal.page.scss'],
})
export class DocumentModalPage {
  pdfLink: any;
  isLoaded = false;

  baseURL = ENV.patient_baseurl;
  functionName: string;
  public pdfList: any = [];
  key: string;
  dataKey: string;
  configurationData: ConfigurationInterface;
  ccdDocumentType = Constants.PATIENT_DOCUMENT_TYPE.CCD;
  presentedForm = Constants.REPORT_TYPE.PDF;
  documentList: any;
  isDocFromDashboard:boolean;

  constructor(
    public documentService: DocumentService,
    public modalCtrl: ModalController,
    public sanitized: DomSanitizer,
    public navParams: NavParams,
    public configurationService: ConfigurationService
  ) {
    this.configurationData = this.configurationService.getConfigurationDetails();
    this.isLoaded = false;
    this.getDocumentsByType();
  }

  getDocumentsByType() {
    if (this.navParams.data.type === this.ccdDocumentType) {
      this.key = this.navParams.data.type;
      this.pdfLink = this.navParams.data.ref_link ? this.navParams.data.ref_link + '#toolbar=0': undefined;     
      this.loadComplete();
    } else {
      this.getFunctionCallByType();
      const params = {
        all: 1,
        id: this.navParams.data.patientId,
        report_type: this.key
      };

      this.documentService.getDocuments(params).subscribe(
        res => {
          if (res && res.data) {
            // this.pdfList = res.data;
            this.pdfList = res.data.data ? [res.data.data] : []; //#57871-while clicking ECG,ECHO in profile screen it is not displaying the document
            if (this.pdfList && this.pdfList.length) { //#57871-while clicking ECG,ECHO in profile screen it is not displaying the document
              
              this.pdfList = this.pdfList[0] //#57871-while clicking ECG,ECHO in profile screen it is not displaying the document

              // this.pdfList.forEach((item) => {
              //   item.date = item && item.date && item.date.$date ? moment(item.date.$date).format('YYYY-MM-DDTHH:mm:ss') :
              //     item.date;
              // });

              this.pdfList.date = this.pdfList && this.pdfList.date && this.pdfList.date.$date ? moment(this.pdfList.date.$date).format('YYYY-MM-DDTHH:mm:ss') : //#57871-while clicking ECG,ECHO in profile screen it is not displaying the document
              this.pdfList = this.pdfList.filter(res => res[this.dataKey].ref_link); //#57871-while clicking ECG,ECHO in profile screen it is not displaying the document

              // this.pdfLink = this.pdfList && this.pdfList.length && this.pdfList[0][this.dataKey] &&
              //   this.pdfList[0][this.dataKey].ref_link ?
              //   (this.pdfList[0][this.dataKey].ref_link + '#toolbar=0') : undefined;
              // this.changeDateFormat(); 

              this.pdfLink = this.pdfList && this.pdfList[this.dataKey] &&
                this.pdfList[this.dataKey].ref_link ?
                (this.pdfList[this.dataKey].ref_link + '#toolbar=0') : undefined; //#57871-while clicking ECG,ECHO in profile screen it is not displaying the document
            }
            this.loadComplete();
          }
        }
      );
    }
  }
  getFunctionCallByType() {
    if (this.navParams.data.type === 'echo') {
      this.key = 'echo';
      this.dataKey = 'echo_data';
    } else if (this.navParams.data.type === 'cath') {
      this.key = 'cath';
      this.dataKey = 'cath_data';
    } else if (this.navParams.data.type === 'stress') {
      this.key = 'stress';
      this.dataKey = 'stress_data';
    } else if (this.navParams.data.type === 'lab') {
      this.key = 'lab';
      this.dataKey = 'lab_data';
    } else if (this.navParams.data.type === 'ecg') {
      this.key = 'ecg';
      this.dataKey = 'ecg_data';
    }
  }
  changeDateFormat() {
    this.pdfList.forEach(element => {
      element.date = moment(moment(element.date).tz(this.configurationData.hospitalTimezone)).format('DD MMM YYYY hh:mm A');
    });
  }

  public changePdfDocument(pdfData) {
    this.pdfLink = pdfData.value[this.dataKey].ref_link + '#toolbar=0';
    this.loadComplete();
  }

  onError(event) {
    this.pdfLink = '';
  }

  loadComplete() {
    this.isLoaded = true;
  }

  closeModal() {
    if (this.pdfList.length) {
      this.modalCtrl.dismiss(true);
    } else {
      this.modalCtrl.dismiss(false);
    }
  }
}
