import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModeSwitchComponent } from './overlay-mode-switch.component';



@NgModule({
  declarations: [OverlayModeSwitchComponent],
  imports: [
    CommonModule
  ],
  exports: [OverlayModeSwitchComponent]
})
export class OverlayModeSwitchModule { }
