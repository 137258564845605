import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { IonicModule } from '@ionic/angular';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PatientVitalInfoModule } from 'src/app/modules/components/patient/patient-info/patient-vital-info/patient-vital-info.module';
import { EcgGraphModule } from 'src/app/modules/components/patient/patient-info/ecg-graph/ecg-graph.module';
import { PatientMetaDataModule } from 'src/app/modules/components/patient/patient-info/patient-meta-data/patient-meta-data.module';
import { PipesModule } from '../../pipes/pipes.module';
import { ChangePasswordPopupPage } from 'src/app/modules/modals/change-password-popup/change-password-popup.page';
import { ChangePasswordPopupPageModule } from 'src/app/modules/modals/change-password-popup/change-password-popup.module';
import { VitalsModalPageModule } from 'src/app/modules/modals/vitals-modal/vitals-modal.module';
import { VitalsModalPage } from 'src/app/modules/modals/vitals-modal/vitals-modal.page';
import { ViewAboutBoxPageModule } from 'src/app/modules/modals/view-about-box/view-about-box.module';
import { ViewAboutBoxPage } from 'src/app/modules/modals/view-about-box/view-about-box.page';
import { UserProfilePageModule } from 'src/app/modules/modals/user-profile/user-profile.module';
import { UserProfilePage } from 'src/app/modules/modals/user-profile/user-profile.page';

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    IonicModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CommonModule,
    PatientMetaDataModule,
    PatientVitalInfoModule,
    EcgGraphModule,
    PipesModule,
    ChangePasswordPopupPageModule,
    ViewAboutBoxPageModule,
    UserProfilePageModule
  ],
  exports: [
    HeaderComponent
  ],
  entryComponents: [
    ChangePasswordPopupPage,
    ViewAboutBoxPage,
    UserProfilePage
  ]
})
export class HeaderModule { }
