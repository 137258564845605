import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';

import { ApiUrls } from './../../../shared/api-urls';
import { map } from 'rxjs/operators';
import { ApiService } from '../http/api.service';
import { HttpClient } from '@angular/common/http';
import { StorageService } from './storage.service';
import { AuthGroup, Permission } from '../../models/role-permission';
import { Constants } from 'src/app/shared/constants';
import { ENV } from 'src/environments/environment';
import { DataTransferService } from '../data-transfer/data-transfer.service';
import { UtilsService } from 'src/app/shared/common';
import { CategoryBasedPermissionInterface, CategoryInterface } from '../../models/user';
import { PatientService } from 'src/app/modules/components/patient/patient.service';

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService extends ApiService {

  permissions: Permission;
  permissionCheckTypes = Constants.PERMISSION_CHECK_TYPE;
  isFullScreen = false;
  private isFullScreenModeEnabled = new Subject<boolean>();
  getIsFullScreenModeEnabled = this.isFullScreenModeEnabled.asObservable();
  isSSOorLaunchFromEpic = false;
  constructor(public http: HttpClient,
    private utilsService: UtilsService, public patientService: PatientService,
    public storageService: StorageService, public dataTransferService: DataTransferService,
  ) {
    super(http, storageService);
  }

  /**
   * @description To check whether the login credentials are correct or not
   * @param params
   */
  login(params): Observable<any> {
    const url = `${ENV.auth_baseurl}${ApiUrls.AUTH_SERVICE.login}`
    return this.post(url, params, Constants.MSG_HEADER.UNENCRYPTED)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  /**
   * @description To Logout a user, destroy token and remove
   *              every information related to a user
   */
  logout(params?): Observable<any> {
    const url = `${ENV.auth_baseurl}${ApiUrls.AUTH_SERVICE.logout}`
    return this.post(url, params, Constants.MSG_HEADER.UNENCRYPTED).pipe(map(
      data => data,
      err => err
    ));
  }

  /**
   * @description To get category permissions
   * @param params
   */
  getCategoryPermissions(params): Observable<any> {
    const url = `${ENV.auth_baseurl}${ApiUrls.AUTH_SERVICE.categoryPermission}`
    return this.get(url, params, Constants.MSG_HEADER.UNENCRYPTED)
      .pipe(map(
        data => data,
        err => err
      ));
  }
  /**
   * @description To get url for EPIC login
   * @param params
   */
  getEpicUrl(params = null): Observable<any> {
    const url = `${ENV.auth_baseurl}${ApiUrls.AUTH_SERVICE.getEpicUrl}`;
    return this.get(url, params, Constants.MSG_HEADER.UNENCRYPTED)
      .pipe(map(
        data => data,
        err => err
      ));
  }
  checkAuthoriZation(params = null): Observable<any> {
    const url = `${ENV.auth_baseurl}${ApiUrls.AUTH_SERVICE.getEpicUrl}`;
    return this.post(url, params, Constants.MSG_HEADER.UNENCRYPTED)
      .pipe(map(
        data => data,
        err => err
      ));
  }
  // callAccessTokenApi(params = null): Observable<any> {
  //   const url = `${Constants.ACCESS_TOKEN_API}`;
  //   return this.post(url, params, Constants.MSG_HEADER.UNENCRYPTED, null, true)
  //     .pipe(map(
  //       data => data,
  //       err => err
  //     ));
  // }

  /**
   * @description Returns whether the user is currently authenticated
   */
  authenticated(): boolean {
    const storageData = this.storageService.getStorageItem(sessionStorage, 'userData', true);
    return true === (storageData && storageData.isLoggedIn);
  }

  /**
   * @description Returns whether the user has permission
   */
  hasPermission(feature, isPhysicianCheckRequired = true) {
    let status = false;
    let userdata = this.dataTransferService.getUserData();
    if (feature) {
      const featureData = this.getFeatureObj(feature, userdata);
      if (featureData && featureData.categoryObj && featureData.categoryObj.isEnabled === true) {
        if (feature.action) {
          switch (feature.action) {
            case Constants.PERMISSION_ACTION_TEXT.read:
              status = featureData && featureData.featureObj && featureData.featureObj.read !== undefined ?
                featureData.featureObj.read : featureData.categoryObj.isEnabled;
              break;
            case Constants.PERMISSION_ACTION_TEXT.write:
              if ((!this.utilsService.isEmpty(this.dataTransferService.patientDemographic) &&
                this.dataTransferService.patientDemographic.appointment_status !== undefined &&
                this.dataTransferService.patientDemographic.appointment_status === 'Cancelled') &&
                this.patientService.workflow === Constants.WORKFLOW.CLINICAL) {
                status = false;
              } else {
                status = featureData && featureData.featureObj && featureData.featureObj.write !== undefined ?
                  featureData.featureObj.write : featureData.categoryObj.isEnabled;
                if (status && userdata.permission[0].group['name'] === 'Physician' && isPhysicianCheckRequired
                  && Constants.PERMISSION_FEAUTRE_NAME.Care_Plan !== feature.feature_name) { // care plan then everyone have permission those who have action permission
                  status = this.utilsService.isAssignedPhysician();
                }
              }
              break;
          }
        }
      }
    }
    return status;
  }

  getFeatureObj(feature, userdata) {
    let categoryObj: CategoryInterface = this.utilsService.findObject(userdata.categoryList, 'id', feature.feature_name);
    let featureObj: CategoryBasedPermissionInterface;
    if (!categoryObj) {
      featureObj = this.utilsService.findObject(userdata.permission[0].permissions, 'feature_tag', feature.feature_name);
      if (featureObj) {
        categoryObj = this.utilsService.findObject(userdata.categoryList, 'id', featureObj.categoryId);
      }
    }
    return { featureObj, categoryObj };
  }

  checkFeaturePermission(permissionCheckType) {
    let isAuthorized = false;
    switch (permissionCheckType) {
      case this.permissionCheckTypes.CORE:
        isAuthorized = this.hasPermission(Constants.FEATURE_PERMISSION.CORE);
        break;
      case this.permissionCheckTypes.RPM:
        isAuthorized = this.hasPermission(Constants.FEATURE_PERMISSION.RPM);
        break;
        /* commented below code, for RPM_STANDALONE in to check the RPM permission and not have CORE permission */
      // case this.permissionCheckTypes.RPM_STANDALONE:
      //   isAuthorized = this.hasPermission(Constants.FEATURE_PERMISSION.RPM_STANDALONE) &&
      //     !this.hasPermission(Constants.FEATURE_PERMISSION.CORE);
      //   break;
      case this.permissionCheckTypes.RPM_STANDALONE:
        isAuthorized = this.hasPermission(Constants.FEATURE_PERMISSION.RPM) &&
          !this.hasPermission(Constants.FEATURE_PERMISSION.CORE);
        break;
      case this.permissionCheckTypes.EHR:
        isAuthorized = this.hasPermission(Constants.FEATURE_PERMISSION.EHR);
        break;
      case this.permissionCheckTypes.TELECARDIA:
        isAuthorized = this.hasPermission(Constants.FEATURE_PERMISSION.TELECARDIA);
        break;
    }
    return isAuthorized;
  }

  setIsFullScreenModeEnabled(status) {
    this.isFullScreen = status;
    this.isFullScreenModeEnabled.next(status);
  }

  public getLandingPage() {
    return '/appointments';
  }

  /**
  * @description To save new password info
  */
  saveNewPassword(params): Observable<any> {
    // params['workflow'] = this.workflow;
    const url = `${ENV.auth_baseurl}${ApiUrls.AUTH_SERVICE.reset_password}`;
    return this.post(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  /**
  * @description To save new password info
  */
  changePassword(params): Observable<any> {
    // params['workflow'] = this.workflow;
    const url = `${ENV.auth_baseurl}${ApiUrls.AUTH_SERVICE.changePassword}`;
    return this.post(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  /**
  * @description To initiate forgot password email on sending username
  */
  initiateForgotPasswordEmail(params): Observable<any> {
    const url = `${ENV.auth_baseurl}${ApiUrls.AUTH_SERVICE.forgot_password}`;
    return this.post(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  /**
  * @description To verify reset link validity
  */
  checkResetLinkValidity(params): Observable<any> {
    const url = `${ENV.auth_baseurl}${ApiUrls.AUTH_SERVICE.checkResetlinkValidity}`;
    return this.get(url, params)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  /**
   * @description To get basic token from db
   * @param params
   */
  getBasicToken(params): Observable<any> {
    const url = `${ENV.fhir_baseurl}${ApiUrls.LAUNCH_WITH_EPIC_SERVICE.getBasicToken}`
    return this.get(url, params, Constants.MSG_HEADER.UNENCRYPTED)
      .pipe(map(
        data => data,
        err => err
      ));
  }

  /**
   * Function will be used to get the features list before login
   * @returns 
   */
  getFeatures() {
    const url = `${ENV.auth_baseurl}${ApiUrls.AUTH_SERVICE.getFeatures}`
    return this.get(url, [], Constants.MSG_HEADER.UNENCRYPTED)
      .pipe(map(
        data => data,
        err => err
      ));
  }

}

