import * as _ from 'lodash';
import moment from 'moment';
import { Constants } from 'src/app/shared/constants';
import { CardioInterface, CardioModel } from './cardio';

export interface ECGInterface {
    ECG: any[],
    QRS: number,
    Rate: number,
    notes: string,
    QT: number,
    QTc: number,
    date: Date,
    patient: string,
    ref_link: string,
    nlp_status_label: string,
    ocr_text: string,
    object_id: string,
    rhythm: string,
    leadName: string,
    pr: number,
    qrsd: number,
    p: number,
    t: number,
    summary: string,
    presented_form : string,
    presented_data : string,
    is_reviewed : boolean,
    source: string
}

export class ECGModel {

    public static toTitleCase(data) {
        return data.charAt(0).toUpperCase() + data.substr(1).toLowerCase()
    }

    public static create(data, timezone): ECGInterface {
        const ecg_data = data && data.ecg && data.ecg.ecg_data ? data.ecg.ecg_data : undefined;
        const date = data && data.ecg && data.ecg.date && data.ecg.date['$date'] ? moment(data.ecg.date['$date']).tz(timezone).format('YYYY-MM-DDTHH:mm:ss') : undefined;
        // TODO: dummy data for the below medicardia ID's. We need to remove this later. once advanced NLP is in production
        let staticECG = [];
        if(data.patient_details && data.patient_details.medicardia_id && (data.patient_details.medicardia_id === '142128c9-6fd1-4467-9d2e-03fd80321cb8' 
              || data.patient_details.medicardia_id === 'f8a857c1-118a-49b1-b4bc-b495b12b6f3b'
              || data.patient_details.medicardia_id === '3813b556-fb12-4420-92a4-953bcb80ef69')){
                staticECG = Constants.ECG_GRAPH_DATA;
              }
        return {
            nlp_status_label: data && data.ecg && data.ecg.nlp_status_label ? data.ecg.nlp_status_label : '',
            ocr_text: data && data.ecg && data.ecg.ocr_text ? data.ecg.ocr_text : '',
            //ECG: ecg_data && ecg_data.ecg && ecg_data.ecg.length && ecg_data.ecg[0].data ? ecg_data.ecg[0].data: staticECG,
            ECG: ecg_data && ecg_data.ecg && ecg_data.ecg.length ? ecg_data.ecg: staticECG,
            QTc: ecg_data && ecg_data.qtc && ecg_data.qtc.val ? ecg_data.qtc.val : '',
            QRS: ecg_data && ecg_data.qrs && ecg_data.qrs.val ? ecg_data.qrs.val : '',
            QT: ecg_data && ecg_data.qt && ecg_data.qt.val ? ecg_data.qt.val : '',
            notes: ecg_data && ecg_data.notes && ecg_data.notes.val ? ecg_data.notes.val : '',
            date: date ? moment(date).toDate() : undefined,
            Rate: ecg_data && ecg_data.rate && ecg_data.rate.val ? ecg_data.rate.val : '',
            patient: data && data.patient_details && data.patient_details.medicardia_id ?
                data.patient_details.medicardia_id : '',
            rhythm: ecg_data && ecg_data.rhythm && ecg_data.rhythm.val ? ecg_data.rhythm.val : '',
            ref_link: ecg_data && ecg_data.ref_link ? ecg_data.ref_link : '',
            object_id: data.ecg && data.ecg._id ? data.ecg._id.$oid : '',
            //leadName: ecg_data && ecg_data.ecg && ecg_data.ecg.length && ecg_data.ecg[0].lead_name ? ecg_data.ecg[0].lead_name : '',
            leadName: '',
            pr: ecg_data && ecg_data.pr && ecg_data.pr.val ? ecg_data.pr.val : '',
            qrsd: ecg_data && ecg_data.qrsd && ecg_data.qrsd.val ? ecg_data.qrsd.val : '',
            p: ecg_data && ecg_data.p && ecg_data.p.val ? ecg_data.p.val : '',
            t: ecg_data && ecg_data.t && ecg_data.t.val ? ecg_data.t.val : '',
            summary: ecg_data && ecg_data.summary && ecg_data.summary.val ? ecg_data.summary.val : '',
            presented_form: ecg_data && ecg_data.presented_form ? ecg_data.presented_form : '',
            presented_data: ecg_data && ecg_data.presented_data ? ecg_data.presented_data : '',
            is_reviewed: data.ecg && data.ecg.is_reviewed ? data.ecg.is_reviewed : false,
            source: data.ecg && data.ecg.source && data.ecg.source.classification ? data.ecg.source.classification : null
        };
    }
}

export interface EcgRequestInterface {
    date: string,
    ecg_data: {
        ecg: CardioInterface,
        rhythm: CardioInterface,
        rate: CardioInterface,
        qrs: CardioInterface,
        qt: CardioInterface,
        qtc: CardioInterface,
        notes: CardioInterface,
        ref_link: string,
        // date: CardioInterface,
        pr: CardioInterface,
        qrsd: CardioInterface,
        p: CardioInterface,
        t: CardioInterface,
        summary: CardioInterface //ecg report summary
    }
}

export class EcgRequestModel {
    public static createParams(data, nlpList = null): EcgRequestInterface {
        const ecg_data = data;
        return {
            date: '',
            ecg_data: {
                ecg: CardioModel.createParams(ecg_data.ECG ? ecg_data.ECG : undefined, nlpList ? nlpList.ecg : null),
                rhythm: CardioModel.createParams(ecg_data.rhythm ? ecg_data.rhythm.value : undefined, nlpList ? nlpList.rhythm : null),
                rate: CardioModel.createParams(ecg_data.Rate ? ecg_data.Rate : undefined, nlpList ? nlpList.Rate : null),
                qrs: CardioModel.createParams(ecg_data.QRS ? ecg_data.QRS : undefined, nlpList ? nlpList.QRS : null),
                // date: CardioModel.createParams(ecg_data.date ? ecg_data.date : undefined, nlpList ? nlpList.date : null),
                qt: CardioModel.createParams(ecg_data.QT ? ecg_data.QT : undefined, nlpList ? nlpList.QT : null),
                qtc: CardioModel.createParams(ecg_data.QTc ? ecg_data.QTc : undefined, nlpList ? nlpList.QTc : null),
                notes: CardioModel.createParams(ecg_data.notes ? ecg_data.notes : undefined, nlpList ? nlpList.notes : null),
                ref_link: '',
                pr: CardioModel.createParams(ecg_data.pr ? ecg_data.pr : undefined, nlpList ? nlpList.pr : null),
                qrsd: CardioModel.createParams(ecg_data.qrsd ? ecg_data.qrsd : undefined, nlpList ? nlpList.qrsd : null),
                p: CardioModel.createParams(ecg_data.p ? ecg_data.p : undefined, nlpList ? nlpList.p : null),
                t: CardioModel.createParams(ecg_data.t ? ecg_data.t : undefined, nlpList ? nlpList.t : null),
                summary: CardioModel.createParams(ecg_data.summary ? ecg_data.summary : undefined, nlpList ? nlpList.summary : null)
            }
        };
    }
}

export interface EcgAcceptRejectInterface {
    QRS: CardioInterface,
    Rate: CardioInterface,
    notes: CardioInterface,
    QT: CardioInterface,
    QTc: CardioInterface,
    rhythm: CardioInterface,
    pr: CardioInterface,
    qrsd: CardioInterface,
    p: CardioInterface,
    t: CardioInterface,
    summary: CardioInterface,
    // date: CardioInterface,
    is_reviewed: string
}

export class EcgAcceptRejectModel {

    public static create(data): EcgAcceptRejectInterface {
        const ecg_data = data && data.ecg && data.ecg.ecg_data ? data.ecg.ecg_data : undefined;
        return {
            is_reviewed: data && data.ecg && data.ecg.is_reviewed ? data.ecg.is_reviewed : false,
            QTc: CardioModel.create(ecg_data && ecg_data.qtc ? ecg_data.qtc : ''),
            // date: CardioModel.create(ecg_data && ecg_data.date ? ecg_data.date : ''),
            QRS: CardioModel.create(ecg_data && ecg_data.qrs ? ecg_data.qrs : ''),
            QT: CardioModel.create(ecg_data && ecg_data.qt ? ecg_data.qt : ''),
            notes: CardioModel.create(ecg_data && ecg_data.notes ? ecg_data.notes : ''),
            Rate: CardioModel.create(ecg_data && ecg_data.rate ? ecg_data.rate : ''),
            rhythm: CardioModel.create(ecg_data && ecg_data.rhythm ? ecg_data.rhythm : ''),
            pr: CardioModel.create(ecg_data && ecg_data.pr ? ecg_data.pr : ''),
            qrsd: CardioModel.create(ecg_data && ecg_data.qrsd ? ecg_data.qrsd : ''),
            p: CardioModel.create(ecg_data && ecg_data.p ? ecg_data.p : ''),
            t: CardioModel.create(ecg_data && ecg_data.t ? ecg_data.t : ''),
            summary: CardioModel.create(ecg_data && ecg_data.summary ? ecg_data.summary : '')
        };
    }
}
