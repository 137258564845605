import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../../../core/services/authentication/storage.service';
import { ApiService } from '../../../core/services/http/api.service';
import { map } from 'rxjs/operators';
import { ENV } from 'src/environments/environment';
import { ApiUrls } from 'src/app/shared/api-urls';
import { DataTransferService } from 'src/app/core/services/data-transfer/data-transfer.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AppointmentsService extends ApiService {

  isTabSwitch: boolean = false;

  public subjectOnScroll = new Subject<any>();
  getScrollEvent = this.subjectOnScroll.asObservable();

  private apppointmentUpdateStatus = new Subject<any>();
  getApppointmentUpdateStatus = this.apppointmentUpdateStatus.asObservable();

  private appointmentReload = new Subject<any>();
  public getAppointmentReload = this.appointmentReload.asObservable();

  labelAttribute = 'patient_full_name';
  autoCompleteList: any = [];
  isDropdownOpen = false;

  constructor(public http: HttpClient, public storageService: StorageService,
    public dataTransferService: DataTransferService, public router: Router) {
    super(http, storageService);
    // TO DO: Uncomment after search api is ready
    // this.getSearchResult({ });
    if (this.router.url === '/appointments') {
      this.dataTransferService.setSelectedProvider(undefined);
    }

  }

  setApppointmentUpdateStatus(status) {
    this.apppointmentUpdateStatus.next(status)
  }

  getResults(keyword: string) {
    return this.autoCompleteList.filter(item => item.patient_full_name.toLowerCase().includes(keyword.toLowerCase()));
  }

  getSearchResult(params) {
    this.get('appointment/patient-appointment-search/', params).subscribe(
      (res: any) => {
        this.autoCompleteList = res && res.data && res.data.results ? res.data.results : [];
      }
    );
  }

  /**
   * @description To get list of patient appointments
   * @param params
   */
  getAppointmentList(params): Observable<any> {
    const path = `${ENV.appointment_baseurl}${ApiUrls.APPOINTMENT_SERVICE.appointmentCRUD}`;
    return this.get(path, params).pipe(
      map(
        data => data,
        err => err
      ));
  }
  getEHRAppointmentList(params): Observable<any> {
    const path = `${ENV.fhir_baseurl}${ApiUrls.FHIR_SERVICE.patientSearchFhir}`;
    return this.get(path, params).pipe(
      map(
        data => data,
        err => err
      ));
  }

  getOrderMessagetListForPatient(params): Observable<any> {
    const path = `${ENV.fhir_baseurl}${ApiUrls.FHIR_SERVICE.getOrders}`;
    return this.get(path, params).pipe(
      map(
        data => data,
        err => err
      ));
  }

  getProviderList(params): Observable<any> {
    const path = `${ENV.appointment_baseurl}${ApiUrls.APPOINTMENT_SERVICE.providerListing}`;
    return this.get(path, params).pipe(
      map(
        data => data,
        err => err
      ));
  }

  /**
   * @description To save a new appointment for a patient
   */
  updateAppointmentData(params): Observable<any> {
    // const path = Object.keys(params).indexOf('visit_type') === -1 ?
    //   `${ENV.appointment_baseurl}${ApiUrls.APPOINTMENT_SERVICE.appointmentUpdate}` :
    //   `${ENV.telehealth_baseurl}${ApiUrls.TELEHEALTH_SERVICE.visitTypeUpdate}`;
    const path = `${ENV.appointment_baseurl}${ApiUrls.APPOINTMENT_SERVICE.appointmentUpdate}`;
    return this.post(path, params).pipe(
      map(
        data => data,
        err => err
      ));
  }
  getAppointmentDateList(params): Observable<any> {
    const path = `${ENV.appointment_baseurl}${ApiUrls.APPOINTMENT_SERVICE.appointmentDateList}`;
    return this.post(path, params).pipe(
      map(
        data => data,
        err => err
      ));
  }

  /**
   * @description patient appointments on scroll
   */

  Onscroll(pageNummber) {
    this.subjectOnScroll.next(pageNummber);
  }

  reloadApppointment(value) {
    this.appointmentReload.next(value);
  }

  /**
   * -------------------------------------------------------------------- 
   * RPM related methods start here
   * --------------------------------------------------------------------
   */


  getRPMDashboardDetails(params): Observable<any> {
    const path = `${ENV.rpm_baseurl}${ApiUrls.RPM_SERVICE.dashboardDetails}`;
    return this.get(path, params).pipe(
      map(
        data => data,
        err => err
      ));
  }


  getRPMAppointmentList(params): Observable<any> {
    const path = `${ENV.rpm_baseurl}${ApiUrls.RPM_SERVICE.appointmentListing}`;
    return this.get(path, params).pipe(
      map(
        data => data,
        err => err
      ));
  }
  getManualEhrSyncResponse(params = null): Observable<any> {
    const path = `${ENV.fhir_baseurl}${ApiUrls.FHIR_SERVICE.ehrAppointment}`;
    return this.get(path, params).pipe(
      map(
        data => data,
        err => err
      ));
  }

  postAuthtokenInfo(params): Observable<any> {
    const path = ApiUrls.FHIR_SERVICE.auth_token;
    return this.postfhir(path,params,true,ENV.fhir_baseurl).pipe(
      map(
        data => data,
        err => err
      ));
  }

  /**
   * Get the trend three days data
   * @param params 
   * @returns 
   */
  getTrendThreeDaysList(params): Observable<any> {
    const path = `${ENV.rpm_baseurl}${ApiUrls.RPM_SERVICE.rpmTrendThreeDays}`;
    return this.post(path, params).pipe(
      map(
        data => data,
        err => err
      ));
  }

  /**
   * @description To save new icd code to data base
   */
   updateNewICD(params): Observable<any> {   
    const path = `${ENV.admin_baseurl}${ApiUrls.ADMIN_SERVCE.create_icd}`;
    return this.post(path, params).pipe(
      map(
        data => data,
        err => err
      ));
  }



  /**
   * -------------------------------------------------------------------- 
   * RPM related methods end here
   * --------------------------------------------------------------------
   */
  /**
   * -------------------------------------------------------------------- 
   * MongoDB Charts in Analytics Dashboard starts here
   * --------------------------------------------------------------------
   */

   mongoChartJWT(): Observable<any> {   
    const path = `${ENV.admin_baseurl}${ApiUrls.ADMIN_SERVCE.mongo_chart_jwt}`;
    return this.get(path, {}).pipe(
      map(
        data => data,
        err => err
      ));
  }


  /**
   * -------------------------------------------------------------------- 
   * MongoDB Charts in Analytics Dashboard ends here
   * --------------------------------------------------------------------
   */


}
