import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResetpasswordGuard implements CanActivate {
  constructor(
    public navCtrl: NavController
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let url = state.url;
      if(url.includes('error=')){ // if error on the paramter thne redirect to the login and can activate is false
        this.navCtrl.navigateRoot('login');
        return false;
      } else if(!url.includes('code=')){
        return false;
      }
    return true;
  }
  
}
