import { Injectable } from '@angular/core';
import { Route, UrlSegment, CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PatientService } from 'src/app/modules/components/patient/patient.service';
import { UtilsService } from 'src/app/shared/common';
import { StorageService } from '../storage.service';
export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root'
})

export class DirtyGuard implements CanDeactivate<CanComponentDeactivate> {
  constructor(public patientService: PatientService, public storageService: StorageService,
    public utilService: UtilsService) { }
  canDeactivate(component: CanComponentDeactivate,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    const isExpired = this.storageService.getStorageItem(sessionStorage, 'userData', true) ?
      this.storageService.getStorageItem(sessionStorage, 'userData', true).sessionExpired : true;
    if (isExpired) {
      this.utilService.resetIsDirty();
    }
    return isExpired ? true : (!this.patientService.isDirty.status);
  }
}
