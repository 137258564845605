import { Component, SimpleChanges, OnChanges, Input, OnDestroy } from '@angular/core';
import { UtilsService } from 'src/app/shared/common';
import { ModalController } from '@ionic/angular';
import { PatientService } from '../../patient.service';
import { Constants } from 'src/app/shared/constants';
import { DocumentService } from 'src/app/core/services/document.service';
import { DocumentModalPage } from 'src/app/modules/modals/document-modal/document-modal.page';
import { EcgModalPage } from 'src/app/modules/modals/ecg-modal/ecg-modal.page';

import * as _ from 'lodash';
import { AuthenticationService } from 'src/app/core';
import { ECGModel } from 'src/app/core/models/ecg';
import { ConfigurationService } from 'src/app/core/services/configuration/configuration.service';
import { TimerService } from 'src/app/core/services/timer/timer.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { RemoveNewlinePipe } from 'src/app/shared/pipes/remove-newline/remove-newline.pipe';
import { AmChartsService } from '@amcharts/amcharts3-angular';

@Component({
  selector: 'app-ecg-graph',
  templateUrl: './ecg-graph.component.html',
  styleUrls: ['./ecg-graph.component.scss'],
})
export class EcgGraphComponent implements OnChanges, OnDestroy {

  @Input() patientData;
  @Input() ecgLocation;

  ctx = [];
  canvas = [];
  canvasWidth = [];
  canvasHeight = [0];

  prevY = [];
  currX = [0];
  prevX = [0];
  speed = 0.1;
  dataIndex = [0];
  scanBarWidth = 1;
  gridLineWidth = 1;
  gridLineColor = '#152835';
  gridDuration = 45;
  currY = [this.canvasHeight[0] / 2];
  ecgReport: any = [];
  ecgData: any[] = [];

  minData = [];
  maxData = [];
  canWidth= [600]; // pixels
  ecgFirstTime = true;

  private patientId: string;
  private visit_number: string;
  graphText = '';
  documentType = Constants.CARDIOLOGY_DOCUMENT_TYPE;
  isPopupClicked: boolean;
  documentPageModal: HTMLIonModalElement;
  public ecgPageModal: any;
  permission: any;
  syncFrequencySubscription: Subscription;
  discardConsentSubscription: Subscription;
  permissionCheckType = Constants.PERMISSION_CHECK_TYPE;
 
  ecgTooltip = [''];
  ecgToggle = false;
  permission_ecg = {
    feature_name: Constants.PERMISSION_FEAUTRE_NAME.ECG,
    action: Constants.PERMISSION_ACTION_TEXT.read
  };
  medicardiaIdCheck: boolean;
  isRpm = this.patientService.workflow == Constants.WORKFLOW.RPM ? true: false;
  isRPMOnlyMode  = false;

  ecgChartData = [];
  ecgChartType = Constants.ECG_CHART_TYPES.CANVAS;
  ecgChartMinMax = Constants.ECG_CHART_MIN_MAX;

  ecgNewMin = this.ecgChartMinMax.MIN;
  ecgNewMax = this.ecgChartMinMax.MAX;

  ecgArray = [];
  timezone: string;

  constructor(
    public patientService: PatientService,
    public authService: AuthenticationService,
    public documentService: DocumentService,
    public utilsService: UtilsService,
    public route: ActivatedRoute,
    private configurationService: ConfigurationService,
    private timerService: TimerService,
    private modalCtrl: ModalController,
    private removeNewLinePipe: RemoveNewlinePipe,
    private AmCharts: AmChartsService,) {
    this.isPopupClicked = false;
    this.subscribeChanges();
    this.isRPMOnlyMode = this.authService.checkFeaturePermission(Constants.PERMISSION_CHECK_TYPE.RPM_STANDALONE);
    this.ecgFirstTime = true;
    this.timezone = this.configurationService.getConfigurationDetails().hospitalTimezone;

    this.gridLineColor = this.utilsService.themeKey == 'light'? '#cccccc' : this.gridLineColor;
  }

  async ngOnChanges(changes: SimpleChanges) { 
    /* this.ecgNewMin = this.ecgChartMinMax.MIN;
    this.ecgNewMax = this.ecgChartMinMax.MAX; */
    const PATIENTDATA = 'patientData';
    for (const propName in changes) {
      if (propName) {
        const change = changes[propName];
        const curVal = change.currentValue;
        const preVal = change.previousValue;
        const strCurVal = JSON.stringify(curVal);
        const strPrevVal = JSON.stringify(preVal);
        if (strCurVal !== strPrevVal && strCurVal) {
          switch (propName) {
            case PATIENTDATA: 
              this.patientId = curVal['patientInfo'] ? curVal['patientInfo']['medicardia_id'] : undefined;
              this.permission = this.getPermission();
              /** As per the discussion with Leya on 28-Jan-2022 'f8a857c1-118a-49b1-b4bc-b495b12b6f3b is added using or for demo purpose */
              /* this.medicardiaIdCheck = this.patientId === '142128c9-6fd1-4467-9d2e-03fd80321cb8' 
              || this.patientId === 'f8a857c1-118a-49b1-b4bc-b495b12b6f3b'
              || this.patientId === 'e73434a4-8e4c-4031-b778-b4b3978fb8e3'; */
              this.medicardiaIdCheck = true; // always make this true. We can remove this check from UI later
              if(this.ecgLocation === 'header'){
                let ecgData = {};
                ecgData['ecgReport'] = this.utilsService.deepCopy(curVal.ecgReport);
                ecgData['ecgReport']['ECG'] = curVal.ecgReport && curVal.ecgReport.ECG && curVal.ecgReport.ECG[0] && curVal.ecgReport.ECG[0].data ? 
                curVal.ecgReport.ECG[0].data:[];
                ecgData['ecgReport']['leadName'] = curVal.ecgReport && curVal.ecgReport.ECG && curVal.ecgReport.ECG[0] && curVal.ecgReport.ECG[0].lead_name ? 
                curVal.ecgReport.ECG[0].lead_name:'';
                this.updatePatientData(ecgData);
              }else{
                this.ecgArray = curVal.ecgReport && curVal.ecgReport.ECG && curVal.ecgReport.ECG.length? curVal.ecgReport.ECG:[];
                for(let i=0; i < this.ecgArray.length; i++){
                  let ecgData = {};
                  ecgData['ecgReport'] = this.utilsService.deepCopy(curVal.ecgReport);
                  ecgData['ecgReport']['ECG'] = this.ecgArray[i] && this.ecgArray[i].data ? this.ecgArray[i].data:[];
                  ecgData['ecgReport']['leadName'] = this.ecgArray[i] && this.ecgArray[i].lead_name ? this.ecgArray[i].lead_name:'';
                  this.updatePatientData(ecgData, i);
                  await this.delay(500);
                }
                
              }              
              break;
          }
        }
      }
    } 
}

  subscribeChanges() {
    this.syncFrequencySubscription = this.timerService.syncFrequency.subscribe(res => {
      if (res) {
        this.getECGUpdate();
      }
    });

    this.discardConsentSubscription = this.patientService.discardConsent.subscribe(res => {
      if (res && res.status == true) {
        if (res.component == 'ecg') {
          this.ecgPageModal.dismiss();
        }
      }
    });
  }

  getPermission() {
    return {
      feature_name: Constants.PERMISSION_FEAUTRE_NAME.ECG,
      action: Constants.PERMISSION_ACTION_TEXT.write
    }
  }

  updatePatientData(data, i = 0) {
    if (data) {
      //console.log(this.patientData)
      this.ecgReport[i] = data['ecgReport'] ? data['ecgReport'] : {};
      // this.visit_number = data['patientInfo'] ? data['patientInfo']['visit_number'] : undefined;
      if (!this.utilsService.isEmpty(this.ecgReport[i])) {
        this.getEcgFullDataTooltip(i);
      }
      this.ecgReport[i].rhythmKey = this.utilsService.getRhythm(this.ecgReport[i]);
      /* this.ecgData = this.patientData && this.patientData.ecgReport && this.patientData.ecgReport.ECG ?
        this.patientData.ecgReport.ECG : []; */
      this.ecgData = this.ecgReport[i].ECG ? this.ecgReport[i].ECG : [];
      if(this.ecgData.length && this.ecgData[0] && this.ecgData[0].data){
        this.ecgData = this.ecgData[0].data;
      }
      if (this.ecgData && this.ecgData.length) {
        if(this.ecgChartType === 'canvas'){
          if(this.ecgLocation !== 'header'){
            this.ecgNewMin = this.ecgChartMinMax.MIN * 2; // double the size
            this.ecgNewMax = this.ecgChartMinMax.MAX * 2; // double the size
            this.speed = 0.2;
            this.gridDuration = 90;
          }

          this.minData[i] = Math.min.apply(Math, this.ecgData);
          this.maxData[i] = Math.max.apply(Math, this.ecgData);
          this.canWidth[i] = this.ecgData.length * this.speed + 1;
          this.initECGGraph(i);
        } else{
          this.initECGAmChart();
        }        
      }else{
        this.ecgToggle = true;
      }
      this.graphText = this.ecgReport && this.ecgReport[i] && this.ecgReport[i].leadName ?
        `${this.ecgReport[i].leadName}` : '';
    }

  }

  initECGAmChart() {
    this.ecgChartData = this.createECGChartdata();
    const id = 'ecg-chart';
    this.AmCharts.makeChart(id, {
      "type": "serial",
      "theme": 'none',
      "dataProvider": this.ecgChartData,
      "autoMargins": true,
      "autoMarginOffset": 0,
      "marginBottom": 0,
      "marginLeft": 2,
      "marginTop": 0,
      "marginRight": 2,
      "graphs": [{
        "type": "line",
        "valueField": "value",
        "labelsEnabled": false,
        "lineColor": "#00ff00",
      }],
      "categoryField": "ecg_count",
      "categoryAxis": {
        "axisColor": "#041420",
        "labelsEnabled": false,
        "minorGridEnabled": false,
        "gridEnabled": false,
        "autoGridCount": false,
        "gridAlpha": 0,
        "gridThickness": 0,
      },
      "valueAxes": [{
        "axisColor": "#041420",
        "includeAllValues": true,
        "autoGridCount": true,
        "labelsEnabled": false,
        "gridEnabled": false,
        "inside": false,
        "gridThickness": 0,
        "gridAlpha": 0,
        "ignoreAxisWidth": true,
      }]
    });
  } 

  createECGChartdata(){
    let dataArr = [];
    for(let i=0;i<this.ecgData.length; i++){
      let obj = {
        ecg_count: i + 1,
        value: this.ecgData[i]
      }
      dataArr.push(obj);
    }
    return dataArr;
  }
  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  async initECGGraph(i = 0) {
    if(this.ecgLocation === 'header'){
      this.canvas[i] = document.getElementById('ecg');
    }else{
      await this.delay(200);
      this.canvas[i] = document.getElementById('ecgbody_'+i);
    }    
    this.ctx[i] = this.canvas[i].getContext('2d');
    this.ctx[i].canvas.width = this.canWidth[i];
    this.canvasWidth[i] = this.canWidth[i];
    this.canvasHeight[i] = this.canvas[i].height;
    if(this.ecgFirstTime){
      await this.delay(500);
      this.ecgFirstTime = false;
    }
    let color = '#00ff00';
    let lineWidth = 1;
    if(this.utilsService.themeKey == 'light'){
      color = '#008906';
      lineWidth = 2;
    }
    
    // #ffb600
    this.prevY[i] = this.currY[i];
    this.ctx[i].strokeStyle = color;
    this.ctx[i].lineWidth = lineWidth;
    this.ctx[i].setTransform(1, 0, 0, -1, 0, this.canvasHeight[i]);
    //this.drawGrid(0, 0, this.canvasWidth, this.canvasHeight); // As per the discussion on 18 Feb 2022,this grid can be hidden

    /* Uncomment for animation
    this.drawECGWave();*/
     /* setInterval(()=>{
      this.drawECGWave();
    }, 1); */

    /**
     * For static display of ECG. If animation required, comment the following
    */ 
    this.dataIndex[i] = 0;
    this.prevY[i] = 0;
    this.currX[i] = 0;
    this.prevX[i] = 0;
    this.currY[i] = 0;
    this.ctx[i].clearRect(this.currX, 0, this.scanBarWidth, this.canvasHeight[i]);//await this.delay(2000);
    //this.drawGrid(this.currX, 0, this.currX + this.scanBarWidth, this.canvasHeight); // As per the discussion on 18 Feb 2022,this grid can be hidden
    for (let ind = this.dataIndex[i]; this.dataIndex[i] < this.ecgData.length; ind = ++this.dataIndex[i]) {
      this.drawECGWave(i);
    }
  }

  /**
   * Function to convert the value to a range
   * @param value value to convert
   * @param r1 value min and max array
   * @param r2 new range min and Max array
   * @returns new vale
   */
  convertRange(value, r1, r2) {
    return (value - r1[0]) * (r2[1] - r2[0]) / (r1[1] - r1[0]) + r2[0];
  }

  drawECGWave(i=0) {
    //this.currX = _.round((this.currX + this.speed), 1);  // multiply with 3 for speed increase. Only for POC purpose
    this.currX[i] = this.currX[i] + this.speed;
    this.ctx[i].clearRect(this.currX[i], 0, this.scanBarWidth, this.canvasHeight[i]);
    if(_.round(this.currX[i])% this.gridDuration == 0) {
      this.drawGrid(this.currX[i], 0, this.currX[i] + this.scanBarWidth, this.canvasHeight[i], i); // As per the discussion on 18 Feb 2022,this grid can be hidden
    }
    let color = '#00ff00';
    let lineWidth = 1;
    if(this.utilsService.themeKey == 'light'){
      color = '#008906'; //#ba3902
      lineWidth = 2;
    }
    this.ctx[i].strokeStyle = color;
    this.ctx[i].beginPath();
    this.ctx[i].lineWidth = lineWidth;
    this.ctx[i].moveTo(this.prevX[i], this.prevY[i]);
    this.ctx[i].lineJoin = 'round';
    /* Uncomment for animation
      if ((this.dataIndex+8) >= this.ecgData.length) {  // Add 8 for speed increase. Only for POC purpose
        this.dataIndex = 0;
      } else {
        this.dataIndex++;
        this.dataIndex = this.dataIndex+8; // Add 8 for speed increase. Only for POC purpose
      }
    */

    /** ----------------------------------------------------------------------
      * For static display of ECG. If animation required, comment the following
    */  
    //this.dataIndex++;
    /** ----------------------------------------------------------------------
      * End
      */

    //this.currY = (this.ecgData[this.dataIndex] / 200 + 35);
    let tempY = [];
    tempY[i] = this.ecgData[this.dataIndex[i]];
    this.currY[i] = this.convertRange(tempY[i], [this.minData[i], this.maxData[i]], [this.ecgNewMin, this.ecgNewMax]);
    this.ctx[i].lineTo(this.currX[i], this.currY[i]);
    this.ctx[i].stroke();
    this.prevX[i] = this.currX[i];
    this.prevY[i] = this.currY[i];

    // Commenting below as no need to draw in loop
    //if (this.prevX > this.canvasWidth) { this.currX = this.prevX = -this.speed; } // multiply with 3 for speed increase. Only for POC purpose

    /* Uncomment for animation
      
    requestAnimationFrame(this.drawECGWave.bind(this));
    */ 
    
  }

  /**
   * @description To draw the grids on the canvas.
   * @param x1
   */
  drawGrid = function (x1, y1, x2, y2, i) {

    this.ctx[i].strokeStyle = this.gridLineColor;

    /* for (let x = x1; x <= x2; x += this.gridLineWidth) {
      if (x1 % this.gridLineWidth === 0) {
        this.ctx.beginPath();
        this.ctx.lineWidth = 1;
        this.ctx.moveTo(x, y1);
        this.ctx.lineTo(x, y2);
        this.ctx.stroke();
      }
    } */

    for (let y = y1; y <= y2; y += this.gridLineWidth) {
      this.ctx[i].beginPath();
      this.ctx[i].lineWidth = 0.5;
      this.ctx[i].moveTo(x1, y);
      this.ctx[i].lineTo(x2, y);
      this.ctx[i].stroke();
    }
  };

  /**
   * @description To get the parameters for the popup modal
   * @param modal
   */
  getParams() {
    const params: any = {
      patientId: this.patientId,
      ecgReport: this.ecgReport,
      visit_number: this.visit_number
    };
    return params;
  }

  async openEcgModal() {
    this.isPopupClicked = true;
    const params = this.getParams();
    this.ecgPageModal = await this.modalCtrl.create({
      component: EcgModalPage,
      componentProps: params, cssClass: 'echo-page',
      backdropDismiss: false
    });
    this.ecgPageModal.onDidDismiss().then(data => {
      this.isPopupClicked = false;
      if (data && !this.utilsService.isEmpty(data.data)) {
        this.ecgReport = data.data['ecg_data'];
        this.ecgReport.rhythmKey = this.utilsService.getRhythm(this.ecgReport);
        this.patientService.updateECG(this.ecgReport);
      }
    });
    this.ecgPageModal.present();
  }
  openCardioEdit(type) {
    this.patientService.setCardioAddEdit(type);
  }
  getECGUpdate() {
    const params = {
      id: this.patientId,
      report_type: 'ecg'
    };
    this.patientService.getECGReport(params).subscribe(res => {
      if (res.is_success) {
        let ecgData = res.data && res.data.data ? res.data.data : undefined;
        const configurationData = this.configurationService.getConfigurationDetails();
        this.patientData['ecgReport'] = { ...ECGModel.create({ ecg: ecgData }, configurationData.hospitalTimezone) };
        this.patientData['ecgReport']['leadName'] = ecgData && ecgData.ecg_data && ecgData.ecg_data.ecg && ecgData.ecg_data.ecg[0]  && ecgData.ecg_data.ecg[0].lead_name ? 
        ecgData.ecg_data.ecg[0].lead_name:'';
        // TODO: dummy data for the below medicardia ID's. We need to remove this later. once advanced NLP is in production
        if((this.patientId === '142128c9-6fd1-4467-9d2e-03fd80321cb8' 
              || this.patientId === 'f8a857c1-118a-49b1-b4bc-b495b12b6f3b'
              || this.patientId === '3813b556-fb12-4420-92a4-953bcb80ef69') 
              && this.patientData['ecgReport']['ECG'].length !== undefined && this.patientData['ecgReport']['ECG'].length == 0){
                this.patientData['ecgReport']['ECG'] = Constants.ECG_GRAPH_DATA;
              }
        this.updatePatientData(this.patientData);
        this.patientService.updateECG(this.ecgReport[0]);
      }
    });
  }

  getPdfDocument(docType) {
    if (this.authService.hasPermission(this.permission)) {
      this.patientService.setReviewData('diagnostics', true);
    }
    this.patientService.setReviewStatus(docType);
    const params = { type: docType };
    // this.documentService.getDocumentUrl(params).subscribe(
    //   res => {
    //     if (!this.utilsService.isEmpty(res)) {
    //       this.openDocumentModal(res);
    //     }
    //   }
    // );
  }

  async openDocumentModal(docType) {
    const params = {
      type: docType,
      patientId: this.patientId
    };
    this.documentService.isPopupClicked = true;
    this.documentPageModal = await this.modalCtrl.create({
      component: DocumentModalPage,
      componentProps: params, cssClass: 'document-page'
    });
    this.documentPageModal.onDidDismiss().then(res => {
      this.documentService.isPopupClicked = false;
      if (res.data) {
        this.getPdfDocument(params.type);
      }
    });
    return await this.documentPageModal.present();
  }

  getEcgFullDataTooltip(i=0) {
    let ecgTooltip = '';
    ecgTooltip = this.ecgReport[i].QRS ? `${ecgTooltip}QRS: ${this.ecgReport[i].QRS}` : ecgTooltip;
    ecgTooltip = this.ecgReport[i].Rate ? `${ecgTooltip}\nRate: ${this.ecgReport[i].Rate}` : ecgTooltip;
    ecgTooltip = this.ecgReport[i].notes ? `${ecgTooltip}\nNotes: ${this.ecgReport[i].notes}` : ecgTooltip;
    ecgTooltip = this.ecgReport[i].QT ? `${ecgTooltip}\nQT: ${this.ecgReport[i].QT}` : ecgTooltip;
    ecgTooltip = this.ecgReport[i].QTc ? `${ecgTooltip}\nQTc: ${this.ecgReport[i].QTc}` : ecgTooltip;
    ecgTooltip = this.ecgReport[i].rhythm ? `${ecgTooltip}\nRhythm: ${this.ecgReport[i].rhythm}` : ecgTooltip;
    ecgTooltip = this.ecgReport[i].pr ? `${ecgTooltip}\nPR: ${this.ecgReport[i].pr}` : ecgTooltip;
    ecgTooltip = this.ecgReport[i].qrsd ? `${ecgTooltip}\nQRSd: ${this.ecgReport[i].qrsd}` : ecgTooltip;
    ecgTooltip = this.ecgReport[i].p ? `${ecgTooltip}\nP: ${this.ecgReport[i].p}` : ecgTooltip;
    ecgTooltip = this.ecgReport[i].t ? `${ecgTooltip}\nT: ${this.ecgReport[i].t}` : ecgTooltip;
    ecgTooltip = this.ecgReport[i].summary ? `${ecgTooltip}\nSummary: ${this.removeNewLinePipe.transform(this.ecgReport[i].summary)}` : ecgTooltip;
    this.ecgTooltip[i] = ecgTooltip;
  }

  removeLineBreaks(text){  
    if(text && text.length)  {
      return this.removeNewLinePipe.transform(text);
    }else{
      return "";
    } 
  }

  toggleECGView(){
    this.ecgToggle = !this.ecgToggle;
  }

 
  ngOnDestroy() {
    if (this.documentPageModal) {
      this.documentPageModal.dismiss();
    }
    if (this.ecgPageModal) {
      this.ecgPageModal.dismiss();
    }
    if (this.syncFrequencySubscription) {
      this.syncFrequencySubscription.unsubscribe();
    }
  }

}
