import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatientMetaDataComponent } from './patient-meta-data.component';
import { EcgGraphModule } from '../ecg-graph/ecg-graph.module';
import { DemographicDataModalPage } from 'src/app/modules/modals/demographic-data-modal/demographic-data-modal.page';
import { DemographicDataModalPageModule } from 'src/app/modules/modals/demographic-data-modal/demographic-data-modal.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';

@NgModule({
  declarations: [
    PatientMetaDataComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    // EcgGraphModule,
    NgbModule,
    DemographicDataModalPageModule
  ],
  exports: [
    PatientMetaDataComponent
  ],
  entryComponents: [
    DemographicDataModalPage
  ]
})
export class PatientMetaDataModule { }
