import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appPhoneNumberCountryCode]'
})
export class PhoneNumberDirective {
  @Input() inputContentType;

  constructor(private el: ElementRef, private control: NgControl,
  ) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this.el.nativeElement.value;

    if (Number(this.inputContentType) === 2) {
      this.el.nativeElement.value = initalValue ? initalValue.replace(/[^.+0-9 ]/gi, '') : '';

    } else {
      this.el.nativeElement.value = initalValue.replace(/[^+0-9]*/g, '');
    }
    if (initalValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
  }

}
