import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Event, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core';
import { ENV } from 'src/environments/environment';
import { ApiUrls } from 'src/app/shared/api-urls';
import { StorageService } from 'src/app/core/services/authentication/storage.service';
import { DataTransferService } from 'src/app/core/services/data-transfer/data-transfer.service';
import { map } from 'rxjs/operators';
import { Constants } from 'src/app/shared/constants';

@Injectable({
  providedIn: 'root'
})
export class CiedService extends ApiService {

  public addUpdateTemplateStatus: EventEmitter<string> = new EventEmitter();
  public selectedCiedTransmission: any;
  public addUpdateNoteStatus: EventEmitter<string> = new EventEmitter();
  public editNoteStatus: EventEmitter<string> = new EventEmitter();
  public reportsListStatus: EventEmitter<string> = new EventEmitter();
  public addPatientBtnStatus: EventEmitter<string> = new EventEmitter();
  public isCiedPatientDetailsPage = false;
  public selectedPatientDetails:any;
  public selectedTab: string;
  public transmissionStatus: EventEmitter<string> = new EventEmitter();
  public dashboardSelectedProvider: any;
  public patientSelectedProvider: any;
  public selectedDocForRead: any;
  public savePatientBtnStatus: EventEmitter<string> = new EventEmitter();
  public emitSuccessfullPatientSave: EventEmitter<string> = new EventEmitter();
  public viewSummaryBtnClickStatus: EventEmitter<string> = new EventEmitter();
  public reloadPatientList: EventEmitter<string> = new EventEmitter();
  public dashboardSelectedFilter: any;
  public dashboardSelectedStatus: any;
  public dashboardSelectedSearch: any;
  public showSummaryHTMLTemplate: EventEmitter<string> = new EventEmitter();
  public summaryStatus: EventEmitter<string> = new EventEmitter();
  alertPriority = Constants.CIED_ALERT_PRIORITY;
  constructor(public http: HttpClient, public storageService: StorageService,
    public dataTransferService: DataTransferService, public router: Router) {
    super(http, storageService);

  }
  /**
* To get all transmission list
* @param params 
* @returns 
*/
  getTransmissionList(params): Observable<any> {
    const path = `${ENV.idc_baseurl}${ApiUrls.CIED_SERVICE.ciedTransmission}`;
    return this.get(path, params).pipe(
      map(
        data => data,
        err => err
      ));
  }

  getCIEDPatientList(params): Observable<any> {
    const path = `${ENV.idc_baseurl}${ApiUrls.CIED_SERVICE.patientListing}`;
    return this.get(path, params).pipe(
      map(
        data => data,
        err => err
      ));
  }

  addUpdateTemplate(params): Observable<any> {
      const path = `${ENV.idc_baseurl}${ApiUrls.CIED_SERVICE.addUpdateTemplate}`;
      return this.post(path, params).pipe(
        map(
          data => data,
          err => err
        ));
    }

    
  getTemplateList(params): Observable<any> {
    const path = `${ENV.idc_baseurl}${ApiUrls.CIED_SERVICE.listTemplates}`;
    return this.get(path, params).pipe(
      map(
        data => data,
        err => err
      ));
  }

  public emitAddUpdateTemplateEvent(params?) {
    this.addUpdateTemplateStatus.emit(params);
  }

  public getAddUpdateTemplateEventEmitter() {
    return this.addUpdateTemplateStatus;
  }

  addUpdateNotes(params): Observable<any> {
    const path = `${ENV.idc_baseurl}${ApiUrls.CIED_SERVICE.addUpdateNotes}`;
    return this.post(path, params).pipe(
      map(
        data => data,
        err => err
      ));
  }

  getNotesList(params): Observable<any> {
    const path = `${ENV.idc_baseurl}${ApiUrls.CIED_SERVICE.listNotes}`;
    return this.get(path, params).pipe(
      map(
        data => data,
        err => err
      ));
  }

  public emitAddUpdateNotesEvent(params?) {
    this.addUpdateNoteStatus.emit(params);
  }

  public getAddUpdateNotesEventEmitter() {
    return this.addUpdateNoteStatus;
  }

  public emitEditNotesEvent(params?) {
    this.editNoteStatus.emit(params);
  }

  public getEditNotesEventEmitter() {
    return this.editNoteStatus;
  }

  public emitAddPatientClickEvent(params?) {
    this.addPatientBtnStatus.emit(params);
  }

  public getAddPatientClickEventEmitter() {
    return this.addPatientBtnStatus;
  }

  /**
   * To assign patient and physician to a transmission
   * @param params 
   * @returns 
   */
  updateTransmission(params): Observable<any> {
    const path = `${ENV.idc_baseurl}${ApiUrls.CIED_SERVICE.assignDevice}`;
    return this.post(path, params).pipe(
      map(
        data => data,
        err => err
      ));
  }

  /**
   * To get the all transmission of selected patient
   * @param params 
   * @returns 
   */
  getPatientTransmissions(params): Observable<any> {
    const path = `${ENV.idc_baseurl}${ApiUrls.CIED_SERVICE.patientTransmission}`;
    return this.get(path, params).pipe(
      map(
        data => data,
        err => err
      ));
  }

  /**
   * To get the details of selected transmission
   * @param params 
   * @returns 
   */
  getTransmissionData(params): Observable<any> {
    const path = `${ENV.idc_baseurl}${ApiUrls.CIED_SERVICE.transmissionData}`;
    return this.get(path, params).pipe(
      map(
        data => data,
        err => err
      ));
  }

  /**
   * To update Review Status
   * @param params 
   * @returns 
   */
   updateReviewStatus(params): Observable<any> {
    const path = `${ENV.idc_baseurl}${ApiUrls.CIED_SERVICE.updateReviewStatus}`;
    return this.post(path, params).pipe(
      map(
        data => data,
        err => err
      ));
  }

  public emitReportsListingEvent(params?) {
    this.reportsListStatus.emit(params);
  }

  public getReportsListingEventEmitter() {
    return this.reportsListStatus;
  }

  public emitSummaryEventEmitter(params?) {
    this.summaryStatus.emit(params);
  }

  public getSummaryEventEmitter() {
    return this.summaryStatus;
  }

  public emitTransmissionStatusEvent(params?) {
    this.transmissionStatus.emit(params);
  }

  public getTransmissionStatusEventEmitter() {
    return this.transmissionStatus;
  }

  
  // getUserDetails(params): Observable<any> {
  //   const path = `${ENV.appointment_baseurl}${ApiUrls.APPOINTMENT_SERVICE.providerListing}`;
  //   return this.get(path, params).pipe(
  //     map(
  //       data => data,
  //       err => err
  //     ));
  // }

  public emitSavePatientClickEvent(params?) {
    this.savePatientBtnStatus.emit(params);
  }

  public getSavePatientClickEventEmitter() {
    return this.savePatientBtnStatus;
  }
  public emitSuccessfullPatientSaveEvent(params?) {
    this.emitSuccessfullPatientSave.emit(params);
  }

  public getSuccessfullPatientSaveEmitter() {
    return this.emitSuccessfullPatientSave;
  }

  /**
   * Function to get the transmission history of the device
   * @param params 
   * @returns 
   */
  getTransmissionHistory(params): Observable<any> {
    const path = `${ENV.idc_baseurl}${ApiUrls.CIED_SERVICE.getTransmissionHistory}`;
    return this.get(path, params).pipe(
      map(
        data => data,
        err => err
      ));
  }

  public emitViewSummaryBtnClickEvent(params?) {
    this.viewSummaryBtnClickStatus.emit(params);
  }

  public getViewSummaryBtnClickEmitter() {
    return this.viewSummaryBtnClickStatus;
  }

  /**
   * Event status on reloadPatientList emit
   */
  public getreloadPatientListEvent(){
    return this.reloadPatientList;
  }

    /**
* To get all billing list
* @param params 
* @returns 
*/
getBillingHistory(params): Observable<any> {
  const path = `${ENV.idc_baseurl}${ApiUrls.CIED_SERVICE.billingHistory}`;
  return this.get(path, params).pipe(
    map(
      data => data,
      err => err
    ));
}

saveBill(params): Observable<any> {
  const path = `${ENV.idc_baseurl}${ApiUrls.CIED_SERVICE.saveBill}`;
  return this.post(path, params).pipe(
    map(
      data => data,
      err => err
    ));
}

  /**
* exportToCSV
* @param params 
* @returns 
*/
// csvUpload(params): Observable<any> {
  
//   const path = `${ENV.idc_baseurl}${ApiUrls.CIED_SERVICE.csvUpload}`;
//   return this.get(path, params).pipe(
//     map(
//       data => data,
//       err => err
//     ));
// }

public exportToCSV(params) {
  const apiUrl = `${ENV.idc_baseurl}${ApiUrls.CIED_SERVICE.csvExport}`;
  return this.get(apiUrl , params, 1, '', true);
}

public emitShowSummaryHTMLTemplate(params) {
  this.showSummaryHTMLTemplate.emit(params);
}

public getShowSummaryHTMLTemplateEmitter() {
  return this.showSummaryHTMLTemplate;
}

}
