import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { LoginPageRoutingModule } from './login-routing.module';
import { LoginPage } from './login.page';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { ForgotPasswordPopupPage } from '../../modals/forgot-password-popup/forgot-password-popup.page';
import { ForgotPasswordPopupPageModule } from '../../modals/forgot-password-popup/forgot-password-popup.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    DirectivesModule,
    LoginPageRoutingModule,
    ForgotPasswordPopupPageModule
  ],
  declarations: [LoginPage],
  entryComponents: [
    ForgotPasswordPopupPage
  ]
})
export class LoginPageModule {}
