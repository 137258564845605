import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone',
})
export class PhoneNumberPipe implements PipeTransform {

  transform(tel) {
    if (!tel || tel.length === 0) { return ''; }

    let value: any = tel[0] === '+' ? tel.toString().slice(1) : tel;

    const isFormatCorrect = ((/\([0-9]{3}\) [0-9]{3}-[0-9]{4}/g).test(value) === false
      && (/[0-9]{10}/).test(value) === false) ? false : true;

    if (tel.match(/[^0-9]/g) && !isFormatCorrect) {
      value = tel.toString().replace(/[^0-9]/g, '');
    }else{
      value = tel;
    }

    if (value.length >= 10) {

      let country: any;
      let city: string;
      let phoneNumber: string;

      switch (value.length) {
        case 10:
          country = 1;
          city = value.slice(0, 3);
          phoneNumber = value.slice(3);
          break;

        case 11:
          country = value[0];
          city = value.slice(1, 4);
          phoneNumber = value.slice(4);
          break;

        case 12:
          // country = value.slice(0, 3);
          // city = value.slice(3, 5);
          // phoneNumber = value.slice(5);

          // #56987-Issue with the Phone number in the Demographic profile page .
          // eg: value: +11234567890
          const code = value.slice(0, -10); // +1
          const phoneNo = value.slice(-10); // 1234567890
          country = code; // +1
          city = phoneNo.slice(0, 3); // 123
          phoneNumber = phoneNo.slice(3); // 4567890
          break;

        default:
          const code1 = value.slice(0, -10); // +1
          const phoneNo1 = value.slice(-10); // 1234567890
          country = code1; // +1
          city = phoneNo1.slice(0, 3); // 123
          phoneNumber = phoneNo1.slice(3); // 4567890
          break;
          // return tel;
      }

      if (country === 1) {
        country = '';
      }

      phoneNumber = phoneNumber.slice(0, 3) + '-' + phoneNumber.slice(3);
      

      return (country + '(' + city + ')' + phoneNumber).trim(); 
    } else {
      return value;
    }

  }
}
