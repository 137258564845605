import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {

  transform(value: any, args: any, isSearch = false): any {
    if (!args) {
      return value;
    } else {
      if (typeof args === 'string') {
        args = args.trim();
      }
    }
    if (!isSearch) {
      const start_key = (args.entity_key && args.entity_key.start_offset) ? args.entity_key.start_offset : '';
      const end_key = (args.entity_key && args.entity_key.end_offset) ? args.entity_key.end_offset : '';
      const start_value = (args.entity_value && args.entity_value.start_offset) ? args.entity_value.start_offset : '';
      const end_value = (args.entity_value && args.entity_value.end_offset) ? args.entity_value.end_offset : '';
      if (start_value && end_value) {
        if (start_value > start_key && start_value > end_key && end_value > start_key && end_value > end_key) {
          return value.slice(0, start_key) + '<span class="highlight-text">' + value.slice(start_key, end_key) + '</span>'
            + value.slice(end_key, start_value) + '<span class="highlight-text"> ' + value.slice(start_value, end_value)
            + '</span>' + value.slice(end_value, (value.length));
        } else if (start_value < start_key && start_value < end_key && end_value < start_key && end_value < end_key) {
          return value.slice(0, start_value) + '<span class="highlight-text">' + value.slice(start_value, end_value) + '</span>'
            + value.slice(end_value, start_key) + '<span class="highlight-text"> ' + value.slice(start_key, end_key)
            + '</span>' + value.slice(end_key, (value.length));
        } else {
          let re;
          let result = value;
          if (args.entity_key && args.entity_key.entity_text) {
            let key = args.entity_key.replace(/[!@#$%^&*()+=\-[\]\\';,./{}|":<>?~_]/g, "\\$&");
            let re = new RegExp(key, 'gi');
            result = result.replace(re, '<span class="highlight-text">$&</span>');
          }
          if (args.entity_value && args.entity_value.entity_text) {
            let entity_value = args.entity_value.replace(/[!@#$%^&*()+=\-[\]\\';,./{}|":<>?~_]/g, "\\$&");
            re = new RegExp(entity_value, 'gi');
            return result.replace(re, '<span class="highlight-text">$&</span>');
          }
        }
      } else {
        return value.slice(0, start_key) + '<span class="highlight-text">' + value.slice(start_key, end_key) + '</span>'
          + value.slice(end_key, (value.length));
      }
    } else {
      args = args.replace(/[!@#$%^&*()+=\-[\]\\';,./{}|":<>?~_]/g, "\\$&");
      const re = new RegExp(args, 'gi');
      return value.replace(re, '<span class="highlight-text">$&</span>');
    }
  }
}
