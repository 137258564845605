import { Injectable } from '@angular/core';
import { Constants } from 'src/app/shared/constants';
import { ENV } from 'src/environments/environment';
import { ConfigurationInterface } from '../../models/configuration';
import { StorageService } from '../authentication/storage.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  private configurationDetails: ConfigurationInterface;
  isEHRAvailable: any;

  constructor(private storageService: StorageService) {
    this.getConfigurationFromStorage();
  }

  private getConfigurationFromStorage() {
    const userDetails = this.storageService.getStorageItem(sessionStorage, 'userData', true);
    this.configurationDetails = userDetails && userDetails.user && userDetails.user.configuration ?
      userDetails.user.configuration : undefined;
    this.setEHRConfig();
  }

  getConfigurationDetails() {
    if (this.configurationDetails) {
      this.getConfigurationFromStorage();
    }
    return this.configurationDetails;
  }

  setEHRConfig() {
    const userDetails = this.storageService.getStorageItem(sessionStorage, 'userData', true);
    //T0409: no need to take the feature category form the feature list. can take from the feature caegory list.
    /* 
    const categories = userDetails && userDetails.user && userDetails.user.categoryList ?
      userDetails.user.categoryList : undefined;
    const ehr = categories && categories.length ? categories.filter(res => res.id == 5) : []; 
    this.isEHRAvailable = ehr && ehr.length && ehr[0].isEnabled;*/
    const categories = userDetails && userDetails.user && userDetails.user.featureCategoryList ?
      userDetails.user.featureCategoryList : undefined;
    const ehr = categories && categories.length ? categories.filter(res => res.category_id == 5) : [];
    this.isEHRAvailable = ehr && ehr.length && ehr[0].is_enabled;
  }

  setConfigurationDetails(data) {
    data.isEHRAvailable = this.getIsEHRAvailable();
    this.configurationDetails = data;
  }

  getIsEHRAvailable() {
    return this.isEHRAvailable;
  }
}
